

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$String from "rescript/lib/es6/string.js";

function reverse(fn, a, b) {
  var match = Curry._2(fn, a, b);
  switch (match) {
    case /* LT */0 :
        return /* GT */2;
    case /* EQ */1 :
        return /* EQ */1;
    case /* GT */2 :
        return /* LT */0;
    
  }
}

function max(a1, a2, cmp) {
  var match = Curry._2(cmp, a1, a2);
  if (match !== 0) {
    return a1;
  } else {
    return a2;
  }
}

function string(s1, s2) {
  var match = $$String.compare(s1, s2);
  if ((match + 1 >>> 0) > 2) {
    throw {
          RE_EXN_ID: "Match_failure",
          _1: [
            "Ordering.res",
            23,
            2
          ],
          Error: new Error()
        };
  }
  return match + 1 | 0;
}

export {
  reverse ,
  max ,
  string ,
  
}
/* No side effect */
