

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../../util/Arr.bs.js";
import * as Int$RecountTools from "../../../util/Int.bs.js";
import * as Str$RecountTools from "../../../util/Str.bs.js";
import * as Xhr$RecountTools from "../../../util/Xhr.bs.js";
import * as Async$RecountTools from "../../../util/Async.bs.js";
import * as Modal$RecountTools from "../../../components/Modal.bs.js";
import * as Option$RecountTools from "../../../util/Option.bs.js";
import * as Request$RecountTools from "../../../util/Request.bs.js";
import * as Constants$RecountTools from "../../../util/Constants.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../../models/Models_Id.bs.js";
import * as ShowStore$RecountTools from "../../../stores/ShowStore.bs.js";
import * as VideoStore$RecountTools from "../../../stores/VideoStore.bs.js";
import * as ProseMirror$RecountTools from "../../../components/ProseMirror/ProseMirror.bs.js";
import * as Models_Video$RecountTools from "../../../models/Models_Video.bs.js";
import * as ToastProvider$RecountTools from "../../../components/ToastProvider.bs.js";

var progressHeading = Curry._1(Css.merge, {
      hd: "has-text-white has-text-centered",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize(Css.rem(1.6)),
              tl: {
                hd: Css.marginBottom(Css.rem(1)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  progressHeading: progressHeading
};

var validProjectId = /^[a-zA-Z0-9_]+$/;

var validSlug = /^[a-z0-9-]+$/;

var validators_rssMicrosoft = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.rssMicrosoft
            };
    })
};

var validators_rssVideoElephant = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.rssVideoElephant
            };
    })
};

var validators_rssYahoo = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.rssYahoo
            };
    })
};

var validators_pinned = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.pinned
            };
    })
};

var validators_seasonNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var season = input.seasonNumber;
      if (season === "") {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Option$RecountTools.mapWithDefault(Str$RecountTools.toInt(season), {
                    TAG: /* Error */1,
                    _0: "Season must be a number"
                  }, (function (a) {
                      return {
                              TAG: /* Ok */0,
                              _0: a
                            };
                    }));
      }
    })
};

var validators_episodeNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var episode = input.episodeNumber;
      if (episode === "") {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Option$RecountTools.mapWithDefault(Str$RecountTools.toInt(episode), {
                    TAG: /* Error */1,
                    _0: "Episode must be a number"
                  }, (function (a) {
                      return {
                              TAG: /* Ok */0,
                              _0: a
                            };
                    }));
      }
    })
};

var validators_thumbnailFile = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.thumbnailFile
            };
    })
};

var validators_videoFile = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.videoFile
            };
    })
};

var validators_sections = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.sections
            };
    })
};

var validators_show = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.show
            };
    })
};

var validators_tags = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.tags
            };
    })
};

var validators_publishedAt = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.publishedAt
            };
    })
};

var validators_article = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var body = input.article;
      if (body === undefined) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var body$1 = Caml_option.valFromOption(body);
      var plainText = ProseMirror$RecountTools.$$Document.toPlainText(body$1);
      if (plainText.length !== 0 && plainText.split(" ").length < 150) {
        return {
                TAG: /* Error */1,
                _0: "Article must be at least 150 words long"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: Caml_option.some(body$1)
              };
      }
    })
};

var validators_description = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var d = input.description;
      if (d === "") {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: d
              };
      }
    })
};

var validators_status = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      return {
              TAG: /* Ok */0,
              _0: input.status
            };
    })
};

var validators_slug = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var s = input.slug;
      if (s === "") {
        return {
                TAG: /* Error */1,
                _0: "Slug is required"
              };
      } else if (validSlug.test(s)) {
        return {
                TAG: /* Ok */0,
                _0: s
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Slug can only have lowercase letters, numbers, and dashes"
              };
      }
    }),
  validateAsync: (function (param) {
      var dispatch = param[2];
      var value = param[0];
      var validate = function (slug, metadata) {
        Request$RecountTools.ApiEnvelope({
              fromJson: Models_Video$RecountTools.fromJson,
              toJson: Models_Video$RecountTools.toJson
            });
        return Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                        TAG: /* Api */0,
                        _0: "/v2/videos/views/by-slug/" + slug
                      }), (function (req) {
                      if (req.status === 404) {
                        return Promise.resolve({
                                    TAG: /* Ok */0,
                                    _0: slug
                                  });
                      } else {
                        return Async$RecountTools.map(req.json(), (function (res) {
                                      var id = Json_decode.field("data", (function (param) {
                                              return Json_decode.field("id", Models_Id$RecountTools.fromJson, param);
                                            }), res);
                                      if (id === metadata.id) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: slug
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: "Slug must be unique"
                                              };
                                      }
                                    }));
                      }
                    }));
      };
      return Formality.Async.validateAsyncWithMetadata(value, param[1], validate, (function (res) {
                    return Curry._1(dispatch, {
                                TAG: /* ApplyAsyncResultForSlugField */18,
                                _0: value,
                                _1: res
                              });
                  }));
    }),
  eq: (function (prim0, prim1) {
      return prim0 === prim1;
    })
};

var validators_title = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var match = input.title;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Title is required"
              };
      } else if (input.show === Constants$RecountTools.recountWire && input.title.length > 120) {
        return {
                TAG: /* Error */1,
                _0: "Title must be maximum 120 characters"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.title
              };
      }
    })
};

var validators_projectId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input, param) {
      var s = input.projectId;
      if (s === "") {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else if (validProjectId.test(s)) {
        return {
                TAG: /* Ok */0,
                _0: Str$RecountTools.toUpperCase(s)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Project ID can only have letters, numbers, and underscores"
              };
      }
    })
};

var validators = {
  rssMicrosoft: validators_rssMicrosoft,
  rssVideoElephant: validators_rssVideoElephant,
  rssYahoo: validators_rssYahoo,
  pinned: validators_pinned,
  seasonNumber: validators_seasonNumber,
  episodeNumber: validators_episodeNumber,
  thumbnailFile: validators_thumbnailFile,
  videoFile: validators_videoFile,
  sections: validators_sections,
  show: validators_show,
  tags: validators_tags,
  publishedAt: validators_publishedAt,
  article: validators_article,
  description: validators_description,
  status: validators_status,
  slug: validators_slug,
  title: validators_title,
  projectId: validators_projectId
};

function initialFieldsStatuses(_input) {
  return {
          rssMicrosoft: /* Pristine */0,
          rssVideoElephant: /* Pristine */0,
          rssYahoo: /* Pristine */0,
          pinned: /* Pristine */0,
          seasonNumber: /* Pristine */0,
          episodeNumber: /* Pristine */0,
          thumbnailFile: /* Pristine */0,
          videoFile: /* Pristine */0,
          sections: /* Pristine */0,
          show: /* Pristine */0,
          tags: /* Pristine */0,
          publishedAt: /* Pristine */0,
          article: /* Pristine */0,
          description: /* Pristine */0,
          status: /* Pristine */0,
          slug: /* Pristine */0,
          title: /* Pristine */0,
          projectId: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.rssMicrosoft;
  var match_0 = match ? match._0 : Curry._2(validators.rssMicrosoft.validate, input, metadata);
  var match$1 = fieldsStatuses.rssVideoElephant;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.rssVideoElephant.validate, input, metadata);
  var match$2 = fieldsStatuses.rssYahoo;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.rssYahoo.validate, input, metadata);
  var match$3 = fieldsStatuses.pinned;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.pinned.validate, input, metadata);
  var match$4 = fieldsStatuses.seasonNumber;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.seasonNumber.validate, input, metadata);
  var match$5 = fieldsStatuses.episodeNumber;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.episodeNumber.validate, input, metadata);
  var match$6 = fieldsStatuses.thumbnailFile;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.thumbnailFile.validate, input, metadata);
  var match$7 = fieldsStatuses.videoFile;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.videoFile.validate, input, metadata);
  var match$8 = fieldsStatuses.sections;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.sections.validate, input, metadata);
  var match$9 = fieldsStatuses.show;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.show.validate, input, metadata);
  var match$10 = fieldsStatuses.tags;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.tags.validate, input, metadata);
  var match$11 = fieldsStatuses.publishedAt;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.publishedAt.validate, input, metadata);
  var match$12 = fieldsStatuses.article;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.article.validate, input, metadata);
  var match$13 = fieldsStatuses.description;
  var match_0$13 = match$13 ? match$13._0 : Curry._2(validators.description.validate, input, metadata);
  var match$14 = fieldsStatuses.status;
  var match_0$14 = match$14 ? match$14._0 : Curry._2(validators.status.validate, input, metadata);
  var value = fieldsStatuses.slug;
  var tmp;
  tmp = typeof value === "number" ? ({
        NAME: "Result",
        VAL: Curry._2(validators.slug.validate, input, metadata)
      }) : (
      value.TAG === /* Dirty */0 ? ({
            NAME: "Result",
            VAL: value._0
          }) : ({
            NAME: "Validating",
            VAL: value._0
          })
    );
  var match$15 = fieldsStatuses.title;
  var match_0$15 = match$15 ? match$15._0 : Curry._2(validators.title.validate, input, metadata);
  var match$16 = fieldsStatuses.projectId;
  var match_0$16 = match$16 ? match$16._0 : Curry._2(validators.projectId.validate, input, metadata);
  var rssMicrosoftResult = match_0;
  var exit = 0;
  var slugResult = tmp;
  if (typeof slugResult === "object") {
    if (slugResult.NAME === "Validating") {
      return {
              TAG: /* Validating */2,
              fieldsStatuses: {
                rssMicrosoft: /* Dirty */{
                  _0: rssMicrosoftResult,
                  _1: /* Shown */0
                },
                rssVideoElephant: /* Dirty */{
                  _0: match_0$1,
                  _1: /* Shown */0
                },
                rssYahoo: /* Dirty */{
                  _0: match_0$2,
                  _1: /* Shown */0
                },
                pinned: /* Dirty */{
                  _0: match_0$3,
                  _1: /* Shown */0
                },
                seasonNumber: /* Dirty */{
                  _0: match_0$4,
                  _1: /* Shown */0
                },
                episodeNumber: /* Dirty */{
                  _0: match_0$5,
                  _1: /* Shown */0
                },
                thumbnailFile: /* Dirty */{
                  _0: match_0$6,
                  _1: /* Shown */0
                },
                videoFile: /* Dirty */{
                  _0: match_0$7,
                  _1: /* Shown */0
                },
                sections: /* Dirty */{
                  _0: match_0$8,
                  _1: /* Shown */0
                },
                show: /* Dirty */{
                  _0: match_0$9,
                  _1: /* Shown */0
                },
                tags: /* Dirty */{
                  _0: match_0$10,
                  _1: /* Shown */0
                },
                publishedAt: /* Dirty */{
                  _0: match_0$11,
                  _1: /* Shown */0
                },
                article: /* Dirty */{
                  _0: match_0$12,
                  _1: /* Shown */0
                },
                description: /* Dirty */{
                  _0: match_0$13,
                  _1: /* Shown */0
                },
                status: /* Dirty */{
                  _0: match_0$14,
                  _1: /* Shown */0
                },
                slug: slugResult.NAME === "Result" ? ({
                      TAG: /* Dirty */0,
                      _0: slugResult.VAL,
                      _1: /* Shown */0
                    }) : ({
                      TAG: /* Validating */1,
                      _0: slugResult.VAL
                    }),
                title: /* Dirty */{
                  _0: match_0$15,
                  _1: /* Shown */0
                },
                projectId: /* Dirty */{
                  _0: match_0$16,
                  _1: /* Shown */0
                }
              },
              collectionsStatuses: undefined
            };
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2 && rssMicrosoftResult.TAG === /* Ok */0) {
    var rssVideoElephantResult = match_0$1;
    if (rssVideoElephantResult.TAG === /* Ok */0) {
      var rssYahooResult = match_0$2;
      if (rssYahooResult.TAG === /* Ok */0) {
        var pinnedResult = match_0$3;
        if (pinnedResult.TAG === /* Ok */0) {
          var seasonNumberResult = match_0$4;
          if (seasonNumberResult.TAG === /* Ok */0) {
            var episodeNumberResult = match_0$5;
            if (episodeNumberResult.TAG === /* Ok */0) {
              var thumbnailFileResult = match_0$6;
              if (thumbnailFileResult.TAG === /* Ok */0) {
                var videoFileResult = match_0$7;
                if (videoFileResult.TAG === /* Ok */0) {
                  var sectionsResult = match_0$8;
                  if (sectionsResult.TAG === /* Ok */0) {
                    var showResult = match_0$9;
                    if (showResult.TAG === /* Ok */0) {
                      var tagsResult = match_0$10;
                      if (tagsResult.TAG === /* Ok */0) {
                        var publishedAtResult = match_0$11;
                        if (publishedAtResult.TAG === /* Ok */0) {
                          var articleResult = match_0$12;
                          if (articleResult.TAG === /* Ok */0) {
                            var descriptionResult = match_0$13;
                            if (descriptionResult.TAG === /* Ok */0) {
                              var statusResult = match_0$14;
                              if (statusResult.TAG === /* Ok */0) {
                                var match$17 = tmp;
                                if (typeof match$17 === "object" && match$17.NAME === "Result") {
                                  var slugResult$1 = match$17.VAL;
                                  if (slugResult$1.TAG === /* Ok */0) {
                                    var projectIdResult = match_0$16;
                                    var titleResult = match_0$15;
                                    return {
                                            TAG: /* Valid */0,
                                            output: {
                                              projectId: projectIdResult._0,
                                              title: titleResult._0,
                                              slug: slugResult$1._0,
                                              status: statusResult._0,
                                              description: descriptionResult._0,
                                              article: articleResult._0,
                                              publishedAt: publishedAtResult._0,
                                              tags: tagsResult._0,
                                              show: showResult._0,
                                              sections: sectionsResult._0,
                                              videoFile: videoFileResult._0,
                                              thumbnailFile: thumbnailFileResult._0,
                                              episodeNumber: episodeNumberResult._0,
                                              seasonNumber: seasonNumberResult._0,
                                              pinned: pinnedResult._0,
                                              rssYahoo: rssYahooResult._0,
                                              rssVideoElephant: rssVideoElephantResult._0,
                                              rssMicrosoft: rssMicrosoftResult._0
                                            },
                                            fieldsStatuses: {
                                              rssMicrosoft: /* Dirty */{
                                                _0: rssMicrosoftResult,
                                                _1: /* Shown */0
                                              },
                                              rssVideoElephant: /* Dirty */{
                                                _0: rssVideoElephantResult,
                                                _1: /* Shown */0
                                              },
                                              rssYahoo: /* Dirty */{
                                                _0: rssYahooResult,
                                                _1: /* Shown */0
                                              },
                                              pinned: /* Dirty */{
                                                _0: pinnedResult,
                                                _1: /* Shown */0
                                              },
                                              seasonNumber: /* Dirty */{
                                                _0: seasonNumberResult,
                                                _1: /* Shown */0
                                              },
                                              episodeNumber: /* Dirty */{
                                                _0: episodeNumberResult,
                                                _1: /* Shown */0
                                              },
                                              thumbnailFile: /* Dirty */{
                                                _0: thumbnailFileResult,
                                                _1: /* Shown */0
                                              },
                                              videoFile: /* Dirty */{
                                                _0: videoFileResult,
                                                _1: /* Shown */0
                                              },
                                              sections: /* Dirty */{
                                                _0: sectionsResult,
                                                _1: /* Shown */0
                                              },
                                              show: /* Dirty */{
                                                _0: showResult,
                                                _1: /* Shown */0
                                              },
                                              tags: /* Dirty */{
                                                _0: tagsResult,
                                                _1: /* Shown */0
                                              },
                                              publishedAt: /* Dirty */{
                                                _0: publishedAtResult,
                                                _1: /* Shown */0
                                              },
                                              article: /* Dirty */{
                                                _0: articleResult,
                                                _1: /* Shown */0
                                              },
                                              description: /* Dirty */{
                                                _0: descriptionResult,
                                                _1: /* Shown */0
                                              },
                                              status: /* Dirty */{
                                                _0: statusResult,
                                                _1: /* Shown */0
                                              },
                                              slug: {
                                                TAG: /* Dirty */0,
                                                _0: slugResult$1,
                                                _1: /* Shown */0
                                              },
                                              title: /* Dirty */{
                                                _0: titleResult,
                                                _1: /* Shown */0
                                              },
                                              projectId: /* Dirty */{
                                                _0: projectIdResult,
                                                _1: /* Shown */0
                                              }
                                            },
                                            collectionsStatuses: undefined
                                          };
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          
        }
        
      }
      
    }
    
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            rssMicrosoft: /* Dirty */{
              _0: rssMicrosoftResult,
              _1: /* Shown */0
            },
            rssVideoElephant: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            rssYahoo: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            pinned: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            seasonNumber: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            episodeNumber: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            thumbnailFile: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            videoFile: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            sections: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            show: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            tags: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            publishedAt: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            article: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            description: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            status: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            slug: {
              TAG: /* Dirty */0,
              _0: tmp.VAL,
              _1: /* Shown */0
            },
            title: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            projectId: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurRssMicrosoftField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.rssMicrosoft, validators_rssMicrosoft, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: status,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRssVideoElephantField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.rssVideoElephant, validators_rssVideoElephant, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: status,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRssYahooField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.rssYahoo, validators_rssYahoo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: status,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPinnedField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.pinned, validators_pinned, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: status,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSeasonNumberField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.seasonNumber, validators_seasonNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: status,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEpisodeNumberField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.episodeNumber, validators_episodeNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: status,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurThumbnailFileField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.thumbnailFile, validators_thumbnailFile, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: status,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVideoFileField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.videoFile, validators_videoFile, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: status,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSectionsField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.sections, validators_sections, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: status,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.show, validators_show, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: status,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTagsField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.tags, validators_tags, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: status,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPublishedAtField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.publishedAt, validators_publishedAt, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: status,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurArticleField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.article, validators_article, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: status,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDescriptionField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.description, validators_description, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: status,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStatusField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.status, validators_status, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSlugField */15 :
                  var result$15 = Formality.Async.validateFieldOfStringTypeOnBlurWithMetadata(state.input, metadata, state.fieldsStatuses.slug, validators_slug, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: status,
                                  title: init.title,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$15 === undefined) {
                    return /* NoUpdate */0;
                  }
                  var value = result$15.slug;
                  if (typeof value !== "number" && value.TAG !== /* Dirty */0) {
                    var value$1 = value._0;
                    return {
                            TAG: /* UpdateWithSideEffects */1,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            },
                            _1: (function (param) {
                                return Curry._1(validators_slug.validateAsync, [
                                            value$1,
                                            metadata,
                                            param.dispatch
                                          ]);
                              })
                          };
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: result$15,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
                  break;
              case /* BlurTitleField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: status,
                                  projectId: init.projectId
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurProjectIdField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.projectId, validators_projectId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  rssMicrosoft: init.rssMicrosoft,
                                  rssVideoElephant: init.rssVideoElephant,
                                  rssYahoo: init.rssYahoo,
                                  pinned: init.pinned,
                                  seasonNumber: init.seasonNumber,
                                  episodeNumber: init.episodeNumber,
                                  thumbnailFile: init.thumbnailFile,
                                  videoFile: init.videoFile,
                                  sections: init.sections,
                                  show: init.show,
                                  tags: init.tags,
                                  publishedAt: init.publishedAt,
                                  article: init.article,
                                  description: init.description,
                                  status: init.status,
                                  slug: init.slug,
                                  title: init.title,
                                  projectId: status
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */18 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  switch (match$1.TAG | 0) {
                    case /* Valid */0 :
                        var output = match$1.output;
                        var error = state.formStatus;
                        var tmp;
                        tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                        return {
                                TAG: /* UpdateWithSideEffects */1,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: {
                                    TAG: /* Submitting */0,
                                    _0: tmp
                                  },
                                  submissionStatus: /* AttemptedToSubmit */1
                                },
                                _1: (function (param) {
                                    var dispatch = param.dispatch;
                                    return Curry._2(onSubmit, output, {
                                                notifyOnSuccess: (function (input) {
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetSubmittedStatus */19,
                                                                _0: input
                                                              });
                                                  }),
                                                notifyOnFailure: (function (error) {
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetSubmissionFailedStatus */20,
                                                                _0: error
                                                              });
                                                  }),
                                                reset: (function (param) {
                                                    return Curry._1(dispatch, /* Reset */21);
                                                  }),
                                                dismissSubmissionResult: (function (param) {
                                                    return Curry._1(dispatch, /* DismissSubmissionResult */20);
                                                  })
                                              });
                                  })
                              };
                    case /* Invalid */1 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: /* Editing */0,
                                  submissionStatus: /* AttemptedToSubmit */1
                                }
                              };
                    case /* Validating */2 :
                        return {
                                TAG: /* Update */0,
                                _0: {
                                  input: state.input,
                                  fieldsStatuses: match$1.fieldsStatuses,
                                  collectionsStatuses: match$1.collectionsStatuses,
                                  formStatus: state.formStatus,
                                  submissionStatus: state.submissionStatus
                                }
                              };
                    
                  }
                  break;
              case /* DismissSubmissionError */19 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */20 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */21 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateRssMicrosoftField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.rssMicrosoft, state.submissionStatus, validators_rssMicrosoft, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: status,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRssVideoElephantField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.rssVideoElephant, state.submissionStatus, validators_rssVideoElephant, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: status,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRssYahooField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.rssYahoo, state.submissionStatus, validators_rssYahoo, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: status,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePinnedField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.pinned, state.submissionStatus, validators_pinned, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: status,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSeasonNumberField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.seasonNumber, state.submissionStatus, validators_seasonNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: status,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEpisodeNumberField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.episodeNumber, state.submissionStatus, validators_episodeNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: status,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateThumbnailFileField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.thumbnailFile, state.submissionStatus, validators_thumbnailFile, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: status,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVideoFileField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.videoFile, state.submissionStatus, validators_videoFile, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: status,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSectionsField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.sections, state.submissionStatus, validators_sections, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: status,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.show, state.submissionStatus, validators_show, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: status,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTagsField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.tags, state.submissionStatus, validators_tags, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: status,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePublishedAtField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.publishedAt, state.submissionStatus, validators_publishedAt, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: status,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateArticleField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.article, state.submissionStatus, validators_article, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: status,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDescriptionField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, state.fieldsStatuses.description, state.submissionStatus, validators_description, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: status,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStatusField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.status, state.submissionStatus, validators_status, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSlugField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.Async.validateFieldOfStringTypeOnChangeInOnBlurModeWithMetadata(nextInput$15, metadata, state.fieldsStatuses.slug, state.submissionStatus, validators_slug, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: status,
                                            title: init.title,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTitleField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$16, metadata, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: status,
                                            projectId: init.projectId
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateProjectIdField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$17, metadata, state.fieldsStatuses.projectId, state.submissionStatus, validators_projectId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            rssMicrosoft: init.rssMicrosoft,
                                            rssVideoElephant: init.rssVideoElephant,
                                            rssYahoo: init.rssYahoo,
                                            pinned: init.pinned,
                                            seasonNumber: init.seasonNumber,
                                            episodeNumber: init.episodeNumber,
                                            thumbnailFile: init.thumbnailFile,
                                            videoFile: init.videoFile,
                                            sections: init.sections,
                                            show: init.show,
                                            tags: init.tags,
                                            publishedAt: init.publishedAt,
                                            article: init.article,
                                            description: init.description,
                                            status: init.status,
                                            slug: init.slug,
                                            title: init.title,
                                            projectId: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* ApplyAsyncResultForSlugField */18 :
                  var validator = validators_slug;
                  var x = state.fieldsStatuses.slug;
                  if (typeof x === "number") {
                    return /* NoUpdate */0;
                  }
                  if (x.TAG === /* Dirty */0) {
                    return /* NoUpdate */0;
                  }
                  if (!Curry._2(validator.eq, x._0, action._0)) {
                    return /* NoUpdate */0;
                  }
                  var init = state.fieldsStatuses;
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: {
                              rssMicrosoft: init.rssMicrosoft,
                              rssVideoElephant: init.rssVideoElephant,
                              rssYahoo: init.rssYahoo,
                              pinned: init.pinned,
                              seasonNumber: init.seasonNumber,
                              episodeNumber: init.episodeNumber,
                              thumbnailFile: init.thumbnailFile,
                              videoFile: init.videoFile,
                              sections: init.sections,
                              show: init.show,
                              tags: init.tags,
                              publishedAt: init.publishedAt,
                              article: init.article,
                              description: init.description,
                              status: init.status,
                              slug: {
                                TAG: /* Dirty */0,
                                _0: action._1,
                                _1: /* Shown */0
                              },
                              title: init.title,
                              projectId: init.projectId
                            },
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */19 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */20 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */21 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateRssMicrosoft: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateRssMicrosoftField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateRssVideoElephant: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateRssVideoElephantField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateRssYahoo: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateRssYahooField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updatePinned: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdatePinnedField */3,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateSeasonNumber: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateSeasonNumberField */4,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateEpisodeNumber: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateEpisodeNumberField */5,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateThumbnailFile: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateThumbnailFileField */6,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateVideoFile: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateVideoFileField */7,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateSections: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateSectionsField */8,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateShow: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateShowField */9,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTags: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTagsField */10,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updatePublishedAt: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdatePublishedAtField */11,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateArticle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateArticleField */12,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateDescription: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateDescriptionField */13,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateStatus: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateStatusField */14,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateSlug: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateSlugField */15,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTitleField */16,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateProjectId: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateProjectIdField */17,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurRssMicrosoft: (function (param) {
              return Curry._1(dispatch, /* BlurRssMicrosoftField */0);
            }),
          blurRssVideoElephant: (function (param) {
              return Curry._1(dispatch, /* BlurRssVideoElephantField */1);
            }),
          blurRssYahoo: (function (param) {
              return Curry._1(dispatch, /* BlurRssYahooField */2);
            }),
          blurPinned: (function (param) {
              return Curry._1(dispatch, /* BlurPinnedField */3);
            }),
          blurSeasonNumber: (function (param) {
              return Curry._1(dispatch, /* BlurSeasonNumberField */4);
            }),
          blurEpisodeNumber: (function (param) {
              return Curry._1(dispatch, /* BlurEpisodeNumberField */5);
            }),
          blurThumbnailFile: (function (param) {
              return Curry._1(dispatch, /* BlurThumbnailFileField */6);
            }),
          blurVideoFile: (function (param) {
              return Curry._1(dispatch, /* BlurVideoFileField */7);
            }),
          blurSections: (function (param) {
              return Curry._1(dispatch, /* BlurSectionsField */8);
            }),
          blurShow: (function (param) {
              return Curry._1(dispatch, /* BlurShowField */9);
            }),
          blurTags: (function (param) {
              return Curry._1(dispatch, /* BlurTagsField */10);
            }),
          blurPublishedAt: (function (param) {
              return Curry._1(dispatch, /* BlurPublishedAtField */11);
            }),
          blurArticle: (function (param) {
              return Curry._1(dispatch, /* BlurArticleField */12);
            }),
          blurDescription: (function (param) {
              return Curry._1(dispatch, /* BlurDescriptionField */13);
            }),
          blurStatus: (function (param) {
              return Curry._1(dispatch, /* BlurStatusField */14);
            }),
          blurSlug: (function (param) {
              return Curry._1(dispatch, /* BlurSlugField */15);
            }),
          blurTitle: (function (param) {
              return Curry._1(dispatch, /* BlurTitleField */16);
            }),
          blurProjectId: (function (param) {
              return Curry._1(dispatch, /* BlurProjectIdField */17);
            }),
          rssMicrosoftResult: Formality.exposeFieldResult(state.fieldsStatuses.rssMicrosoft),
          rssVideoElephantResult: Formality.exposeFieldResult(state.fieldsStatuses.rssVideoElephant),
          rssYahooResult: Formality.exposeFieldResult(state.fieldsStatuses.rssYahoo),
          pinnedResult: Formality.exposeFieldResult(state.fieldsStatuses.pinned),
          seasonNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.seasonNumber),
          episodeNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.episodeNumber),
          thumbnailFileResult: Formality.exposeFieldResult(state.fieldsStatuses.thumbnailFile),
          videoFileResult: Formality.exposeFieldResult(state.fieldsStatuses.videoFile),
          sectionsResult: Formality.exposeFieldResult(state.fieldsStatuses.sections),
          showResult: Formality.exposeFieldResult(state.fieldsStatuses.show),
          tagsResult: Formality.exposeFieldResult(state.fieldsStatuses.tags),
          publishedAtResult: Formality.exposeFieldResult(state.fieldsStatuses.publishedAt),
          articleResult: Formality.exposeFieldResult(state.fieldsStatuses.article),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          statusResult: Formality.exposeFieldResult(state.fieldsStatuses.status),
          slugResult: Formality.Async.exposeFieldResult(state.fieldsStatuses.slug),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          projectIdResult: Formality.exposeFieldResult(state.fieldsStatuses.projectId),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.rssMicrosoft || match.rssVideoElephant || match.rssYahoo || match.pinned || match.seasonNumber || match.episodeNumber || match.thumbnailFile || match.videoFile || match.sections || match.show || match.tags || match.publishedAt || match.article || match.description || match.status || !(typeof match.slug === "number" && !match.title)) {
                exit = 1;
              } else {
                if (!match.projectId) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            return true;
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              switch (match.TAG | 0) {
                case /* Valid */0 :
                    return true;
                case /* Invalid */1 :
                    return false;
                case /* Validating */2 :
                    return ;
                
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */18);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */19);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */20);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */21,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */21);
            })
        };
}

var VideoForm = {
  debounceInterval: 700,
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_Videos_Editor_Logic-RecountTools.UnknownError");

function useUploadFile(param) {
  var match = React.useState(function () {
        return false;
      });
  var setProgressModalIsOpen = match[1];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setFileUploadCurrent = match$1[1];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setFileUploadTotal = match$2[1];
  var upload = function (body, url, videoFile, cb) {
    Curry._1(setProgressModalIsOpen, (function (param) {
            return true;
          }));
    return Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(body, undefined, undefined, undefined, {
                                    TAG: /* Api */0,
                                    _0: url
                                  }), (function (prim) {
                                  return prim.json();
                                })), (function (res) {
                              return Json_decode.field("data", (function (param) {
                                            return Json_decode.field("upload_url", Json_decode.string, param);
                                          }), res);
                            })), (function (uploadUrl) {
                          return new Promise((function (resolve, reject) {
                                        Xhr$RecountTools.Immutable.send(Curry._2(Xhr$RecountTools.Immutable.$$Event.onLoad, Curry._2(Xhr$RecountTools.Immutable.$$Event.onError, Curry._2(Xhr$RecountTools.Immutable.$$Event.onUploadProgress, Curry._2(Xhr$RecountTools.Immutable.Body.file, Curry._1(Xhr$RecountTools.Immutable.ResponseType.blob, Xhr$RecountTools.Immutable.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "PUT", uploadUrl)), videoFile), (function (_event) {
                                                            Curry._1(setFileUploadCurrent, (function (param) {
                                                                    return _event.loaded;
                                                                  }));
                                                            return Curry._1(setFileUploadTotal, (function (param) {
                                                                          return _event.total;
                                                                        }));
                                                          })), (function (statusCode, message, param) {
                                                        var makeError = (function(str) {
                  return new Error(str)
                });
                                                        return reject(makeError(Int$RecountTools.toString(undefined, statusCode) + " " + message));
                                                      })), (function (param, param$1) {
                                                    return resolve(undefined);
                                                  })));
                                        
                                      }));
                        })), (function (param) {
                      return Curry._1(setProgressModalIsOpen, (function (param) {
                                    return false;
                                  }));
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
  };
  var progressModal = React.createElement(Modal$RecountTools.make, {
        isActive: match[0],
        children: null,
        onModalClose: (function (param) {
            
          })
      }, React.createElement("h3", {
            className: progressHeading
          }, "Your file is uploading, please do not close the tab"), React.createElement("progress", {
            className: "progress is-small is-primary",
            max: Int$RecountTools.toString(undefined, match$2[0]),
            value: Int$RecountTools.toString(undefined, match$1[0])
          }, "uploading..."));
  return [
          progressModal,
          upload
        ];
}

function useSubmit(video, autoSaveKey) {
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  return function (output, cb) {
    var removedTags = Arr$RecountTools.keep(video.tags, (function (t) {
            return !Arr$RecountTools.some(output.tags, (function (t2) {
                          return t.id === t2.id;
                        }));
          }));
    var addedTags = Arr$RecountTools.keep(output.tags, (function (t) {
            return !Arr$RecountTools.some(video.tags, (function (t2) {
                          return t.id === t2.id;
                        }));
          }));
    var deleteTags = Request$RecountTools.$$delete(Request$RecountTools.RequestBody.json({
              data: Arr$RecountTools.map(removedTags, (function (t) {
                      return Models_Id$RecountTools.toJson(t.id);
                    }))
            }), undefined, undefined, undefined, {
          TAG: /* Api */0,
          _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id) + "/relationships/tags"
        });
    var addTags = Request$RecountTools.post(Request$RecountTools.RequestBody.json({
              data: Arr$RecountTools.map(addedTags, (function (t) {
                      return Models_Id$RecountTools.toJson(t.id);
                    }))
            }), undefined, undefined, undefined, {
          TAG: /* Api */0,
          _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id) + "/relationships/tags"
        });
    var removedSections = Arr$RecountTools.keep(video.sections, (function (s) {
            return !Arr$RecountTools.some(output.sections, (function (sec) {
                          return sec.id === s.id;
                        }));
          }));
    var addedSections = Arr$RecountTools.keep(output.sections, (function (s) {
            return !Arr$RecountTools.some(video.sections, (function (sec) {
                          return sec.id === s.id;
                        }));
          }));
    var removeSections = Request$RecountTools.$$delete(Request$RecountTools.RequestBody.json({
              data: Arr$RecountTools.map(removedSections, (function (s) {
                      return Models_Id$RecountTools.toJson(s.id);
                    }))
            }), undefined, undefined, undefined, {
          TAG: /* Api */0,
          _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id) + "/relationships/sections"
        });
    var addSections = Request$RecountTools.post(Request$RecountTools.RequestBody.json({
              data: Arr$RecountTools.map(addedSections, (function (s) {
                      return Models_Id$RecountTools.toJson(s.id);
                    }))
            }), undefined, undefined, undefined, {
          TAG: /* Api */0,
          _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id) + "/relationships/sections"
        });
    var fields = [];
    if (output.show !== Models_Id$RecountTools.refAsId(video.show)) {
      Arr$RecountTools.push(fields, [
            "show_id",
            Models_Id$RecountTools.toJson(output.show)
          ]);
    }
    if (Caml_obj.caml_notequal(output.projectId, video.projectId)) {
      Arr$RecountTools.push(fields, [
            "project_id",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), output.projectId)
          ]);
    }
    if (output.title !== video.title) {
      Arr$RecountTools.push(fields, [
            "title",
            output.title
          ]);
    }
    if (output.slug !== video.slug) {
      Arr$RecountTools.push(fields, [
            "slug",
            output.slug
          ]);
    }
    if (Caml_obj.caml_notequal(output.description, video.description)) {
      Arr$RecountTools.push(fields, [
            "description",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), output.description)
          ]);
    }
    if (Caml_obj.caml_notequal(Option$RecountTools.map(output.article, ProseMirror$RecountTools.$$Document.toJson), video.article)) {
      Arr$RecountTools.push(fields, [
            "article",
            Json_encode.nullable(ProseMirror$RecountTools.$$Document.toJson, output.article)
          ]);
    }
    if (Caml_obj.caml_notequal(output.publishedAt, video.publishedAt)) {
      Arr$RecountTools.push(fields, [
            "published_at",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), Option$RecountTools.map(output.publishedAt, (function (pa) {
                        return pa.getTime() / 1000 | 0;
                      })))
          ]);
    }
    if (Caml_obj.caml_notequal(output.seasonNumber, video.season)) {
      Arr$RecountTools.push(fields, [
            "season_no",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), output.seasonNumber)
          ]);
    }
    if (Caml_obj.caml_notequal(output.episodeNumber, video.episode)) {
      Arr$RecountTools.push(fields, [
            "episode_no",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), output.episodeNumber)
          ]);
    }
    if (output.pinned !== video.pinned) {
      Arr$RecountTools.push(fields, [
            "pinned",
            output.pinned
          ]);
    }
    if (output.rssYahoo !== video.rssYahoo) {
      Arr$RecountTools.push(fields, [
            "rssYahoo",
            output.rssYahoo
          ]);
    }
    if (output.rssVideoElephant !== video.rssVideoElephant) {
      Arr$RecountTools.push(fields, [
            "rssVideoElephant",
            output.rssVideoElephant
          ]);
    }
    if (output.rssMicrosoft !== video.rssMicrosoft) {
      Arr$RecountTools.push(fields, [
            "rssMicrosoft",
            output.rssMicrosoft
          ]);
    }
    if (Models_Id$RecountTools.refAsId(video.show) !== Constants$RecountTools.recountWire && output.status !== video.status) {
      Arr$RecountTools.push(fields, [
            "status",
            Models_Video$RecountTools.Status.toJson(output.status)
          ]);
    }
    var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
              hd: [
                "data",
                Json_encode.object_(Belt_List.fromArray(fields))
              ],
              tl: /* [] */0
            }));
    var saveForm = Request$RecountTools.patch(body, undefined, undefined, undefined, {
          TAG: /* Api */0,
          _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id)
        });
    Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Promise.all([
                      deleteTags,
                      addTags,
                      addSections,
                      removeSections,
                      saveForm
                    ]), (function (param) {
                    if (param[0].ok && param[1].ok && param[2].ok && param[3].ok && param[4].ok) {
                      return Async$RecountTools.map(ShowStore$RecountTools.resolve(video.show), (function (show) {
                                    return VideoStore$RecountTools.update(video.id, (function (video) {
                                                  var newrecord = Caml_obj.caml_obj_dup(video);
                                                  newrecord.rssVideoElephant = output.rssVideoElephant;
                                                  newrecord.rssYahoo = output.rssYahoo;
                                                  newrecord.pinned = output.pinned;
                                                  newrecord.season = output.seasonNumber;
                                                  newrecord.episode = output.episodeNumber;
                                                  newrecord.publishedAt = output.publishedAt;
                                                  newrecord.updatedAt = new Date();
                                                  newrecord.sections = output.sections;
                                                  newrecord.show = {
                                                    TAG: /* Resolved */0,
                                                    _0: show
                                                  };
                                                  newrecord.slug = output.slug;
                                                  newrecord.title = output.title;
                                                  newrecord.description = output.description;
                                                  newrecord.tags = output.tags;
                                                  newrecord.status = output.status;
                                                  newrecord.projectId = output.projectId;
                                                  return newrecord;
                                                }));
                                  }));
                    } else {
                      Curry._3(makeToast, undefined, /* Error */2, "Unable to save video");
                      return Promise.reject({
                                  RE_EXN_ID: UnknownError
                                });
                    }
                  })), (function (param) {
                Curry._1(cb.reset, undefined);
                localStorage.removeItem(autoSaveKey);
                
              })), (function (param) {
            return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
          }));
    
  };
}

export {
  Styles ,
  VideoForm ,
  UnknownError ,
  useUploadFile ,
  useSubmit ,
  
}
/* progressHeading Not a pure module */
