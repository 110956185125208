

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Int$RecountTools from "../../util/Int.bs.js";
import * as Xhr$RecountTools from "../../util/Xhr.bs.js";
import * as Form$RecountTools from "../../components/Form/Form.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Modal$RecountTools from "../../components/Modal.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Form_Input$RecountTools from "../../components/Form/Form_Input.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";
import * as Models_YoutubeChannel$RecountTools from "../../models/Models_YoutubeChannel.bs.js";

var progressHeading = Curry._1(Css.merge, {
      hd: "has-text-white has-text-centered",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize(Css.rem(1.6)),
              tl: {
                hd: Css.marginBottom(Css.rem(1)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  progressHeading: progressHeading
};

var validators_thumbnail = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.thumbnail
            };
    })
};

var validators_description = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var isTooShort = input.description.length < 64;
      var isTooLong = input.description.length > 390;
      var description = input.description;
      if (description === "") {
        return {
                TAG: /* Error */1,
                _0: "Description is required"
              };
      } else if (isTooShort) {
        return {
                TAG: /* Error */1,
                _0: String(description.length) + "/390 characters (minimum 64)"
              };
      } else if (isTooLong) {
        return {
                TAG: /* Error */1,
                _0: String(description.length) + "/390 characters"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.description
              };
      }
    })
};

var validators_title = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var isTooShort = input.title.length < 25;
      var isTooLong = input.title.length > 120;
      var title = input.title;
      if (title === "") {
        return {
                TAG: /* Error */1,
                _0: "Title is required"
              };
      } else if (isTooShort) {
        return {
                TAG: /* Error */1,
                _0: String(title.length) + "/120 characters (minimum 25)"
              };
      } else if (isTooLong) {
        return {
                TAG: /* Error */1,
                _0: String(title.length) + "/120 characters"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.title
              };
      }
    })
};

var validators = {
  thumbnail: validators_thumbnail,
  description: validators_description,
  title: validators_title
};

function initialFieldsStatuses(_input) {
  return {
          thumbnail: /* Pristine */0,
          description: /* Pristine */0,
          title: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            thumbnail: /* Pristine */0,
            description: /* Pristine */0,
            title: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.thumbnail;
  var match_0 = match ? match._0 : Curry._1(validators.thumbnail.validate, input);
  var match$1 = fieldsStatuses.description;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.description.validate, input);
  var match$2 = fieldsStatuses.title;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.title.validate, input);
  var thumbnailResult = match_0;
  var thumbnailResult$1;
  if (thumbnailResult.TAG === /* Ok */0) {
    var descriptionResult = match_0$1;
    if (descriptionResult.TAG === /* Ok */0) {
      var titleResult = match_0$2;
      if (titleResult.TAG === /* Ok */0) {
        return {
                TAG: /* Valid */0,
                output: {
                  title: titleResult._0,
                  description: descriptionResult._0,
                  thumbnail: thumbnailResult._0
                },
                fieldsStatuses: {
                  thumbnail: /* Dirty */{
                    _0: thumbnailResult,
                    _1: /* Shown */0
                  },
                  description: /* Dirty */{
                    _0: descriptionResult,
                    _1: /* Shown */0
                  },
                  title: /* Dirty */{
                    _0: titleResult,
                    _1: /* Shown */0
                  }
                },
                collectionsStatuses: undefined
              };
      }
      thumbnailResult$1 = thumbnailResult;
    } else {
      thumbnailResult$1 = thumbnailResult;
    }
  } else {
    thumbnailResult$1 = thumbnailResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            thumbnail: /* Dirty */{
              _0: thumbnailResult$1,
              _1: /* Shown */0
            },
            description: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            title: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurThumbnailField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.thumbnail, validators_thumbnail, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  thumbnail: status,
                                  description: init.description,
                                  title: init.title
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDescriptionField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.description, validators_description, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  thumbnail: init.thumbnail,
                                  description: status,
                                  title: init.title
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTitleField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  thumbnail: init.thumbnail,
                                  description: init.description,
                                  title: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */3 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */3,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */4,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */6);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */4 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */5 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */6 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateThumbnailField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.thumbnail, state.submissionStatus, validators_thumbnail, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            thumbnail: status,
                                            description: init.description,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDescriptionField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.description, state.submissionStatus, validators_description, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            thumbnail: init.thumbnail,
                                            description: status,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTitleField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            thumbnail: init.thumbnail,
                                            description: init.description,
                                            title: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */3 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                thumbnail: /* Pristine */0,
                                description: /* Pristine */0,
                                title: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                thumbnail: /* Pristine */0,
                                description: /* Pristine */0,
                                title: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */4 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */5 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateThumbnail: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateThumbnailField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateDescription: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateDescriptionField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTitleField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurThumbnail: (function (param) {
              return Curry._1(dispatch, /* BlurThumbnailField */0);
            }),
          blurDescription: (function (param) {
              return Curry._1(dispatch, /* BlurDescriptionField */1);
            }),
          blurTitle: (function (param) {
              return Curry._1(dispatch, /* BlurTitleField */2);
            }),
          thumbnailResult: Formality.exposeFieldResult(state.fieldsStatuses.thumbnail),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.thumbnail || match.description || match.title) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */5,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */6);
            })
        };
}

var UpdateForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function Page_Youtube_UpdateForm$LoadedState(Props) {
  var title = Props.title;
  var description = Props.description;
  var thumbnail = Props.thumbnail;
  var refresh = Props.refresh;
  var match = React.useState(function () {
        
      });
  var setThumbFile = match[1];
  var thumbFile = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setProgressModalIsOpen = match$1[1];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setFileUploadCurrent = match$2[1];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setFileUploadTotal = match$3[1];
  var match$4 = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match$4[0];
  var form = useForm({
        title: title,
        description: description,
        thumbnail: Option$RecountTools.getWithDefault(thumbnail, "")
      }, (function (output, cb) {
          Option$RecountTools.forEach(thumbFile, (function (thumbnail) {
                  Curry._1(setProgressModalIsOpen, (function (param) {
                          return true;
                        }));
                  var format = Option$RecountTools.keep(Option$RecountTools.flatMap(output.thumbnail, (function (s) {
                              return Arr$RecountTools.last(s.split("."));
                            })), (function (s) {
                          return s.length === 3;
                        }));
                  var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                            hd: [
                              "format",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), format)
                            ],
                            tl: /* [] */0
                          }));
                  Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(body, undefined, undefined, undefined, {
                                            TAG: /* Api */0,
                                            _0: "/v2/youtube-channels/by/replace-thumbnail/1"
                                          }), (function (prim) {
                                          return prim.json();
                                        })), (function (res) {
                                      return Json_decode.field("data", (function (param) {
                                                    return Json_decode.field("upload_url", Json_decode.string, param);
                                                  }), res);
                                    })), (function (uploadUrl) {
                                  return new Promise((function (resolve, reject) {
                                                Xhr$RecountTools.Immutable.send(Curry._2(Xhr$RecountTools.Immutable.$$Event.onLoad, Curry._2(Xhr$RecountTools.Immutable.$$Event.onError, Curry._2(Xhr$RecountTools.Immutable.$$Event.onUploadProgress, Curry._2(Xhr$RecountTools.Immutable.Body.file, Curry._1(Xhr$RecountTools.Immutable.ResponseType.blob, Xhr$RecountTools.Immutable.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "PUT", uploadUrl)), thumbnail), (function (_event) {
                                                                    Curry._1(setFileUploadCurrent, (function (param) {
                                                                            return _event.loaded;
                                                                          }));
                                                                    return Curry._1(setFileUploadTotal, (function (param) {
                                                                                  return _event.total;
                                                                                }));
                                                                  })), (function (statusCode, message, param) {
                                                                var makeError = (function(str) {
                      return new Error(str)
                    });
                                                                return reject(makeError(Int$RecountTools.toString(undefined, statusCode) + " " + message));
                                                              })), (function (param, param$1) {
                                                            return resolve(undefined);
                                                          })));
                                                
                                              }));
                                })), (function (param) {
                              Curry._1(setProgressModalIsOpen, (function (param) {
                                      return false;
                                    }));
                              Curry._1(refresh, undefined);
                              Curry._3(makeToast, undefined, /* Confirm */1, "Success! Your new thumbnail is uploaded");
                              
                            })), (function (param) {
                          return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                        }));
                  
                }));
          var body = Request$RecountTools.RequestBody.json({
                data: {
                  title: output.title,
                  description: output.description
                }
              });
          Async$RecountTools.onFailure(Async$RecountTools.map(Request$RecountTools.patch(body, undefined, undefined, undefined, {
                        TAG: /* Api */0,
                        _0: "/v2/youtube-channels/1"
                      }), (function (response) {
                      if (response.ok) {
                        return Curry._1(cb.notifyOnSuccess, undefined);
                      } else {
                        Curry._3(makeToast, undefined, /* Error */2, "Unable to save compilation");
                        return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                      }
                    })), (function (param) {
                  Curry._3(makeToast, undefined, /* Error */2, "Unable to save compilation");
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var tmp = {
    value: Option$RecountTools.map(thumbFile, (function (f) {
            return {
                    file: f,
                    name: form.input.thumbnail
                  };
          })),
    fileTypes: "image/*",
    label: "Update Thumbnail",
    disabled: form.submitting,
    onBlur: (function (param) {
        return Curry._1(form.blurThumbnail, undefined);
      }),
    onChange: (function (v) {
        Curry._1(setThumbFile, (function (param) {
                return Caml_option.some(v.file);
              }));
        return Curry._2(form.updateThumbnail, (function (input, value) {
                      return {
                              title: input.title,
                              description: input.description,
                              thumbnail: value
                            };
                    }), v.name);
      }),
    error: form.thumbnailResult
  };
  var tmp$1 = Option$RecountTools.map(thumbnail, (function (img) {
          var startInt = img.lastIndexOf("/") + 1 | 0;
          return img.substring(startInt);
        }));
  if (tmp$1 !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, React.createElement(Form_Input$RecountTools.make, {
                          value: form.input.title,
                          label: "Title",
                          error: form.titleResult,
                          onChange: (function (e) {
                              return Curry._2(form.updateTitle, (function (input, value) {
                                            return {
                                                    title: value,
                                                    description: input.description,
                                                    thumbnail: input.thumbnail
                                                  };
                                          }), e.target.value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurTitle, undefined);
                            }),
                          disabled: form.submitting
                        }), React.createElement(Form$RecountTools.TextArea.make, {
                          label: "Description",
                          placeholder: "Description for Channel",
                          error: form.descriptionResult,
                          disabled: form.submitting,
                          value: form.input.description,
                          onBlur: (function (param) {
                              return Curry._1(form.blurDescription, undefined);
                            }),
                          onChange: (function (e) {
                              return Curry._2(form.updateDescription, (function (input, value) {
                                            return {
                                                    title: input.title,
                                                    description: value,
                                                    thumbnail: input.thumbnail
                                                  };
                                          }), e.target.value);
                            })
                        })), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, thumbnail !== undefined ? React.createElement(Layout$RecountTools.Columns.Column.make, {
                            children: React.createElement("img", {
                                  alt: "",
                                  src: thumbnail
                                })
                          }) : null, React.createElement(Form$RecountTools.$$File.make, tmp))), React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: React.createElement(Form_Button$RecountTools.make, {
                              label: "Save",
                              disabled: form.submitting,
                              className: "is-link",
                              onClick: (function (param) {
                                  return Curry._1(form.submit, undefined);
                                })
                            })
                      })
                }), React.createElement(Modal$RecountTools.make, {
                  isActive: match$1[0],
                  children: null,
                  onModalClose: (function (param) {
                      
                    })
                }, React.createElement("h3", {
                      className: progressHeading
                    }, "Your file is uploading, please do not close the tab"), React.createElement("progress", {
                      className: "progress is-small is-primary",
                      max: Int$RecountTools.toString(undefined, match$3[0]),
                      value: Int$RecountTools.toString(undefined, match$2[0])
                    }, "uploading...")));
}

var LoadedState = {
  make: Page_Youtube_UpdateForm$LoadedState
};

function Page_Youtube_UpdateForm(Props) {
  var match = React.useState(function () {
        
      });
  var setChannelData = match[1];
  var channelData = match[0];
  var refresh = function (param) {
    var R = Request$RecountTools.ApiEnvelope({
          fromJson: Models_YoutubeChannel$RecountTools.fromJson,
          toJson: Models_YoutubeChannel$RecountTools.toJson
        });
    Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                  TAG: /* Api */0,
                  _0: "/v2/youtube-channels/1"
                }), (function (prim) {
                return prim.json();
              })), (function (json) {
            var data = Curry._1(R.fromJson, json).data;
            return Curry._1(setChannelData, (function (param) {
                          return data;
                        }));
          }));
    
  };
  React.useEffect((function () {
          refresh(undefined);
          
        }), []);
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: channelData !== undefined ? React.createElement(Page_Youtube_UpdateForm$LoadedState, {
                      title: channelData.title,
                      description: channelData.description,
                      thumbnail: channelData.thumbnail,
                      refresh: refresh
                    }) : "loading",
              pageName: "Twitch Details"
            });
}

var make = Page_Youtube_UpdateForm;

export {
  Styles ,
  UpdateForm ,
  LoadedState ,
  make ,
  
}
/* progressHeading Not a pure module */
