

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Css_Core from "bs-css/src/Css_Core.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Form_Input$RecountTools from "./Form_Input.bs.js";
import * as Models_Tag$RecountTools from "../../models/Models_Tag.bs.js";

var inlineTagsInputContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.marginTop(Css.rem(1)),
        tl: {
          hd: Css.selector("input", {
                hd: Css.selector("&[disabled]", {
                      hd: Css.backgroundColor(Css.hex("fff")),
                      tl: {
                        hd: Css.borderColor(Css.hex("dbdbdb")),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var inlineTag = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.rem(0.5)),
        tl: {
          hd: Css.top(Css.pct(55)),
          tl: /* [] */0
        }
      }
    });

var tagsInputContainer = Curry._1(Css.merge, {
      hd: "mt-3",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var suggestionsContainer = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.backgroundColor(Css.hex("fff")),
        tl: {
          hd: Css.boxShadow(Css_Core.Shadow.box(undefined, undefined, Css.px(5), undefined, undefined, {
                    NAME: "rgba",
                    VAL: [
                      100,
                      100,
                      100,
                      {
                        NAME: "percent",
                        VAL: 30
                      }
                    ]
                  })),
          tl: {
            hd: Css.top(Css.rem(4)),
            tl: {
              hd: Css.left(Css.rem(1)),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Css.hex("dbdbdb")),
                  tl: {
                    hd: Css.paddingBottom(Css.rem(1)),
                    tl: {
                      hd: Css.paddingTop(Css.rem(1)),
                      tl: {
                        hd: Css.zIndex(5),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var suggestion = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.rem(0.5)),
      tl: {
        hd: Css.paddingBottom(Css.rem(0.5)),
        tl: {
          hd: Css.paddingLeft(Css.rem(1)),
          tl: {
            hd: Css.paddingRight(Css.rem(1)),
            tl: {
              hd: Css.selector("&:hover", {
                    hd: Css.backgroundColor(Css.hex("f3f3f3")),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var tagsContainer = Curry._1(Css.merge, {
      hd: "tags",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.marginTop(Css.rem(1)),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var tagContent = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.paddingLeft(Css.rem(1)),
        tl: {
          hd: Css.paddingRight(Css.rem(1)),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var tagKind = Curry._1(Css.style, {
      hd: Css.textAlign("right"),
      tl: {
        hd: Css.$$float("left"),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 50
              }),
          tl: {
            hd: Css.textTransform("uppercase"),
            tl: {
              hd: Css.color(Css.hex("#dbdbdb")),
              tl: {
                hd: Css.fontSize(Css.rem(0.8)),
                tl: {
                  hd: Css.padding(Css.rem(0.2)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tagText = Curry._1(Css.style, {
      hd: Css.textAlign("left"),
      tl: {
        hd: Css.$$float("left"),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 50
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  inlineTagsInputContainer: inlineTagsInputContainer,
  inlineTag: inlineTag,
  tagsInputContainer: tagsInputContainer,
  suggestionsContainer: suggestionsContainer,
  suggestion: suggestion,
  tagsContainer: tagsContainer,
  tagContent: tagContent,
  tagKind: tagKind,
  tagText: tagText
};

function Form_Tags(Props) {
  var label = Props.label;
  var value = Props.value;
  var kind = Props.kind;
  var maxTags = Props.maxTags;
  var exclude = Props.exclude;
  var displayInlineOpt = Props.displayInline;
  var disabled = Props.disabled;
  var error = Props.error;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var displayInline = displayInlineOpt !== undefined ? displayInlineOpt : false;
  var match = React.useState(function () {
        return "";
      });
  var setText = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDisplaySuggestions = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setTagSuggestions = match$2[1];
  var makeTagsSuggestionRequest = function (tagText) {
    var fromJson = function (param) {
      return Json_decode.array(Models_Tag$RecountTools.fromJson, param);
    };
    var toJson = function (param) {
      return Json_encode.array(Models_Tag$RecountTools.toJson, param);
    };
    var Resp = Request$RecountTools.ApiEnvelope({
          fromJson: fromJson,
          toJson: toJson
        });
    var url = kind !== undefined ? ({
          TAG: /* Api */0,
          _0: "/v2/tags/views/search?types=" + Models_Tag$RecountTools.Kind.toString(kind) + "&q=" + tagText
        }) : ({
          TAG: /* Api */0,
          _0: "/v2/tags/views/search?q=" + tagText
        });
    Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, url), (function (prim) {
                return prim.json();
              })), (function (json) {
            var data = Curry._1(Resp.fromJson, json).data;
            var suggestions = exclude !== undefined ? Belt_Array.keep(data, (function (tag) {
                      return Caml_obj.caml_notequal(tag, exclude);
                    })) : data;
            Curry._1(setTagSuggestions, (function (param) {
                    return suggestions;
                  }));
            return Curry._1(setDisplaySuggestions, (function (param) {
                          return suggestions.length !== 0;
                        }));
          }));
    
  };
  var handleInputChange = function (e) {
    var iValue = e.target.value;
    if (Option$RecountTools.mapWithDefault(maxTags, true, (function (m) {
              return value.length < m;
            }))) {
      if (iValue.length > 1) {
        makeTagsSuggestionRequest(iValue);
      } else {
        Curry._1(setDisplaySuggestions, (function (param) {
                return false;
              }));
      }
      return Curry._1(setText, (function (param) {
                    return iValue;
                  }));
    } else {
      return Curry._1(setDisplaySuggestions, (function (param) {
                    return false;
                  }));
    }
  };
  var addTag = function (tag) {
    Curry._1(setText, (function (param) {
            return "";
          }));
    if (!Arr$RecountTools.some(value, (function (t) {
              return t.id === tag.id;
            }))) {
      return Curry._1(onChange, {
                  TAG: /* Added */0,
                  _0: tag
                });
    }
    
  };
  var createNewTag = function (tagText, tagKind) {
    var tagsBody = Request$RecountTools.RequestBody.json({
          data: {
            text: tagText,
            tag_type_id: Models_Tag$RecountTools.Kind.toJson(tagKind)
          }
        });
    var Resp = Request$RecountTools.ApiEnvelope({
          fromJson: Models_Tag$RecountTools.fromJson,
          toJson: Models_Tag$RecountTools.toJson
        });
    Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(tagsBody, undefined, undefined, undefined, {
                  TAG: /* Api */0,
                  _0: "/v2/tags"
                }), (function (prim) {
                return prim.json();
              })), (function (json) {
            Curry._1(setText, (function (param) {
                    return "";
                  }));
            return addTag(Curry._1(Resp.fromJson, json).data);
          }));
    
  };
  var handleOnKeyDown = function (e) {
    if (e.key !== "Enter") {
      return ;
    }
    e.preventDefault();
    var tagText = e.target.value;
    if (tagText !== "") {
      if (kind !== undefined) {
        createNewTag(tagText, kind);
      } else {
        Curry._1(setDisplaySuggestions, (function (param) {
                return false;
              }));
      }
    }
    return Curry._1(setDisplaySuggestions, (function (param) {
                  return false;
                }));
  };
  var tagsOutput = Arr$RecountTools.map(value, (function (t) {
          return React.createElement("span", {
                      key: Models_Id$RecountTools.toString(t.id),
                      className: "tag"
                    }, t.text, React.createElement("button", {
                          className: "delete is-small",
                          onClick: (function (param) {
                              return Curry._1(onChange, {
                                          TAG: /* Removed */1,
                                          _0: t
                                        });
                            })
                        }));
        }));
  return React.createElement("div", {
              onBlur: (function (param) {
                  Curry._1(onBlur, undefined);
                  return Curry._1(setDisplaySuggestions, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement("div", {
                  className: tagsInputContainer
                }, React.createElement(Form_Input$RecountTools.make, {
                      value: match[0],
                      label: label,
                      error: error,
                      onChange: handleInputChange,
                      onBlur: (function (param) {
                          
                        }),
                      onKeyDown: handleOnKeyDown,
                      disabled: disabled
                    }), match$1[0] ? React.createElement("ul", {
                        className: suggestionsContainer
                      }, Arr$RecountTools.map(match$2[0], (function (t) {
                              return React.createElement("li", {
                                          key: Models_Id$RecountTools.toString(t.id),
                                          className: suggestion,
                                          tabIndex: 0,
                                          onClick: (function (param) {
                                              Curry._1(setDisplaySuggestions, (function (param) {
                                                      return false;
                                                    }));
                                              return addTag(t);
                                            }),
                                          onMouseDown: (function (e) {
                                              e.preventDefault();
                                              
                                            })
                                        }, React.createElement("div", {
                                              className: tagContent
                                            }, React.createElement("div", {
                                                  className: tagText
                                                }, t.text), React.createElement("div", {
                                                  className: tagKind
                                                }, Models_Tag$RecountTools.Kind.toDisplay(t.kind))));
                            }))) : null, displayInline ? React.createElement("span", {
                        className: inlineTag
                      }, tagsOutput) : null), displayInline ? null : React.createElement("div", {
                    className: tagsContainer
                  }, tagsOutput));
}

var Input;

var make = Form_Tags;

export {
  Input ,
  Styles ,
  make ,
  
}
/* inlineTagsInputContainer Not a pure module */
