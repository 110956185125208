

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Icon$RecountTools from "./Icon.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as ClickOutside$RecountTools from "./ClickOutside.bs.js";

var menu = Curry._1(Css.merge, {
      hd: "dropdown-menu",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.maxHeight(Css.px(500)),
              tl: {
                hd: Css.overflowY("scroll"),
                tl: {
                  hd: Css.border(Css.px(1), "solid", {
                        NAME: "hex",
                        VAL: "e5ecf1"
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var dropDownWidth = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.width(Css.px(160)),
          tl: /* [] */0
        }
      }
    });

var button = Curry._1(Css.merge, {
      hd: "button is-white",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.width(Css.rem(8)),
                tl: {
                  hd: Css.selector("> span", {
                        hd: Css.width(Css.rem(8)),
                        tl: {
                          hd: Css.whiteSpace("nowrap"),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.textOverflow("ellipsis"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var removeIcon = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.left(Css.rem(0.5)),
        tl: {
          hd: Css.zIndex(10),
          tl: {
            hd: Css.backgroundColor("transparent"),
            tl: {
              hd: Css.border(Css.px(0), "solid", Css.hex("fff")),
              tl: /* [] */0
            }
          }
        }
      }
    });

var DropDownStyles = {
  menu: menu,
  dropDownWidth: dropDownWidth,
  button: button,
  removeIcon: removeIcon
};

function DropDown(Props) {
  var label = Props.label;
  var options = Props.options;
  var onSelect = Props.onSelect;
  var disabledOpt = Props.disabled;
  var value = Props.value;
  var onClear = Props.onClear;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setActive = match[1];
  var handleClickOutside = function (param) {
    return Curry._1(setActive, (function (param) {
                  return false;
                }));
  };
  var controlRef = ClickOutside$RecountTools.useClickOutside(handleClickOutside);
  var activeOption = Arr$RecountTools.find(options, (function (opt) {
          return Option$RecountTools.mapWithDefault(value, false, (function (v) {
                        return Caml_obj.caml_equal(opt.value, v);
                      }));
        }));
  var expand = onClear !== undefined ? Curry._1(Css.merge, {
          hd: "dropdown-trigger",
          tl: {
            hd: dropDownWidth,
            tl: /* [] */0
          }
        }) : "dropdown-trigger";
  return React.createElement("div", {
              ref: controlRef,
              className: "control"
            }, React.createElement("div", {
                  className: match[0] ? "dropdown is-active" : "dropdown"
                }, React.createElement("div", {
                      className: expand
                    }, React.createElement("button", {
                          "aria-controls": "dropdown-menu",
                          className: button,
                          disabled: disabled,
                          onClick: (function (param) {
                              return Curry._1(setActive, (function (a) {
                                            return !a;
                                          }));
                            })
                        }, React.createElement("span", undefined, Option$RecountTools.mapWithDefault(activeOption, label, (function (o) {
                                    return o.label;
                                  }))), React.createElement("span", {
                              className: "icon is-small"
                            }, React.createElement("i", {
                                  "aria-hidden": true,
                                  className: "fa fa-angle-down"
                                }))), value !== undefined && onClear !== undefined ? React.createElement("button", {
                            className: removeIcon,
                            onClick: (function (param) {
                                return Curry._1(onClear, undefined);
                              })
                          }, React.createElement(Icon$RecountTools.make, {
                                margin: "mr-1",
                                classes: "has-text-danger",
                                icon: /* Remove */4
                              })) : null), React.createElement("div", {
                      className: menu,
                      id: "dropdown-menu",
                      role: "menu"
                    }, React.createElement("div", {
                          className: "dropdown-content"
                        }, Arr$RecountTools.map(options, (function (o) {
                                var partial_arg = o.value;
                                return React.createElement("a", {
                                            key: o.label,
                                            className: "dropdown-item",
                                            href: "#",
                                            onClick: (function (param) {
                                                param.preventDefault();
                                                Curry._1(setActive, (function (param) {
                                                        return false;
                                                      }));
                                                return Curry._1(onSelect, partial_arg);
                                              })
                                          }, o.label);
                              }))))));
}

var make = DropDown;

export {
  DropDownStyles ,
  make ,
  
}
/* menu Not a pure module */
