

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Arr$RecountTools from "./util/Arr.bs.js";
import * as Url$RecountTools from "./util/Url.bs.js";
import * as Async$RecountTools from "./util/Async.bs.js";
import * as Modal$RecountTools from "./components/Modal.bs.js";
import * as Option$RecountTools from "./util/Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Request$RecountTools from "./util/Request.bs.js";
import * as AuthStore$RecountTools from "./stores/AuthStore.bs.js";
import * as Models_Id$RecountTools from "./models/Models_Id.bs.js";
import * as Observable$RecountTools from "./util/Observable.bs.js";
import * as Page_Login$RecountTools from "./pages/Page_Login.bs.js";
import * as PageHistory$RecountTools from "./util/PageHistory.bs.js";
import * as Page_NotFound$RecountTools from "./pages/NotFound/Page_NotFound.bs.js";
import * as ToastProvider$RecountTools from "./components/ToastProvider.bs.js";
import * as Page_Tags_Editor$RecountTools from "./pages/Tags/Page_Tags_Editor.bs.js";
import * as Page_VideoEditor$RecountTools from "./pages/Page_VideoEditor.bs.js";
import * as Page_Tags_Listing$RecountTools from "./pages/Tags/Page_Tags_Listing.bs.js";
import * as Page_Users_Editor$RecountTools from "./pages/Users/Page_Users_Editor.bs.js";
import * as Page_Users_Listing$RecountTools from "./pages/Users/Page_Users_Listing.bs.js";
import * as Page_Videos_Editor$RecountTools from "./pages/Videos/Editor/Page_Videos_Editor.bs.js";
import * as Page_Videos_Listing$RecountTools from "./pages/Videos/Page_Videos_Listing.bs.js";
import * as ConfirmationProvider$RecountTools from "./components/ConfirmationProvider.bs.js";
import * as Page_Sections_Editor$RecountTools from "./pages/Sections/Page_Sections_Editor.bs.js";
import * as Page_Sections_Listing$RecountTools from "./pages/Sections/Page_Sections_Listing.bs.js";
import * as Page_Youtube_UpdateForm$RecountTools from "./pages/Youtube/Page_Youtube_UpdateForm.bs.js";
import * as Page_PodcastEpisodes_Editor$RecountTools from "./pages/PodcastEpisodes/Page_PodcastEpisodes_Editor.bs.js";
import * as Page_PodcastEpisodes_Listing$RecountTools from "./pages/PodcastEpisodes/Page_PodcastEpisodes_Listing.bs.js";

import "micro-observables/batchingForReactDom"
;

import "react-datepicker/dist/react-datepicker.css"
;

function App(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setSlackModalIsOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSlackAuthIsError = match$1[1];
  var slackAuthIsError = match$1[0];
  React.useEffect((function () {
          PageHistory$RecountTools.addPage(url.path);
          
        }), [url.path]);
  React.useEffect((function () {
          var slackCode = Url$RecountTools.SearchParams.get(new URLSearchParams(url.search), "code");
          if (slackCode !== undefined) {
            Observable$RecountTools.listenWhile(AuthStore$RecountTools.getOpt, (function (auth, param) {
                    if (auth === undefined) {
                      return true;
                    }
                    if (slackCode !== undefined) {
                      var body = Request$RecountTools.RequestBody.json({
                            data: slackCode
                          });
                      Async$RecountTools.map(Request$RecountTools.post(body, undefined, undefined, undefined, {
                                TAG: /* Api */0,
                                _0: "/auth/slack-auth"
                              }), (function (response) {
                              if (!response.ok) {
                                Curry._1(setSlackAuthIsError, (function (param) {
                                        return true;
                                      }));
                              }
                              return Curry._1(setSlackModalIsOpen, (function (param) {
                                            return true;
                                          }));
                            }));
                    }
                    return false;
                  }));
          } else if (Belt_List.length(url.path) === 0) {
            var lvp = Option$RecountTools.getWithDefault(PageHistory$RecountTools.lastVisitedPage, {
                  hd: "videos",
                  tl: /* [] */0
                });
            RescriptReactRouter.push("/" + Arr$RecountTools.join(Belt_List.toArray(lvp), "/"));
          }
          
        }), []);
  var match$2 = url.path;
  var page;
  var exit = 0;
  if (match$2) {
    switch (match$2.hd) {
      case "podcast-episodes" :
          var match$3 = match$2.tl;
          if (match$3) {
            if (match$3.hd === "edit") {
              var match$4 = match$3.tl;
              if (match$4 && !match$4.tl) {
                page = React.createElement(Page_PodcastEpisodes_Editor$RecountTools.make, {
                      id: Models_Id$RecountTools.unsafe_fromString(match$4.hd)
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            page = React.createElement(Page_PodcastEpisodes_Listing$RecountTools.make, {});
          }
          break;
      case "sections" :
          var match$5 = match$2.tl;
          if (match$5) {
            if (match$5.hd === "edit") {
              var match$6 = match$5.tl;
              if (match$6 && !match$6.tl) {
                page = React.createElement(Page_Sections_Editor$RecountTools.make, {
                      id: Models_Id$RecountTools.unsafe_fromString(match$6.hd)
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            page = React.createElement(Page_Sections_Listing$RecountTools.make, {});
          }
          break;
      case "tags" :
          var match$7 = match$2.tl;
          if (match$7) {
            if (match$7.hd === "edit") {
              var match$8 = match$7.tl;
              if (match$8 && !match$8.tl) {
                page = React.createElement(Page_Tags_Editor$RecountTools.make, {
                      id: Models_Id$RecountTools.unsafe_fromString(match$8.hd)
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            page = React.createElement(Page_Tags_Listing$RecountTools.make, {});
          }
          break;
      case "users" :
          var match$9 = match$2.tl;
          if (match$9) {
            if (match$9.hd === "edit") {
              var match$10 = match$9.tl;
              if (match$10 && !match$10.tl) {
                page = React.createElement(Page_Users_Editor$RecountTools.make, {
                      id: Models_Id$RecountTools.unsafe_fromString(match$10.hd)
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            page = React.createElement(Page_Users_Listing$RecountTools.make, {});
          }
          break;
      case "video-editor" :
          if (match$2.tl) {
            exit = 1;
          } else {
            page = React.createElement(Page_VideoEditor$RecountTools.make, {});
          }
          break;
      case "videos" :
          var match$11 = match$2.tl;
          if (match$11) {
            if (match$11.hd === "edit") {
              var match$12 = match$11.tl;
              if (match$12 && !match$12.tl) {
                page = React.createElement(Page_Videos_Editor$RecountTools.make, {
                      id: Models_Id$RecountTools.unsafe_fromString(match$12.hd)
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            page = React.createElement(Page_Videos_Listing$RecountTools.make, {});
          }
          break;
      case "youtube" :
          if (match$2.tl) {
            exit = 1;
          } else {
            page = React.createElement(Page_Youtube_UpdateForm$RecountTools.make, {});
          }
          break;
      default:
        exit = 1;
    }
  } else {
    page = React.createElement(Modal$RecountTools.make, {
          isActive: match[0],
          children: null,
          onModalClose: (function (param) {
              Curry._1(setSlackModalIsOpen, (function (param) {
                      return false;
                    }));
              return RescriptReactRouter.push("/videos");
            })
        }, React.createElement(Modal$RecountTools.Header.make, {
              children: slackAuthIsError ? "Error" : "Success"
            }), React.createElement(Modal$RecountTools.Body.make, {
              children: slackAuthIsError ? "Oops! Looks like something went wrong. Please try again or contact your dev team." : "Successfully installed Slack Publisher Bot!"
            }));
  }
  if (exit === 1) {
    page = React.createElement(Page_NotFound$RecountTools.make, {});
  }
  return React.createElement(ConfirmationProvider$RecountTools.make, {
              children: React.createElement(ToastProvider$RecountTools.make, {
                    children: React.createElement(Page_Login$RecountTools.make, {
                          children: page
                        })
                  })
            });
}

var make = App;

export {
  make ,
  
}
/*  Not a pure module */
