

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Ordering$RecountTools from "./Ordering.bs.js";

function push(arr, value) {
  arr.push(value);
  
}

function find(prim0, prim1) {
  return Caml_option.undefined_to_opt(prim0.find(Curry.__1(prim1)));
}

function sliceFromTo(prim0, prim1, prim2) {
  return prim0.slice(prim1, prim2);
}

function join(prim0, prim1) {
  return prim0.join(prim1);
}

function spliceInPlace(prim0, prim1, prim2, prim3) {
  return Caml_splice_call.spliceObjApply(prim0, "splice", [
              prim1,
              prim2,
              prim3
            ]);
}

function omit(arr, idx) {
  return Belt_Array.concat(arr.slice(0, idx), Belt_Array.sliceToEnd(arr, idx + 1 | 0));
}

function splitAt(arr, idx) {
  var init = arr.slice(0, idx);
  var back = Belt_Array.sliceToEnd(arr, idx);
  return [
          init,
          back
        ];
}

function take(arr, n) {
  return arr.slice(0, n);
}

function sort(arr, cmp) {
  var fn = function (a, b) {
    var match = Curry._2(cmp, a, b);
    return match - 1 | 0;
  };
  return Belt_SortArray.stableSortBy(arr, fn);
}

function maxBy(arr, cmp) {
  return Belt_Array.reduce(arr, undefined, (function (acc, a) {
                if (acc !== undefined) {
                  return Caml_option.some(Ordering$RecountTools.max(Caml_option.valFromOption(acc), a, cmp));
                } else {
                  return Caml_option.some(a);
                }
              }));
}

function sumBy(arr, toInt) {
  return Belt_Array.reduce(arr, undefined, (function (acc, a) {
                if (acc !== undefined) {
                  return Curry._1(toInt, a) + acc | 0;
                } else {
                  return Curry._1(toInt, a);
                }
              }));
}

function indexOf(arr, x) {
  var i = arr.findIndex(function (x$p) {
        return Caml_obj.caml_equal(x$p, x);
      });
  if (i !== -1) {
    return i;
  }
  
}

function dedupe(arr, id) {
  return Belt_Set.toArray(Belt_Set.fromArray(arr, id));
}

function last(arr) {
  return Belt_Array.get(arr, arr.length - 1 | 0);
}

function findMap(arr, pred) {
  return Belt_Array.get(arr.flatMap(function (v) {
                  var v$1 = Curry._1(pred, v);
                  if (v$1 !== undefined) {
                    return [Caml_option.valFromOption(v$1)];
                  } else {
                    return [];
                  }
                }), 0);
}

function append(arr, v) {
  return Belt_Array.concat(arr, [v]);
}

var get = Belt_Array.get;

var getExn = Belt_Array.getExn;

var set = Belt_Array.set;

var setExn = Belt_Array.setExn;

var shuffleInPlace = Belt_Array.shuffleInPlace;

var shuffle = Belt_Array.shuffle;

var reverseInPlace = Belt_Array.reverseInPlace;

var reverse = Belt_Array.reverse;

var make = Belt_Array.make;

var range = Belt_Array.range;

var rangeBy = Belt_Array.rangeBy;

var makeByU = Belt_Array.makeByU;

var makeBy = Belt_Array.makeBy;

var makeByAndShuffleU = Belt_Array.makeByAndShuffleU;

var makeByAndShuffle = Belt_Array.makeByAndShuffle;

var zip = Belt_Array.zip;

var zipByU = Belt_Array.zipByU;

var zipBy = Belt_Array.zipBy;

var unzip = Belt_Array.unzip;

var concat = Belt_Array.concat;

var concatMany = Belt_Array.concatMany;

var slice = Belt_Array.slice;

var sliceToEnd = Belt_Array.sliceToEnd;

var fill = Belt_Array.fill;

var blit = Belt_Array.blit;

var blitUnsafe = Belt_Array.blitUnsafe;

var forEachU = Belt_Array.forEachU;

var forEach = Belt_Array.forEach;

var mapU = Belt_Array.mapU;

var map = Belt_Array.map;

var getByU = Belt_Array.getByU;

var getBy = Belt_Array.getBy;

var getIndexByU = Belt_Array.getIndexByU;

var getIndexBy = Belt_Array.getIndexBy;

var keepU = Belt_Array.keepU;

var keep = Belt_Array.keep;

var keepWithIndexU = Belt_Array.keepWithIndexU;

var keepWithIndex = Belt_Array.keepWithIndex;

var keepMapU = Belt_Array.keepMapU;

var keepMap = Belt_Array.keepMap;

var forEachWithIndexU = Belt_Array.forEachWithIndexU;

var forEachWithIndex = Belt_Array.forEachWithIndex;

var mapWithIndexU = Belt_Array.mapWithIndexU;

var mapWithIndex = Belt_Array.mapWithIndex;

var partitionU = Belt_Array.partitionU;

var partition = Belt_Array.partition;

var reduceU = Belt_Array.reduceU;

var reduce = Belt_Array.reduce;

var reduceReverseU = Belt_Array.reduceReverseU;

var reduceReverse = Belt_Array.reduceReverse;

var reduceReverse2U = Belt_Array.reduceReverse2U;

var reduceReverse2 = Belt_Array.reduceReverse2;

var reduceWithIndexU = Belt_Array.reduceWithIndexU;

var reduceWithIndex = Belt_Array.reduceWithIndex;

var joinWithU = Belt_Array.joinWithU;

var joinWith = Belt_Array.joinWith;

var someU = Belt_Array.someU;

var some = Belt_Array.some;

var everyU = Belt_Array.everyU;

var every = Belt_Array.every;

var every2U = Belt_Array.every2U;

var every2 = Belt_Array.every2;

var some2U = Belt_Array.some2U;

var some2 = Belt_Array.some2;

var cmpU = Belt_Array.cmpU;

var cmp = Belt_Array.cmp;

var eqU = Belt_Array.eqU;

var eq = Belt_Array.eq;

export {
  get ,
  getExn ,
  set ,
  setExn ,
  shuffleInPlace ,
  shuffle ,
  reverseInPlace ,
  reverse ,
  make ,
  range ,
  rangeBy ,
  makeByU ,
  makeBy ,
  makeByAndShuffleU ,
  makeByAndShuffle ,
  zip ,
  zipByU ,
  zipBy ,
  unzip ,
  concat ,
  concatMany ,
  slice ,
  sliceToEnd ,
  fill ,
  blit ,
  blitUnsafe ,
  forEachU ,
  forEach ,
  mapU ,
  map ,
  getByU ,
  getBy ,
  getIndexByU ,
  getIndexBy ,
  keepU ,
  keep ,
  keepWithIndexU ,
  keepWithIndex ,
  keepMapU ,
  keepMap ,
  forEachWithIndexU ,
  forEachWithIndex ,
  mapWithIndexU ,
  mapWithIndex ,
  partitionU ,
  partition ,
  reduceU ,
  reduce ,
  reduceReverseU ,
  reduceReverse ,
  reduceReverse2U ,
  reduceReverse2 ,
  reduceWithIndexU ,
  reduceWithIndex ,
  joinWithU ,
  joinWith ,
  someU ,
  some ,
  everyU ,
  every ,
  every2U ,
  every2 ,
  some2U ,
  some2 ,
  cmpU ,
  cmp ,
  eqU ,
  eq ,
  push ,
  find ,
  sliceFromTo ,
  join ,
  spliceInPlace ,
  omit ,
  splitAt ,
  take ,
  sort ,
  maxBy ,
  sumBy ,
  indexOf ,
  dedupe ,
  last ,
  findMap ,
  append ,
  
}
/* No side effect */
