

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Int$RecountTools from "../util/Int.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function dateFromEpoch(json) {
  return new Date(Json_decode.$$float(json) * 1000);
}

function toDisplay(p) {
  if (p === "editVideo") {
    return "Edit Videos";
  } else if (p === "readSlackCredentials") {
    return "Read Slack Credentials";
  } else if (p === "editUsers") {
    return "Edit Users";
  } else {
    return "Publish Videos";
  }
}

function fromJson(json) {
  var i = Json_decode.$$int(json);
  switch (i) {
    case 0 :
        return "editUsers";
    case 1 :
        return "readSlackCredentials";
    case 2 :
        return "editVideo";
    case 3 :
        return "publishVideo";
    default:
      throw {
            RE_EXN_ID: Json_decode.DecodeError,
            _1: "Invalid Permission: " + Int$RecountTools.toString(undefined, i),
            Error: new Error()
          };
  }
}

function toJson(p) {
  if (p === "editVideo") {
    return 2;
  } else if (p === "readSlackCredentials") {
    return 1;
  } else if (p === "editUsers") {
    return 0;
  } else {
    return 3;
  }
}

function toString(p) {
  if (p === "editVideo") {
    return "2";
  } else if (p === "readSlackCredentials") {
    return "1";
  } else if (p === "editUsers") {
    return "0";
  } else {
    return "3";
  }
}

function fromString(p) {
  var PermissionException = /* @__PURE__ */Caml_exceptions.create("PermissionException");
  switch (p) {
    case "0" :
        return "editUsers";
    case "1" :
        return "readSlackCredentials";
    case "2" :
        return "editVideo";
    case "3" :
        return "publishVideo";
    default:
      throw {
            RE_EXN_ID: PermissionException,
            _1: "Invalid permission",
            Error: new Error()
          };
  }
}

var Permission = {
  toDisplay: toDisplay,
  fromJson: fromJson,
  toJson: toJson,
  toString: toString,
  fromString: fromString
};

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          email: Json_decode.field("email", Json_decode.string, json),
          slackUserId: Json_decode.optional((function (param) {
                  return Json_decode.field("slack_user_id", Json_decode.string, param);
                }), json),
          slackToken: Json_decode.optional((function (param) {
                  return Json_decode.field("slack_token", Json_decode.string, param);
                }), json),
          permissions: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("permissions", (function (param) {
                                return Json_decode.array(fromJson, param);
                              }), param);
                }), json),
          createdAt: Json_decode.field("updated_at", dateFromEpoch, json),
          updatedAt: Json_decode.field("created_at", dateFromEpoch, json)
        };
}

function toJson$1(u) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(u.id)
              ],
              tl: {
                hd: [
                  "slackUserId",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), u.slackUserId)
                ],
                tl: {
                  hd: [
                    "permissions",
                    Json_encode.array(toJson, u.permissions)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

var Id;

export {
  Id ,
  Permission ,
  fromJson$1 as fromJson,
  toJson$1 as toJson,
  
}
/* No side effect */
