

import * as React from "react";
import * as Tooltip$RecountTools from "../Tooltip.bs.js";

function Form_Button(Props) {
  var label = Props.label;
  var disabled = Props.disabled;
  var disabledReason = Props.disabledReason;
  var kindOpt = Props.kind;
  var classNameOpt = Props.className;
  var onClickOpt = Props.onClick;
  var kind = kindOpt !== undefined ? kindOpt : "button";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var type_ = kind === "reset" ? "reset" : (
      kind === "submit" ? "submit" : "button"
    );
  var button = React.createElement("div", {
        className: "control"
      }, React.createElement("button", {
            className: "button " + className,
            disabled: disabled,
            type: type_,
            onClick: onClick
          }, label));
  if (disabled && disabledReason !== undefined) {
    return React.createElement(Tooltip$RecountTools.make, {
                text: disabledReason,
                size: /* Normal */1,
                color: "has-text-white",
                position: /* Top */0,
                children: button
              });
  } else {
    return button;
  }
}

var make = Form_Button;

export {
  make ,
  
}
/* react Not a pure module */
