

import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Option$RecountTools from "./Option.bs.js";
import * as AuthStore$RecountTools from "../stores/AuthStore.bs.js";
import * as Models_Cursor$RecountTools from "../models/Models_Cursor.bs.js";

function fromJson(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json)
        };
}

var ErrorEnvelope = {
  fromJson: fromJson
};

function ApiEnvelope(Model) {
  var fromJson = function (json) {
    return {
            data: Json_decode.field("data", (function (param) {
                    return Json_decode.array(Model.fromJson, param);
                  }), json),
            pagination: Json_decode.field("links", Models_Cursor$RecountTools.Pagination.fromJson, json),
            meta: Json_decode.field("meta", Models_Cursor$RecountTools.Meta.fromJson, json)
          };
  };
  var Page = {
    fromJson: fromJson
  };
  var fromJson$1 = function (json) {
    return {
            data: Json_decode.field("data", Model.fromJson, json)
          };
  };
  return {
          Page: Page,
          fromJson: fromJson$1
        };
}

function json(js) {
  return [
          /* Json */0,
          JSON.stringify(js)
        ];
}

function string(str) {
  return [
          /* Auto */1,
          str
        ];
}

function blob(blob$1) {
  return [
          /* Auto */1,
          blob$1
        ];
}

function file(blob) {
  return [
          /* Auto */1,
          blob
        ];
}

var RequestBody = {
  json: json,
  string: string,
  blob: blob,
  file: file
};

var encodeParams = (function(p) { return (new URLSearchParams(p)).toString() });

function request(body, params, headers, withCredentialsOpt, method, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  var match = Option$RecountTools.unzip(body);
  var bodyType = match[0];
  var headers$1 = Option$RecountTools.getWithDefault(headers, {});
  var headers1 = bodyType !== undefined && !bodyType ? Object.assign({
          "Content-Type": "application/json"
        }, headers$1) : headers$1;
  var headers2;
  switch (url.TAG | 0) {
    case /* Api */0 :
    case /* VideoEditor */3 :
        headers2 = Object.assign({
              "x-auth-token": AuthStore$RecountTools.get.get().token
            }, headers1);
        break;
    default:
      headers2 = headers1;
  }
  var finalHeaders = Caml_obj.caml_equal(headers2, {}) ? undefined : Caml_option.some(headers2);
  var queryParams = Option$RecountTools.mapWithDefault(params, "", (function (p) {
          return "?" + encodeParams(p);
        }));
  var credentials = Option$RecountTools.guard(withCredentials, /* Include */2);
  var url$1;
  switch (url.TAG | 0) {
    case /* Api */0 :
    case /* Auth */1 :
        url$1 = process.env.API_HOST + url._0;
        break;
    case /* PublicApi */2 :
        url$1 = process.env.PUBLIC_API_HOST + url._0;
        break;
    case /* VideoEditor */3 :
        url$1 = process.env.VIDEO_EDITOR_HOST + url._0;
        break;
    case /* External */4 :
        url$1 = url._0;
        break;
    
  }
  return fetch(url$1 + queryParams, Fetch.RequestInit.make(method, finalHeaders, match[1], undefined, undefined, undefined, credentials, undefined, undefined, undefined, undefined, undefined)(undefined));
}

function get(body, params, headers, withCredentialsOpt, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  return request(body, params, headers, withCredentials, /* Get */0, url);
}

function post(body, params, headers, withCredentialsOpt, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  return request(body, params, headers, withCredentials, /* Post */2, url);
}

function put(body, params, headers, withCredentialsOpt, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  return request(body, params, headers, withCredentials, /* Put */3, url);
}

function patch(body, params, headers, withCredentialsOpt, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  return request(body, params, headers, withCredentials, /* Patch */8, url);
}

function $$delete(body, params, headers, withCredentialsOpt, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : true;
  return request(body, params, headers, withCredentials, /* Delete */4, url);
}

var $$AbortController = Fetch.$$AbortController;

var HeadersInit = Fetch.HeadersInit;

var $$Headers = Fetch.$$Headers;

var BodyInit = Fetch.BodyInit;

var Body = Fetch.Body;

var RequestInit = Fetch.RequestInit;

var $$Request = Fetch.$$Request;

var $$Response = Fetch.$$Response;

var $$FormData = Fetch.$$FormData;

export {
  $$AbortController ,
  HeadersInit ,
  $$Headers ,
  BodyInit ,
  Body ,
  RequestInit ,
  $$Request ,
  $$Response ,
  $$FormData ,
  ErrorEnvelope ,
  ApiEnvelope ,
  RequestBody ,
  request ,
  get ,
  post ,
  put ,
  patch ,
  $$delete ,
  
}
/* AuthStore-RecountTools Not a pure module */
