

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";

function toString(padding, i) {
  var raw = String(i);
  if (padding !== undefined) {
    return $$String.make(padding - raw.length | 0, /* '0' */48) + raw;
  } else {
    return raw;
  }
}

function formatAsTime(secs) {
  return toString(undefined, secs / 60 | 0) + ":" + toString(2, secs % 60);
}

var fromString = Belt_Int.fromString;

export {
  fromString ,
  toString ,
  formatAsTime ,
  
}
/* No side effect */
