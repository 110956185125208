

import * as Warp from "@space-labs/warp/src/Warp.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Warp_Event from "@space-labs/warp/src/Warp/Warp_Event.bs.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Warp_Header from "@space-labs/warp/src/Warp/Warp_Header.bs.js";
import * as Warp_Method from "@space-labs/warp/src/Warp/Warp_Method.bs.js";
import * as Warp_FormData from "@space-labs/warp/src/Warp/Warp_FormData.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Warp_QueryString from "@space-labs/warp/src/Warp/Warp_QueryString.bs.js";
import * as Warp_ResponseType from "@space-labs/warp/src/Warp/Warp_ResponseType.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Ordering$RecountTools from "../util/Ordering.bs.js";
import * as AuthStore$RecountTools from "./AuthStore.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Observable$RecountTools from "../util/Observable.bs.js";
import * as Models_Video$RecountTools from "../models/Models_Video.bs.js";
import * as Models_Cursor$RecountTools from "../models/Models_Cursor.bs.js";
import * as Models_Search$RecountTools from "../models/Models_Search.bs.js";

var rawVideos = MicroObservables.observable(undefined);

var indices = MicroObservables.observable(undefined);

var defaultPageSize = "150";

function makeIndexKey(searchBody, cursor) {
  var index = [];
  var index$1 = cursor !== undefined ? Arr$RecountTools.append(index, "bycursor:" + Models_Cursor$RecountTools.toString(cursor)) : index;
  var tmp;
  if (searchBody !== undefined) {
    var val = searchBody.sort;
    var sort = val !== undefined ? ["sortby" + val.field + "," + Models_Search$RecountTools.Order.toString(val.order)] : [];
    var filters = Arr$RecountTools.map(searchBody.filters, (function (f) {
            return "by" + f.name + ":" + f.value;
          }));
    tmp = Arr$RecountTools.concatMany([
          index$1,
          filters,
          sort
        ]);
  } else {
    tmp = index$1;
  }
  return Arr$RecountTools.join(Arr$RecountTools.sort(tmp, Ordering$RecountTools.string), "/");
}

var defaultIncludes = "show,renditions,tags,externals,caption,sections";

function get(id) {
  return rawVideos.transform(function (vs) {
              var match = Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id));
              if (match === undefined) {
                return /* NotLoaded */0;
              }
              var v = Caml_option.valFromOption(match);
              if (v !== undefined) {
                return /* Loaded */{
                        _0: v
                      };
              } else {
                return /* NotFound */1;
              }
            });
}

function getAll(ids) {
  return rawVideos.transform(function (vs) {
              return Arr$RecountTools.keepMap(ids, (function (id) {
                            return Option$RecountTools.flatten(Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id)));
                          }));
            });
}

function getAllBy(searchBody, cursor) {
  return Observable$RecountTools.Writable.from(rawVideos, indices).transform(function (param) {
              var dict = param[0];
              var page = Belt_MapString.get(param[1], makeIndexKey(searchBody, cursor));
              if (page !== undefined) {
                return {
                        data: Arr$RecountTools.keepMap(page.ids, (function (id) {
                                return Option$RecountTools.flatten(Belt_MapInt.get(dict, Models_Id$RecountTools.toInt(id)));
                              })),
                        pagination: page.pagination
                      };
              }
              
            });
}

function getByShow(id) {
  return rawVideos.transform(function (vs) {
              return Arr$RecountTools.keep(Arr$RecountTools.keepMap(Belt_MapInt.valuesToArray(vs), (function (v) {
                                return v;
                              })), (function (v) {
                            return Models_Id$RecountTools.refAsId(v.show) === id;
                          }));
            });
}

function add(v) {
  rawVideos.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(v.id), v);
      });
  
}

function update(id, fn) {
  rawVideos.update(function (dict) {
        return Belt_MapInt.update(dict, Models_Id$RecountTools.toInt(id), (function (__x) {
                      return Option$RecountTools.map(__x, (function (__x) {
                                    return Option$RecountTools.map(__x, fn);
                                  }));
                    }));
      });
  
}

function addAll(vs) {
  rawVideos.update(function (dict) {
        return Arr$RecountTools.reduce(vs, dict, (function (acc, v) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(v.id), v);
                    }));
      });
  
}

function $$delete(v) {
  rawVideos.update(function (dict) {
        return Belt_MapInt.remove(dict, Models_Id$RecountTools.toInt(v));
      });
  
}

function queryVideo(id) {
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Video$RecountTools.fromJson,
        toJson: Models_Video$RecountTools.toJson
      });
  var params = [[
      "include",
      defaultIncludes
    ]];
  return Async$RecountTools.flatMap(Request$RecountTools.get(undefined, params, undefined, undefined, {
                  TAG: /* Api */0,
                  _0: "/v2/videos/" + Models_Id$RecountTools.toString(id)
                }), (function (req) {
                if (req.status === 404) {
                  return Promise.resolve(undefined);
                } else {
                  return Async$RecountTools.map(Async$RecountTools.map(req.json(), R.fromJson), (function (resp) {
                                return resp.data;
                              }));
                }
              }));
}

function pollVideo(id) {
  setTimeout((function (param) {
          Async$RecountTools.map(queryVideo(id), (function (video) {
                  return Option$RecountTools.forEach(video, (function (video) {
                                var match = video.cloudflareVideoId;
                                var match$1 = video.thumbnailId;
                                if (match !== undefined && typeof match$1 !== "number") {
                                  return add(video);
                                } else {
                                  return pollVideo(id);
                                }
                              }));
                }));
          
        }), 3000);
  
}

function addPending(video) {
  rawVideos.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(video.id), video);
      });
  return pollVideo(video.id);
}

function fetchOne(id) {
  Async$RecountTools.map(queryVideo(id), (function (v) {
          rawVideos.update(function (dict) {
                return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(id), v);
              });
          
        }));
  
}

function ensureFetched(id) {
  var match = Belt_MapInt.get(rawVideos.get(), Models_Id$RecountTools.toInt(id));
  if (match !== undefined) {
    return ;
  } else {
    Async$RecountTools.map(queryVideo(id), (function (v) {
            rawVideos.update(function (dict) {
                  return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(id), v);
                });
            
          }));
    return ;
  }
}

function fetchBy(cbOpt, searchBody, cursor) {
  var cb = cbOpt !== undefined ? cbOpt : (function (param) {
        
      });
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Video$RecountTools.fromJson,
        toJson: Models_Video$RecountTools.toJson
      });
  var params = Arr$RecountTools.concat([], [
        [
          "include",
          defaultIncludes
        ],
        [
          "page[size]",
          defaultPageSize
        ]
      ]);
  var params$1 = cursor !== undefined ? Arr$RecountTools.concat(params, [[
            "page[cursor]",
            Models_Cursor$RecountTools.toString(cursor)
          ]]) : params;
  var body = searchBody !== undefined ? ({
        data: Models_Search$RecountTools.$$Request.toJson(searchBody)
      }) : ({
        data: Models_Search$RecountTools.$$Request.toJson({
              text: undefined,
              filters: [],
              sort: undefined
            })
      });
  return Option$RecountTools.getExn(Warp.send(Warp_Event.onLoad(Warp_QueryString.set(Warp_FormData.setJson(Warp_Header.add(Warp_ResponseType.setJson(Warp_Method.post(process.env.API_HOST + "/v2/videos/views/search")), "x-auth-token", AuthStore$RecountTools.get.get().token), JSON.stringify(body)), Belt_List.fromArray(params$1)), (function (res) {
                        if (res.TAG !== /* Ok */0) {
                          return ;
                        }
                        var json = res._0;
                        if (json === undefined) {
                          return ;
                        }
                        var resp = Curry._1($$Response.Page.fromJson, Caml_option.valFromOption(json));
                        addAll(resp.data);
                        indices.update(function (dict) {
                              return Belt_MapString.set(dict, makeIndexKey(searchBody, cursor), {
                                          ids: Arr$RecountTools.map(resp.data, (function (v) {
                                                  return v.id;
                                                })),
                                          pagination: {
                                            first: resp.pagination.first,
                                            next: resp.pagination.next,
                                            prev: resp.pagination.prev,
                                            last: resp.pagination.last,
                                            total: resp.meta.total
                                          }
                                        });
                            });
                        return Curry._1(cb, undefined);
                      }))));
}

function fetchShow(show) {
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Video$RecountTools.fromJson,
        toJson: Models_Video$RecountTools.toJson
      });
  var params = [
    [
      "filter[show_id]",
      Models_Id$RecountTools.toString(show)
    ],
    [
      "page[size]",
      defaultPageSize
    ],
    [
      "include",
      defaultIncludes
    ]
  ];
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, params, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/v2/videos"
                  }), (function (prim) {
                  return prim.json();
                })), R.Page.fromJson), (function (resp) {
          var videos = resp.data;
          rawVideos.update(function (videoDict) {
                return Arr$RecountTools.reduce(videos, videoDict, (function (acc, v) {
                              return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(v.id), v);
                            }));
              });
          
        }));
  
}

var OW;

var IMap;

var SMap;

export {
  OW ,
  IMap ,
  SMap ,
  get ,
  getAll ,
  getAllBy ,
  getByShow ,
  add ,
  update ,
  addAll ,
  $$delete ,
  addPending ,
  fetchOne ,
  ensureFetched ,
  fetchBy ,
  fetchShow ,
  
}
/* rawVideos Not a pure module */
