

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Url$RecountTools from "../../util/Url.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Image$RecountTools from "../../components/Image.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as SearchBar$RecountTools from "../../components/SearchBar/SearchBar.bs.js";
import * as ShowStore$RecountTools from "../../stores/ShowStore.bs.js";
import * as VideoStore$RecountTools from "../../stores/VideoStore.bs.js";
import * as Models_Video$RecountTools from "../../models/Models_Video.bs.js";
import * as Models_Search$RecountTools from "../../models/Models_Search.bs.js";
import * as PaginationLogic$RecountTools from "../../util/PaginationLogic.bs.js";
import * as SortableHeaders$RecountTools from "../../components/SortableHeaders.bs.js";
import * as Page_Videos_UploadForm$RecountTools from "./Page_Videos_UploadForm.bs.js";

var uploadButtonContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.selector("button", {
              hd: Css.display("block"),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.rem(0)),
                  tl: {
                    hd: Css.top(Css.px(-40)),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var videoRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 10
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 50
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 15
                  },
                  tl: {
                    hd: {
                      NAME: "percent",
                      VAL: 15
                    },
                    tl: {
                      hd: {
                        NAME: "percent",
                        VAL: 10
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: {
          hd: Css.alignItems("start"),
          tl: {
            hd: Css.selector("svg", {
                  hd: Css.width(Css.pct(100)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var headerRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 10
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 50
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 15
                  },
                  tl: {
                    hd: {
                      NAME: "percent",
                      VAL: 15
                    },
                    tl: {
                      hd: {
                        NAME: "percent",
                        VAL: 10
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: {
          hd: Css.alignItems("start"),
          tl: {
            hd: Css.paddingTop(Css.rem(1.5)),
            tl: /* [] */0
          }
        }
      }
    });

var videoActions = Curry._1(Css.style, {
      hd: Css.flexGrow(1),
      tl: {
        hd: Css.textAlign("right"),
        tl: {
          hd: Css.selector("a", {
                hd: Css.textTransform("uppercase"),
                tl: {
                  hd: Css.fontSize(Css.rem(0.75)),
                  tl: {
                    hd: Css.fontWeight("bold"),
                    tl: {
                      hd: Css.padding(Css.rem(0.75)),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var paginationButtons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: /* [] */0
      }
    });

var xPadding = Curry._1(Css.style, {
      hd: Css.padding2(Css.rem(0), Css.rem(1)),
      tl: /* [] */0
    });

var Styles = {
  uploadButtonContainer: uploadButtonContainer,
  videoRow: videoRow,
  headerRow: headerRow,
  videoActions: videoActions,
  paginationButtons: paginationButtons,
  xPadding: xPadding
};

var formatDate = (function(dateObj) {
      if (dateObj) {
        const date = new Date(dateObj).toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" })
        return date;
      }
    });

var formatTime = (function(dateObj) {
      if (dateObj) {
        const time = new Date(dateObj).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: '2-digit' })
        return "at " + time;
      }
    });

function Page_Videos_Listing(Props) {
  var shows = MicroObservables.useObservable(ShowStore$RecountTools.all);
  var fields = [
    {
      name: "id",
      displayName: "ID"
    },
    {
      name: "project_id",
      displayName: "Project ID"
    },
    {
      name: "description",
      displayName: "Description"
    },
    {
      name: "locations",
      displayName: "Location"
    },
    {
      name: "published_at",
      displayName: "Publish Date"
    },
    {
      name: "people",
      displayName: "Person"
    },
    {
      name: "subjects",
      displayName: "Subject"
    },
    {
      name: "organizations",
      displayName: "Organization"
    },
    {
      name: "transcript",
      displayName: "Transcript"
    },
    {
      name: "tags",
      displayName: "Tag"
    },
    {
      name: "title",
      displayName: "Title"
    },
    {
      name: "youtube_id",
      displayName: "YouTube ID"
    },
    {
      name: "frameio_id",
      displayName: "Frame.io ID"
    }
  ];
  var showsDropdDownValues = Arr$RecountTools.map(shows, (function (show) {
          return {
                  displayName: show.title,
                  value: Models_Id$RecountTools.toString(show.id)
                };
        }));
  var publishStatusToString = function (status) {
    if (status === "draft") {
      return "draft";
    } else if (status === "pending") {
      return "pending";
    } else {
      return "live";
    }
  };
  var statusDropDownValues = [
    {
      displayName: "Draft",
      value: publishStatusToString("draft")
    },
    {
      displayName: "Pending",
      value: publishStatusToString("pending")
    },
    {
      displayName: "Published",
      value: publishStatusToString("live")
    }
  ];
  var dropdowns = [
    {
      name: "show.id",
      displayName: "Show",
      values: showsDropdDownValues
    },
    {
      name: "status",
      displayName: "Status",
      values: statusDropDownValues
    }
  ];
  var columns = [
    {
      display: "",
      value: "thumbnail",
      orderable: false
    },
    {
      display: "",
      value: "title",
      orderable: false
    },
    {
      display: "Published At",
      value: "published_at",
      orderable: true
    },
    {
      display: "Updated At",
      value: "updated_at",
      orderable: true
    },
    {
      display: "",
      value: "edit",
      orderable: false
    }
  ];
  var defaultSort = {
    field: "updated_at",
    order: "desc"
  };
  var updateSearchBodyFromSearchQuery = function (currentSearchBody, searchQuery, sort) {
    var generalQuery = Arr$RecountTools.join(Arr$RecountTools.map(searchQuery, (function (qp) {
                switch (qp.TAG | 0) {
                  case /* DropDown */0 :
                  case /* Filter */1 :
                      return "";
                  case /* General */2 :
                      return qp._0;
                  
                }
              })), "");
    var filters = searchQuery.flatMap(function (qp) {
          switch (qp.TAG | 0) {
            case /* DropDown */0 :
                var fs = qp._0;
                return [{
                          name: fs.name,
                          value: fs.value
                        }];
            case /* Filter */1 :
                return [qp._0];
            case /* General */2 :
                return [];
            
          }
        });
    var body;
    if (currentSearchBody !== undefined) {
      var tmp = generalQuery === "" ? undefined : generalQuery;
      body = {
        text: tmp,
        filters: filters,
        sort: currentSearchBody.sort
      };
    } else {
      var tmp$1 = generalQuery === "" ? undefined : generalQuery;
      body = {
        text: tmp$1,
        filters: filters,
        sort: undefined
      };
    }
    if (sort !== undefined) {
      return {
              text: body.text,
              filters: body.filters,
              sort: sort
            };
    } else {
      return body;
    }
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var parseQueryParamsFromUrl = function (param) {
    var urlSearchParams = new URLSearchParams(url.search);
    var value = Url$RecountTools.SearchParams.get(urlSearchParams, "sort");
    var sort;
    if (value !== undefined) {
      var match = value.split(":");
      if (match.length !== 2) {
        sort = defaultSort;
      } else {
        var field = match[0];
        var order = match[1];
        var tmp;
        switch (order) {
          case "asc" :
              tmp = "asc";
              break;
          case "desc" :
              tmp = "desc";
              break;
          default:
            tmp = "desc";
        }
        sort = {
          field: field,
          order: tmp
        };
      }
    } else {
      sort = defaultSort;
    }
    var value$1 = Url$RecountTools.SearchParams.get(urlSearchParams, "text");
    var textField = value$1 !== undefined ? [{
          TAG: /* General */2,
          _0: value$1
        }] : [];
    var searchFields = Arr$RecountTools.reduce(fields, [], (function (filters, field) {
            var newFilters = Arr$RecountTools.reduce(urlSearchParams.getAll(field.name), [], (function (fs, value) {
                    if (value === "") {
                      return fs;
                    } else {
                      return Arr$RecountTools.concat(fs, [{
                                    TAG: /* Filter */1,
                                    _0: {
                                      name: field.name,
                                      value: value
                                    }
                                  }]);
                    }
                  }));
            return Arr$RecountTools.concat(filters, newFilters);
          }));
    var searchDropdowns = Arr$RecountTools.reduce(dropdowns, [], (function (filters, dropdown) {
            var newFilters = Arr$RecountTools.reduce(urlSearchParams.getAll(dropdown.name), [], (function (fs, value) {
                    if (value === "") {
                      return fs;
                    } else {
                      return Arr$RecountTools.concat(fs, [{
                                    TAG: /* DropDown */0,
                                    _0: {
                                      name: dropdown.name,
                                      value: value
                                    }
                                  }]);
                    }
                  }));
            return Arr$RecountTools.concat(filters, newFilters);
          }));
    return [
            Arr$RecountTools.concat(Arr$RecountTools.concat(textField, searchFields), searchDropdowns),
            sort
          ];
  };
  var match = parseQueryParamsFromUrl(undefined);
  var initialSort = match[1];
  var initialQueryParams = match[0];
  var body = updateSearchBodyFromSearchQuery(Models_Search$RecountTools.$$Request.empty, initialQueryParams, initialSort);
  var match$1 = React.useState(function () {
        return false;
      });
  var setNewVideoModalOpen = match$1[1];
  var match$2 = React.useState(function () {
        return body;
      });
  var setSearchBody = match$2[1];
  var searchBody = match$2[0];
  var match$3 = PaginationLogic$RecountTools.usePagination((function (cursor) {
          VideoStore$RecountTools.fetchBy(undefined, searchBody, cursor);
          
        }), (function (cursor) {
          return VideoStore$RecountTools.getAllBy(searchBody, cursor);
        }), 50);
  var clearPagination = match$3[3];
  var selectedCursor = match$3[2];
  var currentPageResults = match$3[1];
  React.useEffect((function () {
          ShowStore$RecountTools.ensureFetched(undefined);
          VideoStore$RecountTools.fetchBy(undefined, searchBody, selectedCursor);
          
        }), []);
  React.useEffect((function () {
          var searchParams = new URLSearchParams(url.search);
          var text = searchBody.text;
          if (text !== undefined) {
            searchParams.set("text", text);
          }
          var s = searchBody.sort;
          if (s !== undefined) {
            var match = s.field;
            var match$1 = s.order;
            var exit = 0;
            if (!(match === "updated_at" && match$1 === "desc")) {
              exit = 1;
            }
            if (exit === 1) {
              searchParams.set("sort", s.field + ":" + Models_Search$RecountTools.Order.toString(s.order));
            }
            
          }
          Arr$RecountTools.forEach(searchBody.filters, (function (f) {
                  searchParams.set(f.name, f.value);
                  
                }));
          RescriptReactRouter.push("/videos?" + searchParams.toString());
          
        }), [searchBody]);
  var openModal = function (param) {
    return Curry._1(setNewVideoModalOpen, (function (param) {
                  return true;
                }));
  };
  var newVideo = function (id) {
    return RescriptReactRouter.push("/videos/edit/" + Models_Id$RecountTools.toString(id));
  };
  var closeModal = function (param) {
    return Curry._1(setNewVideoModalOpen, (function (param) {
                  return false;
                }));
  };
  var onSearch = function (doneFn, searchQuery) {
    Curry._1(clearPagination, undefined);
    var body = updateSearchBodyFromSearchQuery(searchBody, searchQuery, undefined);
    var cancel = VideoStore$RecountTools.fetchBy(doneFn, body, selectedCursor);
    Curry._1(setSearchBody, (function (param) {
            return body;
          }));
    return cancel;
  };
  var onSort = function (sort) {
    Curry._1(clearPagination, undefined);
    var body_text = searchBody.text;
    var body_filters = searchBody.filters;
    var body_sort = sort;
    var body = {
      text: body_text,
      filters: body_filters,
      sort: body_sort
    };
    return Curry._1(setSearchBody, (function (param) {
                  return body;
                }));
  };
  var renderVideos = function (videos) {
    return Arr$RecountTools.map(videos, (function (video) {
                  var match = Models_Video$RecountTools.publishStatus(video);
                  var match$1 = video.publishedAt;
                  var tmp;
                  if (match === "live") {
                    if (match$1 !== undefined) {
                      var publishedAt = Caml_option.valFromOption(match$1);
                      tmp = React.createElement(React.Fragment, undefined, "Published at", React.createElement("br", undefined), formatDate(publishedAt), React.createElement("br", undefined), formatTime(publishedAt));
                    } else {
                      tmp = "Draft";
                    }
                  } else if (match === "pending" && match$1 !== undefined) {
                    var publishedAt$1 = Caml_option.valFromOption(match$1);
                    tmp = React.createElement(React.Fragment, undefined, "Scheduled for", React.createElement("br", undefined), formatDate(publishedAt$1), React.createElement("br", undefined), formatTime(publishedAt$1));
                  } else {
                    tmp = "Draft";
                  }
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: Models_Id$RecountTools.toString(video.id)
                            }, React.createElement("div", {
                                  className: videoRow
                                }, React.createElement(Image$RecountTools.make, {
                                      id: video.thumbnailId,
                                      width: 100,
                                      height: 56,
                                      fit: /* Fit */{
                                        _0: "cover"
                                      },
                                      draggable: false
                                    }), React.createElement("h2", {
                                      className: xPadding
                                    }, video.title), React.createElement("span", {
                                      className: xPadding
                                    }, tmp), React.createElement("span", {
                                      className: xPadding
                                    }, formatDate(video.updatedAt), React.createElement("br", undefined), formatTime(video.updatedAt)), React.createElement("span", {
                                      className: videoActions
                                    }, React.createElement(Link$RecountTools.make, {
                                          href: "/videos/edit/" + Models_Id$RecountTools.toString(video.id),
                                          children: "Edit"
                                        }))), React.createElement("hr", {
                                  className: "hr"
                                }));
                }));
  };
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: null,
              pageName: "Videos"
            }, React.createElement(SearchBar$RecountTools.make, {
                  onSearch: onSearch,
                  fields: fields,
                  dropDowns: dropdowns,
                  initialQueryParams: initialQueryParams
                }), React.createElement("div", {
                  className: uploadButtonContainer
                }, React.createElement("button", {
                      className: "button is-info",
                      onClick: openModal
                    }, "Upload New Video")), React.createElement(Page_Videos_UploadForm$RecountTools.make, {
                  isOpen: match$1[0],
                  onClose: closeModal,
                  onSubmit: newVideo
                }), React.createElement("div", {
                  className: headerRow
                }, React.createElement(SortableHeaders$RecountTools.make, {
                      onSort: onSort,
                      initialSort: initialSort,
                      columns: columns
                    })), React.createElement("hr", {
                  className: "hr"
                }), renderVideos(currentPageResults !== undefined ? currentPageResults : []), Curry._1(match$3[0], undefined));
}

var UploadForm;

var Cursor;

var make = Page_Videos_Listing;

export {
  UploadForm ,
  Cursor ,
  Styles ,
  formatDate ,
  formatTime ,
  make ,
  
}
/* uploadButtonContainer Not a pure module */
