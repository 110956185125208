

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Option$RecountTools from "./Option.bs.js";

function getJson(key) {
  return Option$RecountTools.map(Caml_option.nullable_to_opt(localStorage.getItem(key)), (function (prim) {
                return JSON.parse(prim);
              }));
}

function setJson(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
  
}

function get(key, fromJson) {
  return Option$RecountTools.map(getJson(key), fromJson);
}

function set(key, toJson, value) {
  return setJson(key, Curry._1(toJson, value));
}

export {
  getJson ,
  setJson ,
  get ,
  set ,
  
}
/* No side effect */
