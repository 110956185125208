

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Modal$RecountTools from "../../components/Modal.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Form_Tags$RecountTools from "../../components/Form/Form_Tags.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";

var validators = {
  tags: {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (input) {
        var match = input.tags.length;
        if (match !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: input.tags
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "At least one tag is required"
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          tags: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            tags: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.tags;
  var match$1 = match ? match._0 : Curry._1(validators.tags.validate, input);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              tags: match$1._0
            },
            fieldsStatuses: {
              tags: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              tags: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTagsField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.tags, validators.tags, (function (status) {
                          return {
                                  tags: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */1,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */2,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */4);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */3);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateTagsField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.tags, state.submissionStatus, validators.tags, (function (status) {
                                    return {
                                            tags: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                tags: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                tags: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateTags: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTagsField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurTags: (function (param) {
              return Curry._1(dispatch, /* BlurTagsField */0);
            }),
          tagsResult: Formality.exposeFieldResult(state.fieldsStatuses.tags),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.tags) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */3,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */4);
            })
        };
}

var MergeTagForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function Page_Tags_MergeTagForm(Props) {
  var targetTag = Props.targetTag;
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var form = useForm({
        tags: []
      }, (function (output, cb) {
          var body = Request$RecountTools.RequestBody.json({
                data: {
                  tagsToMerge: Belt_Array.map(output.tags, (function (tag) {
                          return tag.id;
                        })),
                  target: targetTag.id
                }
              });
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(body, undefined, undefined, undefined, {
                            TAG: /* Api */0,
                            _0: "/v2/tags/by/merge"
                          }), (function (prim) {
                          return prim.json();
                        })), (function (param) {
                      Curry._1(onClose, undefined);
                      Curry._1(cb.reset, undefined);
                      Curry._3(makeToast, undefined, /* Confirm */1, "Tags Merged");
                      
                    })), (function (param) {
                  Curry._3(makeToast, undefined, /* Error */2, "Unable to merge Tags");
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var modalBody = React.createElement("form", {
        onSubmit: (function ($$event) {
            $$event.preventDefault();
            return Curry._1(form.submit, undefined);
          })
      }, React.createElement(Modal$RecountTools.Header.make, {
            children: "Merge Tags"
          }), React.createElement(Modal$RecountTools.Body.make, {
            children: React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: React.createElement(Form_Tags$RecountTools.make, {
                              label: "Tags to Merge",
                              value: form.input.tags,
                              exclude: targetTag,
                              disabled: form.submitting,
                              error: form.tagsResult,
                              onBlur: form.blurTags,
                              onChange: (function (tag) {
                                  if (tag.TAG === /* Added */0) {
                                    var tag$1 = tag._0;
                                    return Curry._2(form.updateTags, (function (input, param) {
                                                  var updatedTags = Arr$RecountTools.concat(input.tags, [tag$1]);
                                                  return {
                                                          tags: updatedTags
                                                        };
                                                }), []);
                                  }
                                  var tag$2 = tag._0;
                                  return Curry._2(form.updateTags, (function (input, param) {
                                                var updatedTags = Arr$RecountTools.keep(input.tags, (function (t) {
                                                        return t.id !== tag$2.id;
                                                      }));
                                                return {
                                                        tags: updatedTags
                                                      };
                                              }), []);
                                })
                            })
                      })
                })
          }), React.createElement(Modal$RecountTools.Footer.make, {
            children: React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, {
                      label: "Cancel",
                      disabled: form.submitting,
                      className: "is-gray",
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        })
                    }), React.createElement(Form_Button$RecountTools.make, {
                      label: "Submit",
                      disabled: form.submitting,
                      kind: "submit",
                      className: "is-link"
                    }))
          }));
  return React.createElement(Modal$RecountTools.make, {
              isActive: isOpen,
              children: modalBody,
              onModalClose: onClose
            });
}

var make = Page_Tags_MergeTagForm;

export {
  MergeTagForm ,
  make ,
  
}
/* react Not a pure module */
