

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "./Arr.bs.js";
import * as Option$RecountTools from "./Option.bs.js";

function map(l, fn) {
  if (typeof l === "number") {
    if (l !== 0) {
      return /* NotFound */1;
    } else {
      return /* NotLoaded */0;
    }
  } else {
    return /* Loaded */{
            _0: Curry._1(fn, l._0)
          };
  }
}

function toOption(l) {
  if (typeof l === "number") {
    return ;
  } else {
    return Caml_option.some(l._0);
  }
}

function getExn(l) {
  return Option$RecountTools.getExn(toOption(l));
}

function toList(l) {
  if (typeof l === "number") {
    return /* [] */0;
  } else {
    return {
            hd: l._0,
            tl: /* [] */0
          };
  }
}

function toArray(l) {
  if (typeof l === "number") {
    return [];
  } else {
    return [l._0];
  }
}

function contains(l, v) {
  if (typeof l === "number") {
    return false;
  } else {
    return Caml_obj.caml_equal(v, l._0);
  }
}

function forEach(l, fn) {
  map(l, fn);
  
}

function all(loadables) {
  return Arr$RecountTools.reduce(loadables, /* Loaded */{
              _0: []
            }, (function (acc, loadable) {
                if (typeof acc === "number") {
                  if (acc !== 0) {
                    return /* NotFound */1;
                  }
                  
                } else {
                  if (typeof loadable !== "number") {
                    return /* Loaded */{
                            _0: Arr$RecountTools.concat(acc._0, [loadable._0])
                          };
                  }
                  if (loadable === 0) {
                    return /* NotLoaded */0;
                  }
                  
                }
                if (typeof loadable === "number" && loadable !== 0) {
                  return /* NotFound */1;
                } else {
                  return /* NotLoaded */0;
                }
              }));
}

export {
  map ,
  toOption ,
  getExn ,
  toList ,
  toArray ,
  contains ,
  forEach ,
  all ,
  
}
/* No side effect */
