

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Icon$RecountTools from "./Icon.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";

var container = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.right(Css.px(50)),
        tl: {
          hd: Css.bottom(Css.px(30)),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  container: container
};

var makeToastId = (function (i) { return i; });

var toastIdToInt = (function (i) { return i; });

var toastContext = React.createContext([
      (function (param, param$1, param$2) {
          return makeToastId(0);
        }),
      (function (param) {
          
        })
    ]);

function ToastProvider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return [];
      });
  var setToasts = match[1];
  var toasts = match[0];
  var clearToast = function (id) {
    return Curry._1(setToasts, (function (ts) {
                  return Arr$RecountTools.keep(ts, (function (t) {
                                return Caml_obj.caml_notequal(t.id, id);
                              }));
                }));
  };
  var makeToast = function (closeableOpt, severityOpt, content) {
    var closeable = closeableOpt !== undefined ? closeableOpt : true;
    var severity = severityOpt !== undefined ? severityOpt : /* Info */0;
    var prev = Option$RecountTools.getWithDefault(Arr$RecountTools.sumBy(toasts, (function (t) {
                return toastIdToInt(t.id);
              })), 0);
    var newId = makeToastId(Js_math.random_int(0, 9999) + prev | 0);
    Curry._1(setToasts, (function (ts) {
            return Arr$RecountTools.concat(ts, [{
                          id: newId,
                          content: content,
                          closeable: closeable,
                          severity: severity
                        }]);
          }));
    return newId;
  };
  var value = [
    makeToast,
    clearToast
  ];
  var make = toastContext.Provider;
  var className = function (severity) {
    switch (severity) {
      case /* Info */0 :
          return "notification is-info";
      case /* Confirm */1 :
          return "notification is-success";
      case /* Error */2 :
          return "notification is-danger";
      
    }
  };
  return React.createElement(React.Fragment, {
              children: null
            }, React.useMemo((function () {
                    return React.createElement(make, {
                                value: value,
                                children: children
                              });
                  }), [children]), React.createElement("div", {
                  className: container
                }, Arr$RecountTools.map(toasts, (function (toast) {
                        return React.createElement("div", {
                                    className: className(toast.severity)
                                  }, toast.closeable ? React.createElement("button", {
                                          className: "delete",
                                          onClick: (function (param) {
                                              return clearToast(toast.id);
                                            })
                                        }) : React.createElement(React.Fragment, undefined, React.createElement(Icon$RecountTools.Spinner.make, {}), " "), toast.content);
                      }))));
}

function useToast(param) {
  return React.useContext(toastContext);
}

var make = ToastProvider;

export {
  Styles ,
  toastContext ,
  make ,
  useToast ,
  
}
/* container Not a pure module */
