

import * as React from "react";
import ReactDatepicker from "react-datepicker";

function Form_Date(Props) {
  var value = Props.value;
  var showTimeInput = Props.showTimeInput;
  var label = Props.label;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: "field"
            }, React.createElement("label", {
                  className: "label"
                }, label), React.createElement(ReactDatepicker, {
                  selected: value,
                  onChange: onChange,
                  showTimeInput: showTimeInput,
                  className: "input",
                  dateFormat: "MMMM d, yyyy h:mm aa"
                }));
}

var make = Form_Date;

export {
  make ,
  
}
/* react Not a pure module */
