

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toList(opt) {
  if (opt !== undefined) {
    return {
            hd: Caml_option.valFromOption(opt),
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function toArray(opt) {
  if (opt !== undefined) {
    return [Caml_option.valFromOption(opt)];
  } else {
    return [];
  }
}

function guard(pred, v) {
  if (pred) {
    return Caml_option.some(v);
  }
  
}

function contains(opt, v) {
  if (opt !== undefined) {
    return Caml_obj.caml_equal(v, Caml_option.valFromOption(opt));
  } else {
    return false;
  }
}

function orElse(opt, other) {
  if (opt !== undefined) {
    return opt;
  } else {
    return other;
  }
}

function zip(a, b) {
  if (a !== undefined && b !== undefined) {
    return [
            Caml_option.valFromOption(a),
            Caml_option.valFromOption(b)
          ];
  }
  
}

function unzip(ab) {
  if (ab !== undefined) {
    return [
            Caml_option.some(ab[0]),
            Caml_option.some(ab[1])
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

function forEach(opt, fn) {
  Belt_Option.map(opt, fn);
  
}

function keep(opt, fn) {
  if (opt === undefined) {
    return ;
  }
  var a = Caml_option.valFromOption(opt);
  if (Curry._1(fn, a)) {
    return Caml_option.some(a);
  }
  
}

function flatten(opt) {
  if (opt === undefined) {
    return ;
  }
  var v = Caml_option.valFromOption(opt);
  if (v !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(v));
  }
  
}

var keepU = Belt_Option.keepU;

var forEachU = Belt_Option.forEachU;

var getExn = Belt_Option.getExn;

var mapWithDefaultU = Belt_Option.mapWithDefaultU;

var mapWithDefault = Belt_Option.mapWithDefault;

var mapU = Belt_Option.mapU;

var map = Belt_Option.map;

var flatMapU = Belt_Option.flatMapU;

var flatMap = Belt_Option.flatMap;

var getWithDefault = Belt_Option.getWithDefault;

var isSome = Belt_Option.isSome;

var isNone = Belt_Option.isNone;

var eqU = Belt_Option.eqU;

var eq = Belt_Option.eq;

var cmpU = Belt_Option.cmpU;

var cmp = Belt_Option.cmp;

export {
  keepU ,
  forEachU ,
  getExn ,
  mapWithDefaultU ,
  mapWithDefault ,
  mapU ,
  map ,
  flatMapU ,
  flatMap ,
  getWithDefault ,
  isSome ,
  isNone ,
  eqU ,
  eq ,
  cmpU ,
  cmp ,
  toList ,
  toArray ,
  guard ,
  contains ,
  orElse ,
  zip ,
  unzip ,
  forEach ,
  keep ,
  flatten ,
  
}
/* No side effect */
