

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

function useInterval(fn, time) {
  React.useEffect((function () {
          var id = setInterval(fn, time);
          return (function (param) {
                    clearInterval(id);
                    
                  });
        }), []);
  
}

function useMemo(thunk) {
  var ref = React.useRef(undefined);
  var value = Curry._1(thunk, undefined);
  if (value !== ref.current) {
    ref.current = value;
  }
  return ref.current;
}

function useMemo1(thunk, v1) {
  var ref = React.useRef(Curry._1(thunk, undefined));
  var ref1 = React.useRef(v1);
  if (Caml_obj.caml_notequal(v1, ref1.current)) {
    ref1.current = v1;
    ref.current = Curry._1(thunk, undefined);
  }
  return ref.current;
}

function useMemo2(thunk, v1, v2) {
  var ref = React.useRef(Curry._1(thunk, undefined));
  var ref1 = React.useRef(v1);
  var ref2 = React.useRef(v2);
  if (Caml_obj.caml_notequal(v1, ref1.current) || Caml_obj.caml_notequal(v2, ref2.current)) {
    ref1.current = v1;
    ref2.current = v2;
    ref.current = Curry._1(thunk, undefined);
  }
  return ref.current;
}

export {
  useInterval ,
  useMemo ,
  useMemo1 ,
  useMemo2 ,
  
}
/* react Not a pure module */
