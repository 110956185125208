

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Modal$RecountTools from "../Modal.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Form_Input$RecountTools from "../Form/Form_Input.bs.js";
import * as Form_Button$RecountTools from "../Form/Form_Button.bs.js";

var validators_title = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.title;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Title is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.title
              };
      }
    })
};

var validators_href = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.href;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Href is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.href
              };
      }
    })
};

var validators = {
  title: validators_title,
  href: validators_href
};

function initialFieldsStatuses(_input) {
  return {
          title: /* Pristine */0,
          href: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            title: /* Pristine */0,
            href: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.title;
  var match_0 = match ? match._0 : Curry._1(validators.title.validate, input);
  var match$1 = fieldsStatuses.href;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.href.validate, input);
  var titleResult = match_0;
  var titleResult$1;
  if (titleResult.TAG === /* Ok */0) {
    var hrefResult = match_0$1;
    if (hrefResult.TAG === /* Ok */0) {
      return {
              TAG: /* Valid */0,
              output: {
                href: hrefResult._0,
                title: titleResult._0
              },
              fieldsStatuses: {
                title: /* Dirty */{
                  _0: titleResult,
                  _1: /* Shown */0
                },
                href: /* Dirty */{
                  _0: hrefResult,
                  _1: /* Shown */0
                }
              },
              collectionsStatuses: undefined
            };
    }
    titleResult$1 = titleResult;
  } else {
    titleResult$1 = titleResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            title: /* Dirty */{
              _0: titleResult$1,
              _1: /* Shown */0
            },
            href: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTitleField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: status,
                                  href: init.href
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurHrefField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.href, validators_href, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  href: status
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */2 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */2,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */3,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */5);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */4);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */3 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */4 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */5 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateTitleField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: status,
                                            href: init.href
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateHrefField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.href, state.submissionStatus, validators_href, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            title: init.title,
                                            href: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */2 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                title: /* Pristine */0,
                                href: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                title: /* Pristine */0,
                                href: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */3 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */4 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateTitle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTitleField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateHref: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateHrefField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurTitle: (function (param) {
              return Curry._1(dispatch, /* BlurTitleField */0);
            }),
          blurHref: (function (param) {
              return Curry._1(dispatch, /* BlurHrefField */1);
            }),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          hrefResult: Formality.exposeFieldResult(state.fieldsStatuses.href),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.title || match.href) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */2);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */3);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */4);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */4,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */5);
            })
        };
}

var LinkForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function LinkForm$1(Props) {
  var initialValues = Props.initialValues;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var onDelete = Props.onDelete;
  var initialInput = initialValues !== undefined ? ({
        href: initialValues[0],
        title: initialValues[1]
      }) : ({
        href: "",
        title: ""
      });
  var form = useForm(initialInput, (function (output, param) {
          return Curry._2(onSubmit, output.href, output.title);
        }));
  return React.createElement(Modal$RecountTools.make, {
              isActive: true,
              children: React.createElement("form", {
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        return Curry._1(form.submit, undefined);
                      })
                  }, React.createElement(Modal$RecountTools.Header.make, {
                        children: "Add a Link"
                      }), React.createElement(Modal$RecountTools.Body.make, {
                        children: null
                      }, React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.href,
                            label: "Target",
                            error: form.hrefResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateHref, (function (input, value) {
                                              return {
                                                      href: value,
                                                      title: input.title
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurHref, undefined);
                              }),
                            disabled: form.submitting,
                            placeholder: "https://www.example.com/this-is-a-page"
                          }), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.title,
                            label: "Title",
                            error: form.titleResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateTitle, (function (input, value) {
                                              return {
                                                      href: input.href,
                                                      title: value
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurTitle, undefined);
                              }),
                            disabled: form.submitting
                          })), React.createElement(Modal$RecountTools.Footer.make, {
                        children: null
                      }, React.createElement("div", {
                            className: "field is-horizontal mr-auto mb-0"
                          }, onDelete !== undefined ? React.createElement(Form_Button$RecountTools.make, {
                                  label: "Remove",
                                  disabled: form.submitting,
                                  className: "is-danger",
                                  onClick: (function (param) {
                                      return Curry._1(onDelete, undefined);
                                    })
                                }) : null), React.createElement("div", {
                            className: "field is-horizontal is-grouped"
                          }, React.createElement(Form_Button$RecountTools.make, {
                                label: "Cancel",
                                disabled: form.submitting,
                                className: "is-gray",
                                onClick: (function (param) {
                                    return Curry._1(onClose, undefined);
                                  })
                              }), React.createElement(Form_Button$RecountTools.make, {
                                label: Option$RecountTools.isNone(initialValues) ? "Add" : "Update",
                                disabled: form.submitting,
                                kind: "submit",
                                className: "is-link"
                              })))),
              onModalClose: (function (param) {
                  Curry._1(form.reset, undefined);
                  return Curry._1(onClose, undefined);
                })
            });
}

var make = LinkForm$1;

export {
  LinkForm ,
  make ,
  
}
/* react Not a pure module */
