

import * as ReactGoogleLogin from "react-google-login";

function useGoogleLogin(redirectUri, responseType, onSuccess, onFailure, clientId) {
  return ReactGoogleLogin.useGoogleLogin({
              clientId: clientId,
              redirectUri: redirectUri,
              responseType: responseType,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

export {
  useGoogleLogin ,
  
}
/* react-google-login Not a pure module */
