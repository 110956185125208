

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Int$RecountTools from "../util/Int.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Models_Thumbnail$RecountTools from "../models/Models_Thumbnail.bs.js";
import SimpleSvgPlaceholder from "@cloudfour/simple-svg-placeholder";

var main = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgb",
            VAL: [
              241,
              242,
              243
            ]
          }),
      tl: /* [] */0
    });

var Styles = {
  main: main
};

function Image$Spinner(Props) {
  return React.createElement("svg", {
              className: main,
              preserveAspectRatio: "xMidYMid",
              viewBox: "0 22 100 56",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("circle", {
                  cx: "50",
                  cy: "50",
                  fill: "none",
                  r: "5",
                  stroke: "#6a6a6a",
                  strokeDasharray: "23.5 8",
                  strokeWidth: "1.5"
                }, React.createElement("animateTransform", {
                      type: "rotate",
                      attributeName: "transform",
                      dur: "1s",
                      keyTimes: "0;1",
                      repeatCount: "indefinite",
                      values: "0 50 50;360 50 50"
                    })));
}

var Spinner = {
  make: Image$Spinner
};

function Image$Dots(Props) {
  return React.createElement("svg", {
              className: main,
              preserveAspectRatio: "xMidYMid",
              viewBox: "0 22 100 56",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  transform: "translate(25 50)"
                }, React.createElement("circle", {
                      cx: "0",
                      cy: "0",
                      fill: "#6a6a6a",
                      r: "6"
                    }, React.createElement("animateTransform", {
                          type: "scale",
                          attributeName: "transform",
                          begin: "-0.4694835680751173s",
                          calcMode: "spline",
                          dur: "1.408450704225352s",
                          keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                          keyTimes: "0;0.5;1",
                          repeatCount: "indefinite",
                          values: "0;1;0"
                        }))), React.createElement("g", {
                  transform: "translate(50 50)"
                }, React.createElement("circle", {
                      cx: "0",
                      cy: "0",
                      fill: "#6a6a6a",
                      r: "6"
                    }, React.createElement("animateTransform", {
                          type: "scale",
                          attributeName: "transform",
                          begin: "-0.23474178403755866s",
                          calcMode: "spline",
                          dur: "1.408450704225352s",
                          keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                          keyTimes: "0;0.5;1",
                          repeatCount: "indefinite",
                          values: "0;1;0"
                        }))), React.createElement("g", {
                  transform: "translate(75 50)"
                }, React.createElement("circle", {
                      cx: "0",
                      cy: "0",
                      fill: "#6a6a6a",
                      r: "6"
                    }, React.createElement("animateTransform", {
                          type: "scale",
                          attributeName: "transform",
                          begin: "0s",
                          calcMode: "spline",
                          dur: "1.408450704225352s",
                          keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                          keyTimes: "0;0.5;1",
                          repeatCount: "indefinite",
                          values: "0;1;0"
                        }))));
}

var Dots = {
  make: Image$Dots
};

function imagePlaceholder(width, height, text, fontFamily, fontWeight, fontSize, dy, bgColor, textColor, param) {
  return SimpleSvgPlaceholder({
              width: width,
              height: height,
              text: text,
              fontFamily: fontFamily,
              fontWeight: fontWeight,
              fontSize: fontSize,
              dy: dy,
              bgColor: bgColor,
              textColor: textColor
            });
}

function imageUrl(width, height, fit, gravity, imgId) {
  if (imgId === undefined) {
    return imagePlaceholder(width, height, "No Thumbnail", undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
  var widthStr = Option$RecountTools.toArray(Option$RecountTools.map(width, (function (w) {
              return "width=" + Int$RecountTools.toString(undefined, w);
            })));
  var heightStr = Option$RecountTools.toArray(Option$RecountTools.map(height, (function (w) {
              return "height=" + Int$RecountTools.toString(undefined, w);
            })));
  var fitStr = Option$RecountTools.toArray(Option$RecountTools.map(fit, (function (f) {
              var imgFit = f._0;
              if (imgFit === "cover") {
                return "fit=cover";
              } else if (imgFit === "pad") {
                return "fit=pad";
              } else if (imgFit === "contain") {
                return "fit=contain";
              } else if (imgFit === "scaleDown") {
                return "fit=scale-down";
              } else {
                return "fit=crop";
              }
            })));
  var gravityStr = Option$RecountTools.toArray(Option$RecountTools.map(gravity, (function (g) {
              if (typeof g === "number") {
                return "gravity=auto";
              }
              if (g.TAG !== /* Side */0) {
                return "gravity=" + Int$RecountTools.toString(undefined, g._0) + "x" + Int$RecountTools.toString(undefined, g._1);
              }
              var side = g._0;
              if (side === "bottom") {
                return "gravity=bottom";
              } else if (side === "right") {
                return "gravity=right";
              } else if (side === "top") {
                return "gravity=top";
              } else {
                return "gravity=left";
              }
            })));
  var formatStr = ["f=auto"];
  var cloudflareArgs = Arr$RecountTools.join(Arr$RecountTools.concatMany([
            widthStr,
            heightStr,
            fitStr,
            gravityStr,
            formatStr
          ]), ",");
  return "https://therecount.com/cdn-cgi/image/" + cloudflareArgs + "/" + imgId;
}

function $$Image(Props) {
  var id = Props.id;
  var width = Props.width;
  var height = Props.height;
  var fit = Props.fit;
  var gravity = Props.gravity;
  var draggableOpt = Props.draggable;
  var draggable = draggableOpt !== undefined ? draggableOpt : true;
  if (id === /* ThumbnailProcessing */0) {
    return React.createElement(Image$Spinner, {});
  } else {
    return React.createElement("img", {
                draggable: draggable,
                src: imageUrl(width, height, fit, gravity, Models_Thumbnail$RecountTools.toOption(id))
              });
  }
}

var Thumbnail;

var make = $$Image;

export {
  Thumbnail ,
  Styles ,
  Spinner ,
  Dots ,
  imagePlaceholder ,
  imageUrl ,
  make ,
  
}
/* main Not a pure module */
