

import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as AuthStore$RecountTools from "../stores/AuthStore.bs.js";
import * as GoogleLogin$RecountTools from "../components/GoogleLogin.bs.js";

function Page_Login(Props) {
  var children = Props.children;
  var auth = MicroObservables.useObservable(AuthStore$RecountTools.getOpt);
  var parseAuth = function (json) {
    var parseObj = function (json) {
      return {
              token: Json_decode.field("authToken", Json_decode.string, json),
              expires: Json_decode.field("tokenExpires", Json_decode.$$float, json)
            };
    };
    return Json_decode.field("data", parseObj, json);
  };
  var onSuccess = function (user) {
    var payload = Request$RecountTools.RequestBody.json({
          tokenId: user.tokenId
        });
    Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(payload, undefined, undefined, undefined, {
                  TAG: /* Auth */1,
                  _0: "/auth/google-login"
                }), (function (prim) {
                return prim.json();
              })), (function (json) {
            return AuthStore$RecountTools.set(parseAuth(json));
          }));
    
  };
  var onFailure = function (error) {
    console.error(error);
    
  };
  var match = GoogleLogin$RecountTools.useGoogleLogin(process.env.GOOGLE_CLIENT_REDIRECT, "id_token", onSuccess, onFailure, process.env.GOOGLE_CLIENT_ID);
  if (auth !== undefined) {
    return children;
  } else {
    return React.createElement("div", {
                className: "hero is-fullheight is-primary is-bold"
              }, React.createElement("div", {
                    className: "hero-head has-text-centered"
                  }, React.createElement("h1", {
                        className: "title"
                      }, "The Recount Publishing Tools")), React.createElement("div", {
                    className: "hero-body"
                  }, React.createElement("div", {
                        className: "container has-text-centered has-text-dark"
                      }, React.createElement("button", {
                            className: match.loaded ? "button" : "button is-loading",
                            onClick: match.signIn
                          }, "Log in with Google"))));
  }
}

var make = Page_Login;

export {
  make ,
  
}
/* react Not a pure module */
