

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Icon$RecountTools from "../../components/Icon.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as UserStore$RecountTools from "../../stores/UserStore.bs.js";
import * as Models_User$RecountTools from "../../models/Models_User.bs.js";
import * as Page_NotAuthorized$RecountTools from "../NotAuthorized/Page_NotAuthorized.bs.js";

var userRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 40
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 50
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 7
                  },
                  tl: /* [] */0
                }
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var permissions = Curry._1(Css.style, {
      hd: Css.marginRight("auto"),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: /* [] */0
          }
        }
      }
    });

var userActions = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.textAlign("right"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.selector("a", {
                    hd: Css.textTransform("uppercase"),
                    tl: {
                      hd: Css.fontSize(Css.rem(0.85)),
                      tl: {
                        hd: Css.fontWeight("bold"),
                        tl: {
                          hd: Css.padding(Css.rem(0.75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var xPadding = Curry._1(Css.style, {
      hd: Css.padding2(Css.rem(0), Css.rem(1)),
      tl: /* [] */0
    });

var email = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.verticalAlign("middle"),
        tl: /* [] */0
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(10)),
      tl: {
        hd: Css.display("inlineBlock"),
        tl: {
          hd: Css.verticalAlign("middle"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  userRow: userRow,
  permissions: permissions,
  userActions: userActions,
  xPadding: xPadding,
  email: email,
  icon: icon
};

function Page_Users_Listing(Props) {
  var currentUser = MicroObservables.useObservable(UserStore$RecountTools.getCurrent(undefined));
  var permissions$1 = typeof currentUser === "number" ? [] : currentUser._0.permissions;
  var users = MicroObservables.useObservable(UserStore$RecountTools.all);
  var sortedUsers = Arr$RecountTools.sort(users, (function (a, b) {
          if (a.email > b.email) {
            return /* GT */2;
          } else if (a.email < b.email) {
            return /* LT */0;
          } else {
            return /* EQ */1;
          }
        }));
  React.useEffect((function () {
          UserStore$RecountTools.ensureFetched(undefined);
          UserStore$RecountTools.ensureFetchedCurrent(undefined);
          
        }), []);
  var renderUsers = function (param) {
    return Arr$RecountTools.map(sortedUsers, (function (u) {
                  var match = u.slackUserId;
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: Models_Id$RecountTools.toString(u.id)
                            }, React.createElement("div", {
                                  className: userRow
                                }, React.createElement("div", undefined, React.createElement("h2", {
                                          className: email
                                        }, u.email), match !== undefined ? React.createElement("div", {
                                            className: icon
                                          }, React.createElement(Icon$RecountTools.AddToSlack.make, {})) : null), React.createElement("span", {
                                      className: permissions
                                    }, Arr$RecountTools.join(Arr$RecountTools.map(u.permissions, Models_User$RecountTools.Permission.toDisplay), ", ")), React.createElement("span", {
                                      className: userActions
                                    }, React.createElement(Link$RecountTools.make, {
                                          href: "/users/edit/" + Models_Id$RecountTools.toString(u.id),
                                          children: "Edit"
                                        }))), React.createElement("hr", {
                                  className: "hr"
                                }));
                }));
  };
  if (permissions$1.includes("editUsers")) {
    return React.createElement(Layout$RecountTools.AppContainer.make, {
                children: null,
                pageName: "Users"
              }, React.createElement("hr", {
                    className: "hr"
                  }), renderUsers(undefined));
  } else {
    return React.createElement(Page_NotAuthorized$RecountTools.make, {});
  }
}

var make = Page_Users_Listing;

export {
  Styles ,
  make ,
  
}
/* userRow Not a pure module */
