

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function fromJson(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function toJson(ps) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(ps.id)
              ],
              tl: {
                hd: [
                  "name",
                  ps.name
                ],
                tl: /* [] */0
              }
            });
}

var Id;

export {
  Id ,
  fromJson ,
  toJson ,
  
}
/* No side effect */
