

import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Models_PodcastShow$RecountTools from "../models/Models_PodcastShow.bs.js";

var rawPodcastShows = MicroObservables.observable(undefined);

function getAll(ids) {
  return rawPodcastShows.transform(function (ps) {
              return Arr$RecountTools.keepMap(ids, (function (id) {
                            return Belt_MapInt.get(ps, Models_Id$RecountTools.toInt(id));
                          }));
            });
}

var all = rawPodcastShows.transform(Belt_MapInt.valuesToArray);

function addAll(ps) {
  rawPodcastShows.update(function (dict) {
        return Arr$RecountTools.reduce(ps, dict, (function (acc, p) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(p.id), p);
                    }));
      });
  
}

function queryShows(param) {
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: Models_PodcastShow$RecountTools.fromJson,
        toJson: Models_PodcastShow$RecountTools.toJson
      });
  return Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                          TAG: /* Api */0,
                          _0: "/v2/podcast-shows"
                        }), (function (prim) {
                        return prim.json();
                      })), $$Response.Page.fromJson), (function (resp) {
                return resp.data;
              }));
}

function fetchAll(param) {
  Async$RecountTools.map(queryShows(undefined), addAll);
  
}

function ensureFetched(param) {
  var match = Belt_MapInt.valuesToArray(rawPodcastShows.get());
  if (match.length !== 0) {
    return ;
  } else {
    return fetchAll(undefined);
  }
}

function resolve(ref) {
  if (ref.TAG === /* Resolved */0) {
    return Promise.resolve(ref._0);
  }
  var id = ref._0;
  var show = Belt_MapInt.get(rawPodcastShows.get(), Models_Id$RecountTools.toInt(id));
  if (show !== undefined) {
    return Promise.resolve(show);
  } else {
    return Async$RecountTools.map(queryShows(undefined), (function (shows) {
                  addAll(shows);
                  return Option$RecountTools.getExn(Arr$RecountTools.find(shows, (function (s) {
                                    return s.id === id;
                                  })));
                }));
  }
}

var OW;

var IMap;

export {
  OW ,
  IMap ,
  getAll ,
  all ,
  addAll ,
  fetchAll ,
  ensureFetched ,
  resolve ,
  
}
/* rawPodcastShows Not a pure module */
