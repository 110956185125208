

import * as React from "react";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";

function displayError(e) {
  if (e !== undefined && e.TAG !== /* Ok */0) {
    return React.createElement("p", {
                className: "help is-danger"
              }, e._0);
  } else {
    return null;
  }
}

function Form_Select(Props) {
  var label = Props.label;
  var value = Props.value;
  var options = Props.options;
  var disabled = Props.disabled;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var placeholder = Props.placeholder;
  var error = Props.error;
  var className = error !== undefined && error.TAG !== /* Ok */0 ? "select is-danger" : "select";
  return React.createElement("div", {
              key: label + "-field",
              className: "field"
            }, React.createElement("label", {
                  className: "label"
                }, label), React.createElement("div", {
                  className: "control"
                }, React.createElement("div", {
                      className: className
                    }, React.createElement("select", {
                          disabled: disabled,
                          value: Option$RecountTools.getWithDefault(value, ""),
                          onBlur: onBlur,
                          onChange: onChange
                        }, React.createElement("option", {
                              key: "placeholder-value",
                              value: ""
                            }, placeholder), Arr$RecountTools.map(options, (function (option) {
                                return React.createElement("option", {
                                            key: "option-" + option.value,
                                            value: option.value
                                          }, option.label);
                              }))))), displayError(error));
}

var make = Form_Select;

export {
  make ,
  
}
/* react Not a pure module */
