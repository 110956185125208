

import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Loadable$RecountTools from "../util/Loadable.bs.js";
import * as Ordering$RecountTools from "../util/Ordering.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Models_Tag$RecountTools from "../models/Models_Tag.bs.js";
import * as Observable$RecountTools from "../util/Observable.bs.js";
import * as Models_Cursor$RecountTools from "../models/Models_Cursor.bs.js";
import * as Models_Search$RecountTools from "../models/Models_Search.bs.js";

var rawTags = MicroObservables.observable(undefined);

var indices = MicroObservables.observable(undefined);

function makeIndexKey(query) {
  return Arr$RecountTools.join(Arr$RecountTools.sort(Arr$RecountTools.map(query, (function (qp) {
                        switch (qp.TAG | 0) {
                          case /* ByTagType */0 :
                              return "bytagtype:" + Models_Tag$RecountTools.Kind.toString(qp._0);
                          case /* BySearch */1 :
                              return "bysearch:" + qp._0;
                          case /* ByCursor */2 :
                              return "bycursor:" + Models_Cursor$RecountTools.toString(qp._0);
                          case /* SortBy */3 :
                              var s = qp._0;
                              return "sortby:" + s.field + "," + Models_Search$RecountTools.Order.toString(s.order);
                          
                        }
                      })), Ordering$RecountTools.string), "/");
}

function get(id) {
  return rawTags.transform(function (ts) {
              var t = Belt_MapInt.get(ts, Models_Id$RecountTools.toInt(id));
              if (t !== undefined) {
                return t;
              } else {
                return /* NotLoaded */0;
              }
            });
}

function getAllBy(query) {
  return Observable$RecountTools.Writable.from(rawTags, indices).transform(function (param) {
              var dict = param[0];
              var page = Belt_MapString.get(param[1], makeIndexKey(query));
              if (page !== undefined) {
                return {
                        data: Arr$RecountTools.keepMap(page.ids, (function (id) {
                                return Option$RecountTools.flatMap(Belt_MapInt.get(dict, Models_Id$RecountTools.toInt(id)), Loadable$RecountTools.toOption);
                              })),
                        pagination: page.pagination
                      };
              }
              
            });
}

function add(t) {
  rawTags.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(t.id), /* Loaded */{
                    _0: t
                  });
      });
  
}

function addAll(ts) {
  rawTags.update(function (dict) {
        return Arr$RecountTools.reduce(ts, dict, (function (acc, t) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(t.id), /* Loaded */{
                                  _0: t
                                });
                    }));
      });
  
}

function update(id, fn) {
  rawTags.update(function (dict) {
        return Belt_MapInt.update(dict, Models_Id$RecountTools.toInt(id), (function (__x) {
                      return Option$RecountTools.map(__x, (function (__x) {
                                    return Loadable$RecountTools.map(__x, fn);
                                  }));
                    }));
      });
  
}

function $$delete(v) {
  rawTags.update(function (dict) {
        return Belt_MapInt.remove(dict, Models_Id$RecountTools.toInt(v));
      });
  
}

function fetchOne(id) {
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Tag$RecountTools.fromJson,
        toJson: Models_Tag$RecountTools.toJson
      });
  Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                TAG: /* Api */0,
                _0: "/v2/tags/" + Models_Id$RecountTools.toString(id)
              }), (function (req) {
              if (req.status === 404) {
                return Promise.resolve(/* NotFound */1);
              } else {
                return Async$RecountTools.map(Async$RecountTools.map(req.json(), R.fromJson), (function (resp) {
                              return /* Loaded */{
                                      _0: resp.data
                                    };
                            }));
              }
            })), (function (tag) {
          rawTags.update(function (dict) {
                return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(id), tag);
              });
          
        }));
  
}

function fetchBy(query) {
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Tag$RecountTools.fromJson,
        toJson: Models_Tag$RecountTools.toJson
      });
  var params = query.flatMap(function (qp) {
        switch (qp.TAG | 0) {
          case /* ByTagType */0 :
              return [[
                        "filter[tag_type_id]",
                        Models_Tag$RecountTools.Kind.toString(qp._0)
                      ]];
          case /* BySearch */1 :
              return [[
                        "filter[text]",
                        qp._0
                      ]];
          case /* ByCursor */2 :
              return [[
                        "page[cursor]",
                        Models_Cursor$RecountTools.toString(qp._0)
                      ]];
          case /* SortBy */3 :
              var sort = qp._0;
              return [
                      [
                        "sort[field]",
                        sort.field
                      ],
                      [
                        "sort[order]",
                        Models_Search$RecountTools.Order.toString(sort.order)
                      ]
                    ];
          
        }
      });
  var params$1 = Arr$RecountTools.concat(params, [[
          "page[size]",
          "150"
        ]]);
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, params$1, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/v2/tags"
                  }), (function (prim) {
                  return prim.json();
                })), $$Response.Page.fromJson), (function (resp) {
          addAll(resp.data);
          indices.update(function (dict) {
                return Belt_MapString.set(dict, makeIndexKey(query), {
                            ids: Arr$RecountTools.map(resp.data, (function (v) {
                                    return v.id;
                                  })),
                            pagination: {
                              first: resp.pagination.first,
                              next: resp.pagination.next,
                              prev: resp.pagination.prev,
                              last: resp.pagination.last,
                              total: resp.meta.total
                            }
                          });
              });
          
        }));
  
}

var OW;

var IMap;

var SMap;

var Kind;

export {
  OW ,
  IMap ,
  SMap ,
  Kind ,
  get ,
  getAllBy ,
  add ,
  addAll ,
  update ,
  $$delete ,
  fetchOne ,
  fetchBy ,
  
}
/* rawTags Not a pure module */
