

import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Models_Section$RecountTools from "../models/Models_Section.bs.js";

var rawSections = MicroObservables.observable(undefined);

function get(id) {
  return rawSections.transform(function (vs) {
              var match = Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id));
              if (match === undefined) {
                return /* NotLoaded */0;
              }
              var v = Caml_option.valFromOption(match);
              if (v !== undefined) {
                return /* Loaded */{
                        _0: v
                      };
              } else {
                return /* NotFound */1;
              }
            });
}

function getAll(ids) {
  return rawSections.transform(function (vs) {
              return Arr$RecountTools.keepMap(ids, (function (id) {
                            return Option$RecountTools.flatten(Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id)));
                          }));
            });
}

var all = rawSections.transform(function (section) {
      return Belt_MapInt.valuesToArray(section).flatMap(Option$RecountTools.toArray);
    });

function add(s) {
  rawSections.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(s.id), s);
      });
  
}

function update(id, fn) {
  rawSections.update(function (dict) {
        return Belt_MapInt.update(dict, Models_Id$RecountTools.toInt(id), (function (__x) {
                      return Option$RecountTools.map(__x, (function (__x) {
                                    return Option$RecountTools.map(__x, fn);
                                  }));
                    }));
      });
  
}

function addAll(sectionsArray) {
  rawSections.update(function (dict) {
        return Arr$RecountTools.reduce(sectionsArray, dict, (function (acc, currentSection) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(currentSection.id), currentSection);
                    }));
      });
  
}

function $$delete(v) {
  rawSections.update(function (dict) {
        return Belt_MapInt.remove(dict, Models_Id$RecountTools.toInt(v));
      });
  
}

function querySections(param) {
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: Models_Section$RecountTools.fromJson,
        toJson: Models_Section$RecountTools.toJson
      });
  return Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                          TAG: /* Api */0,
                          _0: "/v2/sections"
                        }), (function (prim) {
                        return prim.json();
                      })), $$Response.Page.fromJson), (function (resp) {
                return resp.data;
              }));
}

function fetchAll(param) {
  Async$RecountTools.map(querySections(undefined), addAll);
  
}

function ensureFetched(param) {
  var match = Belt_MapInt.valuesToArray(rawSections.get());
  if (match.length !== 0) {
    return ;
  } else {
    return fetchAll(undefined);
  }
}

var OW;

var IMap;

export {
  OW ,
  IMap ,
  get ,
  getAll ,
  all ,
  add ,
  update ,
  addAll ,
  $$delete ,
  fetchAll ,
  ensureFetched ,
  
}
/* rawSections Not a pure module */
