

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Int$RecountTools from "../util/Int.bs.js";
import * as Str$RecountTools from "../util/Str.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";

function unsafe_fromString(s) {
  return Option$RecountTools.getExn(Belt_Int.fromString(s));
}

function fromString(s) {
  return Option$RecountTools.map(Str$RecountTools.toInt(s), (function (o) {
                return o;
              }));
}

function fromInt(i) {
  return i;
}

function toString(i) {
  return Int$RecountTools.toString(undefined, i);
}

function toInt(i) {
  return i;
}

var fromJson = Json_decode.$$int;

function toJson(i) {
  return i;
}

var orderingInt = Caml.caml_int_compare;

function refAsId(r) {
  if (r.TAG !== /* Resolved */0) {
    return r._0;
  }
  var unsafeGetId = (function(m) { return m.id; });
  return unsafeGetId(r._0);
}

var $$ReferenceError = /* @__PURE__ */Caml_exceptions.create("Models_Id-RecountTools.ReferenceError");

function unsafe_refAsResolved(r) {
  if (r.TAG === /* Resolved */0) {
    return r._0;
  }
  throw {
        RE_EXN_ID: $$ReferenceError,
        _1: "Reference not resolved: " + Int$RecountTools.toString(undefined, r._0),
        Error: new Error()
      };
}

function referenceFromJson(decoder, json) {
  return Json_decode.either((function (json) {
                  return {
                          TAG: /* Resolved */0,
                          _0: Curry._1(decoder, json)
                        };
                }), (function (json) {
                  return {
                          TAG: /* Unresolved */1,
                          _0: Json_decode.$$int(json)
                        };
                }))(json);
}

function refListFromJson(decoder, json) {
  return Json_decode.either((function (json) {
                  return {
                          TAG: /* ResolvedList */0,
                          _0: Json_decode.array(decoder, json)
                        };
                }), (function (json) {
                  return {
                          TAG: /* UnresolvedList */1,
                          _0: Json_decode.array(fromJson, json)
                        };
                }))(json);
}

export {
  unsafe_fromString ,
  fromString ,
  fromInt ,
  toString ,
  toInt ,
  fromJson ,
  toJson ,
  orderingInt ,
  refAsId ,
  $$ReferenceError ,
  unsafe_refAsResolved ,
  referenceFromJson ,
  refListFromJson ,
  
}
/* No side effect */
