

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Int$RecountTools from "../../util/Int.bs.js";
import * as Xhr$RecountTools from "../../util/Xhr.bs.js";
import * as Form$RecountTools from "../../components/Form/Form.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Modal$RecountTools from "../../components/Modal.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Form_Input$RecountTools from "../../components/Form/Form_Input.bs.js";
import * as VideoStore$RecountTools from "../../stores/VideoStore.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as Form_Select$RecountTools from "../../components/Form/Form_Select.bs.js";
import * as Models_Show$RecountTools from "../../models/Models_Show.bs.js";
import * as Models_Video$RecountTools from "../../models/Models_Video.bs.js";

var progressHeading = Curry._1(Css.merge, {
      hd: "has-text-white has-text-centered",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize(Css.rem(1.6)),
              tl: {
                hd: Css.marginBottom(Css.rem(1)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var UploadFormStyles = {
  progressHeading: progressHeading
};

var validators_videoFile = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.videoFile;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "File is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.videoFile
              };
      }
    })
};

var validators_show = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var s = input.show;
      if (s !== undefined) {
        return {
                TAG: /* Ok */0,
                _0: s
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Show is required"
              };
      }
    })
};

var validators_videoTitle = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.videoTitle;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Title is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.videoTitle
              };
      }
    })
};

var validators = {
  videoFile: validators_videoFile,
  show: validators_show,
  videoTitle: validators_videoTitle
};

function initialFieldsStatuses(_input) {
  return {
          videoFile: /* Pristine */0,
          show: /* Pristine */0,
          videoTitle: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            videoFile: /* Pristine */0,
            show: /* Pristine */0,
            videoTitle: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.videoFile;
  var match_0 = match ? match._0 : Curry._1(validators.videoFile.validate, input);
  var match$1 = fieldsStatuses.show;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.show.validate, input);
  var match$2 = fieldsStatuses.videoTitle;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.videoTitle.validate, input);
  var videoFileResult = match_0;
  var videoFileResult$1;
  if (videoFileResult.TAG === /* Ok */0) {
    var showResult = match_0$1;
    if (showResult.TAG === /* Ok */0) {
      var videoTitleResult = match_0$2;
      if (videoTitleResult.TAG === /* Ok */0) {
        return {
                TAG: /* Valid */0,
                output: {
                  videoTitle: videoTitleResult._0,
                  show: showResult._0,
                  videoFile: videoFileResult._0
                },
                fieldsStatuses: {
                  videoFile: /* Dirty */{
                    _0: videoFileResult,
                    _1: /* Shown */0
                  },
                  show: /* Dirty */{
                    _0: showResult,
                    _1: /* Shown */0
                  },
                  videoTitle: /* Dirty */{
                    _0: videoTitleResult,
                    _1: /* Shown */0
                  }
                },
                collectionsStatuses: undefined
              };
      }
      videoFileResult$1 = videoFileResult;
    } else {
      videoFileResult$1 = videoFileResult;
    }
  } else {
    videoFileResult$1 = videoFileResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            videoFile: /* Dirty */{
              _0: videoFileResult$1,
              _1: /* Shown */0
            },
            show: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            videoTitle: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurVideoFileField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.videoFile, validators_videoFile, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  videoFile: status,
                                  show: init.show,
                                  videoTitle: init.videoTitle
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.show, validators_show, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  videoFile: init.videoFile,
                                  show: status,
                                  videoTitle: init.videoTitle
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVideoTitleField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.videoTitle, validators_videoTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  videoFile: init.videoFile,
                                  show: init.show,
                                  videoTitle: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */3 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */3,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */4,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */6);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */4 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */5 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */6 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVideoFileField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.videoFile, state.submissionStatus, validators_videoFile, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            videoFile: status,
                                            show: init.show,
                                            videoTitle: init.videoTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.show, state.submissionStatus, validators_show, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            videoFile: init.videoFile,
                                            show: status,
                                            videoTitle: init.videoTitle
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVideoTitleField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.videoTitle, state.submissionStatus, validators_videoTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            videoFile: init.videoFile,
                                            show: init.show,
                                            videoTitle: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */3 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                videoFile: /* Pristine */0,
                                show: /* Pristine */0,
                                videoTitle: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                videoFile: /* Pristine */0,
                                show: /* Pristine */0,
                                videoTitle: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */4 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */5 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVideoFile: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateVideoFileField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateShow: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateShowField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateVideoTitle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateVideoTitleField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurVideoFile: (function (param) {
              return Curry._1(dispatch, /* BlurVideoFileField */0);
            }),
          blurShow: (function (param) {
              return Curry._1(dispatch, /* BlurShowField */1);
            }),
          blurVideoTitle: (function (param) {
              return Curry._1(dispatch, /* BlurVideoTitleField */2);
            }),
          videoFileResult: Formality.exposeFieldResult(state.fieldsStatuses.videoFile),
          showResult: Formality.exposeFieldResult(state.fieldsStatuses.show),
          videoTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.videoTitle),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.videoFile || match.show || match.videoTitle) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */5,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */6);
            })
        };
}

var UploadForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function Page_Videos_UploadForm(Props) {
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var showId = Props.showId;
  var match = React.useState(function () {
        return [];
      });
  var setShows = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setFile = match$1[1];
  var file = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setFileUploadCurrent = match$2[1];
  var fileUploadCurrent = match$2[0];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setFileUploadTotal = match$3[1];
  var form = useForm({
        videoTitle: "",
        show: showId,
        videoFile: ""
      }, (function (output, cb) {
          var videoFile = Option$RecountTools.getExn(file);
          var body = Request$RecountTools.RequestBody.json({
                data: {
                  show_id: output.show,
                  status: "draft",
                  title: output.videoTitle
                }
              });
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.post(body, undefined, undefined, undefined, {
                                    TAG: /* Api */0,
                                    _0: "/v2/videos"
                                  }), (function (prim) {
                                  return prim.json();
                                })), (function (res) {
                              var uploadUrl = Json_decode.field("data", (function (param) {
                                      return Json_decode.field("upload_url", Json_decode.string, param);
                                    }), res);
                              var video = Json_decode.field("data", (function (param) {
                                      return Json_decode.field("video", Models_Video$RecountTools.fromJson, param);
                                    }), res);
                              VideoStore$RecountTools.addPending(video);
                              return [
                                      uploadUrl,
                                      video
                                    ];
                            })), (function (param) {
                          var video = param[1];
                          var uploadUrl = param[0];
                          return Async$RecountTools.map(new Promise((function (resolve, reject) {
                                            Xhr$RecountTools.Immutable.send(Curry._2(Xhr$RecountTools.Immutable.$$Event.onLoad, Curry._2(Xhr$RecountTools.Immutable.$$Event.onError, Curry._2(Xhr$RecountTools.Immutable.$$Event.onUploadProgress, Curry._2(Xhr$RecountTools.Immutable.Body.file, Curry._1(Xhr$RecountTools.Immutable.ResponseType.blob, Xhr$RecountTools.Immutable.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "PUT", uploadUrl)), videoFile), (function (_event) {
                                                                Curry._1(setFileUploadCurrent, (function (param) {
                                                                        return _event.loaded;
                                                                      }));
                                                                return Curry._1(setFileUploadTotal, (function (param) {
                                                                              return _event.total;
                                                                            }));
                                                              })), (function (statusCode, message, param) {
                                                            var makeError = (function(str) {
                  return new Error(str)
                });
                                                            return reject(makeError(Int$RecountTools.toString(undefined, statusCode) + " " + message));
                                                          })), (function (param, param$1) {
                                                        return resolve(undefined);
                                                      })));
                                            
                                          })), (function (param) {
                                        return video;
                                      }));
                        })), (function (video) {
                      Curry._1(cb.reset, undefined);
                      return Curry._1(onSubmit, video.id);
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  React.useEffect((function () {
          if (Option$RecountTools.isNone(showId)) {
            Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                              TAG: /* Api */0,
                              _0: "/v1/shows"
                            }), (function (prim) {
                            return prim.json();
                          })), (function (param) {
                        return Json_decode.array(Models_Show$RecountTools.fromJson, param);
                      })), (function (shows) {
                    return Curry._1(setShows, (function (param) {
                                  return shows;
                                }));
                  }));
          }
          
        }), []);
  var closeFn = fileUploadCurrent !== undefined ? (function (param) {
        
      }) : (function (param) {
        Curry._1(form.reset, undefined);
        return Curry._1(onClose, undefined);
      });
  var modalBody = fileUploadCurrent !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("h3", {
              className: progressHeading
            }, "Your file is uploading, please do not close the tab"), React.createElement("progress", {
              className: "progress is-small is-primary",
              max: Int$RecountTools.toString(undefined, match$3[0]),
              value: Int$RecountTools.toString(undefined, fileUploadCurrent)
            }, "uploading...")) : React.createElement("form", {
          onSubmit: (function ($$event) {
              $$event.preventDefault();
              return Curry._1(form.submit, undefined);
            })
        }, React.createElement(Modal$RecountTools.Header.make, {
              children: "Upload a Video"
            }), React.createElement(Modal$RecountTools.Body.make, {
              children: null
            }, React.createElement(Form_Input$RecountTools.make, {
                  value: form.input.videoTitle,
                  label: "Title",
                  error: form.videoTitleResult,
                  onChange: (function (e) {
                      return Curry._2(form.updateVideoTitle, (function (input, value) {
                                    return {
                                            videoTitle: value,
                                            show: input.show,
                                            videoFile: input.videoFile
                                          };
                                  }), e.target.value);
                    }),
                  onBlur: (function (param) {
                      return Curry._1(form.blurVideoTitle, undefined);
                    }),
                  disabled: form.submitting
                }), Option$RecountTools.isNone(showId) ? React.createElement(Form_Select$RecountTools.make, {
                    label: "Show",
                    value: Option$RecountTools.map(form.input.show, Models_Id$RecountTools.toString),
                    options: Arr$RecountTools.map(match[0], (function (show) {
                            return {
                                    label: show.title,
                                    value: Models_Id$RecountTools.toString(show.id)
                                  };
                          })),
                    disabled: form.submitting,
                    onChange: (function (e) {
                        return Curry._2(form.updateShow, (function (input, value) {
                                      return {
                                              videoTitle: input.videoTitle,
                                              show: value,
                                              videoFile: input.videoFile
                                            };
                                    }), Models_Id$RecountTools.fromString(e.target.value));
                      }),
                    onBlur: (function (param) {
                        return Curry._1(form.blurShow, undefined);
                      }),
                    placeholder: "Select a show",
                    error: form.showResult
                  }) : null, React.createElement(Form$RecountTools.$$File.make, {
                  value: Option$RecountTools.map(file, (function (f) {
                          return {
                                  file: f,
                                  name: form.input.videoFile
                                };
                        })),
                  fileTypes: "video/*",
                  label: "Video File",
                  disabled: form.submitting,
                  onBlur: (function (param) {
                      return Curry._1(form.blurVideoFile, undefined);
                    }),
                  onChange: (function (v) {
                      Curry._1(setFile, (function (param) {
                              return Caml_option.some(v.file);
                            }));
                      return Curry._2(form.updateVideoFile, (function (input, value) {
                                    return {
                                            videoTitle: input.videoTitle,
                                            show: input.show,
                                            videoFile: value
                                          };
                                  }), v.name);
                    }),
                  error: form.videoFileResult
                })), React.createElement(Modal$RecountTools.Footer.make, {
              children: React.createElement("div", {
                    className: "field is-grouped"
                  }, React.createElement(Form_Button$RecountTools.make, {
                        label: "Cancel",
                        disabled: form.submitting,
                        className: "is-gray",
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          })
                      }), React.createElement(Form_Button$RecountTools.make, {
                        label: "Submit",
                        disabled: form.submitting,
                        kind: "submit",
                        className: "is-link"
                      }))
            }));
  return React.createElement(Modal$RecountTools.make, {
              isActive: isOpen,
              children: modalBody,
              onModalClose: (function (param) {
                  return Curry._1(closeFn, undefined);
                })
            });
}

var make = Page_Videos_UploadForm;

export {
  UploadFormStyles ,
  UploadForm ,
  make ,
  
}
/* progressHeading Not a pure module */
