

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Int$RecountTools from "../util/Int.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function dateFromEpoch(json) {
  return new Date(Json_decode.$$float(json) * 1000);
}

function fromJson(json) {
  var i = Json_decode.$$int(json);
  switch (i) {
    case 1 :
        return "location";
    case 2 :
        return "subject";
    case 3 :
        return "person";
    case 4 :
        return "organization";
    case 5 :
        return "freeform";
    default:
      throw {
            RE_EXN_ID: Json_decode.DecodeError,
            _1: "Invalid Tag.kind: " + Int$RecountTools.toString(undefined, i),
            Error: new Error()
          };
  }
}

function toJson(k) {
  if (k === "location") {
    return 1;
  } else if (k === "freeform") {
    return 5;
  } else if (k === "subject") {
    return 2;
  } else if (k === "person") {
    return 3;
  } else {
    return 4;
  }
}

function toString(k) {
  if (k === "location") {
    return "1";
  } else if (k === "freeform") {
    return "5";
  } else if (k === "subject") {
    return "2";
  } else if (k === "person") {
    return "3";
  } else {
    return "4";
  }
}

function fromString(k) {
  switch (k) {
    case "1" :
        return "location";
    case "2" :
        return "subject";
    case "3" :
        return "person";
    case "4" :
        return "organization";
    case "5" :
        return "freeform";
    default:
      return ;
  }
}

function toDisplay(k) {
  if (k === "location") {
    return "Location";
  } else if (k === "freeform") {
    return "Freeform";
  } else if (k === "subject") {
    return "Subject";
  } else if (k === "person") {
    return "Person";
  } else {
    return "Organization";
  }
}

function toNamedString(k) {
  if (k === "location") {
    return "location";
  } else if (k === "freeform") {
    return "freeform";
  } else if (k === "subject") {
    return "subject";
  } else if (k === "person") {
    return "person";
  } else {
    return "organization";
  }
}

function all(param) {
  return [
          "location",
          "subject",
          "person",
          "organization",
          "freeform"
        ];
}

var Kind = {
  fromJson: fromJson,
  toJson: toJson,
  toString: toString,
  fromString: fromString,
  toDisplay: toDisplay,
  toNamedString: toNamedString,
  all: all
};

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          kind: Json_decode.field("tag_type_id", fromJson, json),
          text: Json_decode.field("text", Json_decode.string, json),
          slug: Caml_option.null_to_opt(Json_decode.field("slug", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          createdAt: Json_decode.field("created_at", dateFromEpoch, json)
        };
}

function toJson$1(data) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(data.id)
              ],
              tl: {
                hd: [
                  "kind",
                  toJson(data.kind)
                ],
                tl: {
                  hd: [
                    "text",
                    data.text
                  ],
                  tl: {
                    hd: [
                      "slug",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), data.slug)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Id;

export {
  Id ,
  Kind ,
  fromJson$1 as fromJson,
  toJson$1 as toJson,
  
}
/* No side effect */
