

import * as Curry from "rescript/lib/es6/curry.js";

function map(p, f) {
  return p.then(function (a) {
              return Promise.resolve(Curry._1(f, a));
            });
}

function flatMap(p, f) {
  return p.then(Curry.__1(f));
}

function $$catch(p, f) {
  return p.catch(Curry.__1(f));
}

function onFailure(p, f) {
  return p.catch(function (e) {
              Curry._1(f, e);
              return Promise.reject(e);
            });
}

export {
  map ,
  flatMap ,
  $$catch ,
  onFailure ,
  
}
/* No side effect */
