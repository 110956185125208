

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/src/Css_Core.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Icon$RecountTools from "../components/Icon.bs.js";
import * as Link$RecountTools from "../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as UserStore$RecountTools from "../stores/UserStore.bs.js";
import * as LocalStorage$RecountTools from "../util/LocalStorage.bs.js";

var navContainerExpanded = Curry._1(Css.style, {
      hd: Css.width(Css.rem(12)),
      tl: /* [] */0
    });

var navContainerCollapsed = Curry._1(Css.style, {
      hd: Css.width(Css.rem(4)),
      tl: /* [] */0
    });

var toggleContainer = Curry._1(Css.merge, {
      hd: "is-flex is-align-items-center pl-2 pr-2",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.height(Css.rem(3)),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var toggleButton = Curry._1(Css.style, {
      hd: Css.backgroundColor("transparent"),
      tl: {
        hd: Css.border(Css.px(0), "none", {
              NAME: "hex",
              VAL: "fff"
            }),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.height(Css.px(32)),
            tl: {
              hd: Css.marginLeft("auto"),
              tl: {
                hd: Css.paddingRight(Css.rem(0.5)),
                tl: {
                  hd: Css.width(Css.px(32)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var toggleIcon = Curry._1(Css.style, {
      hd: Css.width(Css.px(16)),
      tl: {
        hd: Css.color({
              NAME: "rgb",
              VAL: [
                24,
                24,
                24
              ]
            }),
        tl: {
          hd: Css_Core.SVG.fill("currentColor"),
          tl: /* [] */0
        }
      }
    });

var toggleButtonExpand = Curry._1(Css.merge, {
      hd: toggleButton,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.margin("auto"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var toggleIconExpand = Curry._1(Css.style, {
      hd: Css.width(Css.px(16)),
      tl: {
        hd: Css.color({
              NAME: "rgb",
              VAL: [
                24,
                24,
                24
              ]
            }),
        tl: {
          hd: Css_Core.SVG.fill("currentColor"),
          tl: {
            hd: Css.transform({
                  NAME: "rotate",
                  VAL: Css.deg(180)
                }),
            tl: /* [] */0
          }
        }
      }
    });

var nav = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.rem(0.5)),
      tl: {
        hd: Css.paddingRight(Css.rem(0.5)),
        tl: /* [] */0
      }
    });

var navItem = "is-flex is-align-items-center mb-4";

var iconContainer = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(12)),
      tl: {
        hd: Css.display("inlineFlex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.height(Css.px(48)),
              tl: {
                hd: Css.width(Css.px(48)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var iconCenter = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.marginRight("auto"),
        tl: /* [] */0
      }
    });

var videosBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              250,
              202,
              0,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var sectionsBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              10,
              202,
              12,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var podcastsBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              252,
              3,
              3,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var usersBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              0,
              147,
              238,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var tvBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              136,
              6,
              156,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var youtubeBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              241,
              70,
              104,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var tagsBg = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "rgba",
            VAL: [
              247,
              51,
              255,
              {
                NAME: "percent",
                VAL: 20
              }
            ]
          }),
      tl: /* [] */0
    });

var videosColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              250,
              202,
              0
            ]
          }),
      tl: /* [] */0
    });

var sectionsColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              110,
              202,
              12
            ]
          }),
      tl: /* [] */0
    });

var podcastsColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              252,
              3,
              3
            ]
          }),
      tl: /* [] */0
    });

var usersColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              0,
              147,
              238
            ]
          }),
      tl: {
        hd: Css_Core.SVG.fill("currentColor"),
        tl: /* [] */0
      }
    });

var tagsColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              247,
              51,
              255
            ]
          }),
      tl: /* [] */0
    });

var tvColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              136,
              6,
              156
            ]
          }),
      tl: /* [] */0
    });

var youtubeColor = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              241,
              70,
              104
            ]
          }),
      tl: {
        hd: Css_Core.SVG.fill("currentColor"),
        tl: /* [] */0
      }
    });

var Styles = {
  navContainerExpanded: navContainerExpanded,
  navContainerCollapsed: navContainerCollapsed,
  toggleContainer: toggleContainer,
  toggleButton: toggleButton,
  toggleIcon: toggleIcon,
  toggleButtonExpand: toggleButtonExpand,
  toggleIconExpand: toggleIconExpand,
  nav: nav,
  navItem: navItem,
  iconContainer: iconContainer,
  iconCenter: iconCenter,
  videosBg: videosBg,
  sectionsBg: sectionsBg,
  podcastsBg: podcastsBg,
  usersBg: usersBg,
  tvBg: tvBg,
  youtubeBg: youtubeBg,
  tagsBg: tagsBg,
  videosColor: videosColor,
  sectionsColor: sectionsColor,
  podcastsColor: podcastsColor,
  usersColor: usersColor,
  tagsColor: tagsColor,
  tvColor: tvColor,
  youtubeColor: youtubeColor
};

var initialNavState = Option$RecountTools.getWithDefault(LocalStorage$RecountTools.get("navigationState", Json_decode.bool), false);

var isCollapsedO = MicroObservables.observable(initialNavState);

function Layout_Navigation(Props) {
  var pages = [
    {
      link: "/videos",
      name: "Videos",
      color: videosBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: videosColor,
                  tl: {
                    hd: "fa fa-film fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: undefined
    },
    {
      link: "/podcast-episodes",
      name: "Podcast Episodes",
      color: podcastsBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: podcastsColor,
                  tl: {
                    hd: "fa fa-podcast fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: undefined
    },
    {
      link: "/sections",
      name: "Sections",
      color: sectionsBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: sectionsColor,
                  tl: {
                    hd: "fa fa-folder-open fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: undefined
    },
    {
      link: "/tags",
      name: "Tags",
      color: tagsBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: tagsColor,
                  tl: {
                    hd: "fa fa-tags fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: undefined
    },
    {
      link: "/youtube",
      name: "Twitch Details",
      color: tvBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: tvColor,
                  tl: {
                    hd: "fa fa-twitch fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: undefined
    },
    {
      link: "/users",
      name: "Users",
      color: usersBg,
      icon: React.createElement("i", {
            "aria-hidden": true,
            className: Curry._1(Css.merge, {
                  hd: usersColor,
                  tl: {
                    hd: "fa fa-users fa-lg",
                    tl: /* [] */0
                  }
                })
          }),
      requiredPermission: "editUsers"
    }
  ];
  var currentUser = MicroObservables.useObservable(UserStore$RecountTools.getCurrent(undefined));
  var permissions = typeof currentUser === "number" ? [] : currentUser._0.permissions;
  React.useEffect((function () {
          UserStore$RecountTools.ensureFetchedCurrent(undefined);
          
        }), []);
  var isCollapsed = MicroObservables.useObservable(isCollapsedO.readOnly());
  var setIsCollapsed = function (param) {
    isCollapsedO.update(function (prevState) {
          LocalStorage$RecountTools.set("navigationState", (function (prim) {
                  return prim;
                }), !prevState);
          return !prevState;
        });
    
  };
  var renderNavItems = function (param) {
    return React.createElement("div", {
                className: nav
              }, Arr$RecountTools.map(pages, (function (page) {
                      var requiredPermission = page.requiredPermission;
                      var hasPermission = requiredPermission === undefined || permissions.includes(Option$RecountTools.getExn(requiredPermission));
                      if (hasPermission) {
                        return React.createElement(Link$RecountTools.make, {
                                    href: page.link,
                                    children: null,
                                    className: navItem,
                                    key: page.name
                                  }, React.createElement("span", {
                                        className: Curry._1(Css.merge, {
                                              hd: page.color,
                                              tl: {
                                                hd: iconContainer,
                                                tl: /* [] */0
                                              }
                                            })
                                      }, page.icon), isCollapsed ? null : React.createElement("span", {
                                          className: "title is-size-7 ml-2"
                                        }, page.name));
                      } else {
                        return null;
                      }
                    })));
  };
  var renderCollapsedNav = function (param) {
    return React.createElement("div", {
                className: Curry._1(Css.merge, {
                      hd: navContainerCollapsed,
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: toggleContainer
                  }, React.createElement("button", {
                        className: toggleButtonExpand,
                        type: "button",
                        onClick: (function (param) {
                            return setIsCollapsed(undefined);
                          })
                      }, React.createElement(Icon$RecountTools.NavToggleSwitch.make, {
                            className: toggleIconExpand
                          }))), renderNavItems(undefined));
  };
  var renderExpandedNav = function (param) {
    return React.createElement("div", {
                className: Curry._1(Css.merge, {
                      hd: navContainerExpanded,
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: toggleContainer
                  }, React.createElement("p", undefined, "NAVIGATION"), React.createElement("button", {
                        className: toggleButton,
                        type: "button",
                        onClick: (function (param) {
                            return setIsCollapsed(undefined);
                          })
                      }, React.createElement(Icon$RecountTools.NavToggleSwitch.make, {
                            className: toggleIcon
                          }))), renderNavItems(undefined));
  };
  return React.createElement("nav", undefined, isCollapsed ? renderCollapsedNav(undefined) : renderExpandedNav(undefined));
}

var make = Layout_Navigation;

export {
  Styles ,
  make ,
  
}
/* navContainerExpanded Not a pure module */
