

import * as React from "react";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function Link(Props) {
  var href = Props.href;
  var children = Props.children;
  var className = Props.className;
  var onClick = function (e) {
    e.preventDefault();
    return RescriptReactRouter.push(href);
  };
  if (className !== undefined) {
    return React.createElement("a", {
                className: className,
                href: href,
                onClick: onClick
              }, children);
  } else {
    return React.createElement("a", {
                href: href,
                onClick: onClick
              }, children);
  }
}

var make = Link;

export {
  make ,
  
}
/* react Not a pure module */
