

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Int$RecountTools from "../../util/Int.bs.js";
import * as Str$RecountTools from "../../util/Str.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as TagStore$RecountTools from "../../stores/TagStore.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Form_Input$RecountTools from "../../components/Form/Form_Input.bs.js";
import * as Models_Tag$RecountTools from "../../models/Models_Tag.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as Form_Select$RecountTools from "../../components/Form/Form_Select.bs.js";
import * as PageHistory$RecountTools from "../../util/PageHistory.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";
import * as ConfirmationProvider$RecountTools from "../../components/ConfirmationProvider.bs.js";
import * as Page_Tags_MergeTagForm$RecountTools from "./Page_Tags_MergeTagForm.bs.js";

var validators_kind = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.kind
            };
    })
};

var validators_slug = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.slug;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Slug is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.slug
              };
      }
    })
};

var validators_text = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.text;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Tag text is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.text
              };
      }
    })
};

var validators = {
  kind: validators_kind,
  slug: validators_slug,
  text: validators_text
};

function initialFieldsStatuses(_input) {
  return {
          kind: /* Pristine */0,
          slug: /* Pristine */0,
          text: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            kind: /* Pristine */0,
            slug: /* Pristine */0,
            text: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.kind;
  var match_0 = match ? match._0 : Curry._1(validators.kind.validate, input);
  var match$1 = fieldsStatuses.slug;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.slug.validate, input);
  var match$2 = fieldsStatuses.text;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.text.validate, input);
  var kindResult = match_0;
  var kindResult$1;
  if (kindResult.TAG === /* Ok */0) {
    var slugResult = match_0$1;
    if (slugResult.TAG === /* Ok */0) {
      var textResult = match_0$2;
      if (textResult.TAG === /* Ok */0) {
        return {
                TAG: /* Valid */0,
                output: {
                  text: textResult._0,
                  slug: slugResult._0,
                  kind: kindResult._0
                },
                fieldsStatuses: {
                  kind: /* Dirty */{
                    _0: kindResult,
                    _1: /* Shown */0
                  },
                  slug: /* Dirty */{
                    _0: slugResult,
                    _1: /* Shown */0
                  },
                  text: /* Dirty */{
                    _0: textResult,
                    _1: /* Shown */0
                  }
                },
                collectionsStatuses: undefined
              };
      }
      kindResult$1 = kindResult;
    } else {
      kindResult$1 = kindResult;
    }
  } else {
    kindResult$1 = kindResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            kind: /* Dirty */{
              _0: kindResult$1,
              _1: /* Shown */0
            },
            slug: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            text: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurKindField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.kind, validators_kind, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  kind: status,
                                  slug: init.slug,
                                  text: init.text
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSlugField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.slug, validators_slug, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  kind: init.kind,
                                  slug: status,
                                  text: init.text
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTextField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.text, validators_text, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  kind: init.kind,
                                  slug: init.slug,
                                  text: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */3 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */3,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */4,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */6);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */4 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */5 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */6 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateKindField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.kind, state.submissionStatus, validators_kind, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            kind: status,
                                            slug: init.slug,
                                            text: init.text
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSlugField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.slug, state.submissionStatus, validators_slug, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            kind: init.kind,
                                            slug: status,
                                            text: init.text
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTextField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.text, state.submissionStatus, validators_text, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            kind: init.kind,
                                            slug: init.slug,
                                            text: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */3 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                kind: /* Pristine */0,
                                slug: /* Pristine */0,
                                text: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                kind: /* Pristine */0,
                                slug: /* Pristine */0,
                                text: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */4 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */5 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateKind: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateKindField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateSlug: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateSlugField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateText: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTextField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurKind: (function (param) {
              return Curry._1(dispatch, /* BlurKindField */0);
            }),
          blurSlug: (function (param) {
              return Curry._1(dispatch, /* BlurSlugField */1);
            }),
          blurText: (function (param) {
              return Curry._1(dispatch, /* BlurTextField */2);
            }),
          kindResult: Formality.exposeFieldResult(state.fieldsStatuses.kind),
          slugResult: Formality.exposeFieldResult(state.fieldsStatuses.slug),
          textResult: Formality.exposeFieldResult(state.fieldsStatuses.text),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.kind || match.slug || match.text) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */5,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */6);
            })
        };
}

var TagForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_Tags_Editor-RecountTools.UnknownError");

function Page_Tags_Editor$LoadedState(Props) {
  var tag = Props.tag;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var confirm = ConfirmationProvider$RecountTools.useConfirm(undefined);
  var tagTypes = Models_Tag$RecountTools.Kind.all(undefined);
  var match$1 = React.useState(function () {
        return 0;
      });
  var setTagCount = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setTagMergeModalOpen = match$2[1];
  var tagMergeModalOpen = match$2[0];
  var openTagMergeModal = function (param) {
    return Curry._1(setTagMergeModalOpen, (function (param) {
                  return true;
                }));
  };
  React.useEffect((function () {
          var toJson = function (prim) {
            return prim;
          };
          var Resp = Request$RecountTools.ApiEnvelope({
                fromJson: Json_decode.$$int,
                toJson: toJson
              });
          var url = {
            TAG: /* Api */0,
            _0: "/v2/tags/views/videoTagCount/" + Models_Id$RecountTools.toString(tag.id)
          };
          Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, url), (function (prim) {
                      return prim.json();
                    })), (function (json) {
                  var data = Curry._1(Resp.fromJson, json).data;
                  return Curry._1(setTagCount, (function (param) {
                                return data;
                              }));
                }));
          
        }), [tagMergeModalOpen]);
  var form = useForm({
        text: tag.text,
        slug: tag.slug !== undefined ? Option$RecountTools.getExn(tag.slug) : "",
        kind: tag.kind
      }, (function (output, cb) {
          var fields = [];
          if (output.text !== tag.text) {
            Arr$RecountTools.push(fields, [
                  "text",
                  output.text
                ]);
          }
          var slug = tag.slug;
          if (slug !== undefined) {
            if (output.slug !== slug) {
              Arr$RecountTools.push(fields, [
                    "slug",
                    output.slug
                  ]);
            }
            
          } else if (output.slug !== "") {
            Arr$RecountTools.push(fields, [
                  "slug",
                  output.slug
                ]);
          }
          if (output.kind !== tag.kind) {
            Arr$RecountTools.push(fields, [
                  "tag_type_id",
                  Models_Tag$RecountTools.Kind.toJson(output.kind)
                ]);
          }
          var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                    hd: [
                      "data",
                      Json_encode.object_(Belt_List.fromArray(fields))
                    ],
                    tl: /* [] */0
                  }));
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.patch(body, undefined, undefined, undefined, {
                            TAG: /* Api */0,
                            _0: "/v2/tags/" + Models_Id$RecountTools.toString(tag.id)
                          }), (function (response) {
                          if (response.ok) {
                            return Promise.resolve(TagStore$RecountTools.update(tag.id, (function (tag) {
                                              return {
                                                      id: tag.id,
                                                      kind: output.kind,
                                                      text: output.text,
                                                      slug: output.slug,
                                                      createdAt: tag.createdAt
                                                    };
                                            })));
                          } else {
                            Curry._3(makeToast, undefined, /* Error */2, "Unable to save tag");
                            return Promise.reject({
                                        RE_EXN_ID: UnknownError
                                      });
                          }
                        })), (function (param) {
                      return Curry._1(cb.reset, undefined);
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var closeTagMergeModal = function (param) {
    return Curry._1(setTagMergeModalOpen, (function (param) {
                  return false;
                }));
  };
  var match$3 = tag.kind;
  var tagField = match$3 === "person" ? "people" : Models_Tag$RecountTools.Kind.toNamedString(tag.kind).toLowerCase() + "s";
  var tmp = {
    label: "Save",
    disabled: form.submitting || !Curry._1(form.dirty, undefined),
    kind: "submit",
    className: "is-link"
  };
  var tmp$1 = Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save");
  if (tmp$1 !== undefined) {
    tmp.disabledReason = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: null
                      }, React.createElement("div", {
                            className: "field"
                          }, React.createElement("label", {
                                className: "label"
                              }, "Videos Currently Tagged: ", React.createElement(Link$RecountTools.make, {
                                    href: "/videos?" + tagField + "=" + encodeURIComponent(tag.text),
                                    children: Int$RecountTools.toString(undefined, match$1[0])
                                  }))), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.text,
                            label: "Tag Text",
                            error: form.textResult,
                            onChange: (function (e) {
                                if (Str$RecountTools.slugify(form.input.text) === form.input.slug) {
                                  Curry._2(form.updateSlug, (function (input, value) {
                                          return {
                                                  text: input.text,
                                                  slug: value,
                                                  kind: input.kind
                                                };
                                        }), Str$RecountTools.slugify(e.target.value));
                                }
                                return Curry._2(form.updateText, (function (input, value) {
                                              return {
                                                      text: value,
                                                      slug: input.slug,
                                                      kind: input.kind
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurText, undefined);
                              }),
                            disabled: form.submitting
                          }), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.slug,
                            label: "Slug",
                            error: form.slugResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateSlug, (function (input, value) {
                                              return {
                                                      text: input.text,
                                                      slug: value,
                                                      kind: input.kind
                                                    };
                                            }), e.target.value.replace(/\s+/g, "-"));
                              }),
                            onBlur: (function (e) {
                                return Curry._1(form.blurSlug, undefined);
                              }),
                            disabled: form.submitting,
                            placeholder: "my-tag-slug"
                          }), React.createElement(Form_Select$RecountTools.make, {
                            label: "Tag Type",
                            value: Models_Tag$RecountTools.Kind.toString(form.input.kind),
                            options: Arr$RecountTools.map(tagTypes, (function (tt) {
                                    return {
                                            label: Models_Tag$RecountTools.Kind.toDisplay(tt),
                                            value: Models_Tag$RecountTools.Kind.toString(tt)
                                          };
                                  })),
                            disabled: form.submitting,
                            onChange: (function (e) {
                                return Curry._2(form.updateKind, (function (input, value) {
                                              return {
                                                      text: input.text,
                                                      slug: input.slug,
                                                      kind: value
                                                    };
                                            }), Option$RecountTools.getExn(Models_Tag$RecountTools.Kind.fromString(e.target.value)));
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurKind, undefined);
                              }),
                            placeholder: "Select a tag type",
                            error: form.kindResult
                          }))
                }), React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, tmp), React.createElement(Form_Button$RecountTools.make, {
                      label: "Merge Tags",
                      disabled: false,
                      className: "button is-info",
                      onClick: openTagMergeModal
                    }), React.createElement(Form_Button$RecountTools.make, {
                      label: "Delete",
                      disabled: form.submitting,
                      className: "is-danger",
                      onClick: Curry._4(confirm, /* Severe */1, undefined, "Are you sure you want to delete this tag? This action cannot be undone.", (function (param) {
                              var tag$1 = tag.id;
                              Async$RecountTools.map(Request$RecountTools.$$delete(undefined, undefined, undefined, undefined, {
                                        TAG: /* Api */0,
                                        _0: "/v2/tags/" + Models_Id$RecountTools.toString(tag$1)
                                      }), (function (response) {
                                      if (response.ok) {
                                        TagStore$RecountTools.$$delete(tag$1);
                                        return RescriptReactRouter.push("/tags");
                                      } else {
                                        Curry._3(makeToast, undefined, /* Error */2, "Could not delete tag");
                                        return ;
                                      }
                                    }));
                              
                            }))
                    }), React.createElement(Page_Tags_MergeTagForm$RecountTools.make, {
                      targetTag: tag,
                      isOpen: tagMergeModalOpen,
                      onClose: closeTagMergeModal
                    })));
}

var LoadedState = {
  make: Page_Tags_Editor$LoadedState
};

function Page_Tags_Editor(Props) {
  var id = Props.id;
  var tag = MicroObservables.useObservable(TagStore$RecountTools.get(id));
  React.useEffect((function () {
          TagStore$RecountTools.fetchOne(id);
          
        }), []);
  var tmp;
  if (typeof tag === "number") {
    if (tag !== 0) {
      PageHistory$RecountTools.revertPage(undefined);
      tmp = "Tag Not Found";
    } else {
      tmp = "Loading";
    }
  } else {
    var tag$1 = tag._0;
    tmp = React.createElement(Page_Tags_Editor$LoadedState, {
          tag: tag$1,
          key: Models_Id$RecountTools.toString(tag$1.id)
        });
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: tmp,
              pageName: "Edit Tag",
              crumbs: [{
                  link: "/tags",
                  name: "Tags"
                }]
            });
}

var MergeTagForm;

var make = Page_Tags_Editor;

export {
  MergeTagForm ,
  TagForm ,
  UnknownError ,
  LoadedState ,
  make ,
  
}
/* react Not a pure module */
