

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Str$RecountTools from "../../util/Str.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Tooltip$RecountTools from "../Tooltip.bs.js";
import * as DropDown$RecountTools from "../DropDown.bs.js";
import * as AddFilterForm$RecountTools from "./AddFilterForm.bs.js";

var tag = Curry._1(Css.merge, {
      hd: "tag",
      tl: {
        hd: "is-success",
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.flexWrap("nowrap"),
                tl: {
                  hd: Css.display("inlineFlex"),
                  tl: {
                    hd: Css.marginBottom(Css.px(0)),
                    tl: {
                      hd: Css.selector(".tag:first-child", {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.textOverflow("ellipsis"),
                                tl: {
                                  hd: Css.lineHeight(Css.em(2)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }),
                      tl: {
                        hd: Css.selector(".tag:nth-child(2)", {
                              hd: Css.flexShrink(0),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var tags = Curry._1(Css.merge, {
      hd: "tags",
      tl: {
        hd: "has-addons",
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.display("inlineFlex"),
                tl: {
                  hd: Css.important(Css.marginBottom(Css.px(0))),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var tagsControl = Curry._1(Css.merge, {
      hd: "control",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.maxWidth(Css.pct(65)),
              tl: {
                hd: Css.important(Css.marginBottom(Css.px(0))),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var tagsField = Curry._1(Css.merge, {
      hd: "field",
      tl: {
        hd: "input",
        tl: {
          hd: "is-grouped",
          tl: {
            hd: "is-grouped-multiline",
            tl: {
              hd: Curry._1(Css.style, {
                    hd: Css.important(Css.height("auto")),
                    tl: {
                      hd: Css.cursor("text"),
                      tl: {
                        hd: Css.child("div:last-child")({
                              hd: Css.flexGrow(1),
                              tl: {
                                hd: Css.maxWidth(Css.pct(100)),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.selector("input", {
                                          hd: Css.display("block"),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var invisibleInput = Curry._1(Css.style, {
      hd: Css.borderStyle("none"),
      tl: {
        hd: Css.background("none"),
        tl: {
          hd: Css.boxShadow("none"),
          tl: {
            hd: Css.outlineStyle("none"),
            tl: {
              hd: Css.unsafe("MozAppearance", "none"),
              tl: {
                hd: Css.unsafe("WebkitAppearance", "none"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.display("inlineFlex"),
                    tl: {
                      hd: Css.fontSize(Css.rem(1)),
                      tl: {
                        hd: Css.height(Css.em(2.5)),
                        tl: {
                          hd: Css.justifyContent("flexStart"),
                          tl: {
                            hd: Css.lineHeight(Css.em(1.5)),
                            tl: {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.verticalAlign("top"),
                                tl: {
                                  hd: Css.flexGrow(1),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var controls = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginTop(Css.rem(2)),
            tl: {
              hd: Css.child("*:last-child")({
                    hd: Css.flexGrow(0),
                    tl: {
                      hd: Css.textAlign("right"),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var filterButton = Curry._1(Css.merge, {
      hd: "button",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.flexGrow(0),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var dropdownContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  tag: tag,
  tags: tags,
  tagsControl: tagsControl,
  tagsField: tagsField,
  invisibleInput: invisibleInput,
  controls: controls,
  filterButton: filterButton,
  dropdownContainer: dropdownContainer
};

function SearchBar(Props) {
  var onSearch = Props.onSearch;
  var fields = Props.fields;
  var dropDowns = Props.dropDowns;
  var initialQueryParams = Props.initialQueryParams;
  var match = React.useState(function () {
        return initialQueryParams;
      });
  var setQueryParams = match[1];
  var queryParams = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpenFilterModal = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setCancelFn = match$2[1];
  var cancelFn = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsLoading = match$3[1];
  var called = {
    contents: false
  };
  var doneFunction = function (param) {
    if (!called.contents) {
      Curry._1(setIsLoading, (function (param) {
              return false;
            }));
      called.contents = true;
      return ;
    }
    
  };
  var doOnSearch = function (searchParams) {
    if (cancelFn !== undefined) {
      Curry._1(cancelFn, undefined);
    }
    var cancel = Curry._2(onSearch, doneFunction, searchParams);
    return Curry._1(setCancelFn, (function (param) {
                  return cancel;
                }));
  };
  var onChange = function (e) {
    var value = e.target.value;
    if (Str$RecountTools.trim(value) === "") {
      return Curry._1(setQueryParams, (function (prev) {
                    var searchParams = Arr$RecountTools.keep(prev, (function (v) {
                            switch (v.TAG | 0) {
                              case /* DropDown */0 :
                              case /* Filter */1 :
                                  return true;
                              case /* General */2 :
                                  return false;
                              
                            }
                          }));
                    if (cancelFn !== undefined) {
                      Curry._1(cancelFn, undefined);
                    }
                    var t = setTimeout((function (param) {
                            Curry._1(setIsLoading, (function (param) {
                                    return true;
                                  }));
                            var cancel = Curry._2(onSearch, doneFunction, searchParams);
                            return Curry._1(setCancelFn, (function (param) {
                                          return cancel;
                                        }));
                          }), 300);
                    Curry._1(setCancelFn, (function (param) {
                            return (function (param) {
                                      clearTimeout(t);
                                      
                                    });
                          }));
                    return searchParams;
                  }));
    } else {
      return Curry._1(setQueryParams, (function (prev) {
                    var generalQuery = Arr$RecountTools.find(prev, (function (v) {
                            switch (v.TAG | 0) {
                              case /* DropDown */0 :
                              case /* Filter */1 :
                                  return false;
                              case /* General */2 :
                                  return true;
                              
                            }
                          }));
                    var searchParams = generalQuery !== undefined ? Arr$RecountTools.map(prev, (function (v) {
                              switch (v.TAG | 0) {
                                case /* DropDown */0 :
                                case /* Filter */1 :
                                    return v;
                                case /* General */2 :
                                    return {
                                            TAG: /* General */2,
                                            _0: value
                                          };
                                
                              }
                            })) : Arr$RecountTools.concat(prev, [{
                              TAG: /* General */2,
                              _0: value
                            }]);
                    if (cancelFn !== undefined) {
                      Curry._1(cancelFn, undefined);
                    }
                    var t = setTimeout((function (param) {
                            Curry._1(setIsLoading, (function (param) {
                                    return true;
                                  }));
                            var cancel = Curry._2(onSearch, doneFunction, searchParams);
                            return Curry._1(setCancelFn, (function (param) {
                                          return cancel;
                                        }));
                          }), 300);
                    Curry._1(setCancelFn, (function (param) {
                            return (function (param) {
                                      clearTimeout(t);
                                      
                                    });
                          }));
                    return searchParams;
                  }));
    }
  };
  var onKeyDown = function (e) {
    if (e.key !== "Enter") {
      return ;
    }
    var validTag = /^([a-zA-Z0-9\s.]+):([a-zA-Z0-9\s.,]+)$/;
    var generalQuery = Arr$RecountTools.find(queryParams, (function (value) {
            switch (value.TAG | 0) {
              case /* DropDown */0 :
              case /* Filter */1 :
                  return false;
              case /* General */2 :
                  return true;
              
            }
          }));
    var query;
    if (generalQuery !== undefined) {
      switch (generalQuery.TAG | 0) {
        case /* DropDown */0 :
        case /* Filter */1 :
            query = "";
            break;
        case /* General */2 :
            query = generalQuery._0;
            break;
        
      }
    } else {
      query = "";
    }
    var res = validTag.exec(query);
    if (res === null) {
      return ;
    }
    var displayName = Str$RecountTools.trim(Option$RecountTools.getExn(Caml_option.nullable_to_opt(Arr$RecountTools.getExn(res, 1))));
    var value = Option$RecountTools.getExn(Caml_option.nullable_to_opt(Arr$RecountTools.getExn(res, 2)));
    var field = Arr$RecountTools.find(fields, (function (f) {
            return f.displayName.toLocaleLowerCase() === displayName.toLocaleLowerCase();
          }));
    if (field === undefined) {
      return ;
    }
    var name = field.name;
    return Curry._1(setQueryParams, (function (qp) {
                  var newArr = Arr$RecountTools.keep(qp, (function (q) {
                          switch (q.TAG | 0) {
                            case /* DropDown */0 :
                            case /* Filter */1 :
                                return true;
                            case /* General */2 :
                                return false;
                            
                          }
                        }));
                  var searchParams = Arr$RecountTools.concat(newArr, [{
                          TAG: /* Filter */1,
                          _0: {
                            name: name,
                            value: value
                          }
                        }]);
                  doOnSearch(searchParams);
                  return searchParams;
                }));
  };
  var handleAddFilter = function (name, value) {
    Curry._1(setQueryParams, (function (qp) {
            var searchParams = Arr$RecountTools.concat(qp, [{
                    TAG: /* Filter */1,
                    _0: {
                      name: name,
                      value: value
                    }
                  }]);
            doOnSearch(searchParams);
            return searchParams;
          }));
    return Curry._1(setOpenFilterModal, (function (param) {
                  return false;
                }));
  };
  var closeModal = function (param) {
    return Curry._1(setOpenFilterModal, (function (param) {
                  return false;
                }));
  };
  var v = Arr$RecountTools.join(Arr$RecountTools.map(queryParams, (function (q) {
              switch (q.TAG | 0) {
                case /* DropDown */0 :
                case /* Filter */1 :
                    return "";
                case /* General */2 :
                    return q._0;
                
              }
            })), "");
  var msg = Arr$RecountTools.reduce(fields, "Search by field by entering \"field:value\" then pressing enter. \nAvailable fields are: ", (function (msg, field) {
          var firstPos = msg.indexOf("\n");
          var pos = msg.lastIndexOf("\n");
          if ((msg.length - pos | 0) > firstPos) {
            return msg + field.name + ", \n";
          } else {
            return msg + field.name + ", ";
          }
        }));
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "field"
                }, React.createElement("div", {
                      className: match$3[0] ? "control is-large is-loading" : "control"
                    }, React.createElement("div", {
                          className: tagsField
                        }, React.createElement("div", {
                              className: tagsControl
                            }, Arr$RecountTools.map(Arr$RecountTools.keepMap(queryParams, (function (qp) {
                                        switch (qp.TAG | 0) {
                                          case /* Filter */1 :
                                              return qp._0;
                                          case /* DropDown */0 :
                                          case /* General */2 :
                                              return ;
                                          
                                        }
                                      })), (function (field) {
                                    var selectedField = Arr$RecountTools.find(fields, (function (f) {
                                            return f.name === field.name;
                                          }));
                                    var displayName = selectedField !== undefined ? selectedField.displayName : "";
                                    var partial_arg = field.value;
                                    var partial_arg$1 = field.name;
                                    return React.createElement("div", {
                                                key: field.name,
                                                className: tags
                                              }, React.createElement("span", {
                                                    key: field.name,
                                                    className: tag
                                                  }, displayName + ":" + field.value), React.createElement("a", {
                                                    className: "tag is-delete",
                                                    onClick: (function (param) {
                                                        return Curry._1(setQueryParams, (function (prev) {
                                                                      var searchParams = Arr$RecountTools.keep(prev, (function (t) {
                                                                              switch (t.TAG | 0) {
                                                                                case /* Filter */1 :
                                                                                    var params = t._0;
                                                                                    return !(params.name === partial_arg$1 && params.value === partial_arg);
                                                                                case /* DropDown */0 :
                                                                                case /* General */2 :
                                                                                    return true;
                                                                                
                                                                              }
                                                                            }));
                                                                      doOnSearch(searchParams);
                                                                      return searchParams;
                                                                    }));
                                                      })
                                                  }));
                                  }))), React.createElement("input", {
                              className: invisibleInput,
                              placeholder: "Search for videos",
                              type: "text",
                              value: v,
                              onKeyDown: onKeyDown,
                              onChange: onChange
                            }), React.createElement(Tooltip$RecountTools.make, {
                              text: msg,
                              size: /* Large */3,
                              color: "has-text-grey-light",
                              position: /* Left */3,
                              children: React.createElement("span", {
                                    className: "icon"
                                  }, React.createElement("i", {
                                        "aria-hidden": true,
                                        className: "fa fa-question-circle-o"
                                      }))
                            })))), React.createElement("div", {
                  className: controls
                }, Arr$RecountTools.map(dropDowns, (function (dropdown) {
                        var currentValue = Arr$RecountTools.findMap(queryParams, (function (qp) {
                                switch (qp.TAG | 0) {
                                  case /* DropDown */0 :
                                      var match = qp._0;
                                      if (match.name === dropdown.name) {
                                        return match.value;
                                      } else {
                                        return ;
                                      }
                                  case /* Filter */1 :
                                  case /* General */2 :
                                      return ;
                                  
                                }
                              }));
                        var tmp = {
                          label: dropdown.displayName,
                          options: Arr$RecountTools.map(dropdown.values, (function (s) {
                                  return {
                                          label: s.displayName,
                                          value: s.value
                                        };
                                })),
                          onSelect: (function (option) {
                              var dropdownName = dropdown.name;
                              return Curry._1(setQueryParams, (function (qp) {
                                            var newArr = Arr$RecountTools.keep(qp, (function (q) {
                                                    switch (q.TAG | 0) {
                                                      case /* DropDown */0 :
                                                          return q._0.name !== dropdownName;
                                                      case /* Filter */1 :
                                                      case /* General */2 :
                                                          return true;
                                                      
                                                    }
                                                  }));
                                            var searchParams = Arr$RecountTools.concat(newArr, [{
                                                    TAG: /* DropDown */0,
                                                    _0: {
                                                      name: dropdownName,
                                                      value: option
                                                    }
                                                  }]);
                                            doOnSearch(searchParams);
                                            return searchParams;
                                          }));
                            }),
                          onClear: (function (param) {
                              var dropdownName = dropdown.name;
                              var searchParams = Arr$RecountTools.keep(queryParams, (function (qp) {
                                      switch (qp.TAG | 0) {
                                        case /* DropDown */0 :
                                            return qp._0.name !== dropdownName;
                                        case /* Filter */1 :
                                        case /* General */2 :
                                            return true;
                                        
                                      }
                                    }));
                              Curry._1(setQueryParams, (function (param) {
                                      return searchParams;
                                    }));
                              return doOnSearch(searchParams);
                            }),
                          key: dropdown.name
                        };
                        if (currentValue !== undefined) {
                          tmp.value = Caml_option.valFromOption(currentValue);
                        }
                        return React.createElement(DropDown$RecountTools.make, tmp);
                      })), React.createElement("button", {
                      className: filterButton,
                      onClick: (function (param) {
                          return Curry._1(setOpenFilterModal, (function (param) {
                                        return true;
                                      }));
                        })
                    }, "Add Filter")), React.createElement(AddFilterForm$RecountTools.make, {
                  isActive: match$1[0],
                  onModalClose: closeModal,
                  fields: fields,
                  onSubmit: handleAddFilter
                }));
}

var make = SearchBar;

export {
  Styles ,
  make ,
  
}
/* tag Not a pure module */
