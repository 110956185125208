

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$RecountTools from "./Icon.bs.js";
import * as Tooltip$RecountTools from "./Tooltip.bs.js";

var main = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.color(Css.hex("363636")),
        tl: {
          hd: Css.margin2(Css.px(0), Css.px(5)),
          tl: {
            hd: Css.selector("&:active", {
                  hd: Css.color(Css.hex("3273dc")),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  main: main
};

function CopyToClipboard(Props) {
  var text = Props.text;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  var copied = match[0];
  var onClick = function (param) {
    navigator.clipboard.writeText(text);
    return Curry._1(setCopied, (function (param) {
                  return true;
                }));
  };
  React.useEffect((function () {
          if (!copied) {
            return ;
          }
          var id = setTimeout((function (param) {
                  return Curry._1(setCopied, (function (param) {
                                return false;
                              }));
                }), 3000);
          return (function (param) {
                    clearTimeout(id);
                    
                  });
        }), [copied]);
  var button = React.createElement("a", {
        className: main,
        onClick: onClick
      }, React.createElement(Icon$RecountTools.make, {
            icon: /* Clipboard */7
          }));
  if (copied) {
    return React.createElement(Tooltip$RecountTools.make, {
                text: "Copied to clipboard",
                children: button
              });
  } else {
    return button;
  }
}

var make = CopyToClipboard;

export {
  Styles ,
  make ,
  
}
/* main Not a pure module */
