

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Int$RecountTools from "../util/Int.bs.js";

var iconBlue = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              142,
              201,
              241
            ]
          }),
      tl: /* [] */0
    });

var iconGreen = Curry._1(Css.style, {
      hd: Css.color({
            NAME: "rgb",
            VAL: [
              135,
              232,
              218
            ]
          }),
      tl: /* [] */0
    });

var verticalLineLeft = Curry._1(Css.style, {
      hd: Css.height(Css.auto),
      tl: {
        hd: Css.borderLeftWidth(Css.rem(0.2)),
        tl: {
          hd: Css.borderLeftColor({
                NAME: "rgb",
                VAL: [
                  135,
                  232,
                  218
                ]
              }),
          tl: {
            hd: Css.borderLeftStyle("solid"),
            tl: /* [] */0
          }
        }
      }
    });

var verticalLineRight = Curry._1(Css.style, {
      hd: Css.height(Css.auto),
      tl: {
        hd: Css.borderRightWidth(Css.rem(0.2)),
        tl: {
          hd: Css.borderRightColor({
                NAME: "rgb",
                VAL: [
                  135,
                  232,
                  218
                ]
              }),
          tl: {
            hd: Css.borderRightStyle("solid"),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  iconBlue: iconBlue,
  iconGreen: iconGreen,
  verticalLineLeft: verticalLineLeft,
  verticalLineRight: verticalLineRight
};

function Pagination(Props) {
  var onClick = Props.onClick;
  var total = Props.total;
  var pageSize = Props.pageSize;
  var currentPage = Props.currentPage;
  var totalPages = Caml_int32.div(total, pageSize);
  var pages = currentPage === 0 ? [
      0,
      1,
      2
    ] : (
      currentPage === (totalPages - 1 | 0) ? [
          totalPages - 3 | 0,
          totalPages - 2 | 0,
          totalPages - 1 | 0
        ] : [
          currentPage - 1 | 0,
          currentPage,
          currentPage + 1 | 0
        ]
    );
  return React.createElement("div", {
              "aria-label": "pagination",
              className: "is-flex is-justify-content-center",
              role: "navigation"
            }, React.createElement("div", {
                  className: "field has-addons"
                }, currentPage > 1 ? React.createElement("p", {
                        className: "control"
                      }, React.createElement("button", {
                            className: "button is-info is-outlined",
                            onClick: (function (param) {
                                return Curry._1(onClick, 0);
                              })
                          }, "1")) : null, currentPage > 2 ? React.createElement("p", {
                        className: "control"
                      }, React.createElement("button", {
                            className: "button is-info is-outlined",
                            disabled: true
                          }, "...")) : null, Arr$RecountTools.map(pages, (function (page) {
                        if (page >= 0 && page < totalPages) {
                          return React.createElement("p", {
                                      className: "control"
                                    }, React.createElement("button", {
                                          key: Int$RecountTools.toString(undefined, page),
                                          className: "button is-info" + (
                                            page !== currentPage ? " is-outlined" : ""
                                          ),
                                          onClick: (function (param) {
                                              return Curry._1(onClick, page);
                                            })
                                        }, Int$RecountTools.toString(undefined, page + 1 | 0)));
                        } else {
                          return null;
                        }
                      })), currentPage < (totalPages - 3 | 0) ? React.createElement("p", {
                        className: "control"
                      }, React.createElement("button", {
                            className: "button is-info is-outlined",
                            disabled: true
                          }, "...")) : null, currentPage < (totalPages - 2 | 0) ? React.createElement("p", {
                        className: "control"
                      }, React.createElement("button", {
                            className: "button is-info is-outlined",
                            onClick: (function (param) {
                                return Curry._1(onClick, totalPages - 1 | 0);
                              })
                          }, Int$RecountTools.toString(undefined, totalPages))) : null));
}

var Cursor;

var Id;

var make = Pagination;

export {
  Cursor ,
  Id ,
  Styles ,
  make ,
  
}
/* iconBlue Not a pure module */
