

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Int$RecountTools from "../../util/Int.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Image$RecountTools from "../../components/Image.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Observable$RecountTools from "../../util/Observable.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as PageHistory$RecountTools from "../../util/PageHistory.bs.js";
import * as ProseMirror$RecountTools from "../../components/ProseMirror/ProseMirror.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";
import * as PodcastShowStore$RecountTools from "../../stores/PodcastShowStore.bs.js";
import * as PodcastEpisodeStore$RecountTools from "../../stores/PodcastEpisodeStore.bs.js";

var label = Curry._1(Css.style, {
      hd: Css.fontSize(Css.rem(1)),
      tl: {
        hd: Css.color(Css.hex("363636")),
        tl: {
          hd: Css.fontWeight("bold"),
          tl: /* [] */0
        }
      }
    });

var buttonTooltip = Curry._1(Css.merge, {
      hd: "control",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.selector("button", {
                      hd: Css.paddingRight(Css.rem(3)),
                      tl: {
                        hd: Css.transition(200, undefined, "easeInOut", "padding"),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.selector(".has-tooltip-arrow", {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.border(Css.px(0), "none", {
                                NAME: "hex",
                                VAL: "fff"
                              }),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.top(Css.rem(-0.25)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var thumbnail = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignContent("center"),
        tl: /* [] */0
      }
    });

var Styles = {
  label: label,
  buttonTooltip: buttonTooltip,
  thumbnail: thumbnail
};

var validators = {
  transcript: {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (input) {
        return {
                TAG: /* Ok */0,
                _0: input.transcript
              };
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          transcript: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            transcript: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.transcript;
  var match$1 = match ? match._0 : Curry._1(validators.transcript.validate, input);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              transcript: match$1._0
            },
            fieldsStatuses: {
              transcript: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              transcript: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTranscriptField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.transcript, validators.transcript, (function (status) {
                          return {
                                  transcript: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */1,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */2,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */4);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */3);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateTranscriptField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.transcript, state.submissionStatus, validators.transcript, (function (status) {
                                    return {
                                            transcript: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                transcript: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                transcript: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateTranscript: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTranscriptField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurTranscript: (function (param) {
              return Curry._1(dispatch, /* BlurTranscriptField */0);
            }),
          transcriptResult: Formality.exposeFieldResult(state.fieldsStatuses.transcript),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.transcript) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */3,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */4);
            })
        };
}

var PodcastEpisodeForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_PodcastEpisodes_Editor-RecountTools.UnknownError");

function Page_PodcastEpisodes_Editor$LoadedState(Props) {
  var podcastEpisode = Props.podcastEpisode;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var autoSaveKey = "autosave:podcastEpisode.transcript:" + Models_Id$RecountTools.toString(podcastEpisode.id);
  var initialTranscript = Option$RecountTools.map(podcastEpisode.transcript, ProseMirror$RecountTools.$$Document.fromJson);
  var form = useForm({
        transcript: initialTranscript
      }, (function (output, cb) {
          var fields = [];
          if (Caml_obj.caml_notequal(Option$RecountTools.map(output.transcript, ProseMirror$RecountTools.$$Document.toJson), podcastEpisode.transcript)) {
            Arr$RecountTools.push(fields, [
                  "transcript",
                  Json_encode.nullable(ProseMirror$RecountTools.$$Document.toJson, output.transcript)
                ]);
          }
          var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                    hd: [
                      "data",
                      Json_encode.object_(Belt_List.fromArray(fields))
                    ],
                    tl: /* [] */0
                  }));
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.map(Request$RecountTools.patch(body, undefined, undefined, undefined, {
                            TAG: /* Api */0,
                            _0: "/v2/podcast-episodes/" + Models_Id$RecountTools.toString(podcastEpisode.id)
                          }), (function (response) {
                          if (response.ok) {
                            return PodcastEpisodeStore$RecountTools.update(podcastEpisode.id, (function (data) {
                                          return data;
                                        }));
                          } else {
                            Curry._3(makeToast, undefined, /* Error */2, "Unable to save podcast");
                            return ;
                          }
                        })), (function (param) {
                      Curry._1(cb.reset, undefined);
                      localStorage.removeItem(autoSaveKey);
                      
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var tmp = {
    autoSaveKey: autoSaveKey,
    onChange: (function (body) {
        return Curry._2(form.updateTranscript, (function (param, value) {
                      return {
                              transcript: value
                            };
                    }), Caml_option.some(body));
      }),
    onBlur: (function (param) {
        return Curry._1(form.blurTranscript, undefined);
      })
  };
  if (initialTranscript !== undefined) {
    tmp.document = Caml_option.valFromOption(initialTranscript);
  }
  var match$1 = form.transcriptResult;
  var tmp$1;
  tmp$1 = match$1 !== undefined && match$1.TAG !== /* Ok */0 ? React.createElement("p", {
          className: "help is-danger"
        }, match$1._0) : null;
  var tmp$2 = {
    label: "Save",
    disabled: form.submitting || !Curry._1(form.dirty, undefined),
    kind: "submit",
    className: "is-link"
  };
  var tmp$3 = Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save");
  if (tmp$3 !== undefined) {
    tmp$2.disabledReason = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, React.createElement("div", {
                          className: "field"
                        }, React.createElement("label", {
                              className: "label"
                            }, "Title"), React.createElement("p", undefined, podcastEpisode.title)), React.createElement("div", {
                          className: "field"
                        }, React.createElement("label", {
                              className: "label"
                            }, "Show Name"), React.createElement("p", undefined, Models_Id$RecountTools.unsafe_refAsResolved(podcastEpisode.podcastShow).name)), React.createElement("div", {
                          className: "field"
                        }, React.createElement("label", {
                              className: "label"
                            }, "Episode Number"), React.createElement("p", undefined, Option$RecountTools.mapWithDefault(podcastEpisode.episodeNumber, "", (function (eta) {
                                    return Int$RecountTools.toString(undefined, eta);
                                  }))))), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: React.createElement(Image$RecountTools.make, {
                            id: podcastEpisode.thumbnail,
                            height: 400,
                            fit: /* Fit */{
                              _0: "cover"
                            },
                            draggable: false
                          }),
                      className: thumbnail
                    })), React.createElement("label", {
                  className: "label"
                }, "Transcript"), React.createElement(ProseMirror$RecountTools.make, tmp), tmp$1, React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, tmp$2)));
}

var LoadedState = {
  make: Page_PodcastEpisodes_Editor$LoadedState
};

function Page_PodcastEpisodes_Editor(Props) {
  var id = Props.id;
  var podcastEpisode = Observable$RecountTools.useMemoizedObservable(PodcastEpisodeStore$RecountTools.get(id));
  React.useEffect((function () {
          PodcastEpisodeStore$RecountTools.fetchOne(id);
          PodcastShowStore$RecountTools.ensureFetched(undefined);
          
        }), []);
  var tmp;
  if (typeof podcastEpisode === "number") {
    if (podcastEpisode !== 0) {
      PageHistory$RecountTools.revertPage(undefined);
      tmp = "Podcast Episode Not Found";
    } else {
      tmp = "Loading";
    }
  } else {
    var podcastEpisode$1 = podcastEpisode._0;
    tmp = React.createElement(Page_PodcastEpisodes_Editor$LoadedState, {
          podcastEpisode: podcastEpisode$1,
          key: Models_Id$RecountTools.toString(podcastEpisode$1.id)
        });
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: tmp,
              pageName: "Edit Podcast Episode",
              crumbs: [{
                  link: "/podcast-episodes",
                  name: "Podcast Episodes"
                }]
            });
}

var make = Page_PodcastEpisodes_Editor;

export {
  Styles ,
  PodcastEpisodeForm ,
  UnknownError ,
  LoadedState ,
  make ,
  
}
/* label Not a pure module */
