

import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Models_Show$RecountTools from "../models/Models_Show.bs.js";

var rawShows = MicroObservables.observable(undefined);

function get(id) {
  return rawShows.transform(function (vs) {
              return Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id));
            });
}

function getAll(ids) {
  return rawShows.transform(function (vs) {
              return Arr$RecountTools.keepMap(ids, (function (id) {
                            return Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id));
                          }));
            });
}

var all = rawShows.transform(Belt_MapInt.valuesToArray);

function add(v) {
  rawShows.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(v.id), v);
      });
  
}

function addAll(vs) {
  rawShows.update(function (dict) {
        return Arr$RecountTools.reduce(vs, dict, (function (acc, v) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(v.id), v);
                    }));
      });
  
}

function queryShows(param) {
  return Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                      TAG: /* Api */0,
                      _0: "/v1/shows"
                    }), (function (prim) {
                    return prim.json();
                  })), (function (param) {
                return Json_decode.array(Models_Show$RecountTools.fromJson, param);
              }));
}

function fetchAll(param) {
  Async$RecountTools.map(queryShows(undefined), addAll);
  
}

function ensureFetched(param) {
  var match = Belt_MapInt.valuesToArray(rawShows.get());
  if (match.length !== 0) {
    return ;
  } else {
    return fetchAll(undefined);
  }
}

function resolve(ref) {
  if (ref.TAG === /* Resolved */0) {
    return Promise.resolve(ref._0);
  }
  var id = ref._0;
  var show = Belt_MapInt.get(rawShows.get(), Models_Id$RecountTools.toInt(id));
  if (show !== undefined) {
    return Promise.resolve(show);
  } else {
    return Async$RecountTools.map(queryShows(undefined), (function (shows) {
                  addAll(shows);
                  return Option$RecountTools.getExn(Arr$RecountTools.find(shows, (function (s) {
                                    return s.id === id;
                                  })));
                }));
  }
}

var OW;

var IMap;

export {
  OW ,
  IMap ,
  get ,
  getAll ,
  all ,
  add ,
  addAll ,
  fetchAll ,
  ensureFetched ,
  resolve ,
  
}
/* rawShows Not a pure module */
