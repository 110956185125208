

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "./Arr.bs.js";
import * as Option$RecountTools from "./Option.bs.js";

function _make(prim) {
  return new XMLHttpRequest();
}

function make(onReadyStateChange, timeout, withCredentialsOpt, asyncOpt, user, password, mimeType, headersOpt, onAbort, onError, onLoad, onLoadEnd, onLoadStart, onProgress, onTimeout, onUploadAbort, onUploadError, onUploadLoad, onUploadLoadEnd, onUploadLoadStart, onUploadProgress, onUploadTimeout, body, method, url) {
  var withCredentials = withCredentialsOpt !== undefined ? withCredentialsOpt : false;
  var async = asyncOpt !== undefined ? asyncOpt : true;
  var headers = headersOpt !== undefined ? headersOpt : [];
  return {
          onReadyStateChange: onReadyStateChange,
          responseType: "text",
          timeout: timeout,
          withCredentials: withCredentials,
          method: method,
          url: url,
          async: async,
          user: user,
          password: password,
          mimeType: mimeType,
          headers: headers,
          onAbort: onAbort,
          onError: onError,
          onLoad: onLoad,
          onLoadEnd: onLoadEnd,
          onLoadStart: onLoadStart,
          onProgress: onProgress,
          onTimeout: onTimeout,
          onUploadAbort: onUploadAbort,
          onUploadError: onUploadError,
          onUploadLoad: onUploadLoad,
          onUploadLoadEnd: onUploadLoadEnd,
          onUploadLoadStart: onUploadLoadStart,
          onUploadProgress: onUploadProgress,
          onUploadTimeout: onUploadTimeout,
          body: body
        };
}

function send(config) {
  var client = new XMLHttpRequest();
  var errored = {
    contents: false
  };
  client.open(config.method, config.url, config.async, config.user !== undefined ? Caml_option.valFromOption(config.user) : undefined, config.password !== undefined ? Caml_option.valFromOption(config.password) : undefined);
  Option$RecountTools.forEach(config.timeout, (function (param) {
          client.timeout = param;
          
        }));
  client.withCredentials = config.withCredentials;
  Option$RecountTools.forEach(config.mimeType, (function (param) {
          client.overrideMimeType(param);
          
        }));
  client.responseType = config.responseType;
  Arr$RecountTools.forEach(config.headers, (function (param) {
          client.setRequestHeader(param[0], param[1]);
          
        }));
  Option$RecountTools.forEach(config.onReadyStateChange, (function (fn) {
          client.onreadystatechange = (function () {
              return Curry._1(fn, undefined);
            });
          
        }));
  Option$RecountTools.forEach(config.onAbort, (function (fn) {
          client.onabort = Curry.__1(fn);
          
        }));
  var fn = config.onError;
  if (fn !== undefined) {
    client.onerror = (function (e) {
        errored.contents = true;
        var message = Option$RecountTools.getWithDefault(Caml_option.null_to_opt(client.response), "Network error when attempting to fetch resource");
        return Curry._3(fn, client.status, message, e);
      });
  } else {
    client.onerror = (function (param) {
        errored.contents = true;
        
      });
  }
  Option$RecountTools.forEach(config.onLoad, (function (fn) {
          client.onload = (function (param) {
              var response = client.response;
              return Curry._2(fn, client.status, response === null ? undefined : Caml_option.some(response));
            });
          
        }));
  Option$RecountTools.forEach(config.onLoadEnd, (function (fn) {
          client.onloadend = (function (e) {
              if (errored.contents) {
                var message = Option$RecountTools.getWithDefault(Caml_option.null_to_opt(client.response), "Network error when attempting to fetch resource");
                return Curry._3(fn, client.status, {
                            TAG: /* Error */1,
                            _0: message
                          }, e);
              }
              var response = client.response;
              return Curry._3(fn, client.status, {
                          TAG: /* Ok */0,
                          _0: response === null ? undefined : Caml_option.some(response)
                        }, e);
            });
          
        }));
  Option$RecountTools.forEach(config.onLoadStart, (function (fn) {
          client.onloadstart = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onProgress, (function (fn) {
          client.onprogress = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onTimeout, (function (fn) {
          client.ontimeout = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadAbort, (function (fn) {
          client.upload.onabort = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadError, (function (fn) {
          client.upload.onerror = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadLoad, (function (fn) {
          client.upload.onload = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadLoadEnd, (function (fn) {
          client.upload.onloadend = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadLoadStart, (function (fn) {
          client.upload.onloadstart = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadProgress, (function (fn) {
          client.upload.onprogress = Curry.__1(fn);
          
        }));
  Option$RecountTools.forEach(config.onUploadTimeout, (function (fn) {
          client.upload.ontimeout = Curry.__1(fn);
          
        }));
  var b = config.body;
  if (b !== undefined) {
    client.send(b.VAL);
  } else {
    client.send();
  }
  return function (param) {
    client.abort();
    
  };
}

function arrayBuffer(c) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.responseType = "arraybuffer";
  return newrecord;
}

function blob(c) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.responseType = "blob";
  return newrecord;
}

function $$document(c) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.responseType = "document";
  return newrecord;
}

function json(c) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.responseType = "json";
  return newrecord;
}

function text(c) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.responseType = "text";
  return newrecord;
}

var ResponseType = {
  arrayBuffer: arrayBuffer,
  blob: blob,
  $$document: $$document,
  json: json,
  text: text
};

function onAbort(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onAbort = fn;
  return newrecord;
}

function onError(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onError = fn;
  return newrecord;
}

function onLoad(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onLoad = fn;
  return newrecord;
}

function onLoadEnd(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onLoadEnd = fn;
  return newrecord;
}

function onLoadStart(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onLoadStart = fn;
  return newrecord;
}

function onProgress(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onProgress = fn;
  return newrecord;
}

function onUploadProgress(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onUploadProgress = fn;
  return newrecord;
}

function onTimeout(c, fn) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.onTimeout = fn;
  return newrecord;
}

var $$Event = {
  onAbort: onAbort,
  onError: onError,
  onLoad: onLoad,
  onLoadEnd: onLoadEnd,
  onLoadStart: onLoadStart,
  onProgress: onProgress,
  onUploadProgress: onUploadProgress,
  onTimeout: onTimeout
};

function add(c, name, value) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.headers = Arr$RecountTools.append(c.headers, [
        name,
        value
      ]);
  return newrecord;
}

function set(c, headers) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.headers = headers;
  return newrecord;
}

var Header = {
  add: add,
  set: set
};

function blob$1(c, blob$2) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "Blob",
    VAL: blob$2
  };
  return newrecord;
}

function arrayBuffer$1(c, ab) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "ArrayBuffer",
    VAL: ab
  };
  return newrecord;
}

function file(c, f) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "File",
    VAL: f
  };
  return newrecord;
}

function formData(c, fd) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "FormData",
    VAL: fd
  };
  return newrecord;
}

function searchParams(c, sp) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "UrlSearchParams",
    VAL: sp
  };
  return newrecord;
}

function string(c, str) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "String",
    VAL: str
  };
  return newrecord;
}

function json$1(c, json$2) {
  var newrecord = Caml_obj.caml_obj_dup(c);
  newrecord.body = {
    NAME: "String",
    VAL: JSON.stringify(json$2)
  };
  newrecord.headers = Arr$RecountTools.append(c.headers, [
        "Content-Type",
        "application/json"
      ]);
  return newrecord;
}

var Body = {
  blob: blob$1,
  arrayBuffer: arrayBuffer$1,
  file: file,
  formData: formData,
  searchParams: searchParams,
  string: string,
  json: json$1
};

var Immutable = {
  _make: _make,
  make: make,
  send: send,
  ResponseType: ResponseType,
  $$Event: $$Event,
  Header: Header,
  Body: Body
};

export {
  Immutable ,
  
}
/* No side effect */
