

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Icon$RecountTools from "../components/Icon.bs.js";
import * as Link$RecountTools from "../components/Link.bs.js";
import * as UserStore$RecountTools from "../stores/UserStore.bs.js";

var navbar = Curry._1(Css.style, {
      hd: Css.alignItems("center"),
      tl: {
        hd: Css.backgroundColor(Css.white),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", {
                NAME: "hex",
                VAL: "c5dbea"
              }),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.height(Css.rem(3)),
              tl: {
                hd: Css.paddingLeft(Css.rem(0.5)),
                tl: {
                  hd: Css.width(Css.pct(100)),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var appTitle = Curry._1(Css.style, {
      hd: Css.fontSize(Css.rem(1.5)),
      tl: {
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.padding(Css.rem(0.5)),
          tl: /* [] */0
        }
      }
    });

var breadcrumb = Curry._1(Css.merge, {
      hd: "breadcrumb",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.marginLeft("auto"),
              tl: {
                hd: Css.marginRight("auto"),
                tl: {
                  hd: Css.important(Css.marginBottom(Css.px(0))),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var slackButtonStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.fontFamilies({
              hd: "sansSerif",
              tl: {
                hd: {
                  NAME: "custom",
                  VAL: "Lato"
                },
                tl: /* [] */0
              }
            }),
        tl: {
          hd: Css.fontSize(Css.px(14)),
          tl: {
            hd: Css.fontWeight({
                  NAME: "num",
                  VAL: 600
                }),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.textDecoration("none"),
                tl: {
                  hd: Css.width(Css.px(180)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var slackHref = "https://slack.com/oauth/v2/authorize?user_scope=files:write,chat:write&redirect_uri=" + process.env.GOOGLE_CLIENT_REDIRECT + "&client_id=" + process.env.SLACK_CLIENT_ID;

var slackHrefTNM = "https://slack.com/oauth/v2/authorize?user_scope=files:write,chat:write&redirect_uri=" + process.env.GOOGLE_CLIENT_REDIRECT + "&client_id=" + process.env.SLACK_CLIENT_ID_TNM;

var Styles = {
  navbar: navbar,
  appTitle: appTitle,
  breadcrumb: breadcrumb,
  slackButtonStyle: slackButtonStyle,
  slackHref: slackHref,
  slackHrefTNM: slackHrefTNM
};

function Layout_Header(Props) {
  var crumbs = Props.crumbs;
  var pageName = Props.pageName;
  React.useEffect((function () {
          UserStore$RecountTools.ensureFetchedCurrent(undefined);
          
        }), []);
  return React.createElement("div", {
              className: navbar
            }, React.createElement("nav", {
                  "aria-label": "breadcrumbs",
                  className: breadcrumb
                }, React.createElement("ul", {
                      className: "is-flex is-align-items-center"
                    }, React.createElement("li", undefined, React.createElement("h1", {
                              className: appTitle
                            }, "Recount Tools")), Arr$RecountTools.map(crumbs, (function (crumb) {
                            return React.createElement("li", {
                                        key: crumb.name
                                      }, React.createElement(Link$RecountTools.make, {
                                            href: crumb.link,
                                            children: crumb.name
                                          }));
                          })), React.createElement("li", {
                          className: "is-active"
                        }, React.createElement("a", {
                              href: "#"
                            }, pageName)))), React.createElement("nav", undefined, React.createElement("ul", {
                      className: "is-flex is-align-items-center"
                    }, React.createElement("li", undefined, React.createElement("a", {
                              className: slackButtonStyle,
                              href: slackHref
                            }, React.createElement(Icon$RecountTools.AddToSlack.make, {}), "Set Slack: Recount")), React.createElement("li", undefined, React.createElement("a", {
                              className: slackButtonStyle,
                              href: slackHrefTNM
                            }, React.createElement(Icon$RecountTools.AddToSlack.make, {}), "Set Slack: TNM")))));
}

var make = Layout_Header;

export {
  Styles ,
  make ,
  
}
/* navbar Not a pure module */
