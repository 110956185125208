

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Option$RecountTools from "../util/Option.bs.js";

function props(el) {
  if (React.isValidElement(el)) {
    return Caml_option.some(el.props);
  }
  
}

function Tooltip(Props) {
  var text = Props.text;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var positionOpt = Props.position;
  var children = Props.children;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var color = colorOpt !== undefined ? colorOpt : "";
  var position = positionOpt !== undefined ? positionOpt : /* Top */0;
  var elRef = React.useRef(null);
  var sizeClass;
  switch (size) {
    case /* Small */0 :
        sizeClass = "is-small";
        break;
    case /* Normal */1 :
        sizeClass = "";
        break;
    case /* Medium */2 :
        sizeClass = "is-medium";
        break;
    case /* Large */3 :
        sizeClass = "is-large";
        break;
    
  }
  var positionClass;
  switch (position) {
    case /* Top */0 :
        positionClass = "has-tooltip-top";
        break;
    case /* Right */1 :
        positionClass = "has-tooltip-right";
        break;
    case /* Bottom */2 :
        positionClass = "has-tooltip-bottom";
        break;
    case /* Left */3 :
        positionClass = "has-tooltip-left";
        break;
    
  }
  React.useEffect((function () {
          var className = "has-tooltip-arrow " + positionClass + " " + sizeClass + " " + color;
          Option$RecountTools.forEach(Caml_option.nullable_to_opt(elRef.current), (function (el) {
                  el.setAttribute("data-tooltip", text);
                  var classNames = el.getAttribute("class");
                  var classes = (classNames == null) ? className : classNames + " " + className;
                  el.setAttribute("class", classes);
                  
                }));
          
        }), [
        text,
        size,
        color,
        position,
        children
      ]);
  return React.cloneElement(children, {
              ref: elRef
            });
}

var make = Tooltip;

export {
  props ,
  make ,
  
}
/* react Not a pure module */
