

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function fromJson(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(v.id)
              ],
              tl: {
                hd: [
                  "description",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), v.description)
                ],
                tl: {
                  hd: [
                    "title",
                    v.title
                  ],
                  tl: /* [] */0
                }
              }
            });
}

var Id;

export {
  Id ,
  fromJson ,
  toJson ,
  
}
/* No side effect */
