

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";
import * as Models_PodcastShow$RecountTools from "./Models_PodcastShow.bs.js";

function dateFromEpoch(json) {
  return new Date(Json_decode.$$float(json) * 1000);
}

function optionalDateFromEpoch(json) {
  var d = Json_decode.optional(Json_decode.$$float, json);
  return Option$RecountTools.flatMap(d, (function (d) {
                var d$1 = d * 1000;
                if (d$1 === 0) {
                  return ;
                } else {
                  return Caml_option.some(new Date(d$1));
                }
              }));
}

function toJson(p) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(p.id)
              ],
              tl: {
                hd: [
                  "title",
                  p.title
                ],
                tl: {
                  hd: [
                    "episode_no",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), p.episodeNumber)
                  ],
                  tl: {
                    hd: [
                      "transcript",
                      Json_encode.nullable((function (i) {
                              return i;
                            }), p.transcript)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function fromJson(json) {
  var thumbnailUrl = Json_decode.optional((function (param) {
          return Json_decode.field("cover_art", Json_decode.string, param);
        }), json);
  var thumbnail = thumbnailUrl === undefined ? /* NoThumbnail */1 : /* Thumbnail */({
        _0: Option$RecountTools.getExn(thumbnailUrl)
      });
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          title: Json_decode.field("title", Json_decode.string, json),
          episodeNumber: Json_decode.optional((function (param) {
                  return Json_decode.field("episode_no", Json_decode.$$int, param);
                }), json),
          publishedAt: Json_decode.field("published_at", optionalDateFromEpoch, json),
          createdAt: Json_decode.field("created_at", dateFromEpoch, json),
          podcastShow: Json_decode.either((function (json) {
                    return {
                            TAG: /* Resolved */0,
                            _0: Json_decode.field("podcastShow", Models_PodcastShow$RecountTools.fromJson, json)
                          };
                  }), (function (json) {
                    return {
                            TAG: /* Unresolved */1,
                            _0: Json_decode.field("podcast_show_id", Models_Id$RecountTools.fromJson, json)
                          };
                  }))(json),
          thumbnail: thumbnail,
          transcript: Json_decode.optional((function (param) {
                  return Json_decode.field("transcript", (function (i) {
                                return i;
                              }), param);
                }), json)
        };
}

var Id;

var PodcastShow;

var Thumbnail;

export {
  Id ,
  PodcastShow ,
  Thumbnail ,
  toJson ,
  fromJson ,
  
}
/* No side effect */
