

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Form_Select$RecountTools from "./Form_Select.bs.js";

function Form_MultiSelect(Props) {
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var error = Props.error;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var options = Props.options;
  var label = Props.label;
  var values = Props.values;
  var handleOnChange = function (e) {
    var inArray = Arr$RecountTools.some(values, (function (s) {
            return s === e.target.value;
          }));
    if (!inArray) {
      return Curry._1(onChange, {
                  TAG: /* Added */0,
                  _0: e.target.value
                });
    }
    
  };
  var outputDisplay = Arr$RecountTools.map(values, (function (s) {
          var sectionLabel = Arr$RecountTools.find(options, (function (o) {
                  return o.value === s;
                }));
          if (sectionLabel !== undefined) {
            return React.createElement("span", {
                        key: s,
                        className: "tag"
                      }, sectionLabel.label, React.createElement("button", {
                            className: "delete is-small",
                            type: "button",
                            onClick: (function (param) {
                                return Curry._1(onChange, {
                                            TAG: /* Removed */1,
                                            _0: s
                                          });
                              })
                          }));
          } else {
            return null;
          }
        }));
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Form_Select$RecountTools.make, {
                  label: label,
                  value: undefined,
                  options: Arr$RecountTools.map(options, (function (o) {
                          return {
                                  label: o.label,
                                  value: o.value
                                };
                        })),
                  disabled: disabled,
                  onChange: handleOnChange,
                  onBlur: onBlur,
                  placeholder: placeholder,
                  error: error
                }), React.createElement("div", {
                  className: "tags"
                }, outputDisplay));
}

var make = Form_MultiSelect;

export {
  make ,
  
}
/* react Not a pure module */
