

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";

function displayError(e) {
  if (e !== undefined && e.TAG !== /* Ok */0) {
    return React.createElement("p", {
                className: "help is-danger"
              }, e._0);
  } else {
    return null;
  }
}

function Form$File(Props) {
  var value = Props.value;
  var fileTypes = Props.fileTypes;
  var placeholder = Props.placeholder;
  var label = Props.label;
  var disabled = Props.disabled;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var error = Props.error;
  var className = error !== undefined && error.TAG !== /* Ok */0 ? "file has-name is-danger" : "file has-name";
  var name = Option$RecountTools.mapWithDefault(value, "", (function (v) {
          return v.name;
        }));
  var fileName = name === "" && placeholder !== undefined ? placeholder : name.replace("C:\\fakepath\\", "");
  return React.createElement("div", {
              key: label + "-field",
              className: "field"
            }, React.createElement("label", {
                  className: "label"
                }, label), React.createElement("div", {
                  className: className
                }, React.createElement("label", {
                      className: "file-label"
                    }, React.createElement("input", {
                          className: "file-input",
                          accept: fileTypes,
                          disabled: disabled,
                          placeholder: fileName,
                          type: "file",
                          value: name,
                          onBlur: onBlur,
                          onChange: (function (e) {
                              var file = Arr$RecountTools.getExn(e.target.files, 0);
                              var name = e.target.value;
                              return Curry._1(onChange, {
                                          file: file,
                                          name: name
                                        });
                            })
                        }), React.createElement("span", {
                          className: "file-cta"
                        }, React.createElement("span", {
                              className: "file-icon"
                            }, React.createElement("i", {
                                  className: "fa fa-upload"
                                })), React.createElement("span", {
                              className: "file-label"
                            }, "Choose a file...")), React.createElement("span", {
                          className: "file-name"
                        }, fileName))), displayError(error));
}

var $$File = {
  make: Form$File
};

function Form$TextArea(Props) {
  var label = Props.label;
  var placeholder = Props.placeholder;
  var error = Props.error;
  var disabled = Props.disabled;
  var value = Props.value;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var className = error !== undefined && error.TAG !== /* Ok */0 ? "textarea is-danger" : "textarea";
  return React.createElement("div", {
              className: "field"
            }, React.createElement("label", {
                  className: "label"
                }, label), React.createElement("div", {
                  className: "control"
                }, React.createElement("textarea", {
                      className: className,
                      disabled: disabled,
                      placeholder: placeholder,
                      value: value,
                      onBlur: onBlur,
                      onChange: onChange
                    })), displayError(error));
}

var TextArea = {
  make: Form$TextArea
};

var Button;

var CheckBox;

var $$Date;

var Input;

var Select;

var MultiSelect;

var Tags;

export {
  Button ,
  CheckBox ,
  $$Date ,
  Input ,
  Select ,
  MultiSelect ,
  Tags ,
  $$File ,
  TextArea ,
  
}
/* react Not a pure module */
