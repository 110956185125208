

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

var _map = {"cloudflare":"cloudflare","mux":"mux","normalized":"normalized"};

function renditionPlatformToJs(param) {
  return param;
}

function renditionPlatformFromJs(param) {
  return _map[param];
}

function fromJson(json) {
  return {
          path: Json_decode.field("path", Json_decode.string, json),
          count: Json_decode.field("count", Json_decode.$$int, json),
          duration: Json_decode.field("duration", Json_decode.$$float, json),
          finalSegmentDuration: Json_decode.field("final_segment_duration", Json_decode.$$float, json),
          queryParams: Belt_MapString.fromArray(Js_dict.entries(Json_decode.field("query_params", (function (param) {
                          return Json_decode.dict(Json_decode.string, param);
                        }), json)))
        };
}

var SegmentInfo = {
  fromJson: fromJson
};

var _map$1 = {"STREAM":"STREAM","AUDIO":"AUDIO"};

function playlistTypeToJs(param) {
  return param;
}

function playlistTypeFromJs(param) {
  return _map$1[param];
}

function fromJson$1(json) {
  var arr = Json_decode.array(Json_decode.$$int, json);
  var match = Arr$RecountTools.get(arr, 0);
  var match$1 = Arr$RecountTools.get(arr, 1);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return {
              x: match,
              y: match$1
            };
    }
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "Invalid resolution: " + JSON.stringify(json),
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Invalid resolution: " + JSON.stringify(json),
        Error: new Error()
      };
}

var Resolution = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  var type_ = Json_decode.field("type", Json_decode.string, json);
  if (type_ === "STREAM") {
    return {
            TAG: /* StreamPlaylist */0,
            _0: {
              resolution: Json_decode.field("resolution", fromJson$1, json),
              segmentInfo: Json_decode.field("segments", fromJson, json)
            }
          };
  } else {
    return {
            TAG: /* AudioPlaylist */1,
            _0: {
              segmentInfo: Json_decode.field("segments", fromJson, json)
            }
          };
  }
}

var Playlist = {
  fromJson: fromJson$2
};

function fromJson$3(json) {
  var s = Json_decode.field("type", Json_decode.string, json);
  var tmp;
  switch (s) {
    case "hls" :
        tmp = /* Hls */0;
        break;
    case "mp4" :
        tmp = /* Mp4 */1;
        break;
    default:
      throw {
            RE_EXN_ID: Json_decode.DecodeError,
            _1: "Could not parse " + s + " as rendition type",
            Error: new Error()
          };
  }
  var str = Json_decode.field("transcoding_platform", Json_decode.string, json);
  var p = renditionPlatformFromJs(str);
  var tmp$1;
  if (p !== undefined) {
    tmp$1 = p;
  } else {
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "Could not parse " + str + " as rendition platform",
          Error: new Error()
        };
  }
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          typ: tmp,
          jobId: Json_decode.field("transcoding_job_id", Json_decode.string, json),
          platform: tmp$1,
          playlists: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("playlists", (function (param) {
                                return Json_decode.array(fromJson$2, param);
                              }), param);
                }), json)
        };
}

var Id;

export {
  Id ,
  renditionPlatformToJs ,
  renditionPlatformFromJs ,
  SegmentInfo ,
  playlistTypeToJs ,
  playlistTypeFromJs ,
  Resolution ,
  Playlist ,
  fromJson$3 as fromJson,
  
}
/* No side effect */
