

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Image$RecountTools from "../../components/Image.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as DropDown$RecountTools from "../../components/DropDown.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as PaginationLogic$RecountTools from "../../util/PaginationLogic.bs.js";
import * as PodcastShowStore$RecountTools from "../../stores/PodcastShowStore.bs.js";
import * as PodcastEpisodeStore$RecountTools from "../../stores/PodcastEpisodeStore.bs.js";

var podcastRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 15
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 35
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 25
                  },
                  tl: {
                    hd: {
                      NAME: "percent",
                      VAL: 20
                    },
                    tl: {
                      hd: {
                        NAME: "percent",
                        VAL: 5
                      },
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var actions = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.textAlign("right"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.selector("a", {
                    hd: Css.textTransform("uppercase"),
                    tl: {
                      hd: Css.fontSize(Css.rem(0.75)),
                      tl: {
                        hd: Css.fontWeight("bold"),
                        tl: {
                          hd: Css.padding(Css.rem(0.75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var xPadding = Curry._1(Css.style, {
      hd: Css.padding2(Css.rem(0), Css.rem(1)),
      tl: /* [] */0
    });

var Styles = {
  podcastRow: podcastRow,
  actions: actions,
  xPadding: xPadding
};

function Page_PodcastEpisodes_Listing(Props) {
  var podcastShows = MicroObservables.useObservable(PodcastShowStore$RecountTools.all);
  var match = React.useState(function () {
        
      });
  var selectShow = match[1];
  var selectedShow = match[0];
  var match$1 = PaginationLogic$RecountTools.usePagination((function (cursor) {
          PodcastEpisodeStore$RecountTools.fetchBy(Arr$RecountTools.concatMany([
                    Option$RecountTools.toArray(Option$RecountTools.map(selectedShow, (function (s) {
                                return {
                                        TAG: /* ByShow */0,
                                        _0: s
                                      };
                              }))),
                    Option$RecountTools.toArray({
                          TAG: /* ByCursor */1,
                          _0: cursor
                        })
                  ]));
          
        }), (function (cursor) {
          return PodcastEpisodeStore$RecountTools.getAllBy(Arr$RecountTools.concatMany([
                          Option$RecountTools.toArray(Option$RecountTools.map(selectedShow, (function (s) {
                                      return {
                                              TAG: /* ByShow */0,
                                              _0: s
                                            };
                                    }))),
                          Option$RecountTools.toArray(Option$RecountTools.map(cursor, (function (c) {
                                      return {
                                              TAG: /* ByCursor */1,
                                              _0: c
                                            };
                                    })))
                        ]));
        }), 50);
  var clearPagination = match$1[3];
  var currentPageResults = match$1[1];
  var query = Arr$RecountTools.concatMany([
        Option$RecountTools.toArray(Option$RecountTools.map(selectedShow, (function (s) {
                    return {
                            TAG: /* ByShow */0,
                            _0: s
                          };
                  }))),
        Option$RecountTools.toArray(Option$RecountTools.map(match$1[2], (function (cursor) {
                    return {
                            TAG: /* ByCursor */1,
                            _0: cursor
                          };
                  })))
      ]);
  React.useEffect((function () {
          PodcastShowStore$RecountTools.ensureFetched(undefined);
          PodcastEpisodeStore$RecountTools.fetchBy(query);
          
        }), []);
  React.useEffect((function () {
          if (selectedShow !== undefined) {
            PodcastEpisodeStore$RecountTools.fetchBy(query);
          }
          
        }), [selectedShow]);
  var handleClear = function (param) {
    Curry._1(clearPagination, undefined);
    return Curry._1(selectShow, (function (param) {
                  
                }));
  };
  var formatDate = (function(dateObj) {
      if (dateObj) {
        const date = new Date(dateObj).toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" })
        return date;
      }
    });
  var formatTime = (function(dateObj) {
      if (dateObj) {
        const time = new Date(dateObj).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: '2-digit' })
        return "at " + time;
      }
    });
  var renderPodcasts = function (podcasts) {
    return Arr$RecountTools.map(podcasts, (function (podcast) {
                  var publishedAt = podcast.publishedAt;
                  var tmp;
                  if (publishedAt !== undefined) {
                    var publishedAt$1 = Caml_option.valFromOption(publishedAt);
                    tmp = React.createElement(React.Fragment, undefined, "Published at", React.createElement("br", undefined), formatDate(publishedAt$1), React.createElement("br", undefined), formatTime(publishedAt$1));
                  } else {
                    tmp = "Draft";
                  }
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: Models_Id$RecountTools.toString(podcast.id)
                            }, React.createElement("div", {
                                  className: podcastRow
                                }, React.createElement(Image$RecountTools.make, {
                                      id: podcast.thumbnail,
                                      width: 100,
                                      height: 56,
                                      fit: /* Fit */{
                                        _0: "cover"
                                      },
                                      draggable: false
                                    }), React.createElement("h2", {
                                      className: xPadding
                                    }, podcast.title), React.createElement("span", {
                                      className: xPadding
                                    }, Models_Id$RecountTools.unsafe_refAsResolved(podcast.podcastShow).name), React.createElement("span", {
                                      className: xPadding
                                    }, tmp), React.createElement("span", {
                                      className: actions
                                    }, React.createElement(Link$RecountTools.make, {
                                          href: "/podcast-episodes/edit/" + Models_Id$RecountTools.toString(podcast.id),
                                          children: "Edit"
                                        }))), React.createElement("hr", {
                                  className: "hr"
                                }));
                }));
  };
  var tmp = {
    label: "Show",
    options: Arr$RecountTools.map(podcastShows, (function (s) {
            return {
                    label: s.name,
                    value: s.id
                  };
          })),
    onSelect: (function (option) {
        Curry._1(clearPagination, undefined);
        return Curry._1(selectShow, (function (param) {
                      return option;
                    }));
      }),
    onClear: handleClear
  };
  if (selectedShow !== undefined) {
    tmp.value = Caml_option.valFromOption(selectedShow);
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: null,
              pageName: "Podcasts"
            }, React.createElement(DropDown$RecountTools.make, tmp), React.createElement("hr", {
                  className: "hr"
                }), renderPodcasts(currentPageResults !== undefined ? currentPageResults : []), Curry._1(match$1[0], undefined));
}

var make = Page_PodcastEpisodes_Listing;

export {
  Styles ,
  make ,
  
}
/* podcastRow Not a pure module */
