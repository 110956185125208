

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function fromJson(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          text: Json_decode.field("text", Json_decode.string, json)
        };
}

var Id;

export {
  Id ,
  fromJson ,
  
}
/* No side effect */
