

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";

function dateFromEpoch(json) {
  return new Date(Json_decode.$$float(json) * 1000);
}

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(v.id)
              ],
              tl: {
                hd: [
                  "video_id",
                  v.videoId
                ],
                tl: {
                  hd: [
                    "title",
                    v.title
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          videoId: Json_decode.field("video_id", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          thumbnail: Json_decode.optional((function (param) {
                  return Json_decode.field("thumbnail", Json_decode.string, param);
                }), json),
          createdAt: Json_decode.field("created_at", dateFromEpoch, json),
          updatedAt: Json_decode.field("updated_at", dateFromEpoch, json)
        };
}

var Id;

export {
  Id ,
  toJson ,
  fromJson ,
  
}
/* No side effect */
