

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Int$RecountTools from "../../util/Int.bs.js";
import * as Str$RecountTools from "../../util/Str.bs.js";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Form_Input$RecountTools from "../../components/Form/Form_Input.bs.js";
import * as Observable$RecountTools from "../../util/Observable.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as PageHistory$RecountTools from "../../util/PageHistory.bs.js";
import * as SectionStore$RecountTools from "../../stores/SectionStore.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";

var validSlug = /^[a-z0-9-]+$/;

var validators_treatment = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      if (input.treatment.length !== 6) {
        return {
                TAG: /* Error */1,
                _0: "Treatment must be a six character hexadecimal color code"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.treatment
              };
      }
    })
};

var validators_position = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return Option$RecountTools.mapWithDefault(Str$RecountTools.toInt(input.position), {
                  TAG: /* Error */1,
                  _0: "Position must be a number"
                }, (function (a) {
                    return {
                            TAG: /* Ok */0,
                            _0: a
                          };
                  }));
    })
};

var validators_slug = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var s = input.slug;
      if (s === "") {
        return {
                TAG: /* Error */1,
                _0: "Slug is required"
              };
      } else if (validSlug.test(s)) {
        return {
                TAG: /* Ok */0,
                _0: s
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Slug can only have lowercase letters, numbers, and dashes"
              };
      }
    })
};

var validators_title = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      var match = input.title;
      if (match === "") {
        return {
                TAG: /* Error */1,
                _0: "Title is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.title
              };
      }
    })
};

var validators = {
  treatment: validators_treatment,
  position: validators_position,
  slug: validators_slug,
  title: validators_title
};

function initialFieldsStatuses(_input) {
  return {
          treatment: /* Pristine */0,
          position: /* Pristine */0,
          slug: /* Pristine */0,
          title: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            treatment: /* Pristine */0,
            position: /* Pristine */0,
            slug: /* Pristine */0,
            title: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.treatment;
  var match_0 = match ? match._0 : Curry._1(validators.treatment.validate, input);
  var match$1 = fieldsStatuses.position;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.position.validate, input);
  var match$2 = fieldsStatuses.slug;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.slug.validate, input);
  var match$3 = fieldsStatuses.title;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.title.validate, input);
  var treatmentResult = match_0;
  var treatmentResult$1;
  if (treatmentResult.TAG === /* Ok */0) {
    var positionResult = match_0$1;
    if (positionResult.TAG === /* Ok */0) {
      var slugResult = match_0$2;
      if (slugResult.TAG === /* Ok */0) {
        var titleResult = match_0$3;
        if (titleResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    title: titleResult._0,
                    slug: slugResult._0,
                    position: positionResult._0,
                    treatment: treatmentResult._0
                  },
                  fieldsStatuses: {
                    treatment: /* Dirty */{
                      _0: treatmentResult,
                      _1: /* Shown */0
                    },
                    position: /* Dirty */{
                      _0: positionResult,
                      _1: /* Shown */0
                    },
                    slug: /* Dirty */{
                      _0: slugResult,
                      _1: /* Shown */0
                    },
                    title: /* Dirty */{
                      _0: titleResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        treatmentResult$1 = treatmentResult;
      } else {
        treatmentResult$1 = treatmentResult;
      }
    } else {
      treatmentResult$1 = treatmentResult;
    }
  } else {
    treatmentResult$1 = treatmentResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            treatment: /* Dirty */{
              _0: treatmentResult$1,
              _1: /* Shown */0
            },
            position: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            slug: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            title: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTreatmentField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.treatment, validators_treatment, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  treatment: status,
                                  position: init.position,
                                  slug: init.slug,
                                  title: init.title
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPositionField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.position, validators_position, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  treatment: init.treatment,
                                  position: status,
                                  slug: init.slug,
                                  title: init.title
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSlugField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.slug, validators_slug, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  treatment: init.treatment,
                                  position: init.position,
                                  slug: status,
                                  title: init.title
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTitleField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  treatment: init.treatment,
                                  position: init.position,
                                  slug: init.slug,
                                  title: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */4,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */5,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */7);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */6);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateTreatmentField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.treatment, state.submissionStatus, validators_treatment, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            treatment: status,
                                            position: init.position,
                                            slug: init.slug,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePositionField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.position, state.submissionStatus, validators_position, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            treatment: init.treatment,
                                            position: status,
                                            slug: init.slug,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSlugField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.slug, state.submissionStatus, validators_slug, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            treatment: init.treatment,
                                            position: init.position,
                                            slug: status,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTitleField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            treatment: init.treatment,
                                            position: init.position,
                                            slug: init.slug,
                                            title: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                treatment: /* Pristine */0,
                                position: /* Pristine */0,
                                slug: /* Pristine */0,
                                title: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                treatment: /* Pristine */0,
                                position: /* Pristine */0,
                                slug: /* Pristine */0,
                                title: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateTreatment: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTreatmentField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updatePosition: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdatePositionField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateSlug: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateSlugField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTitleField */3,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurTreatment: (function (param) {
              return Curry._1(dispatch, /* BlurTreatmentField */0);
            }),
          blurPosition: (function (param) {
              return Curry._1(dispatch, /* BlurPositionField */1);
            }),
          blurSlug: (function (param) {
              return Curry._1(dispatch, /* BlurSlugField */2);
            }),
          blurTitle: (function (param) {
              return Curry._1(dispatch, /* BlurTitleField */3);
            }),
          treatmentResult: Formality.exposeFieldResult(state.fieldsStatuses.treatment),
          positionResult: Formality.exposeFieldResult(state.fieldsStatuses.position),
          slugResult: Formality.exposeFieldResult(state.fieldsStatuses.slug),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.treatment || match.position || match.slug || match.title) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */6,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */7);
            })
        };
}

var SectionForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_Sections_Editor-RecountTools.UnknownError");

function Page_Sections_Editor$LoadedState(Props) {
  var section = Props.section;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var form = useForm({
        title: section.title,
        slug: section.slug,
        position: Int$RecountTools.toString(undefined, section.position),
        treatment: section.colorTreatment
      }, (function (output, cb) {
          var fields = [];
          if (output.title !== section.title) {
            Arr$RecountTools.push(fields, [
                  "title",
                  output.title
                ]);
          }
          if (output.slug !== section.slug) {
            Arr$RecountTools.push(fields, [
                  "slug",
                  output.slug
                ]);
          }
          if (output.position !== section.position) {
            Arr$RecountTools.push(fields, [
                  "position",
                  output.position
                ]);
          }
          if (output.treatment !== section.colorTreatment) {
            Arr$RecountTools.push(fields, [
                  "color_treatment",
                  output.treatment
                ]);
          }
          var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                    hd: [
                      "data",
                      Json_encode.object_(Belt_List.fromArray(fields))
                    ],
                    tl: /* [] */0
                  }));
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.patch(body, undefined, undefined, undefined, {
                            TAG: /* Api */0,
                            _0: "/v2/sections/" + Models_Id$RecountTools.toString(section.id)
                          }), (function (response) {
                          if (response.ok) {
                            return Promise.resolve(SectionStore$RecountTools.update(section.id, (function (section) {
                                              return {
                                                      id: section.id,
                                                      slug: output.slug,
                                                      title: output.title,
                                                      position: output.position,
                                                      colorTreatment: output.treatment,
                                                      imageTreatment: section.imageTreatment
                                                    };
                                            })));
                          } else {
                            Curry._3(makeToast, undefined, /* Error */2, "Unable to save section");
                            return Promise.reject({
                                        RE_EXN_ID: UnknownError
                                      });
                          }
                        })), (function (param) {
                      return Curry._1(cb.reset, undefined);
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var tmp = {
    label: "Save",
    disabled: form.submitting || !Curry._1(form.dirty, undefined),
    kind: "submit",
    className: "is-link"
  };
  var tmp$1 = Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save");
  if (tmp$1 !== undefined) {
    tmp.disabledReason = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: null
                      }, React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.title,
                            label: "Title",
                            error: form.titleResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateTitle, (function (input, value) {
                                              return {
                                                      title: value,
                                                      slug: input.slug,
                                                      position: input.position,
                                                      treatment: input.treatment
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurTitle, undefined);
                              }),
                            disabled: form.submitting
                          }), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.slug,
                            label: "Slug",
                            error: form.slugResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateSlug, (function (input, value) {
                                              return {
                                                      title: input.title,
                                                      slug: value,
                                                      position: input.position,
                                                      treatment: input.treatment
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurSlug, undefined);
                              }),
                            disabled: form.submitting
                          }), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.position,
                            label: "Position",
                            error: form.positionResult,
                            onChange: (function (e) {
                                return Curry._2(form.updatePosition, (function (input, value) {
                                              return {
                                                      title: input.title,
                                                      slug: input.slug,
                                                      position: value,
                                                      treatment: input.treatment
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurPosition, undefined);
                              }),
                            disabled: form.submitting
                          }), React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.treatment,
                            label: "Color Treatment",
                            error: form.treatmentResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateTreatment, (function (input, value) {
                                              return {
                                                      title: input.title,
                                                      slug: input.slug,
                                                      position: input.position,
                                                      treatment: value
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurTreatment, undefined);
                              }),
                            disabled: form.submitting
                          }))
                }), React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, tmp)));
}

var LoadedState = {
  make: Page_Sections_Editor$LoadedState
};

function Page_Sections_Editor(Props) {
  var id = Props.id;
  var section = Observable$RecountTools.useMemoizedObservable(SectionStore$RecountTools.get(id));
  React.useEffect((function () {
          SectionStore$RecountTools.ensureFetched(undefined);
          
        }), []);
  var tmp;
  if (typeof section === "number") {
    if (section !== 0) {
      PageHistory$RecountTools.revertPage(undefined);
      tmp = "Section Not Found";
    } else {
      tmp = "Loading";
    }
  } else {
    var section$1 = section._0;
    tmp = React.createElement(Page_Sections_Editor$LoadedState, {
          section: section$1,
          key: Models_Id$RecountTools.toString(section$1.id)
        });
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: tmp,
              pageName: "Edit Section",
              crumbs: [{
                  link: "/sections",
                  name: "Sections"
                }]
            });
}

var make = Page_Sections_Editor;

export {
  SectionForm ,
  UnknownError ,
  LoadedState ,
  make ,
  
}
/* react Not a pure module */
