

import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function toJson(cm) {
  if (cm === "vertical") {
    return 1;
  } else if (cm === "horizontal") {
    return 2;
  } else {
    return 3;
  }
}

function toDisplay(cm) {
  if (cm === "vertical") {
    return "Vertical";
  } else if (cm === "horizontal") {
    return "Horizontal";
  } else {
    return "Square";
  }
}

function toString(cm) {
  if (cm === "vertical") {
    return "1";
  } else if (cm === "horizontal") {
    return "2";
  } else {
    return "3";
  }
}

function fromString(cm) {
  switch (cm) {
    case "1" :
        return "vertical";
    case "2" :
        return "horizontal";
    case "3" :
        return "square";
    default:
      return ;
  }
}

var CropMode = {
  toJson: toJson,
  toDisplay: toDisplay,
  toString: toString,
  fromString: fromString
};

function toJson$1(box) {
  return Json_encode.array((function (prim) {
                return prim;
              }), [
              box.x,
              box.y,
              box.width,
              box.height
            ]);
}

var BoundingBox = {
  toJson: toJson$1
};

function toJson$2(r) {
  if (typeof r === "number") {
    if (r === /* AudioNormalizeTransform */0) {
      return Json_encode.object_({
                  hd: [
                    "type",
                    "audio_normalize"
                  ],
                  tl: /* [] */0
                });
    } else {
      return Json_encode.object_({
                  hd: [
                    "type",
                    "watermark"
                  ],
                  tl: /* [] */0
                });
    }
  }
  switch (r.TAG | 0) {
    case /* CropTransform */0 :
        return Json_encode.object_({
                    hd: [
                      "crop_mode",
                      toJson(r.cropMode)
                    ],
                    tl: {
                      hd: [
                        "center_x",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), r.centerX)
                      ],
                      tl: {
                        hd: [
                          "type",
                          "crop"
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* TrackingCropTransform */1 :
        return Json_encode.object_({
                    hd: [
                      "crop_mode",
                      toJson(r.cropMode)
                    ],
                    tl: {
                      hd: [
                        "bounding_box",
                        toJson$1(r.boundingBox)
                      ],
                      tl: {
                        hd: [
                          "type",
                          "tracking_crop"
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    case /* TrimTransform */2 :
        return Json_encode.object_({
                    hd: [
                      "start",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), r.start)
                    ],
                    tl: {
                      hd: [
                        "end",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), r.end)
                      ],
                      tl: {
                        hd: [
                          "type",
                          "trim"
                        ],
                        tl: /* [] */0
                      }
                    }
                  });
    
  }
}

var Transform = {
  toJson: toJson$2
};

function toJson$3(r) {
  return Json_encode.object_({
              hd: [
                "bucket_name",
                r.bucketName
              ],
              tl: {
                hd: [
                  "destination_file_path",
                  r.destinationFilePath
                ],
                tl: {
                  hd: [
                    "channel_id",
                    r.channelId
                  ],
                  tl: {
                    hd: [
                      "thread_ts",
                      r.threadTs
                    ],
                    tl: {
                      hd: [
                        "video_url",
                        r.videoUrl
                      ],
                      tl: {
                        hd: [
                          "transforms",
                          Json_encode.array(toJson$2, r.transforms)
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

export {
  CropMode ,
  BoundingBox ,
  Transform ,
  toJson$3 as toJson,
  
}
/* No side effect */
