

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Icon$RecountTools from "./Icon.bs.js";

var selected = Curry._1(Css.style, {
      hd: Css.fontWeight("bold"),
      tl: /* [] */0
    });

var xPadding = Curry._1(Css.style, {
      hd: Css.padding2(Css.rem(0), Css.rem(1)),
      tl: /* [] */0
    });

var orderable = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: /* [] */0
    });

var Styles = {
  selected: selected,
  xPadding: xPadding,
  orderable: orderable
};

function SortableHeaders(Props) {
  var onSort = Props.onSort;
  var initialSort = Props.initialSort;
  var columns = Props.columns;
  var match = React.useState(function () {
        return initialSort;
      });
  var setSort = match[1];
  var sort = match[0];
  var initialLoad = React.useRef(true);
  React.useEffect((function () {
          if (initialLoad.current) {
            initialLoad.current = false;
          } else {
            Curry._1(onSort, sort);
          }
          
        }), [sort]);
  return React.createElement(React.Fragment, undefined, Arr$RecountTools.map(columns, (function (column) {
                    return React.createElement("span", {
                                key: column.value,
                                className: xPadding
                              }, React.createElement("div", {
                                    className: (
                                      column.orderable ? orderable : ""
                                    ) + " " + (
                                      sort.field === column.value ? selected : ""
                                    ),
                                    onClick: (function (param) {
                                        if (column.orderable) {
                                          var column$1 = column.value;
                                          var order = column$1 === sort.field && sort.order !== "asc" ? "asc" : "desc";
                                          return Curry._1(setSort, (function (param) {
                                                        return {
                                                                field: column$1,
                                                                order: order
                                                              };
                                                      }));
                                        }
                                        
                                      })
                                  }, column.display, column.orderable && sort.field === column.value ? React.createElement("span", {
                                          className: "icon is-small"
                                        }, React.createElement(Icon$RecountTools.make, {
                                              margin: "mr-1",
                                              icon: sort.order === "desc" ? /* ArrowDown */6 : /* ArrowUp */5
                                            })) : null));
                  })));
}

var make = SortableHeaders;

export {
  Styles ,
  make ,
  
}
/* selected Not a pure module */
