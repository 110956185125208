

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../../util/Arr.bs.js";
import * as Int$RecountTools from "../../../util/Int.bs.js";
import * as Str$RecountTools from "../../../util/Str.bs.js";
import * as Form$RecountTools from "../../../components/Form/Form.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../../../util/Async.bs.js";
import * as Image$RecountTools from "../../../components/Image.bs.js";
import * as Layout$RecountTools from "../../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../../util/Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Request$RecountTools from "../../../util/Request.bs.js";
import * as Constants$RecountTools from "../../../util/Constants.bs.js";
import * as Form_Date$RecountTools from "../../../components/Form/Form_Date.bs.js";
import * as Form_Tags$RecountTools from "../../../components/Form/Form_Tags.bs.js";
import * as Models_Id$RecountTools from "../../../models/Models_Id.bs.js";
import * as ReactUtil$RecountTools from "../../../util/ReactUtil.bs.js";
import * as ShowStore$RecountTools from "../../../stores/ShowStore.bs.js";
import * as Form_Input$RecountTools from "../../../components/Form/Form_Input.bs.js";
import * as Observable$RecountTools from "../../../util/Observable.bs.js";
import * as VideoStore$RecountTools from "../../../stores/VideoStore.bs.js";
import * as StreamReact from "@cloudflare/stream-react";
import * as Form_Button$RecountTools from "../../../components/Form/Form_Button.bs.js";
import * as Form_Select$RecountTools from "../../../components/Form/Form_Select.bs.js";
import * as PageHistory$RecountTools from "../../../util/PageHistory.bs.js";
import * as ProseMirror$RecountTools from "../../../components/ProseMirror/ProseMirror.bs.js";
import * as Models_Video$RecountTools from "../../../models/Models_Video.bs.js";
import * as SectionStore$RecountTools from "../../../stores/SectionStore.bs.js";
import * as Form_CheckBox$RecountTools from "../../../components/Form/Form_CheckBox.bs.js";
import * as ToastProvider$RecountTools from "../../../components/ToastProvider.bs.js";
import * as CopyToClipboard$RecountTools from "../../../components/CopyToClipboard.bs.js";
import * as Form_MultiSelect$RecountTools from "../../../components/Form/Form_MultiSelect.bs.js";
import * as Models_Thumbnail$RecountTools from "../../../models/Models_Thumbnail.bs.js";
import * as ConfirmationProvider$RecountTools from "../../../components/ConfirmationProvider.bs.js";
import * as Page_Videos_Editor_Logic$RecountTools from "./Page_Videos_Editor_Logic.bs.js";
import * as Page_Videos_Editor_ActionBar$RecountTools from "./Page_Videos_Editor_ActionBar.bs.js";

var label = Curry._1(Css.style, {
      hd: Css.fontSize(Css.rem(1)),
      tl: {
        hd: Css.color(Css.hex("363636")),
        tl: {
          hd: Css.fontWeight("bold"),
          tl: /* [] */0
        }
      }
    });

var wireIndicator = "tag is-warning mb-3";

var buttonTooltip = Curry._1(Css.merge, {
      hd: "control",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.selector("button", {
                      hd: Css.paddingRight(Css.rem(3)),
                      tl: {
                        hd: Css.transition(200, undefined, "easeInOut", "padding"),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.selector(".has-tooltip-arrow", {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.border(Css.px(0), "none", {
                                NAME: "hex",
                                VAL: "fff"
                              }),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.top(Css.rem(-0.25)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var progressHeading = Curry._1(Css.merge, {
      hd: "has-text-white has-text-centered",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize(Css.rem(1.6)),
              tl: {
                hd: Css.marginBottom(Css.rem(1)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  label: label,
  wireIndicator: wireIndicator,
  buttonTooltip: buttonTooltip,
  progressHeading: progressHeading
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_Videos_Editor-RecountTools.UnknownError");

function Page_Videos_Editor$LoadedState(Props) {
  var video = Props.video;
  var match = React.useState(function () {
        
      });
  var setFile = match[1];
  var file = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setThumbFile = match$1[1];
  var thumbFile = match$1[0];
  var isWire = Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire;
  var episode = Option$RecountTools.mapWithDefault(video.episode, "", (function (eta) {
          return Int$RecountTools.toString(undefined, eta);
        }));
  var season = Option$RecountTools.mapWithDefault(video.season, "", (function (eta) {
          return Int$RecountTools.toString(undefined, eta);
        }));
  var shows = MicroObservables.useObservable(ShowStore$RecountTools.all);
  var allSections = MicroObservables.useObservable(SectionStore$RecountTools.all);
  var match$2 = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match$2[0];
  var confirm = ConfirmationProvider$RecountTools.useConfirm(undefined);
  var autoSaveKey = "autosave:video.article:" + Models_Id$RecountTools.toString(video.id);
  var submit = Page_Videos_Editor_Logic$RecountTools.useSubmit(video, autoSaveKey);
  var match$3 = Page_Videos_Editor_Logic$RecountTools.useUploadFile(undefined);
  var uploadFile = match$3[1];
  var initialArticle = Option$RecountTools.map(video.article, ProseMirror$RecountTools.$$Document.fromJson);
  ReactUtil$RecountTools.useInterval((function (param) {
          return VideoStore$RecountTools.fetchOne(video.id);
        }), 10000);
  var form = Page_Videos_Editor_Logic$RecountTools.VideoForm.useForm({
        projectId: Option$RecountTools.getWithDefault(video.projectId, ""),
        title: video.title,
        slug: video.slug,
        status: video.status,
        description: Option$RecountTools.getWithDefault(video.description, ""),
        article: initialArticle,
        publishedAt: video.publishedAt,
        tags: video.tags,
        show: Models_Id$RecountTools.refAsId(video.show),
        sections: video.sections,
        videoFile: "",
        thumbnailFile: "",
        episodeNumber: episode,
        seasonNumber: season,
        pinned: video.pinned,
        rssYahoo: video.rssYahoo,
        rssVideoElephant: video.rssVideoElephant,
        rssMicrosoft: video.rssMicrosoft
      }, {
        id: video.id
      }, (function (output, cb) {
          Option$RecountTools.forEach(file, (function (videoFile) {
                  return Async$RecountTools.map(Curry._4(uploadFile, undefined, "/v2/videos/by/replace-file/" + Models_Id$RecountTools.toString(video.id), videoFile, cb), (function (param) {
                                Curry._3(makeToast, undefined, /* Confirm */1, "Success! New video file will be available when transcoding is complete.");
                                
                              }));
                }));
          Option$RecountTools.forEach(thumbFile, (function (file) {
                  var format = Option$RecountTools.keep(Option$RecountTools.flatMap(output.thumbnailFile, (function (s) {
                              return Arr$RecountTools.last(s.split("."));
                            })), (function (s) {
                          return s.length === 3;
                        }));
                  var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                            hd: [
                              "format",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), format)
                            ],
                            tl: /* [] */0
                          }));
                  return Async$RecountTools.map(Curry._4(uploadFile, body, "/v2/videos/by/replace-thumbnail/" + Models_Id$RecountTools.toString(video.id), file, cb), (function (param) {
                                return VideoStore$RecountTools.fetchOne(video.id);
                              }));
                }));
          return Curry._2(submit, output, cb);
        }));
  var updateVideo = function (payload) {
    var body = Request$RecountTools.RequestBody.json(payload);
    Async$RecountTools.map(Request$RecountTools.patch(body, undefined, undefined, undefined, {
              TAG: /* Api */0,
              _0: "/v2/videos/" + Models_Id$RecountTools.toString(video.id)
            }), (function (response) {
            if (response.ok) {
              if (isWire) {
                return VideoStore$RecountTools.update(video.id, (function (data) {
                              var newrecord = Caml_obj.caml_obj_dup(data);
                              newrecord.publishedAt = undefined;
                              return newrecord;
                            }));
              } else {
                return VideoStore$RecountTools.update(video.id, (function (data) {
                              var newrecord = Caml_obj.caml_obj_dup(data);
                              newrecord.status = "draft";
                              return newrecord;
                            }));
              }
            } else {
              Curry._3(makeToast, undefined, /* Error */2, "Unable to unpublish video");
              return ;
            }
          }));
    
  };
  var match$4 = Models_Video$RecountTools.publishStatus(video);
  var videoId = video.cloudflareVideoId;
  var tmp;
  if (videoId !== undefined) {
    var imgUrl = video.thumbnailId;
    tmp = React.createElement(StreamReact.Stream, {
          poster: Image$RecountTools.imageUrl(320, 180, /* Fit */{
                _0: "cover"
              }, undefined, Models_Thumbnail$RecountTools.toOption(video.thumbnailId)),
          controls: true,
          src: videoId,
          key: typeof imgUrl === "number" ? "nothumb" : imgUrl._0
        });
  } else {
    tmp = React.createElement(Image$RecountTools.make, {
          id: video.thumbnailId
        });
  }
  var fileName = video.title.replace(/^\s+|\s+$/g, "").toLowerCase().replace(/\s+/g, "-").substring(0, 20) + ".mp4".replace(/-$/g, "");
  var tmp$1;
  if (isWire) {
    tmp$1 = null;
  } else {
    var tmp$2 = {
      value: Option$RecountTools.map(thumbFile, (function (f) {
              return {
                      file: f,
                      name: form.input.thumbnailFile
                    };
            })),
      fileTypes: "image/*",
      label: "Update Thumbnail",
      disabled: form.submitting,
      onBlur: (function (param) {
          return Curry._1(form.blurThumbnailFile, undefined);
        }),
      onChange: (function (v) {
          Curry._1(setThumbFile, (function (param) {
                  return Caml_option.some(v.file);
                }));
          return Curry._2(form.updateThumbnailFile, (function (input, value) {
                        return {
                                projectId: input.projectId,
                                title: input.title,
                                slug: input.slug,
                                status: input.status,
                                description: input.description,
                                article: input.article,
                                publishedAt: input.publishedAt,
                                tags: input.tags,
                                show: input.show,
                                sections: input.sections,
                                videoFile: input.videoFile,
                                thumbnailFile: value,
                                episodeNumber: input.episodeNumber,
                                seasonNumber: input.seasonNumber,
                                pinned: input.pinned,
                                rssYahoo: input.rssYahoo,
                                rssVideoElephant: input.rssVideoElephant,
                                rssMicrosoft: input.rssMicrosoft
                              };
                      }), v.name);
        }),
      error: form.thumbnailFileResult
    };
    var tmp$3 = Option$RecountTools.map(Models_Thumbnail$RecountTools.toOption(video.thumbnailId), (function (img) {
            var startInt = img.lastIndexOf("/") + 1 | 0;
            return img.substring(startInt);
          }));
    if (tmp$3 !== undefined) {
      tmp$2.placeholder = Caml_option.valFromOption(tmp$3);
    }
    var img = video.thumbnailId;
    tmp$1 = React.createElement(Layout$RecountTools.Columns.make, {
          children: null
        }, React.createElement(Layout$RecountTools.Columns.Column.make, {
              children: React.createElement(Form$RecountTools.$$File.make, tmp$2)
            }), typeof img === "number" ? null : React.createElement(Layout$RecountTools.Columns.Column.make, {
                children: React.createElement("img", {
                      alt: "",
                      src: img._0
                    })
              }));
  }
  var tmp$4 = {
    autoSaveKey: autoSaveKey,
    onChange: (function (body) {
        return Curry._2(form.updateArticle, (function (input, value) {
                      return {
                              projectId: input.projectId,
                              title: input.title,
                              slug: input.slug,
                              status: input.status,
                              description: input.description,
                              article: value,
                              publishedAt: input.publishedAt,
                              tags: input.tags,
                              show: input.show,
                              sections: input.sections,
                              videoFile: input.videoFile,
                              thumbnailFile: input.thumbnailFile,
                              episodeNumber: input.episodeNumber,
                              seasonNumber: input.seasonNumber,
                              pinned: input.pinned,
                              rssYahoo: input.rssYahoo,
                              rssVideoElephant: input.rssVideoElephant,
                              rssMicrosoft: input.rssMicrosoft
                            };
                    }), Caml_option.some(body));
      }),
    onBlur: (function (param) {
        return Curry._1(form.blurArticle, undefined);
      })
  };
  if (initialArticle !== undefined) {
    tmp$4.document = Caml_option.valFromOption(initialArticle);
  }
  var tmp$5 = Option$RecountTools.map(video.caption, (function (transcript) {
          return Curry._1(ProseMirror$RecountTools.Schema.Article.$$document, [Curry._1(ProseMirror$RecountTools.Schema.Article.paragraph, [Curry._1(ProseMirror$RecountTools.Schema.Article.text, transcript.text)])]);
        }));
  if (tmp$5 !== undefined) {
    tmp$4.prepopulated = Caml_option.valFromOption(tmp$5);
  }
  var match$5 = form.articleResult;
  var tmp$6;
  tmp$6 = match$5 !== undefined && match$5.TAG !== /* Ok */0 ? React.createElement("p", {
          className: "help is-danger"
        }, match$5._0) : null;
  var isWireShow = Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire;
  var reasons = [];
  if (!isWireShow && form.input.thumbnailFile.length < 1) {
    var match$6 = video.thumbnailId;
    if (typeof match$6 === "number") {
      if (match$6 !== 0) {
        Arr$RecountTools.push(reasons, "Thumbnail is required.");
      } else {
        Arr$RecountTools.push(reasons, "Thumbnail is still processing, please wait.");
      }
    }
    
  }
  if (!isWireShow && !Option$RecountTools.isSome(form.input.publishedAt)) {
    Arr$RecountTools.push(reasons, "Publish Time is required.");
  }
  if (isWireShow) {
    if (!Arr$RecountTools.some(form.input.tags, (function (t) {
              return t.kind === "location";
            })) && !Arr$RecountTools.some(form.input.tags, (function (t) {
              return t.kind === "organization";
            }))) {
      Arr$RecountTools.push(reasons, "A Location or Organization Tag is required.");
    }
    var personTagArray = Arr$RecountTools.some(form.input.tags, (function (t) {
            return t.kind === "person";
          }));
    if (!personTagArray) {
      Arr$RecountTools.push(reasons, "A Person Tag is required.");
    }
    var subjectTagArray = Arr$RecountTools.some(form.input.tags, (function (t) {
            return t.kind === "subject";
          }));
    if (!subjectTagArray) {
      Arr$RecountTools.push(reasons, "A Subject Tag is required.");
    }
    if (form.input.title.length < 1) {
      Arr$RecountTools.push(reasons, "A Title is required.");
    }
    
  }
  var canPublish = reasons.length === 0;
  var match$7 = Models_Video$RecountTools.publishStatus(video);
  var tmp$7;
  if (match$7 === "draft") {
    var tmp$8 = {
      label: "Save & Publish",
      disabled: !canPublish || form.submitting,
      className: "is-link",
      onClick: (function (param) {
          if (Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire) {
            var now = new Date();
            Curry._2(form.updatePublishedAt, (function (input, value) {
                    return {
                            projectId: input.projectId,
                            title: input.title,
                            slug: input.slug,
                            status: input.status,
                            description: input.description,
                            article: input.article,
                            publishedAt: value,
                            tags: input.tags,
                            show: input.show,
                            sections: input.sections,
                            videoFile: input.videoFile,
                            thumbnailFile: input.thumbnailFile,
                            episodeNumber: input.episodeNumber,
                            seasonNumber: input.seasonNumber,
                            pinned: input.pinned,
                            rssYahoo: input.rssYahoo,
                            rssVideoElephant: input.rssVideoElephant,
                            rssMicrosoft: input.rssMicrosoft
                          };
                  }), Caml_option.some(now));
          } else {
            Curry._2(form.updateStatus, (function (input, value) {
                    return {
                            projectId: input.projectId,
                            title: input.title,
                            slug: input.slug,
                            status: value,
                            description: input.description,
                            article: input.article,
                            publishedAt: input.publishedAt,
                            tags: input.tags,
                            show: input.show,
                            sections: input.sections,
                            videoFile: input.videoFile,
                            thumbnailFile: input.thumbnailFile,
                            episodeNumber: input.episodeNumber,
                            seasonNumber: input.seasonNumber,
                            pinned: input.pinned,
                            rssYahoo: input.rssYahoo,
                            rssVideoElephant: input.rssVideoElephant,
                            rssMicrosoft: input.rssMicrosoft
                          };
                  }), "live");
          }
          return Curry._1(form.submit, undefined);
        })
    };
    var tmp$9 = Option$RecountTools.guard(!canPublish, Arr$RecountTools.join(reasons, " "));
    if (tmp$9 !== undefined) {
      tmp$8.disabledReason = Caml_option.valFromOption(tmp$9);
    }
    var tmp$10 = {
      label: "Save",
      disabled: form.submitting || !Curry._1(form.dirty, undefined),
      kind: "submit",
      className: "is-link"
    };
    var tmp$11 = Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save");
    if (tmp$11 !== undefined) {
      tmp$10.disabledReason = Caml_option.valFromOption(tmp$11);
    }
    tmp$7 = React.createElement(React.Fragment, undefined, React.createElement(Form_Button$RecountTools.make, tmp$8), React.createElement(Form_Button$RecountTools.make, tmp$10));
  } else {
    var tmp$12 = {
      label: "Save",
      disabled: form.submitting || !Curry._1(form.dirty, undefined) || !canPublish,
      kind: "submit",
      className: "is-link"
    };
    var tmp$13 = Option$RecountTools.orElse(Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save"), Option$RecountTools.guard(!canPublish, Arr$RecountTools.join(reasons, " ")));
    if (tmp$13 !== undefined) {
      tmp$12.disabledReason = Caml_option.valFromOption(tmp$13);
    }
    tmp$7 = React.createElement(React.Fragment, undefined, React.createElement(Form_Button$RecountTools.make, {
              label: "Unpublish",
              disabled: form.submitting,
              className: "is-link",
              onClick: (function (param) {
                  if (Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire) {
                    Curry._2(form.updatePublishedAt, (function (input, value) {
                            return {
                                    projectId: input.projectId,
                                    title: input.title,
                                    slug: input.slug,
                                    status: input.status,
                                    description: input.description,
                                    article: input.article,
                                    publishedAt: value,
                                    tags: input.tags,
                                    show: input.show,
                                    sections: input.sections,
                                    videoFile: input.videoFile,
                                    thumbnailFile: input.thumbnailFile,
                                    episodeNumber: input.episodeNumber,
                                    seasonNumber: input.seasonNumber,
                                    pinned: input.pinned,
                                    rssYahoo: false,
                                    rssVideoElephant: false,
                                    rssMicrosoft: false
                                  };
                          }), undefined);
                    return updateVideo({
                                data: {
                                  published_at: null
                                }
                              });
                  } else {
                    Curry._2(form.updateStatus, (function (input, value) {
                            return {
                                    projectId: input.projectId,
                                    title: input.title,
                                    slug: input.slug,
                                    status: value,
                                    description: input.description,
                                    article: input.article,
                                    publishedAt: input.publishedAt,
                                    tags: input.tags,
                                    show: input.show,
                                    sections: input.sections,
                                    videoFile: input.videoFile,
                                    thumbnailFile: input.thumbnailFile,
                                    episodeNumber: input.episodeNumber,
                                    seasonNumber: input.seasonNumber,
                                    pinned: input.pinned,
                                    rssYahoo: false,
                                    rssVideoElephant: false,
                                    rssMicrosoft: false
                                  };
                          }), "draft");
                    return updateVideo({
                                data: {
                                  status: Models_Video$RecountTools.Status.toJson("draft")
                                }
                              });
                  }
                })
            }), React.createElement(Form_Button$RecountTools.make, tmp$12));
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire ? React.createElement("div", {
                            className: wireIndicator
                          }, "Recount Wire") : null, match$4 === "live" ? React.createElement("div", {
                            className: "field"
                          }, React.createElement("label", {
                                className: "label"
                              }, "Public Link"), React.createElement("a", {
                                href: "https://therecount.com/watch/" + video.slug + "/" + Models_Id$RecountTools.toString(video.id)
                              }, "therecount.com/watch/" + video.slug + "/" + Models_Id$RecountTools.toString(video.id)), React.createElement(CopyToClipboard$RecountTools.make, {
                                text: "https://therecount.com/watch/" + video.slug + "/" + Models_Id$RecountTools.toString(video.id)
                              })) : null, Models_Id$RecountTools.refAsId(video.show) !== Constants$RecountTools.recountWire ? React.createElement(Form_Input$RecountTools.make, {
                            value: form.input.projectId,
                            label: "Project ID",
                            error: form.projectIdResult,
                            onChange: (function (e) {
                                return Curry._2(form.updateProjectId, (function (input, value) {
                                              return {
                                                      projectId: value,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: input.tags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), e.target.value);
                              }),
                            onBlur: (function (param) {
                                Curry._1(form.blurProjectId, undefined);
                                return Curry._2(form.updateProjectId, (function (input, param) {
                                              return {
                                                      projectId: Str$RecountTools.toUpperCase(input.projectId),
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: input.tags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), "");
                              }),
                            disabled: form.submitting
                          }) : null, React.createElement(Form_Input$RecountTools.make, {
                          value: form.input.title,
                          label: "Title",
                          error: form.titleResult,
                          onChange: (function (e) {
                              return Curry._2(form.updateTitle, (function (input, value) {
                                            return {
                                                    projectId: input.projectId,
                                                    title: value,
                                                    slug: input.slug,
                                                    status: input.status,
                                                    description: input.description,
                                                    article: input.article,
                                                    publishedAt: input.publishedAt,
                                                    tags: input.tags,
                                                    show: input.show,
                                                    sections: input.sections,
                                                    videoFile: input.videoFile,
                                                    thumbnailFile: input.thumbnailFile,
                                                    episodeNumber: input.episodeNumber,
                                                    seasonNumber: input.seasonNumber,
                                                    pinned: input.pinned,
                                                    rssYahoo: input.rssYahoo,
                                                    rssVideoElephant: input.rssVideoElephant,
                                                    rssMicrosoft: input.rssMicrosoft
                                                  };
                                          }), e.target.value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurTitle, undefined);
                            }),
                          disabled: form.submitting
                        }), React.createElement(Form_Input$RecountTools.Async.make, {
                          value: form.input.slug,
                          label: "Slug",
                          error: form.slugResult,
                          onChange: (function (e) {
                              return Curry._2(form.updateSlug, (function (input, value) {
                                            return {
                                                    projectId: input.projectId,
                                                    title: input.title,
                                                    slug: value,
                                                    status: input.status,
                                                    description: input.description,
                                                    article: input.article,
                                                    publishedAt: input.publishedAt,
                                                    tags: input.tags,
                                                    show: input.show,
                                                    sections: input.sections,
                                                    videoFile: input.videoFile,
                                                    thumbnailFile: input.thumbnailFile,
                                                    episodeNumber: input.episodeNumber,
                                                    seasonNumber: input.seasonNumber,
                                                    pinned: input.pinned,
                                                    rssYahoo: input.rssYahoo,
                                                    rssVideoElephant: input.rssVideoElephant,
                                                    rssMicrosoft: input.rssMicrosoft
                                                  };
                                          }), e.target.value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurSlug, undefined);
                            }),
                          onPaste: (function (e) {
                              return Curry._2(form.updateSlug, (function (input, value) {
                                            return {
                                                    projectId: input.projectId,
                                                    title: input.title,
                                                    slug: value,
                                                    status: input.status,
                                                    description: input.description,
                                                    article: input.article,
                                                    publishedAt: input.publishedAt,
                                                    tags: input.tags,
                                                    show: input.show,
                                                    sections: input.sections,
                                                    videoFile: input.videoFile,
                                                    thumbnailFile: input.thumbnailFile,
                                                    episodeNumber: input.episodeNumber,
                                                    seasonNumber: input.seasonNumber,
                                                    pinned: input.pinned,
                                                    rssYahoo: input.rssYahoo,
                                                    rssVideoElephant: input.rssVideoElephant,
                                                    rssMicrosoft: input.rssMicrosoft
                                                  };
                                          }), Str$RecountTools.slugify(e.target.value));
                            }),
                          disabled: form.submitting
                        }), React.createElement("div", {
                          className: "field"
                        }, React.createElement("label", {
                              className: "label"
                            }, "Status"), Models_Video$RecountTools.Status.toDisplay(Models_Video$RecountTools.publishStatus(video))), Models_Id$RecountTools.refAsId(video.show) !== Constants$RecountTools.recountWire || video.status === "live" && video.publishedAt !== undefined ? React.createElement(Form_Date$RecountTools.make, {
                            value: form.input.publishedAt,
                            showTimeInput: true,
                            label: "Publish Time",
                            onChange: (function (date) {
                                return Curry._2(form.updatePublishedAt, (function (input, value) {
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: value,
                                                      tags: input.tags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), Caml_option.some(date));
                              })
                          }) : null, Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire ? React.createElement("div", {
                            className: "field"
                          }, React.createElement("p", {
                                className: label
                              }, "Show"), React.createElement("p", undefined, "Recount Wire")) : React.createElement(Form_Select$RecountTools.make, {
                            label: "Show",
                            value: Models_Id$RecountTools.toString(form.input.show),
                            options: Arr$RecountTools.map(Arr$RecountTools.keep(shows, (function (show) {
                                        return show.id !== Constants$RecountTools.recountWire;
                                      })), (function (show) {
                                    return {
                                            label: show.title,
                                            value: Models_Id$RecountTools.toString(show.id)
                                          };
                                  })),
                            disabled: form.submitting,
                            onChange: (function (e) {
                                return Curry._2(form.updateShow, (function (input, value) {
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: input.tags,
                                                      show: value,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), Option$RecountTools.getExn(Models_Id$RecountTools.fromString(e.target.value)));
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurShow, undefined);
                              }),
                            placeholder: "Select a show",
                            error: form.showResult
                          }), React.createElement(Form_MultiSelect$RecountTools.make, {
                          placeholder: "Select a section",
                          disabled: form.submitting,
                          error: form.sectionsResult,
                          onChange: (function (section) {
                              if (section.TAG === /* Added */0) {
                                var sectionOptionValue = section._0;
                                return Curry._2(form.updateSections, (function (input, param) {
                                              var sec = Arr$RecountTools.keep(allSections, (function (s) {
                                                      return Models_Id$RecountTools.toString(s.id) === sectionOptionValue;
                                                    }));
                                              var updatedSections = Arr$RecountTools.concat(input.sections, sec);
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: input.tags,
                                                      show: input.show,
                                                      sections: updatedSections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), []);
                              }
                              var sectionOptionValue$1 = section._0;
                              return Curry._2(form.updateSections, (function (input, param) {
                                            var updatedSections = Arr$RecountTools.keep(input.sections, (function (s) {
                                                    return Models_Id$RecountTools.toString(s.id) !== sectionOptionValue$1;
                                                  }));
                                            return {
                                                    projectId: input.projectId,
                                                    title: input.title,
                                                    slug: input.slug,
                                                    status: input.status,
                                                    description: input.description,
                                                    article: input.article,
                                                    publishedAt: input.publishedAt,
                                                    tags: input.tags,
                                                    show: input.show,
                                                    sections: updatedSections,
                                                    videoFile: input.videoFile,
                                                    thumbnailFile: input.thumbnailFile,
                                                    episodeNumber: input.episodeNumber,
                                                    seasonNumber: input.seasonNumber,
                                                    pinned: input.pinned,
                                                    rssYahoo: input.rssYahoo,
                                                    rssVideoElephant: input.rssVideoElephant,
                                                    rssMicrosoft: input.rssMicrosoft
                                                  };
                                          }), []);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurSections, undefined);
                            }),
                          options: Arr$RecountTools.map(allSections, (function (s) {
                                  return {
                                          label: s.title,
                                          value: Models_Id$RecountTools.toString(s.id)
                                        };
                                })),
                          label: "Section",
                          values: Arr$RecountTools.map(form.input.sections, (function (s) {
                                  return Models_Id$RecountTools.toString(s.id);
                                }))
                        })), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, React.createElement(Page_Videos_Editor_ActionBar$RecountTools.make, {
                          video: video
                        }), tmp)), React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: Models_Id$RecountTools.refAsId(video.show) !== Constants$RecountTools.recountWire ? React.createElement(React.Fragment, undefined, React.createElement(Form$RecountTools.TextArea.make, {
                                  label: "Description",
                                  placeholder: "Description for video",
                                  error: form.descriptionResult,
                                  disabled: form.submitting,
                                  value: form.input.description,
                                  onBlur: (function (param) {
                                      return Curry._1(form.blurDescription, undefined);
                                    }),
                                  onChange: (function (e) {
                                      return Curry._2(form.updateDescription, (function (input, value) {
                                                    return {
                                                            projectId: input.projectId,
                                                            title: input.title,
                                                            slug: input.slug,
                                                            status: input.status,
                                                            description: value,
                                                            article: input.article,
                                                            publishedAt: input.publishedAt,
                                                            tags: input.tags,
                                                            show: input.show,
                                                            sections: input.sections,
                                                            videoFile: input.videoFile,
                                                            thumbnailFile: input.thumbnailFile,
                                                            episodeNumber: input.episodeNumber,
                                                            seasonNumber: input.seasonNumber,
                                                            pinned: input.pinned,
                                                            rssYahoo: input.rssYahoo,
                                                            rssVideoElephant: input.rssVideoElephant,
                                                            rssMicrosoft: input.rssMicrosoft
                                                          };
                                                  }), e.target.value);
                                    })
                                })) : null
                    }), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, React.createElement(Form$RecountTools.$$File.make, {
                          value: Option$RecountTools.map(file, (function (f) {
                                  return {
                                          file: f,
                                          name: form.input.videoFile
                                        };
                                })),
                          fileTypes: "video/*",
                          placeholder: fileName,
                          label: "Replace Video File",
                          disabled: form.submitting,
                          onBlur: (function (param) {
                              return Curry._1(form.blurVideoFile, undefined);
                            }),
                          onChange: (function (v) {
                              Curry._1(setFile, (function (param) {
                                      return Caml_option.some(v.file);
                                    }));
                              return Curry._2(form.updateVideoFile, (function (input, value) {
                                            return {
                                                    projectId: input.projectId,
                                                    title: input.title,
                                                    slug: input.slug,
                                                    status: input.status,
                                                    description: input.description,
                                                    article: input.article,
                                                    publishedAt: input.publishedAt,
                                                    tags: input.tags,
                                                    show: input.show,
                                                    sections: input.sections,
                                                    videoFile: value,
                                                    thumbnailFile: input.thumbnailFile,
                                                    episodeNumber: input.episodeNumber,
                                                    seasonNumber: input.seasonNumber,
                                                    pinned: input.pinned,
                                                    rssYahoo: input.rssYahoo,
                                                    rssVideoElephant: input.rssVideoElephant,
                                                    rssMicrosoft: input.rssMicrosoft
                                                  };
                                          }), v.name);
                            }),
                          error: form.videoFileResult
                        }), tmp$1)), React.createElement("label", {
                  className: "label"
                }, "Article"), React.createElement(ProseMirror$RecountTools.make, tmp$4), tmp$6, isWire ? null : React.createElement(Layout$RecountTools.Columns.make, {
                    children: null
                  }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: React.createElement(Form_Input$RecountTools.make, {
                              value: form.input.seasonNumber,
                              label: "Season Number",
                              error: form.seasonNumberResult,
                              onChange: (function (e) {
                                  return Curry._2(form.updateSeasonNumber, (function (input, value) {
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: input.tags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: input.episodeNumber,
                                                        seasonNumber: value,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), e.target.value);
                                }),
                              onBlur: (function (param) {
                                  return Curry._1(form.blurSeasonNumber, undefined);
                                }),
                              disabled: form.submitting
                            })
                      }), React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: React.createElement(Form_Input$RecountTools.make, {
                              value: form.input.episodeNumber,
                              label: "Episode Number",
                              error: form.episodeNumberResult,
                              onChange: (function (e) {
                                  return Curry._2(form.updateEpisodeNumber, (function (input, value) {
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: input.tags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: value,
                                                        seasonNumber: input.seasonNumber,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), e.target.value);
                                }),
                              onBlur: (function (param) {
                                  return Curry._1(form.blurEpisodeNumber, undefined);
                                }),
                              disabled: form.submitting
                            })
                      })), React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: React.createElement(Form_Tags$RecountTools.make, {
                            label: "Location",
                            value: Arr$RecountTools.keep(form.input.tags, (function (t) {
                                    return t.kind === "location";
                                  })),
                            kind: "location",
                            maxTags: 1,
                            displayInline: true,
                            disabled: form.submitting,
                            error: form.tagsResult,
                            onBlur: form.blurTags,
                            onChange: (function (tag) {
                                if (tag.TAG === /* Added */0) {
                                  var tag$1 = tag._0;
                                  return Curry._2(form.updateTags, (function (input, param) {
                                                var updatedTags = Arr$RecountTools.concat(input.tags, [tag$1]);
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: updatedTags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: input.episodeNumber,
                                                        seasonNumber: input.seasonNumber,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), []);
                                }
                                var tag$2 = tag._0;
                                return Curry._2(form.updateTags, (function (input, param) {
                                              var updatedTags = Arr$RecountTools.keep(input.tags, (function (t) {
                                                      return t.id !== tag$2.id;
                                                    }));
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: updatedTags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), []);
                              })
                          })
                    }), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: React.createElement(Form_Tags$RecountTools.make, {
                            label: "Organization",
                            value: Arr$RecountTools.keep(form.input.tags, (function (t) {
                                    return t.kind === "organization";
                                  })),
                            kind: "organization",
                            maxTags: 1,
                            displayInline: true,
                            disabled: form.submitting,
                            error: form.tagsResult,
                            onBlur: form.blurTags,
                            onChange: (function (tag) {
                                if (tag.TAG === /* Added */0) {
                                  var tag$1 = tag._0;
                                  return Curry._2(form.updateTags, (function (input, param) {
                                                var updatedTags = Arr$RecountTools.concat(input.tags, [tag$1]);
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: updatedTags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: input.episodeNumber,
                                                        seasonNumber: input.seasonNumber,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), []);
                                }
                                var tag$2 = tag._0;
                                return Curry._2(form.updateTags, (function (input, param) {
                                              var updatedTags = Arr$RecountTools.keep(input.tags, (function (t) {
                                                      return t.id !== tag$2.id;
                                                    }));
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: updatedTags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), []);
                              })
                          })
                    })), React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: React.createElement(Form_Tags$RecountTools.make, {
                            label: "Person",
                            value: Arr$RecountTools.keep(form.input.tags, (function (t) {
                                    return t.kind === "person";
                                  })),
                            kind: "person",
                            disabled: form.submitting,
                            error: form.tagsResult,
                            onBlur: form.blurTags,
                            onChange: (function (tag) {
                                if (tag.TAG === /* Added */0) {
                                  var tag$1 = tag._0;
                                  return Curry._2(form.updateTags, (function (input, param) {
                                                var updatedTags = Arr$RecountTools.concat(input.tags, [tag$1]);
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: updatedTags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: input.episodeNumber,
                                                        seasonNumber: input.seasonNumber,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), []);
                                }
                                var tag$2 = tag._0;
                                return Curry._2(form.updateTags, (function (input, param) {
                                              var updatedTags = Arr$RecountTools.keep(input.tags, (function (t) {
                                                      return t.id !== tag$2.id;
                                                    }));
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: updatedTags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), []);
                              })
                          })
                    }), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: React.createElement(Form_Tags$RecountTools.make, {
                            label: "Subject",
                            value: Arr$RecountTools.keep(form.input.tags, (function (t) {
                                    return t.kind === "subject";
                                  })),
                            kind: "subject",
                            disabled: form.submitting,
                            error: form.tagsResult,
                            onBlur: form.blurTags,
                            onChange: (function (tag) {
                                if (tag.TAG === /* Added */0) {
                                  var tag$1 = tag._0;
                                  return Curry._2(form.updateTags, (function (input, param) {
                                                var updatedTags = Arr$RecountTools.concat(input.tags, [tag$1]);
                                                return {
                                                        projectId: input.projectId,
                                                        title: input.title,
                                                        slug: input.slug,
                                                        status: input.status,
                                                        description: input.description,
                                                        article: input.article,
                                                        publishedAt: input.publishedAt,
                                                        tags: updatedTags,
                                                        show: input.show,
                                                        sections: input.sections,
                                                        videoFile: input.videoFile,
                                                        thumbnailFile: input.thumbnailFile,
                                                        episodeNumber: input.episodeNumber,
                                                        seasonNumber: input.seasonNumber,
                                                        pinned: input.pinned,
                                                        rssYahoo: input.rssYahoo,
                                                        rssVideoElephant: input.rssVideoElephant,
                                                        rssMicrosoft: input.rssMicrosoft
                                                      };
                                              }), []);
                                }
                                var tag$2 = tag._0;
                                return Curry._2(form.updateTags, (function (input, param) {
                                              var updatedTags = Arr$RecountTools.keep(input.tags, (function (t) {
                                                      return t.id !== tag$2.id;
                                                    }));
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: updatedTags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: input.pinned,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), []);
                              })
                          })
                    })), React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: null
                      }, React.createElement(Form_CheckBox$RecountTools.make, {
                            value: form.input.pinned,
                            label: "Pin Video",
                            onChange: (function (value) {
                                return Curry._2(form.updatePinned, (function (input, value) {
                                              return {
                                                      projectId: input.projectId,
                                                      title: input.title,
                                                      slug: input.slug,
                                                      status: input.status,
                                                      description: input.description,
                                                      article: input.article,
                                                      publishedAt: input.publishedAt,
                                                      tags: input.tags,
                                                      show: input.show,
                                                      sections: input.sections,
                                                      videoFile: input.videoFile,
                                                      thumbnailFile: input.thumbnailFile,
                                                      episodeNumber: input.episodeNumber,
                                                      seasonNumber: input.seasonNumber,
                                                      pinned: value,
                                                      rssYahoo: input.rssYahoo,
                                                      rssVideoElephant: input.rssVideoElephant,
                                                      rssMicrosoft: input.rssMicrosoft
                                                    };
                                            }), value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurPinned, undefined);
                              }),
                            disabled: form.submitting
                          }), Models_Video$RecountTools.publishStatus(video) === "live" ? React.createElement("div", undefined, React.createElement(Form_CheckBox$RecountTools.make, {
                                  value: form.input.rssYahoo,
                                  label: "Post to Yahoo RSS Feed",
                                  onChange: (function (value) {
                                      return Curry._2(form.updateRssYahoo, (function (input, value) {
                                                    return {
                                                            projectId: input.projectId,
                                                            title: input.title,
                                                            slug: input.slug,
                                                            status: input.status,
                                                            description: input.description,
                                                            article: input.article,
                                                            publishedAt: input.publishedAt,
                                                            tags: input.tags,
                                                            show: input.show,
                                                            sections: input.sections,
                                                            videoFile: input.videoFile,
                                                            thumbnailFile: input.thumbnailFile,
                                                            episodeNumber: input.episodeNumber,
                                                            seasonNumber: input.seasonNumber,
                                                            pinned: input.pinned,
                                                            rssYahoo: value,
                                                            rssVideoElephant: input.rssVideoElephant,
                                                            rssMicrosoft: input.rssMicrosoft
                                                          };
                                                  }), value);
                                    }),
                                  onBlur: (function (param) {
                                      return Curry._1(form.blurRssYahoo, undefined);
                                    }),
                                  disabled: form.submitting
                                }), React.createElement(Form_CheckBox$RecountTools.make, {
                                  value: form.input.rssVideoElephant,
                                  label: "Post to Video Elephant RSS Feed",
                                  onChange: (function (value) {
                                      return Curry._2(form.updateRssVideoElephant, (function (input, value) {
                                                    return {
                                                            projectId: input.projectId,
                                                            title: input.title,
                                                            slug: input.slug,
                                                            status: input.status,
                                                            description: input.description,
                                                            article: input.article,
                                                            publishedAt: input.publishedAt,
                                                            tags: input.tags,
                                                            show: input.show,
                                                            sections: input.sections,
                                                            videoFile: input.videoFile,
                                                            thumbnailFile: input.thumbnailFile,
                                                            episodeNumber: input.episodeNumber,
                                                            seasonNumber: input.seasonNumber,
                                                            pinned: input.pinned,
                                                            rssYahoo: input.rssYahoo,
                                                            rssVideoElephant: value,
                                                            rssMicrosoft: input.rssMicrosoft
                                                          };
                                                  }), value);
                                    }),
                                  onBlur: (function (param) {
                                      return Curry._1(form.blurRssVideoElephant, undefined);
                                    }),
                                  disabled: form.submitting
                                }), React.createElement(Form_CheckBox$RecountTools.make, {
                                  value: form.input.rssMicrosoft,
                                  label: "Post to Microsoft RSS Feed",
                                  onChange: (function (value) {
                                      return Curry._2(form.updateRssMicrosoft, (function (input, value) {
                                                    return {
                                                            projectId: input.projectId,
                                                            title: input.title,
                                                            slug: input.slug,
                                                            status: input.status,
                                                            description: input.description,
                                                            article: input.article,
                                                            publishedAt: input.publishedAt,
                                                            tags: input.tags,
                                                            show: input.show,
                                                            sections: input.sections,
                                                            videoFile: input.videoFile,
                                                            thumbnailFile: input.thumbnailFile,
                                                            episodeNumber: input.episodeNumber,
                                                            seasonNumber: input.seasonNumber,
                                                            pinned: input.pinned,
                                                            rssYahoo: input.rssYahoo,
                                                            rssVideoElephant: input.rssVideoElephant,
                                                            rssMicrosoft: value
                                                          };
                                                  }), value);
                                    }),
                                  onBlur: (function (param) {
                                      return Curry._1(form.blurRssMicrosoft, undefined);
                                    }),
                                  disabled: form.submitting
                                })) : null)
                }), React.createElement("div", {
                  className: "field is-grouped"
                }, tmp$7, React.createElement(Form_Button$RecountTools.make, {
                      label: "Delete",
                      disabled: form.submitting,
                      className: "is-danger",
                      onClick: Curry._4(confirm, /* Severe */1, undefined, "Are you sure you want to delete \"" + video.title + "\"? This action cannot be undone.", (function (param) {
                              var video$1 = video.id;
                              Async$RecountTools.map(Request$RecountTools.$$delete(undefined, undefined, undefined, undefined, {
                                        TAG: /* Api */0,
                                        _0: "/v2/videos/" + Models_Id$RecountTools.toString(video$1)
                                      }), (function (response) {
                                      if (response.ok) {
                                        VideoStore$RecountTools.$$delete(video$1);
                                        return RescriptReactRouter.push("/videos");
                                      } else {
                                        Curry._3(makeToast, undefined, /* Error */2, "Could not delete video");
                                        return ;
                                      }
                                    }));
                              
                            }))
                    })), match$3[0]);
}

var LoadedState = {
  make: Page_Videos_Editor$LoadedState
};

function Page_Videos_Editor(Props) {
  var id = Props.id;
  var video = Observable$RecountTools.useMemoizedObservable(VideoStore$RecountTools.get(id));
  React.useEffect((function () {
          VideoStore$RecountTools.fetchOne(id);
          ShowStore$RecountTools.ensureFetched(undefined);
          SectionStore$RecountTools.ensureFetched(undefined);
          
        }), []);
  var tmp;
  if (typeof video === "number") {
    if (video !== 0) {
      PageHistory$RecountTools.revertPage(undefined);
      tmp = "Video Not Found";
    } else {
      tmp = "Loading";
    }
  } else {
    var video$1 = video._0;
    tmp = React.createElement(Page_Videos_Editor$LoadedState, {
          video: video$1,
          key: Models_Id$RecountTools.toString(video$1.id)
        });
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: tmp,
              pageName: "Edit Video",
              crumbs: [{
                  link: "/videos",
                  name: "Videos"
                }]
            });
}

var ActionBar;

var Logic;

var VideoForm;

var make = Page_Videos_Editor;

export {
  ActionBar ,
  Logic ,
  VideoForm ,
  Styles ,
  UnknownError ,
  LoadedState ,
  make ,
  
}
/* label Not a pure module */
