import React, { useEffect, useRef, useState } from 'react';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { undoItem, redoItem, joinUpItem, MenuItem, icons } from "prosemirror-menu";
import { wrapIn, lift } from "prosemirror-commands";
import { Schema } from 'prosemirror-model';
import { schema } from 'prosemirror-schema-basic';
import { exampleSetup, buildMenuItems } from 'prosemirror-example-setup';

import { toggleLink } from './toggle-link';

// TODO rewrite to use emotion css
import './ProseMirror.css';

const toggleWrap = (nodeType, options) => {
  return new MenuItem({
    run(state, dispatch) {
      const canUnwrap = lift(state);
      if (canUnwrap) {
        return lift(state, dispatch)
      }
      return wrapIn(nodeType, options.attrs)(state, dispatch)
    },
    active(state) {
      return lift(state);
    },
    ...options,
  });
};

const recountSchema = new Schema({
  nodes: {
    doc: schema.spec.nodes.get('doc'),
    paragraph: schema.spec.nodes.get('paragraph'),
    blockquote: schema.spec.nodes.get('blockquote'),
    text: schema.spec.nodes.get('text'),
  },
  marks: {
    link: schema.spec.marks.get('link'),
    em: schema.spec.marks.get('em'),
    strong: schema.spec.marks.get('strong'),
  },
});

// :: Array<BlockNode> => int
const countWords = (article) => {
  const countInlineWords = (fragment) => {
    const nodes = fragment.content;
    let numWords = 0;
    for (const node of nodes) {
      numWords += node.text.split(' ').length;
    }
    return numWords;
  }

  const countBlockWords = (fragment) => {
    let article = fragment.content;
    let numWords = 0;
    for (const node of article) {
      if (node.type == recountSchema.nodes.paragraph) {
        numWords += countInlineWords(node.content);
      } else if (node.type == recountSchema.nodes.blockquote) {
        numWords += countBlockWords(node.content);
      } else {
        console.error('Invalid node encountered', node)
      }
    }
    return numWords;
  };
  return countBlockWords(article);
};

class WordCount extends MenuItem {
  // :: (EditorView) → {dom: dom.Node, update: (EditorState) → bool}
  render(view) {
    const { state } = view;
    const dom = document.createElement('div');
    dom.setAttribute('class', 'word-count');
    const numWords = countWords(state.doc.content);
    let text = document.createTextNode(`${numWords} words`);

    dom.appendChild(text);

    const update = (state) => {
      const numWords = countWords(state.doc.content);
      const newText = document.createTextNode(`${numWords} words`);
      dom.replaceChild(newText, text);
      text = newText;
      return true;
    }

    return { dom, update };
  }
}


const menu = (() => {
  const basic = buildMenuItems(recountSchema);
  return [
    [
      basic.toggleStrong,
      basic.toggleEm,
      toggleLink(recountSchema.marks.link),
      toggleWrap(recountSchema.nodes.blockquote, {
        title: "Wrap in block quote",
        icon: icons.blockquote
      }),
      joinUpItem,
    ],
    [undoItem, redoItem],
    [new WordCount()]
  ];
})();

const ProseMirror = ({
  document,
  className,
  autoSaveKey,
  prepopulated,
  onChange = () => { },
  onBlur: handleBlur = () => { },
}) => {
  const editorRef = useRef();
  const isModified = useRef(false);
  const isPlaceholder = (
    (document === undefined || document === null)
    && (prepopulated !== undefined && prepopulated !== null)
  );

  useEffect(() => {
    const view = new EditorView(editorRef.current, {
      state: EditorState.create({
        doc: document || prepopulated,
        schema: recountSchema,
        plugins: exampleSetup({
          schema: recountSchema,
          menuContent: menu,
        }),
      }),
      dispatchTransaction(tr) {
        view.updateState(view.state.apply(tr));
        if (tr.steps.length > 0) {
          if (!isModified.current) {
            editorRef.current.classList.remove("has-placeholder-text");
            isModified.current = true;
          }
          onChange(view.state.doc);
          if (autoSaveKey) {
            localStorage.setItem(
              autoSaveKey,
              JSON.stringify(view.state.doc.content.toJSON())
              );
            }
          }
      },
      onBlur(view, event) {
        handleBlur(event);
      }
    });
    return () => view.destroy();
  });

  const classString = isPlaceholder ? `has-placeholder-text ${className}` : className;

  return <div className={classString} ref={editorRef} />;
};

export { ProseMirror, recountSchema };