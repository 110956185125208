

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Modal$RecountTools from "./Modal.bs.js";

function button(color) {
  return Curry._1(Css.merge, {
              hd: "button",
              tl: {
                hd: "is-large",
                tl: {
                  hd: color,
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(50)),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Styles = {
  button: button
};

function ConfirmationProvider$ConfirmationModal(Props) {
  var isActive = Props.isActive;
  var onConfirm = Props.onConfirm;
  var onCancel = Props.onCancel;
  var onModalClose = Props.onModalClose;
  var children = Props.children;
  var severity = Props.severity;
  var color = severity ? "is-danger" : "is-primary";
  return React.createElement(Modal$RecountTools.make, {
              isActive: isActive,
              children: React.createElement("div", {
                    className: "notification " + color + " is-light has-text-centered"
                  }, React.createElement("section", {
                        className: "section"
                      }, children), React.createElement("div", {
                        className: "buttons is-centered has-addons"
                      }, React.createElement("button", {
                            className: button(color),
                            onClick: (function (param) {
                                return onConfirm();
                              })
                          }, "Yes"), React.createElement("button", {
                            className: button(color + " is-light is-outlined"),
                            onClick: (function (param) {
                                Curry._1(onModalClose, undefined);
                                return onCancel();
                              })
                          }, "No"))),
              onModalClose: onModalClose
            });
}

var ConfirmationModal = {
  make: ConfirmationProvider$ConfirmationModal
};

var confirmContext = React.createContext({
      apply: (function (param, param$1, param$2, param$3, param$4) {
          
        })
    });

function ConfirmationProvider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setOpen = match[1];
  var match$1 = React.useState(function () {
        return null;
      });
  var setContents = match$1[1];
  var match$2 = React.useState(function () {
        return function () {
          
        };
      });
  var setCallback = match$2[1];
  var match$3 = React.useState(function () {
        return /* Normal */0;
      });
  var setSeverity = match$3[1];
  var match$4 = React.useState(function () {
        return function () {
          
        };
      });
  var setCancelCallback = match$4[1];
  var value = {
    apply: (function (severityOpt, onCancelOpt, content, cb, ev) {
        var severity = severityOpt !== undefined ? severityOpt : /* Normal */0;
        var onCancel = onCancelOpt !== undefined ? onCancelOpt : (function (param) {
              
            });
        var stopPropagation = (function(ev) {
          if (ev && ev.stopPropagation) {
            ev.stopPropagation();
          }
        });
        stopPropagation(ev);
        Curry._1(setContents, (function (param) {
                return content;
              }));
        Curry._1(setSeverity, (function (param) {
                return severity;
              }));
        Curry._1(setCallback, (function (param) {
                return function () {
                  Curry._1(cb, ev);
                  return Curry._1(setOpen, (function (param) {
                                return false;
                              }));
                };
              }));
        Curry._1(setOpen, (function (param) {
                return true;
              }));
        return Curry._1(setCancelCallback, (function (param) {
                      return function () {
                        return Curry._1(onCancel, ev);
                      };
                    }));
      })
  };
  var make = confirmContext.Provider;
  return React.createElement(React.Fragment, {
              children: null
            }, React.useMemo((function () {
                    return React.createElement(make, {
                                value: value,
                                children: children
                              });
                  }), [children]), React.createElement(ConfirmationProvider$ConfirmationModal, {
                  isActive: match[0],
                  onConfirm: match$2[0],
                  onCancel: match$4[0],
                  onModalClose: (function (param) {
                      return Curry._1(setOpen, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: match$1[0],
                  severity: match$3[0]
                }));
}

function useConfirm(param) {
  return React.useContext(confirmContext).apply;
}

var make = ConfirmationProvider;

export {
  Styles ,
  ConfirmationModal ,
  confirmContext ,
  make ,
  useConfirm ,
  
}
/* confirmContext Not a pure module */
