

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function get(sp, key) {
  return Caml_option.nullable_to_opt(sp.get(key));
}

var SearchParams = {
  get: get
};

function make(base, url) {
  try {
    return Caml_option.some(new URL(url, base !== undefined ? Caml_option.valFromOption(base) : undefined));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      return ;
    }
    throw exn;
  }
}

function makeExn(base, url) {
  return new URL(url, base !== undefined ? Caml_option.valFromOption(base) : undefined);
}

export {
  SearchParams ,
  make ,
  makeExn ,
  
}
/* No side effect */
