

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../../../util/Arr.bs.js";
import * as Icon$RecountTools from "../../../components/Icon.bs.js";
import * as Async$RecountTools from "../../../util/Async.bs.js";
import * as Modal$RecountTools from "../../../components/Modal.bs.js";
import * as Button$RecountTools from "../../../components/Button.bs.js";
import * as Option$RecountTools from "../../../util/Option.bs.js";
import * as Request$RecountTools from "../../../util/Request.bs.js";
import * as Constants$RecountTools from "../../../util/Constants.bs.js";
import * as Models_Id$RecountTools from "../../../models/Models_Id.bs.js";
import * as Form_Button$RecountTools from "../../../components/Form/Form_Button.bs.js";
import * as Form_CheckBox$RecountTools from "../../../components/Form/Form_CheckBox.bs.js";
import * as ToastProvider$RecountTools from "../../../components/ToastProvider.bs.js";
import * as ConfirmationProvider$RecountTools from "../../../components/ConfirmationProvider.bs.js";

var actionsBar = Curry._1(Css.merge, {
      hd: "field has-addons",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.paddingBottom(Css.rem(0.5)),
              tl: {
                hd: Css.selector(".control:nth-child(1)", {
                      hd: Css.marginLeft("auto"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var actionButton = Curry._1(Css.style, {
      hd: Css.color(Css.hex("f14668")),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.selector("span", {
                  hd: Css.color(Css.hex("363636")),
                  tl: {
                    hd: Css.paddingRight(Css.rem(0.5)),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.selector("&:hover", {
                    hd: Css.color(Css.hex("f14668")),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var actionButtonDefault = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.selector("span", {
                hd: Css.paddingRight(Css.rem(0.5)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  actionsBar: actionsBar,
  actionButton: actionButton,
  actionButtonDefault: actionButtonDefault
};

function usePushToTwitter(videoId) {
  var match = React.useState(function () {
        return false;
      });
  var setIsActive = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSubmitting = match$1[1];
  var submitting = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPushed = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setHasWatermark = match$3[1];
  var hasWatermark = match$3[0];
  var match$4 = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match$4[0];
  var pushToTwitter = function (e) {
    Curry._1(setSubmitting, (function (param) {
            return true;
          }));
    e.preventDefault();
    var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
              hd: [
                "data",
                Json_encode.object_({
                      hd: [
                        "watermark",
                        hasWatermark
                      ],
                      tl: /* [] */0
                    })
              ],
              tl: /* [] */0
            }));
    Async$RecountTools.map(Request$RecountTools.post(body, undefined, undefined, undefined, {
              TAG: /* Api */0,
              _0: "/v2/videos/by/upload-to-twitter/" + Models_Id$RecountTools.toString(videoId)
            }), (function (response) {
            if (response.ok) {
              Curry._1(setPushed, (function (param) {
                      return true;
                    }));
              Curry._1(setSubmitting, (function (param) {
                      return false;
                    }));
              return Curry._1(setIsActive, (function (param) {
                            return false;
                          }));
            } else {
              Async$RecountTools.map(response.json(), (function (body) {
                      var resp = Request$RecountTools.ErrorEnvelope.fromJson(body);
                      return Curry._3(makeToast, undefined, /* Error */2, resp.error);
                    }));
              return ;
            }
          }));
    
  };
  var openModal = function (param) {
    return Curry._1(setIsActive, (function (param) {
                  return true;
                }));
  };
  var modal = React.createElement(Modal$RecountTools.make, {
        isActive: match[0],
        children: null,
        onModalClose: (function (param) {
            return Curry._1(setIsActive, (function (param) {
                          return false;
                        }));
          })
      }, React.createElement(Modal$RecountTools.Header.make, {
            children: "Push to Twitter"
          }), React.createElement(Modal$RecountTools.Body.make, {
            children: null
          }, React.createElement("div", {
                className: "field"
              }, React.createElement("p", undefined, "This will add the video to the Media Studio in Twitter")), React.createElement(Form_CheckBox$RecountTools.make, {
                value: hasWatermark,
                label: "Apply Watermark",
                onChange: (function (s) {
                    return Curry._1(setHasWatermark, (function (param) {
                                  return s;
                                }));
                  }),
                onBlur: (function (param) {
                    
                  }),
                disabled: submitting
              })), React.createElement(Modal$RecountTools.Footer.make, {
            children: React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, {
                      label: "Cancel",
                      disabled: submitting,
                      className: "is-gray",
                      onClick: (function (param) {
                          return Curry._1(setIsActive, (function (param) {
                                        return false;
                                      }));
                        })
                    }), React.createElement(Form_Button$RecountTools.make, {
                      label: "Push",
                      disabled: submitting,
                      kind: "button",
                      className: "is-link",
                      onClick: pushToTwitter
                    }))
          }));
  return [
          modal,
          match$2[0],
          openModal
        ];
}

function Page_Videos_Editor_ActionBar(Props) {
  var video = Props.video;
  var match = usePushToTwitter(video.id);
  var pushToTwitter = match[2];
  var twitterIsDisabled = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setYoutubeIsDisabled = match$1[1];
  var youtubeIsDisabled = match$1[0];
  var confirm = ConfirmationProvider$RecountTools.useConfirm(undefined);
  var match$2 = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match$2[0];
  var isWire = Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire;
  var twitterExternal = Arr$RecountTools.find(video.videoExternals, (function (e) {
          return e.type_ === "twitter";
        }));
  var youtubeExternal = Arr$RecountTools.find(video.videoExternals, (function (e) {
          return e.type_ === "youtube";
        }));
  var frameioExternal = Arr$RecountTools.find(video.videoExternals, (function (e) {
          return e.type_ === "frameio";
        }));
  var twitterPending = Option$RecountTools.mapWithDefault(twitterExternal, false, (function (e) {
          return e.externalId === "awaiting-watermarked-file";
        }));
  var tmp;
  var exit = 0;
  if (twitterExternal !== undefined && !twitterPending) {
    tmp = React.createElement("a", {
          className: Curry._1(Css.merge, {
                hd: "button",
                tl: {
                  hd: actionButton,
                  tl: /* [] */0
                }
              }),
          href: "https://studio.twitter.com/library/" + twitterExternal.externalId + "/edit",
          target: "_blank"
        }, React.createElement("span", undefined, "View on"), React.createElement("i", {
              "aria-hidden": true,
              className: "fa fa-twitter fa-lg has-text-info"
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1 = {
      children: null,
      isLoading: twitterIsDisabled || twitterPending,
      disabled: twitterIsDisabled || twitterPending || Option$RecountTools.isNone(video.originalUrl),
      kind: "button",
      className: Curry._1(Css.merge, {
            hd: actionButtonDefault,
            tl: /* [] */0
          }),
      onClick: (function (param) {
          return Curry._1(pushToTwitter, undefined);
        })
    };
    var tmp$2 = Option$RecountTools.guard(Option$RecountTools.isNone(video.originalUrl), "Video is not finished uploading to Rainbow");
    if (tmp$2 !== undefined) {
      tmp$1.disabledReason = tmp$2;
    }
    tmp = React.createElement(Button$RecountTools.make, tmp$1, React.createElement("span", undefined, twitterIsDisabled || twitterPending ? "Pushing to" : "Push to"), React.createElement("i", {
              "aria-hidden": true,
              className: "fa fa-twitter fa-lg has-text-info"
            }));
  }
  var tmp$3;
  if (isWire) {
    tmp$3 = null;
  } else {
    var tmp$4;
    if (youtubeExternal !== undefined) {
      var channel = Option$RecountTools.mapWithDefault(Belt_MapString.get(youtubeExternal.meta, "channel_id"), "", (function (c) {
              return "?c=" + c;
            }));
      tmp$4 = React.createElement("a", {
            className: Curry._1(Css.merge, {
                  hd: "button",
                  tl: {
                    hd: actionButton,
                    tl: /* [] */0
                  }
                }),
            href: "https://studio.youtube.com/video/" + youtubeExternal.externalId + "/edit" + channel,
            target: "_blank"
          }, React.createElement("span", undefined, "View on"), React.createElement("i", {
                "aria-hidden": true,
                className: "fa fa-youtube fa-lg has-text-danger"
              }));
    } else {
      var tmp$5 = {
        children: null,
        isLoading: youtubeIsDisabled,
        disabled: youtubeIsDisabled || Option$RecountTools.isNone(video.originalUrl),
        kind: "button",
        className: Curry._1(Css.merge, {
              hd: actionButtonDefault,
              tl: /* [] */0
            }),
        onClick: Curry._4(confirm, /* Normal */0, undefined, "You are about to push this video to YouTube. Would you like to continue?", (function (param) {
                var externalType = "youtube";
                var onComplete = function (param) {
                  return Curry._1(setYoutubeIsDisabled, (function (param) {
                                return true;
                              }));
                };
                param.preventDefault();
                Async$RecountTools.map(Request$RecountTools.post(undefined, undefined, undefined, undefined, {
                          TAG: /* Api */0,
                          _0: "/v2/videos/by/upload-to-" + externalType + "/" + Models_Id$RecountTools.toString(video.id)
                        }), (function (response) {
                        if (response.ok) {
                          return Curry._1(onComplete, undefined);
                        } else {
                          Async$RecountTools.map(response.json(), (function (body) {
                                  var resp = Request$RecountTools.ErrorEnvelope.fromJson(body);
                                  return Curry._3(makeToast, undefined, /* Error */2, resp.error);
                                }));
                          return ;
                        }
                      }));
                
              }))
      };
      var tmp$6 = Option$RecountTools.guard(Option$RecountTools.isNone(video.originalUrl), "Video is not finished uploading to Rainbow");
      if (tmp$6 !== undefined) {
        tmp$5.disabledReason = tmp$6;
      }
      tmp$4 = React.createElement(Button$RecountTools.make, tmp$5, React.createElement("span", undefined, youtubeIsDisabled ? "Pushing to" : "Push to"), React.createElement("i", {
                "aria-hidden": true,
                className: "fa fa-youtube fa-lg has-text-danger"
              }));
    }
    tmp$3 = React.createElement(React.Fragment, undefined, React.createElement("p", {
              className: "control"
            }, tmp$4));
  }
  return React.createElement("div", {
              className: actionsBar
            }, match[0], frameioExternal !== undefined ? React.createElement("p", {
                    className: "control"
                  }, React.createElement("a", {
                        className: Curry._1(Css.merge, {
                              hd: "button",
                              tl: {
                                hd: actionButton,
                                tl: /* [] */0
                              }
                            }),
                        href: "https://app.frame.io/player/" + frameioExternal.externalId,
                        target: "_blank"
                      }, React.createElement("span", undefined, "View on"), React.createElement(Icon$RecountTools.FrameioLogo.make, {
                            width: "20",
                            color: "#5A52FF"
                          }))) : null, React.createElement("p", {
                  className: "control"
                }, tmp), tmp$3);
}

var make = Page_Videos_Editor_ActionBar;

export {
  Styles ,
  make ,
  
}
/* actionsBar Not a pure module */
