

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as LocalStorage$RecountTools from "./LocalStorage.bs.js";

var lastVisitedPage = LocalStorage$RecountTools.get("lastVisitedPage", (function (param) {
        return Json_decode.list(Json_decode.string, param);
      }));

function addPage(path) {
  if (lastVisitedPage !== undefined) {
    LocalStorage$RecountTools.set("lastFoundPage", (function (param) {
            return Json_encode.list((function (prim) {
                          return prim;
                        }), param);
          }), lastVisitedPage);
  }
  return LocalStorage$RecountTools.set("lastVisitedPage", (function (param) {
                return Json_encode.list((function (prim) {
                              return prim;
                            }), param);
              }), path);
}

function revertPage(param) {
  var lastFoundPage = LocalStorage$RecountTools.get("lastFoundPage", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }));
  if (lastFoundPage !== undefined) {
    return LocalStorage$RecountTools.set("lastVisitedPage", (function (param) {
                  return Json_encode.list((function (prim) {
                                return prim;
                              }), param);
                }), lastFoundPage);
  }
  
}

export {
  lastVisitedPage ,
  addPage ,
  revertPage ,
  
}
/* lastVisitedPage Not a pure module */
