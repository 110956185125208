

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MicroObservables from "micro-observables";
import * as Option$RecountTools from "./Option.bs.js";

function listenWhile(obs, fn) {
  var unsubscribe = {
    contents: undefined
  };
  var unsub = obs.onChange(function (v, ov) {
        if (!Curry._2(fn, v, ov)) {
          return Option$RecountTools.forEach(unsubscribe.contents, (function (u) {
                        return Curry._1(u, undefined);
                      }));
        }
        
      });
  unsubscribe.contents = unsub;
  
}

function resolveWhen(obs, fn) {
  return new Promise((function (resolve, param) {
                var curr = obs.get();
                if (Curry._1(fn, curr)) {
                  return resolve(curr);
                } else {
                  return listenWhile(obs, (function (v1, param) {
                                var done_ = Curry._1(fn, v1);
                                if (done_) {
                                  resolve(v1);
                                  return false;
                                } else {
                                  return true;
                                }
                              }));
                }
              }));
}

function resolveWhenOpt(obs, fn) {
  return new Promise((function (resolve, param) {
                var curr = Curry._1(fn, obs.get());
                if (curr !== undefined) {
                  return resolve(Caml_option.valFromOption(curr));
                } else {
                  return listenWhile(obs, (function (v, param) {
                                var value = Curry._1(fn, v);
                                if (value !== undefined) {
                                  resolve(Caml_option.valFromOption(value));
                                  return false;
                                } else {
                                  return true;
                                }
                              }));
                }
              }));
}

function onlyIf(obs, pred) {
  return obs.onlyIf(pred).transform(function (prim) {
              if (prim === undefined) {
                return ;
              } else {
                return Caml_option.some(prim);
              }
            });
}

function from(o1, o2) {
  return MicroObservables.Observable.from(o1, o2);
}

function from3(o1, o2, o3) {
  return MicroObservables.Observable.from(o1, o2, o3);
}

function merge(os) {
  return MicroObservables.Observable.merge(os);
}

function useMemoizedObservable(o) {
  var match = React.useState(function () {
        return {};
      });
  var forceRender = match[1];
  var value = o.get();
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(o.get(), value)) {
            Curry._1(forceRender, (function (param) {
                    return {};
                  }));
          }
          o.onChange(function (value, prevValue) {
                if (Caml_obj.caml_notequal(value, prevValue)) {
                  return Curry._1(forceRender, (function (param) {
                                return {};
                              }));
                }
                
              });
          
        }), [o]);
  return value;
}

function listenWhile$1(obs, fn) {
  return listenWhile(obs.readOnly(), fn);
}

function resolveWhen$1(obs, fn) {
  return resolveWhen(obs.readOnly(), fn);
}

function resolveWhenOpt$1(obs, fn) {
  return resolveWhenOpt(obs.readOnly(), fn);
}

function onlyIf$1(obs, pred) {
  return obs.onlyIf(pred).transform(function (prim) {
              if (prim === undefined) {
                return ;
              } else {
                return Caml_option.some(prim);
              }
            });
}

function from$1(o1, o2) {
  return MicroObservables.Observable.from(o1, o2);
}

function from3$1(o1, o2, o3) {
  return MicroObservables.Observable.from(o1, o2, o3);
}

var Writable = {
  listenWhile: listenWhile$1,
  resolveWhen: resolveWhen$1,
  resolveWhenOpt: resolveWhenOpt$1,
  onlyIf: onlyIf$1,
  from: from$1,
  from3: from3$1
};

export {
  listenWhile ,
  resolveWhen ,
  resolveWhenOpt ,
  onlyIf ,
  from ,
  from3 ,
  merge ,
  useMemoizedObservable ,
  Writable ,
  
}
/* react Not a pure module */
