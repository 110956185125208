

import * as React from "react";
import * as Tooltip$RecountTools from "./Tooltip.bs.js";

function Button(Props) {
  var children = Props.children;
  var isLoadingOpt = Props.isLoading;
  var disabled = Props.disabled;
  var disabledReason = Props.disabledReason;
  var kindOpt = Props.kind;
  var classNameOpt = Props.className;
  var onClickOpt = Props.onClick;
  var isLoading = isLoadingOpt !== undefined ? isLoadingOpt : false;
  var kind = kindOpt !== undefined ? kindOpt : "button";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var type_ = kind === "reset" ? "reset" : (
      kind === "submit" ? "submit" : "button"
    );
  var button = React.createElement("button", {
        className: "button " + className + " " + (
          isLoading ? "is-loading" : ""
        ),
        disabled: disabled,
        type: type_,
        onClick: onClick
      }, children);
  if (disabled && disabledReason !== undefined) {
    return React.createElement(Tooltip$RecountTools.make, {
                text: disabledReason,
                color: "has-text-white",
                children: React.createElement("div", undefined, button)
              });
  } else {
    return button;
  }
}

var make = Button;

export {
  make ,
  
}
/* react Not a pure module */
