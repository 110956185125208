

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Layout_Header$RecountTools from "./Layout_Header.bs.js";
import * as Layout_Navigation$RecountTools from "./Layout_Navigation.bs.js";

var appContainer = Curry._1(Css.merge, {
      hd: "app-container",
      tl: {
        hd: "is-flex",
        tl: /* [] */0
      }
    });

var fullWidth = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "hex",
            VAL: "e5ecf1"
          }),
      tl: {
        hd: Css.width(Css.pct(100)),
        tl: /* [] */0
      }
    });

var mainContainer = Curry._1(Css.merge, {
      hd: "has-background-white pl-5 pr-5",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", {
                    NAME: "hex",
                    VAL: "c5dbea"
                  }),
              tl: {
                hd: Css.marginLeft("auto"),
                tl: {
                  hd: Css.marginRight("auto"),
                  tl: {
                    hd: Css.marginTop(Css.rem(1)),
                    tl: {
                      hd: Css.minHeight(Css.vh(90)),
                      tl: {
                        hd: Css.media("(max-width: 1259px)", {
                              hd: Css.borderTop(Css.px(0), "solid", {
                                    NAME: "hex",
                                    VAL: "fff"
                                  }),
                              tl: {
                                hd: Css.marginTop(Css.rem(0)),
                                tl: {
                                  hd: Css.minHeight(Css.vh(100)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.media("(min-width: 1260px)", {
                                hd: Css.width(Css.px(1200)),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var scrolling = Curry._1(Css.style, {
      hd: Css.height(Css.vh(90)),
      tl: {
        hd: Css.overflowY("hidden"),
        tl: /* [] */0
      }
    });

var stagingBanner = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: {
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.padding2(Css.px(5), Css.px(0)),
          tl: {
            hd: Css.overflow("hidden"),
            tl: {
              hd: Css.whiteSpace("nowrap"),
              tl: {
                hd: Css.background(Css.hex("ffd942")),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var LayoutStyles = {
  appContainer: appContainer,
  fullWidth: fullWidth,
  mainContainer: mainContainer,
  scrolling: scrolling,
  stagingBanner: stagingBanner
};

function Layout$Columns(Props) {
  var children = Props.children;
  var centeredOpt = Props.centered;
  var className = Props.className;
  var centered = centeredOpt !== undefined ? centeredOpt : false;
  var cn = centered ? "columns is-vcentered is-centered" : "columns";
  var cn$1 = className !== undefined ? cn + " " + className : cn;
  return React.createElement("div", {
              className: cn$1
            }, children);
}

function Layout$Columns$Column(Props) {
  var children = Props.children;
  var size = Props.size;
  var className = Props.className;
  var onDragLeave = Props.onDragLeave;
  var onDragExit = Props.onDragExit;
  var onDragEnter = Props.onDragEnter;
  var onDragOver = Props.onDragOver;
  var cn;
  if (size !== undefined) {
    switch (size) {
      case /* ThreeQuarters */0 :
          cn = "column is-three-quarters";
          break;
      case /* TwoThirds */1 :
          cn = "column is-two-thirds";
          break;
      case /* Half */2 :
          cn = "column is-half";
          break;
      case /* Third */3 :
          cn = "column is-one-third";
          break;
      case /* Quarter */4 :
          cn = "column is-one-quarter";
          break;
      case /* Full */5 :
          cn = "column is-full";
          break;
      
    }
  } else {
    cn = "column";
  }
  var cn$1 = className !== undefined ? cn + " " + className : cn;
  var tmp = {
    className: cn$1
  };
  if (onDragEnter !== undefined) {
    tmp.onDragEnter = Caml_option.valFromOption(onDragEnter);
  }
  if (onDragExit !== undefined) {
    tmp.onDragExit = Caml_option.valFromOption(onDragExit);
  }
  if (onDragLeave !== undefined) {
    tmp.onDragLeave = Caml_option.valFromOption(onDragLeave);
  }
  if (onDragOver !== undefined) {
    tmp.onDragOver = Caml_option.valFromOption(onDragOver);
  }
  return React.createElement("div", tmp, children);
}

var Column = {
  make: Layout$Columns$Column
};

var Columns = {
  make: Layout$Columns,
  Column: Column
};

function Layout$Panel(Props) {
  var children = Props.children;
  var className = Props.className;
  var draggable = Props.draggable;
  var onDragEnter = Props.onDragEnter;
  var onDragOver = Props.onDragOver;
  var onDragEnd = Props.onDragEnd;
  var cn = className !== undefined ? className + " panel" : "panel";
  var tmp = {
    className: cn
  };
  if (draggable !== undefined) {
    tmp.draggable = Caml_option.valFromOption(draggable);
  }
  if (onDragEnd !== undefined) {
    tmp.onDragEnd = Caml_option.valFromOption(onDragEnd);
  }
  if (onDragEnter !== undefined) {
    tmp.onDragEnter = Caml_option.valFromOption(onDragEnter);
  }
  if (onDragOver !== undefined) {
    tmp.onDragOver = Caml_option.valFromOption(onDragOver);
  }
  return React.createElement("nav", tmp, children);
}

function Layout$Panel$Heading(Props) {
  var children = Props.children;
  var className = Props.className;
  var cn = className !== undefined ? className + " panel-heading" : "panel-heading";
  return React.createElement("p", {
              className: cn
            }, children);
}

var Heading = {
  make: Layout$Panel$Heading
};

var Panel = {
  make: Layout$Panel,
  Heading: Heading
};

function Layout$Container(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("div", {
              className: Curry._1(Css.merge, Belt_List.add(Option$RecountTools.toList(className), "container"))
            }, children);
}

var Container = {
  make: Layout$Container
};

function Layout$AppContainer(Props) {
  var children = Props.children;
  var pageName = Props.pageName;
  var crumbsOpt = Props.crumbs;
  var scrollingOpt = Props.scrolling;
  var crumbs = crumbsOpt !== undefined ? crumbsOpt : [];
  var scrolling$1 = scrollingOpt !== undefined ? scrollingOpt : false;
  return React.createElement(React.Fragment, undefined, process.env.ENVIRONMENT === "staging" ? React.createElement("div", {
                    className: stagingBanner
                  }, "STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING STAGING") : React.createElement("div", {
                    className: "rainbow"
                  }), React.createElement(Layout_Header$RecountTools.make, {
                  crumbs: crumbs,
                  pageName: pageName
                }), React.createElement("div", {
                  className: appContainer
                }, React.createElement(Layout_Navigation$RecountTools.make, {}), React.createElement("div", {
                      className: fullWidth
                    }, React.createElement(Layout$Columns$Column, {
                          children: children,
                          className: Curry._1(Css.merge, {
                                hd: mainContainer,
                                tl: {
                                  hd: scrolling$1 ? scrolling : "",
                                  tl: /* [] */0
                                }
                              })
                        }))));
}

var AppContainer = {
  make: Layout$AppContainer
};

var Navigation;

var Header;

export {
  Navigation ,
  Header ,
  LayoutStyles ,
  Columns ,
  Panel ,
  Container ,
  AppContainer ,
  
}
/* appContainer Not a pure module */
