

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function kindToString(kind) {
  if (kind === "password") {
    return "password";
  } else if (kind === "color") {
    return "color";
  } else if (kind === "text") {
    return "text";
  } else if (kind === "week") {
    return "week";
  } else if (kind === "telephone") {
    return "tel";
  } else if (kind === "timeOfDay") {
    return "time";
  } else if (kind === "email") {
    return "email";
  } else if (kind === "url") {
    return "url";
  } else if (kind === "month") {
    return "month";
  } else if (kind === "number") {
    return "number";
  } else {
    return "date";
  }
}

function Form_Input$BaseInput(Props) {
  var type_Opt = Props.type_;
  var value = Props.value;
  var label = Props.label;
  var error = Props.error;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onPaste = Props.onPaste;
  var onKeyDown = Props.onKeyDown;
  var autoComplete = Props.autoComplete;
  var autoFocus = Props.autoFocus;
  var disabled = Props.disabled;
  var max = Props.max;
  var min = Props.min;
  var name = Props.name;
  var readOnly = Props.readOnly;
  var placeholder = Props.placeholder;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var displayErrorSync = function (e) {
    if (e !== undefined && e.TAG !== /* Ok */0) {
      return React.createElement("p", {
                  className: "help is-danger"
                }, e._0);
    } else {
      return null;
    }
  };
  var displayErrorAsync = function (e) {
    if (e === undefined) {
      return null;
    }
    if (e.TAG === /* Validating */0) {
      return null;
    }
    var message = e._0;
    if (message.TAG === /* Ok */0) {
      return null;
    } else {
      return React.createElement("p", {
                  className: "help is-danger"
                }, message._0);
    }
  };
  var classNameSync = function (error) {
    if (error !== undefined && error.TAG !== /* Ok */0) {
      return "input is-danger";
    } else {
      return "input";
    }
  };
  var classNameAsync = function (error) {
    if (error !== undefined && !(error.TAG === /* Validating */0 || error._0.TAG === /* Ok */0)) {
      return "input is-danger";
    } else {
      return "input";
    }
  };
  var pasted = React.useRef(false);
  var tmp = {
    className: error.NAME === "async" ? classNameAsync(error.VAL) : classNameSync(error.VAL),
    type: kindToString(type_),
    value: value,
    onPaste: (function (e) {
        pasted.current = true;
        
      }),
    onBlur: onBlur,
    onChange: (function (e) {
        if (pasted.current && onPaste !== undefined) {
          if (onPaste !== undefined) {
            Curry._1(onPaste, e);
          }
          pasted.current = false;
          return ;
        } else {
          return Curry._1(onChange, e);
        }
      })
  };
  if (autoComplete !== undefined) {
    tmp.autoComplete = Caml_option.valFromOption(autoComplete);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (max !== undefined) {
    tmp.max = Caml_option.valFromOption(max);
  }
  if (min !== undefined) {
    tmp.min = Caml_option.valFromOption(min);
  }
  if (name !== undefined) {
    tmp.name = Caml_option.valFromOption(name);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (readOnly !== undefined) {
    tmp.readOnly = Caml_option.valFromOption(readOnly);
  }
  if (onKeyDown !== undefined) {
    tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
  }
  return React.createElement("div", {
              key: label + "-field",
              className: "field"
            }, React.createElement("label", {
                  className: "label"
                }, label), React.createElement("div", {
                  className: "control"
                }, React.createElement("input", tmp)), error.NAME === "async" ? displayErrorAsync(error.VAL) : displayErrorSync(error.VAL));
}

var BaseInput = {
  make: Form_Input$BaseInput
};

function Form_Input$Async(Props) {
  var type_Opt = Props.type_;
  var value = Props.value;
  var label = Props.label;
  var error = Props.error;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onPaste = Props.onPaste;
  var onKeyDown = Props.onKeyDown;
  var autoComplete = Props.autoComplete;
  var autoFocus = Props.autoFocus;
  var disabled = Props.disabled;
  var max = Props.max;
  var min = Props.min;
  var name = Props.name;
  var readOnly = Props.readOnly;
  var placeholder = Props.placeholder;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  return React.createElement(Form_Input$BaseInput, {
              type_: type_,
              value: value,
              label: label,
              error: {
                NAME: "async",
                VAL: error
              },
              onChange: onChange,
              onBlur: onBlur,
              onPaste: onPaste,
              onKeyDown: onKeyDown,
              autoComplete: autoComplete,
              autoFocus: autoFocus,
              disabled: disabled,
              max: max,
              min: min,
              name: name,
              readOnly: readOnly,
              placeholder: placeholder
            });
}

var Async = {
  make: Form_Input$Async
};

function Form_Input(Props) {
  var type_Opt = Props.type_;
  var value = Props.value;
  var label = Props.label;
  var error = Props.error;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onPaste = Props.onPaste;
  var onKeyDown = Props.onKeyDown;
  var autoComplete = Props.autoComplete;
  var autoFocus = Props.autoFocus;
  var disabled = Props.disabled;
  var max = Props.max;
  var min = Props.min;
  var name = Props.name;
  var readOnly = Props.readOnly;
  var placeholder = Props.placeholder;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  return React.createElement(Form_Input$BaseInput, {
              type_: type_,
              value: value,
              label: label,
              error: {
                NAME: "sync",
                VAL: error
              },
              onChange: onChange,
              onBlur: onBlur,
              onPaste: onPaste,
              onKeyDown: onKeyDown,
              autoComplete: autoComplete,
              autoFocus: autoFocus,
              disabled: disabled,
              max: max,
              min: min,
              name: name,
              readOnly: readOnly,
              placeholder: placeholder
            });
}

var make = Form_Input;

export {
  BaseInput ,
  Async ,
  make ,
  
}
/* react Not a pure module */
