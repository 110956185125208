

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Icon$RecountTools from "../../components/Icon.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../../util/Async.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as Request$RecountTools from "../../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as UserStore$RecountTools from "../../stores/UserStore.bs.js";
import * as Form_Button$RecountTools from "../../components/Form/Form_Button.bs.js";
import * as Models_User$RecountTools from "../../models/Models_User.bs.js";
import * as PageHistory$RecountTools from "../../util/PageHistory.bs.js";
import * as ToastProvider$RecountTools from "../../components/ToastProvider.bs.js";
import * as Form_MultiSelect$RecountTools from "../../components/Form/Form_MultiSelect.bs.js";
import * as Page_NotAuthorized$RecountTools from "../NotAuthorized/Page_NotAuthorized.bs.js";

var email = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.verticalAlign("middle"),
        tl: /* [] */0
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(10)),
      tl: {
        hd: Css.display("inlineBlock"),
        tl: {
          hd: Css.verticalAlign("middle"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  email: email,
  icon: icon
};

var validators = {
  permissions: {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (input) {
        return {
                TAG: /* Ok */0,
                _0: input.permissions
              };
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          permissions: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            permissions: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.permissions;
  var match$1 = match ? match._0 : Curry._1(validators.permissions.validate, input);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              permissions: match$1._0
            },
            fieldsStatuses: {
              permissions: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              permissions: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurPermissionsField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.permissions, validators.permissions, (function (status) {
                          return {
                                  permissions: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */1,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */2,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */4);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */3);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdatePermissionsField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.permissions, state.submissionStatus, validators.permissions, (function (status) {
                                    return {
                                            permissions: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                permissions: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                permissions: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updatePermissions: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdatePermissionsField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurPermissions: (function (param) {
              return Curry._1(dispatch, /* BlurPermissionsField */0);
            }),
          permissionsResult: Formality.exposeFieldResult(state.fieldsStatuses.permissions),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.permissions) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */3,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */4);
            })
        };
}

var UserForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_Users_Editor-RecountTools.UnknownError");

function Page_Users_Editor$LoadedState(Props) {
  var user = Props.user;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var allPermissions = [
    "editUsers",
    "editVideo",
    "publishVideo"
  ];
  var form = useForm({
        permissions: user.permissions
      }, (function (output, cb) {
          var fields = [];
          if (Caml_obj.caml_notequal(output.permissions, user.permissions)) {
            Arr$RecountTools.push(fields, [
                  "permissions",
                  Json_encode.array(Models_User$RecountTools.Permission.toJson, output.permissions)
                ]);
          }
          var body = Request$RecountTools.RequestBody.json(Json_encode.object_({
                    hd: [
                      "data",
                      Json_encode.object_(Belt_List.fromArray(fields))
                    ],
                    tl: /* [] */0
                  }));
          Async$RecountTools.onFailure(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.patch(body, undefined, undefined, undefined, {
                            TAG: /* Api */0,
                            _0: "/v2/users/" + Models_Id$RecountTools.toString(user.id)
                          }), (function (response) {
                          if (response.ok) {
                            return Promise.resolve(UserStore$RecountTools.update(user.id, (function (user) {
                                              return {
                                                      id: user.id,
                                                      email: user.email,
                                                      slackUserId: user.slackUserId,
                                                      slackToken: user.slackToken,
                                                      permissions: output.permissions,
                                                      createdAt: user.createdAt,
                                                      updatedAt: user.updatedAt
                                                    };
                                            })));
                          } else {
                            Curry._3(makeToast, undefined, /* Error */2, "Unable to save user");
                            return Promise.reject({
                                        RE_EXN_ID: UnknownError
                                      });
                          }
                        })), (function (param) {
                      return Curry._1(cb.reset, undefined);
                    })), (function (param) {
                  return Curry._1(cb.notifyOnFailure, /* UnexpectedServerError */0);
                }));
          
        }));
  var match$1 = user.slackUserId;
  var tmp = {
    label: "Save",
    disabled: form.submitting || !Curry._1(form.dirty, undefined),
    kind: "submit",
    className: "is-link"
  };
  var tmp$1 = Option$RecountTools.guard(!Curry._1(form.dirty, undefined), "No changes to save");
  if (tmp$1 !== undefined) {
    tmp.disabledReason = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: null
                      }, React.createElement("div", {
                            className: "content is-normal"
                          }, React.createElement("h1", {
                                className: email
                              }, user.email), match$1 !== undefined ? React.createElement("div", {
                                  className: icon
                                }, React.createElement(Icon$RecountTools.AddToSlack.make, {})) : null), React.createElement(Form_MultiSelect$RecountTools.make, {
                            placeholder: "Select permissions for this user",
                            disabled: form.submitting,
                            error: form.permissionsResult,
                            onChange: (function (permission) {
                                if (permission.TAG === /* Added */0) {
                                  var updatedPermissions = Arr$RecountTools.concat(form.input.permissions, [Models_User$RecountTools.Permission.fromString(permission._0)]);
                                  return Curry._2(form.updatePermissions, (function (param, updatedPermissions) {
                                                return {
                                                        permissions: updatedPermissions
                                                      };
                                              }), updatedPermissions);
                                }
                                var position = Arr$RecountTools.indexOf(form.input.permissions, Models_User$RecountTools.Permission.fromString(permission._0));
                                if (position === undefined) {
                                  return ;
                                }
                                var updatedPermissions$1 = Arr$RecountTools.omit(form.input.permissions, Option$RecountTools.getExn(position));
                                return Curry._2(form.updatePermissions, (function (param, updatedPermissions) {
                                              return {
                                                      permissions: updatedPermissions
                                                    };
                                            }), updatedPermissions$1);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurPermissions, undefined);
                              }),
                            options: Arr$RecountTools.map(allPermissions, (function (p) {
                                    return {
                                            label: Models_User$RecountTools.Permission.toDisplay(p),
                                            value: Models_User$RecountTools.Permission.toString(p)
                                          };
                                  })),
                            label: "Permissions",
                            values: Arr$RecountTools.map(form.input.permissions, (function (p) {
                                    return Models_User$RecountTools.Permission.toString(p);
                                  }))
                          }))
                }), React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, tmp)));
}

var LoadedState = {
  make: Page_Users_Editor$LoadedState
};

function Page_Users_Editor(Props) {
  var id = Props.id;
  var user = MicroObservables.useObservable(UserStore$RecountTools.get(id));
  var currentUser = MicroObservables.useObservable(UserStore$RecountTools.getCurrent(undefined));
  React.useEffect((function () {
          UserStore$RecountTools.fetchOne(id);
          UserStore$RecountTools.ensureFetchedCurrent(undefined);
          
        }), []);
  if (typeof currentUser === "number") {
    return React.createElement(Page_NotAuthorized$RecountTools.make, {});
  }
  if (!currentUser._0.permissions.includes("editUsers")) {
    return React.createElement(Page_NotAuthorized$RecountTools.make, {});
  }
  var tmp;
  if (typeof user === "number") {
    if (user !== 0) {
      PageHistory$RecountTools.revertPage(undefined);
      tmp = "User Not Found";
    } else {
      tmp = "Loading";
    }
  } else {
    var user$1 = user._0;
    tmp = React.createElement(Page_Users_Editor$LoadedState, {
          user: user$1,
          key: Models_Id$RecountTools.toString(user$1.id)
        });
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: tmp,
              pageName: "Edit User",
              crumbs: [{
                  link: "/users",
                  name: "Users"
                }]
            });
}

var make = Page_Users_Editor;

export {
  Styles ,
  UserForm ,
  UnknownError ,
  LoadedState ,
  make ,
  
}
/* email Not a pure module */
