

import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";

var AuthException = /* @__PURE__ */Caml_exceptions.create("AuthStore-RecountTools.AuthException");

var rawAuth = MicroObservables.observable(undefined);

function set(auth) {
  rawAuth.set(auth);
  
}

var getOpt = rawAuth.readOnly();

var get = rawAuth.transform(function (auth) {
      if (auth !== undefined) {
        return auth;
      }
      throw {
            RE_EXN_ID: AuthException,
            _1: "Authentication not initialized",
            Error: new Error()
          };
    });

function refreshToken(param) {
  return Async$RecountTools.flatMap(fetch(process.env.API_HOST + "/auth/refresh-token", Fetch.RequestInit.make(/* Post */2, undefined, undefined, undefined, undefined, undefined, /* Include */2, undefined, undefined, undefined, undefined, undefined)(undefined)), (function (prim) {
                return prim.json();
              }));
}

function parseAuth(json) {
  var parseObj = function (json) {
    return {
            token: Json_decode.field("authToken", Json_decode.string, json),
            expires: Json_decode.field("tokenExpires", Json_decode.$$float, json)
          };
  };
  return Json_decode.field("data", parseObj, json);
}

function checkForSession(param) {
  Async$RecountTools.map(refreshToken(undefined), (function (json) {
          var auth = Json_decode.optional(parseAuth, json);
          if (auth !== undefined) {
            rawAuth.set(auth);
            return ;
          }
          
        }));
  
}

function startTokenRefresher(param) {
  var now = Date.now();
  setTimeout((function (param) {
          Async$RecountTools.map(refreshToken(undefined), (function (json) {
                  return set(parseAuth(json));
                }));
          
        }), get.get().expires - now - 60000 | 0);
  
}

rawAuth.readOnly().onChange(function (param, param$1) {
      return startTokenRefresher(undefined);
    });

checkForSession(undefined);

var OW;

export {
  OW ,
  AuthException ,
  set ,
  getOpt ,
  get ,
  checkForSession ,
  startTokenRefresher ,
  
}
/* rawAuth Not a pure module */
