

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

var fromJson = Json_decode.string;

function fromJson$1(json) {
  return {
          first: Json_decode.field("first", fromJson, json),
          next: Caml_option.null_to_opt(Json_decode.field("next", (function (param) {
                      return Json_decode.nullable(fromJson, param);
                    }), json)),
          prev: Caml_option.null_to_opt(Json_decode.field("prev", (function (param) {
                      return Json_decode.nullable(fromJson, param);
                    }), json)),
          last: Json_decode.field("last", fromJson, json),
          total: 0
        };
}

var Pagination = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          total: Json_decode.field("total", Json_decode.$$int, json)
        };
}

var Meta = {
  fromJson: fromJson$2
};

function toString(c) {
  return c;
}

export {
  fromJson ,
  Pagination ,
  Meta ,
  toString ,
  
}
/* No side effect */
