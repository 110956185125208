

import * as React from "react";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as PageHistory$RecountTools from "../../util/PageHistory.bs.js";

function Page_NotAuthorized(Props) {
  PageHistory$RecountTools.revertPage(undefined);
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: React.createElement(Layout$RecountTools.Columns.make, {
                    children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                          children: "You are not authorized to view this page."
                        })
                  }),
              pageName: "Not Authorized"
            });
}

var make = Page_NotAuthorized;

export {
  make ,
  
}
/* react Not a pure module */
