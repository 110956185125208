

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Int$RecountTools from "../util/Int.bs.js";
import * as Url$RecountTools from "../util/Url.bs.js";
import * as Uuid$RecountTools from "../util/Uuid.bs.js";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Modal$RecountTools from "../components/Modal.bs.js";
import * as Layout$RecountTools from "../layout/Layout.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Form_Input$RecountTools from "../components/Form/Form_Input.bs.js";
import * as Page_Error$RecountTools from "./Error/Page_Error.bs.js";
import * as Form_Button$RecountTools from "../components/Form/Form_Button.bs.js";
import * as Form_Select$RecountTools from "../components/Form/Form_Select.bs.js";
import * as PageHistory$RecountTools from "../util/PageHistory.bs.js";
import * as Form_CheckBox$RecountTools from "../components/Form/Form_CheckBox.bs.js";
import * as ToastProvider$RecountTools from "../components/ToastProvider.bs.js";
import * as Models_VideoEditRequest$RecountTools from "../models/Models_VideoEditRequest.bs.js";

var label = Curry._1(Css.style, {
      hd: Css.fontSize(Css.rem(1)),
      tl: {
        hd: Css.color(Css.hex("363636")),
        tl: {
          hd: Css.fontWeight("bold"),
          tl: /* [] */0
        }
      }
    });

var buttonTooltip = Curry._1(Css.merge, {
      hd: "control",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.selector("button", {
                      hd: Css.paddingRight(Css.rem(3)),
                      tl: {
                        hd: Css.transition(200, undefined, "easeInOut", "padding"),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.selector(".has-tooltip-arrow", {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.border(Css.px(0), "none", {
                                NAME: "hex",
                                VAL: "fff"
                              }),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.top(Css.rem(-0.25)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var thumbnail = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignContent("center"),
        tl: /* [] */0
      }
    });

var cropSelector = Curry._1(Css.style, {
      hd: Css.verticalAlign("bottom"),
      tl: /* [] */0
    });

var cropSelectorCaption = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: {
        hd: Css.paddingTop(Css.px(5)),
        tl: /* [] */0
      }
    });

var cropSelectorWrapper = Curry._1(Css.merge, {
      hd: "has-background-warning-light",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.margin2(Css.px(0), "auto"),
              tl: {
                hd: Css.padding(Css.px(5)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Css.hsl(Css.deg(48), Css.pct(100), Css.pct(67))),
                  tl: {
                    hd: Css.display("inlineBlock"),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  label: label,
  buttonTooltip: buttonTooltip,
  thumbnail: thumbnail,
  cropSelector: cropSelector,
  cropSelectorCaption: cropSelectorCaption,
  cropSelectorWrapper: cropSelectorWrapper
};

var validators_trimEnd = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.trimEnd
            };
    })
};

var validators_trimStart = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.trimStart
            };
    })
};

var validators_watermark = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.watermark
            };
    })
};

var validators_trackingCoords = {
  strategy: /* OnSubmit */4,
  validate: (function (input) {
      if (input.cropIsTracking && Option$RecountTools.isNone(input.trackingCoords)) {
        return {
                TAG: /* Error */1,
                _0: "Please select an object to track"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.trackingCoords
              };
      }
    })
};

var validators_cropIsTracking = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.cropIsTracking
            };
    })
};

var validators_cropCenterX = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      if (input.cropCenterX < 1) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: input.cropCenterX
              };
      }
    })
};

var validators_cropMode = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (input) {
      return {
              TAG: /* Ok */0,
              _0: input.cropMode
            };
    })
};

var validators = {
  trimEnd: validators_trimEnd,
  trimStart: validators_trimStart,
  watermark: validators_watermark,
  trackingCoords: validators_trackingCoords,
  cropIsTracking: validators_cropIsTracking,
  cropCenterX: validators_cropCenterX,
  cropMode: validators_cropMode
};

function initialFieldsStatuses(_input) {
  return {
          trimEnd: /* Pristine */0,
          trimStart: /* Pristine */0,
          watermark: /* Pristine */0,
          trackingCoords: /* Pristine */0,
          cropIsTracking: /* Pristine */0,
          cropCenterX: /* Pristine */0,
          cropMode: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.trimEnd;
  var match_0 = match ? match._0 : Curry._1(validators.trimEnd.validate, input);
  var match$1 = fieldsStatuses.trimStart;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.trimStart.validate, input);
  var match$2 = fieldsStatuses.watermark;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.watermark.validate, input);
  var match$3 = fieldsStatuses.trackingCoords;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.trackingCoords.validate, input);
  var match$4 = fieldsStatuses.cropIsTracking;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.cropIsTracking.validate, input);
  var match$5 = fieldsStatuses.cropCenterX;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.cropCenterX.validate, input);
  var match$6 = fieldsStatuses.cropMode;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.cropMode.validate, input);
  var trimEndResult = match_0;
  var trimEndResult$1;
  if (trimEndResult.TAG === /* Ok */0) {
    var trimStartResult = match_0$1;
    if (trimStartResult.TAG === /* Ok */0) {
      var watermarkResult = match_0$2;
      if (watermarkResult.TAG === /* Ok */0) {
        var trackingCoordsResult = match_0$3;
        if (trackingCoordsResult.TAG === /* Ok */0) {
          var cropIsTrackingResult = match_0$4;
          if (cropIsTrackingResult.TAG === /* Ok */0) {
            var cropCenterXResult = match_0$5;
            if (cropCenterXResult.TAG === /* Ok */0) {
              var cropModeResult = match_0$6;
              if (cropModeResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          cropMode: cropModeResult._0,
                          cropCenterX: cropCenterXResult._0,
                          cropIsTracking: cropIsTrackingResult._0,
                          trackingCoords: trackingCoordsResult._0,
                          watermark: watermarkResult._0,
                          trimStart: trimStartResult._0,
                          trimEnd: trimEndResult._0
                        },
                        fieldsStatuses: {
                          trimEnd: /* Dirty */{
                            _0: trimEndResult,
                            _1: /* Shown */0
                          },
                          trimStart: /* Dirty */{
                            _0: trimStartResult,
                            _1: /* Shown */0
                          },
                          watermark: /* Dirty */{
                            _0: watermarkResult,
                            _1: /* Shown */0
                          },
                          trackingCoords: /* Dirty */{
                            _0: trackingCoordsResult,
                            _1: /* Shown */0
                          },
                          cropIsTracking: /* Dirty */{
                            _0: cropIsTrackingResult,
                            _1: /* Shown */0
                          },
                          cropCenterX: /* Dirty */{
                            _0: cropCenterXResult,
                            _1: /* Shown */0
                          },
                          cropMode: /* Dirty */{
                            _0: cropModeResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              trimEndResult$1 = trimEndResult;
            } else {
              trimEndResult$1 = trimEndResult;
            }
          } else {
            trimEndResult$1 = trimEndResult;
          }
        } else {
          trimEndResult$1 = trimEndResult;
        }
      } else {
        trimEndResult$1 = trimEndResult;
      }
    } else {
      trimEndResult$1 = trimEndResult;
    }
  } else {
    trimEndResult$1 = trimEndResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            trimEnd: /* Dirty */{
              _0: trimEndResult$1,
              _1: /* Shown */0
            },
            trimStart: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            watermark: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            trackingCoords: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            cropIsTracking: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            cropCenterX: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            cropMode: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTrimEndField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.trimEnd, validators_trimEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: status,
                                  trimStart: init.trimStart,
                                  watermark: init.watermark,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTrimStartField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.trimStart, validators_trimStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: status,
                                  watermark: init.watermark,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurWatermarkField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.watermark, validators_watermark, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: init.trimStart,
                                  watermark: status,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurTrackingCoordsField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.trackingCoords, validators_trackingCoords, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: init.trimStart,
                                  watermark: init.watermark,
                                  trackingCoords: status,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCropIsTrackingField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.cropIsTracking, validators_cropIsTracking, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: init.trimStart,
                                  watermark: init.watermark,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: status,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCropCenterXField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.cropCenterX, validators_cropCenterX, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: init.trimStart,
                                  watermark: init.watermark,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: status,
                                  cropMode: init.cropMode
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCropModeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.cropMode, validators_cropMode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  trimEnd: init.trimEnd,
                                  trimStart: init.trimStart,
                                  watermark: init.watermark,
                                  trackingCoords: init.trackingCoords,
                                  cropIsTracking: init.cropIsTracking,
                                  cropCenterX: init.cropCenterX,
                                  cropMode: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */7,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */8,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */10);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */9);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateTrimEndField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.trimEnd, state.submissionStatus, validators_trimEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: status,
                                            trimStart: init.trimStart,
                                            watermark: init.watermark,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTrimStartField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.trimStart, state.submissionStatus, validators_trimStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: status,
                                            watermark: init.watermark,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateWatermarkField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.watermark, state.submissionStatus, validators_watermark, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: init.trimStart,
                                            watermark: status,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateTrackingCoordsField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.trackingCoords, state.submissionStatus, validators_trackingCoords, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: init.trimStart,
                                            watermark: init.watermark,
                                            trackingCoords: status,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCropIsTrackingField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.cropIsTracking, state.submissionStatus, validators_cropIsTracking, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: init.trimStart,
                                            watermark: init.watermark,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: status,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCropCenterXField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.cropCenterX, state.submissionStatus, validators_cropCenterX, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: init.trimStart,
                                            watermark: init.watermark,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: status,
                                            cropMode: init.cropMode
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCropModeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.cropMode, state.submissionStatus, validators_cropMode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            trimEnd: init.trimEnd,
                                            trimStart: init.trimStart,
                                            watermark: init.watermark,
                                            trackingCoords: init.trackingCoords,
                                            cropIsTracking: init.cropIsTracking,
                                            cropCenterX: init.cropCenterX,
                                            cropMode: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateTrimEnd: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTrimEndField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTrimStart: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTrimStartField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateWatermark: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateWatermarkField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateTrackingCoords: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateTrackingCoordsField */3,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateCropIsTracking: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateCropIsTrackingField */4,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateCropCenterX: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateCropCenterXField */5,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateCropMode: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateCropModeField */6,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurTrimEnd: (function (param) {
              return Curry._1(dispatch, /* BlurTrimEndField */0);
            }),
          blurTrimStart: (function (param) {
              return Curry._1(dispatch, /* BlurTrimStartField */1);
            }),
          blurWatermark: (function (param) {
              return Curry._1(dispatch, /* BlurWatermarkField */2);
            }),
          blurTrackingCoords: (function (param) {
              return Curry._1(dispatch, /* BlurTrackingCoordsField */3);
            }),
          blurCropIsTracking: (function (param) {
              return Curry._1(dispatch, /* BlurCropIsTrackingField */4);
            }),
          blurCropCenterX: (function (param) {
              return Curry._1(dispatch, /* BlurCropCenterXField */5);
            }),
          blurCropMode: (function (param) {
              return Curry._1(dispatch, /* BlurCropModeField */6);
            }),
          trimEndResult: Formality.exposeFieldResult(state.fieldsStatuses.trimEnd),
          trimStartResult: Formality.exposeFieldResult(state.fieldsStatuses.trimStart),
          watermarkResult: Formality.exposeFieldResult(state.fieldsStatuses.watermark),
          trackingCoordsResult: Formality.exposeFieldResult(state.fieldsStatuses.trackingCoords),
          cropIsTrackingResult: Formality.exposeFieldResult(state.fieldsStatuses.cropIsTracking),
          cropCenterXResult: Formality.exposeFieldResult(state.fieldsStatuses.cropCenterX),
          cropModeResult: Formality.exposeFieldResult(state.fieldsStatuses.cropMode),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.trimEnd || match.trimStart || match.watermark || match.trackingCoords || match.cropIsTracking || match.cropCenterX || match.cropMode) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */9,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */10);
            })
        };
}

var VideoEditorForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var UnknownError = /* @__PURE__ */Caml_exceptions.create("Page_VideoEditor-RecountTools.UnknownError");

function scaleFactor(max, width) {
  if (width <= max) {
    return 1;
  } else {
    return max / width;
  }
}

function scalePoint(imageWidth, offset, point) {
  var factor = 1 / scaleFactor(640, imageWidth);
  var x = (point[0] - offset[0] | 0) * factor | 0;
  var y = (point[1] - offset[1] | 0) * factor | 0;
  return [
          x,
          y
        ];
}

function Page_VideoEditor$CropSelector(Props) {
  var imageUrl = Props.imageUrl;
  var onChange = Props.onChange;
  var cropMode = Props.cropMode;
  var canvasContext = React.useRef(undefined);
  var image = React.useRef(new Image());
  var offset = React.useRef([
        0,
        0
      ]);
  var match = React.useState(function () {
        return [
                640,
                360
              ];
      });
  var setDimensions = match[1];
  var dimensions = match[0];
  var match$1 = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setCenter = match$1[1];
  var center = match$1[0];
  var setCanvas = function (el) {
    Option$RecountTools.forEach((el == null) ? undefined : Caml_option.some(el), (function (el) {
            offset.current = [
              el.offsetLeft,
              el.offsetTop
            ];
            
          }));
    canvasContext.current = Option$RecountTools.map((el == null) ? undefined : Caml_option.some(el), (function (el) {
            return el.getContext("2d");
          }));
    
  };
  React.useEffect((function () {
          image.current.src = imageUrl;
          image.current.addEventListener("load", (function (param) {
                  var iWidth = image.current.width;
                  var iHeight = image.current.height;
                  var factor = scaleFactor(640, iWidth);
                  var w = Math.min(iWidth, 640);
                  var h = iHeight * factor | 0;
                  Curry._1(setDimensions, (function (param) {
                          return [
                                  w,
                                  h
                                ];
                        }));
                  return Curry._1(setCenter, (function (param) {
                                return [
                                        iWidth / 2 | 0,
                                        iHeight / 2 | 0
                                      ];
                              }));
                }));
          
        }), []);
  React.useEffect((function () {
          var iWidth = image.current.width;
          var w = Math.min(iWidth, 640);
          var iHeight = image.current.height;
          var factor = scaleFactor(640, iWidth);
          var h = iHeight * factor;
          Option$RecountTools.forEach(canvasContext.current, (function (ctx) {
                  ctx.drawImage(image.current, 0, 0, w, h);
                  if (!Option$RecountTools.isSome(cropMode)) {
                    return ;
                  }
                  ctx.save();
                  ctx.globalAlpha = 0.50;
                  ctx.fillStyle = "black";
                  ctx.fillRect(0, 0, w, h);
                  ctx.restore();
                  var cropWidth = cropMode !== undefined ? (
                      cropMode === "square" ? h : (
                          cropMode === "vertical" ? h * (9 / 16) : w
                        )
                    ) : w;
                  var x = center[0] * factor - cropWidth / 2;
                  var x$1 = Math.min(x, w - cropWidth);
                  var x$2 = Math.max(x$1, 0);
                  ctx.save();
                  ctx.beginPath();
                  ctx.clearRect(x$2, 0, cropWidth, h);
                  ctx.rect(x$2, 0, cropWidth, h);
                  ctx.clip();
                  ctx.drawImage(image.current, 0, 0, w, h);
                  ctx.restore();
                  
                }));
          
        }), [
        center,
        dimensions,
        cropMode
      ]);
  var onClick = function (ev) {
    var match = scalePoint(image.current.width, offset.current, [
          ev.pageX,
          ev.pageY
        ]);
    var y = match[1];
    var x = match[0];
    Curry._1(setCenter, (function (param) {
            return [
                    x,
                    y
                  ];
          }));
    return Curry._1(onChange, [
                x,
                y
              ]);
  };
  return React.createElement("div", {
              className: cropSelectorWrapper
            }, React.createElement("canvas", {
                  ref: setCanvas,
                  className: cropSelector,
                  height: Int$RecountTools.toString(undefined, dimensions[1]),
                  width: Int$RecountTools.toString(undefined, dimensions[0]),
                  onClick: onClick
                }), React.createElement("p", {
                  className: cropSelectorCaption
                }, "Select crop center"));
}

var CropSelector = {
  make: Page_VideoEditor$CropSelector
};

function Page_VideoEditor$ObjectSelector(Props) {
  var imageUrl = Props.imageUrl;
  var onChange = Props.onChange;
  var canvasContext = React.useRef(undefined);
  var image = React.useRef(new Image());
  var offset = React.useRef([
        0,
        0
      ]);
  var match = React.useState(function () {
        return [
                640,
                360
              ];
      });
  var setDimensions = match[1];
  var dimensions = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setTopLeft = match$1[1];
  var topLeft = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setBottomRight = match$2[1];
  var bottomRight = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsDragging = match$3[1];
  var isDragging = match$3[0];
  var setCanvas = function (el) {
    Option$RecountTools.forEach((el == null) ? undefined : Caml_option.some(el), (function (el) {
            offset.current = [
              el.offsetLeft,
              el.offsetTop
            ];
            
          }));
    canvasContext.current = Option$RecountTools.map((el == null) ? undefined : Caml_option.some(el), (function (el) {
            return el.getContext("2d");
          }));
    
  };
  React.useEffect((function () {
          image.current.src = imageUrl;
          image.current.addEventListener("load", (function (param) {
                  var iWidth = image.current.width;
                  var iHeight = image.current.height;
                  var factor = scaleFactor(640, iWidth);
                  var w = Math.min(iWidth, 640);
                  var h = iHeight * factor | 0;
                  return Curry._1(setDimensions, (function (param) {
                                return [
                                        w,
                                        h
                                      ];
                              }));
                }));
          
        }), []);
  React.useEffect((function () {
          var iWidth = image.current.width;
          var w = Math.min(iWidth, 640);
          var iHeight = image.current.height;
          var factor = scaleFactor(640, iWidth);
          var h = iHeight * factor;
          Option$RecountTools.forEach(canvasContext.current, (function (ctx) {
                  ctx.drawImage(image.current, 0, 0, w, h);
                  var match = Option$RecountTools.zip(topLeft, bottomRight);
                  if (match === undefined) {
                    return ;
                  }
                  var match$1 = match[1];
                  var match$2 = match[0];
                  var y1 = match$2[1];
                  var x1 = match$2[0];
                  var x = x1 * factor;
                  var y = y1 * factor;
                  var w$1 = (match$1[0] - x1 | 0) * factor;
                  var h$1 = (match$1[1] - y1 | 0) * factor;
                  ctx.save();
                  ctx.strokeStyle = "blue";
                  ctx.strokeRect(x, y, w$1, h$1);
                  ctx.restore();
                  
                }));
          
        }), [
        topLeft,
        bottomRight,
        dimensions
      ]);
  var onMouseDown = function (ev) {
    var match = scalePoint(image.current.width, offset.current, [
          ev.pageX,
          ev.pageY
        ]);
    var y = match[1];
    var x = match[0];
    Curry._1(setTopLeft, (function (param) {
            return [
                    x,
                    y
                  ];
          }));
    Curry._1(setBottomRight, (function (param) {
            
          }));
    return Curry._1(setIsDragging, (function (param) {
                  return true;
                }));
  };
  var onMouseMove = function (ev) {
    var match = scalePoint(image.current.width, offset.current, [
          ev.pageX,
          ev.pageY
        ]);
    if (!isDragging) {
      return ;
    }
    var y = match[1];
    var x = match[0];
    return Curry._1(setBottomRight, (function (param) {
                  return [
                          x,
                          y
                        ];
                }));
  };
  var onMouseUp = function (param) {
    Curry._1(setIsDragging, (function (param) {
            return false;
          }));
    var match = Option$RecountTools.zip(topLeft, bottomRight);
    if (match === undefined) {
      return ;
    }
    var match$1 = match[1];
    var y2 = match$1[1];
    var x2 = match$1[0];
    var match$2 = match[0];
    var y1 = match$2[1];
    var x1 = match$2[0];
    var x = Math.min(x1, x2);
    var y = Math.min(y1, y2);
    var width = Math.abs(x1 - x2 | 0);
    var height = Math.abs(y1 - y2 | 0);
    return Curry._1(onChange, {
                x: x,
                y: y,
                width: width,
                height: height
              });
  };
  return React.createElement("div", {
              className: cropSelectorWrapper
            }, React.createElement("canvas", {
                  ref: setCanvas,
                  className: cropSelector,
                  height: Int$RecountTools.toString(undefined, dimensions[1]),
                  width: Int$RecountTools.toString(undefined, dimensions[0]),
                  onMouseDown: onMouseDown,
                  onMouseLeave: onMouseUp,
                  onMouseMove: onMouseMove,
                  onMouseUp: onMouseUp
                }), React.createElement("p", {
                  className: cropSelectorCaption
                }, "Click and drag to select object in frame"));
}

var ObjectSelector = {
  make: Page_VideoEditor$ObjectSelector
};

function Page_VideoEditor$SuccessModal(Props) {
  var isActive = Props.isActive;
  var onModalClose = Props.onModalClose;
  return React.createElement(Modal$RecountTools.make, {
              isActive: isActive,
              children: null,
              onModalClose: (function (param) {
                  return Curry._1(onModalClose, undefined);
                })
            }, React.createElement(Modal$RecountTools.Header.make, {
                  children: "Success!"
                }), React.createElement(Modal$RecountTools.Body.make, {
                  children: "Edit request sent. Your edit will be available in the original Slack thread."
                }));
}

var SuccessModal = {
  make: Page_VideoEditor$SuccessModal
};

function Page_VideoEditor$VideoEditor(Props) {
  var videoUrl = Props.videoUrl;
  var thumbnailUrl = Props.thumbnailUrl;
  var slackThreadId = Props.slackThreadId;
  var slackChannelId = Props.slackChannelId;
  var match = ToastProvider$RecountTools.useToast(undefined);
  var makeToast = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setModalIsOpen = match$1[1];
  var handleModalClose = function (param) {
    Curry._1(setModalIsOpen, (function (param) {
            return false;
          }));
    return RescriptReactRouter.push("/videos");
  };
  var cropModes = [
    "vertical",
    "horizontal",
    "square"
  ];
  var parseTime = function (rawTime) {
    var time = Belt_List.toArray($$String.split_on_char(/* ':' */58, rawTime));
    return Option$RecountTools.getExn(Belt_Int.fromString(Caml_array.get(time, 1))) + Math.imul(60, Option$RecountTools.getExn(Belt_Int.fromString(Caml_array.get(time, 0)))) | 0;
  };
  var form = useForm({
        cropMode: undefined,
        cropCenterX: 0,
        cropIsTracking: false,
        trackingCoords: undefined,
        watermark: false,
        trimStart: undefined,
        trimEnd: undefined
      }, (function (output, param) {
          var transforms = [];
          Arr$RecountTools.push(transforms, /* AudioNormalizeTransform */0);
          Option$RecountTools.forEach(output.cropMode, (function (cm) {
                  if (output.cropIsTracking) {
                    return Arr$RecountTools.push(transforms, {
                                TAG: /* TrackingCropTransform */1,
                                cropMode: cm,
                                boundingBox: Option$RecountTools.getExn(output.trackingCoords)
                              });
                  } else {
                    return Arr$RecountTools.push(transforms, {
                                TAG: /* CropTransform */0,
                                cropMode: cm,
                                centerX: output.cropCenterX
                              });
                  }
                }));
          if (output.watermark === true) {
            Arr$RecountTools.push(transforms, /* WatermarkTransform */1);
          }
          var trimStart = Option$RecountTools.map(output.trimStart, parseTime);
          var trimEnd = Option$RecountTools.map(output.trimEnd, parseTime);
          if (output.trimEnd !== undefined || output.trimStart !== undefined) {
            Arr$RecountTools.push(transforms, {
                  TAG: /* TrimTransform */2,
                  start: trimStart,
                  end: trimEnd
                });
          }
          var request_destinationFilePath = Uuid$RecountTools.make(undefined) + ".mp4";
          var request = {
            bucketName: process.env.VIDEO_EDIT_BUCKET_NAME,
            destinationFilePath: request_destinationFilePath,
            channelId: slackChannelId,
            threadTs: slackThreadId,
            videoUrl: videoUrl,
            transforms: transforms
          };
          var body = Request$RecountTools.RequestBody.json(Models_VideoEditRequest$RecountTools.toJson(request));
          Async$RecountTools.map(Request$RecountTools.post(body, undefined, undefined, undefined, {
                    TAG: /* VideoEditor */3,
                    _0: "/run-transforms"
                  }), (function (response) {
                  if (response.ok) {
                    return Curry._1(setModalIsOpen, (function (param) {
                                  return true;
                                }));
                  } else {
                    Async$RecountTools.map(response.json(), (function (body) {
                            var resp = Request$RecountTools.ErrorEnvelope.fromJson(body);
                            return Curry._3(makeToast, undefined, /* Error */2, resp.error);
                          }));
                    return ;
                  }
                }));
          
        }));
  var trimStart = form.input.trimStart;
  var trimEnd = form.input.trimEnd;
  var match$2 = form.trackingCoordsResult;
  var tmp;
  tmp = match$2 !== undefined && match$2.TAG !== /* Ok */0 ? React.createElement("p", {
          className: "help is-danger"
        }, match$2._0) : null;
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement(Layout$RecountTools.Columns.make, {
                  children: null
                }, React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: null
                    }, React.createElement(Form_Select$RecountTools.make, {
                          label: "Crop Mode",
                          value: Option$RecountTools.map(form.input.cropMode, (function (x) {
                                  return Models_VideoEditRequest$RecountTools.CropMode.toString(x);
                                })),
                          options: Arr$RecountTools.map(cropModes, (function (cm) {
                                  return {
                                          label: Models_VideoEditRequest$RecountTools.CropMode.toDisplay(cm),
                                          value: Models_VideoEditRequest$RecountTools.CropMode.toString(cm)
                                        };
                                })),
                          disabled: form.submitting,
                          onChange: (function (e) {
                              return Curry._2(form.updateCropMode, (function (input, value) {
                                            return {
                                                    cropMode: value,
                                                    cropCenterX: input.cropCenterX,
                                                    cropIsTracking: input.cropIsTracking,
                                                    trackingCoords: input.trackingCoords,
                                                    watermark: input.watermark,
                                                    trimStart: input.trimStart,
                                                    trimEnd: input.trimEnd
                                                  };
                                          }), Models_VideoEditRequest$RecountTools.CropMode.fromString(e.target.value));
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurCropMode, undefined);
                            }),
                          placeholder: "Select a crop mode",
                          error: form.cropModeResult
                        }), form.input.cropMode !== undefined ? React.createElement(Form_CheckBox$RecountTools.make, {
                            value: form.input.cropIsTracking,
                            label: "Track object in frame",
                            onChange: (function (value) {
                                return Curry._2(form.updateCropIsTracking, (function (input, value) {
                                              return {
                                                      cropMode: input.cropMode,
                                                      cropCenterX: input.cropCenterX,
                                                      cropIsTracking: value,
                                                      trackingCoords: input.trackingCoords,
                                                      watermark: input.watermark,
                                                      trimStart: input.trimStart,
                                                      trimEnd: input.trimEnd
                                                    };
                                            }), value);
                              }),
                            onBlur: (function (param) {
                                return Curry._1(form.blurCropIsTracking, undefined);
                              }),
                            disabled: form.submitting
                          }) : null, React.createElement(Form_CheckBox$RecountTools.make, {
                          value: form.input.watermark,
                          label: "Watermark",
                          onChange: (function (value) {
                              return Curry._2(form.updateWatermark, (function (input, value) {
                                            return {
                                                    cropMode: input.cropMode,
                                                    cropCenterX: input.cropCenterX,
                                                    cropIsTracking: input.cropIsTracking,
                                                    trackingCoords: input.trackingCoords,
                                                    watermark: value,
                                                    trimStart: input.trimStart,
                                                    trimEnd: input.trimEnd
                                                  };
                                          }), value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurWatermark, undefined);
                            }),
                          disabled: form.submitting
                        }), React.createElement(Form_Input$RecountTools.make, {
                          value: trimStart !== undefined ? trimStart : "",
                          label: "Trim Start",
                          error: form.trimStartResult,
                          onChange: (function (e) {
                              return Curry._2(form.updateTrimStart, (function (input, value) {
                                            return {
                                                    cropMode: input.cropMode,
                                                    cropCenterX: input.cropCenterX,
                                                    cropIsTracking: input.cropIsTracking,
                                                    trackingCoords: input.trackingCoords,
                                                    watermark: input.watermark,
                                                    trimStart: value,
                                                    trimEnd: input.trimEnd
                                                  };
                                          }), e.target.value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurTrimStart, undefined);
                            }),
                          disabled: form.submitting,
                          placeholder: "00:00"
                        }), React.createElement(Form_Input$RecountTools.make, {
                          value: trimEnd !== undefined ? trimEnd : "",
                          label: "Trim End",
                          error: form.trimEndResult,
                          onChange: (function (e) {
                              return Curry._2(form.updateTrimEnd, (function (input, value) {
                                            return {
                                                    cropMode: input.cropMode,
                                                    cropCenterX: input.cropCenterX,
                                                    cropIsTracking: input.cropIsTracking,
                                                    trackingCoords: input.trackingCoords,
                                                    watermark: input.watermark,
                                                    trimStart: input.trimStart,
                                                    trimEnd: value
                                                  };
                                          }), e.target.value);
                            }),
                          onBlur: (function (param) {
                              return Curry._1(form.blurTrimEnd, undefined);
                            }),
                          disabled: form.submitting,
                          placeholder: "00.00"
                        })), React.createElement(Layout$RecountTools.Columns.Column.make, {
                      children: form.input.cropIsTracking ? React.createElement(Page_VideoEditor$ObjectSelector, {
                              imageUrl: thumbnailUrl,
                              onChange: (function (box) {
                                  return Curry._2(form.updateTrackingCoords, (function (input, value) {
                                                return {
                                                        cropMode: input.cropMode,
                                                        cropCenterX: input.cropCenterX,
                                                        cropIsTracking: input.cropIsTracking,
                                                        trackingCoords: value,
                                                        watermark: input.watermark,
                                                        trimStart: input.trimStart,
                                                        trimEnd: input.trimEnd
                                                      };
                                              }), box);
                                })
                            }) : React.createElement(Page_VideoEditor$CropSelector, {
                              imageUrl: thumbnailUrl,
                              onChange: (function (param) {
                                  return Curry._2(form.updateCropCenterX, (function (input, value) {
                                                return {
                                                        cropMode: input.cropMode,
                                                        cropCenterX: value,
                                                        cropIsTracking: input.cropIsTracking,
                                                        trackingCoords: input.trackingCoords,
                                                        watermark: input.watermark,
                                                        trimStart: input.trimStart,
                                                        trimEnd: input.trimEnd
                                                      };
                                              }), param[0]);
                                }),
                              cropMode: form.input.cropMode
                            }),
                      className: thumbnail
                    })), React.createElement(Layout$RecountTools.Columns.make, {
                  children: React.createElement(Layout$RecountTools.Columns.Column.make, {
                        children: tmp
                      })
                }), React.createElement("div", {
                  className: "field is-grouped"
                }, React.createElement(Form_Button$RecountTools.make, {
                      label: "Request Video Edit",
                      disabled: form.submitting,
                      kind: "submit",
                      className: "is-link"
                    })), React.createElement(Page_VideoEditor$SuccessModal, {
                  isActive: match$1[0],
                  onModalClose: handleModalClose
                }));
}

var VideoEditor = {
  make: Page_VideoEditor$VideoEditor
};

function Page_VideoEditor(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var videoUrl = Url$RecountTools.SearchParams.get(new URLSearchParams(url.search), "videoUrl");
  var thumbnailUrl = Url$RecountTools.SearchParams.get(new URLSearchParams(url.search), "thumbnailUrl");
  var slackThreadId = Url$RecountTools.SearchParams.get(new URLSearchParams(url.search), "slackThreadId");
  var slackChannelId = Url$RecountTools.SearchParams.get(new URLSearchParams(url.search), "slackChannelId");
  React.useEffect((function () {
          PageHistory$RecountTools.revertPage(undefined);
          
        }), []);
  if (videoUrl === undefined || thumbnailUrl === undefined || slackThreadId === undefined || slackChannelId === undefined) {
    return React.createElement(Page_Error$RecountTools.make, {});
  } else {
    return React.createElement(Layout$RecountTools.AppContainer.make, {
                children: React.createElement(Page_VideoEditor$VideoEditor, {
                      videoUrl: Option$RecountTools.getExn(videoUrl),
                      thumbnailUrl: Option$RecountTools.getExn(thumbnailUrl),
                      slackThreadId: Option$RecountTools.getExn(slackThreadId),
                      slackChannelId: Option$RecountTools.getExn(slackChannelId)
                    }),
                pageName: "Video Editor"
              });
  }
}

var make = Page_VideoEditor;

export {
  Styles ,
  VideoEditorForm ,
  UnknownError ,
  CropSelector ,
  ObjectSelector ,
  SuccessModal ,
  VideoEditor ,
  make ,
  
}
/* label Not a pure module */
