

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as DropDown$RecountTools from "../../components/DropDown.bs.js";
import * as TagStore$RecountTools from "../../stores/TagStore.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as Models_Tag$RecountTools from "../../models/Models_Tag.bs.js";
import * as PaginationLogic$RecountTools from "../../util/PaginationLogic.bs.js";
import * as SortableHeaders$RecountTools from "../../components/SortableHeaders.bs.js";
import * as Page_Tags_AddTagForm$RecountTools from "./Page_Tags_AddTagForm.bs.js";

var tagRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 30
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 30
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 30
                  },
                  tl: {
                    hd: {
                      NAME: "percent",
                      VAL: 10
                    },
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: {
          hd: Css.alignItems("start"),
          tl: /* [] */0
        }
      }
    });

var headerRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "percent",
                VAL: 30
              },
              tl: {
                hd: {
                  NAME: "percent",
                  VAL: 30
                },
                tl: {
                  hd: {
                    NAME: "percent",
                    VAL: 30
                  },
                  tl: {
                    hd: {
                      NAME: "percent",
                      VAL: 10
                    },
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: {
          hd: Css.alignItems("start"),
          tl: {
            hd: Css.paddingTop(Css.rem(2.5)),
            tl: /* [] */0
          }
        }
      }
    });

var tagActions = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.textAlign("right"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.selector("a", {
                    hd: Css.textTransform("uppercase"),
                    tl: {
                      hd: Css.fontSize(Css.rem(0.75)),
                      tl: {
                        hd: Css.fontWeight("bold"),
                        tl: {
                          hd: Css.padding(Css.rem(0.75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var controls = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("start"),
          tl: {
            hd: Css.marginTop(Css.rem(2)),
            tl: {
              hd: Css.child("*:last-child")({
                    hd: Css.flexGrow(0),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var invisibleInput = Curry._1(Css.style, {
      hd: Css.borderStyle("none"),
      tl: {
        hd: Css.background("none"),
        tl: {
          hd: Css.boxShadow("none"),
          tl: {
            hd: Css.outlineStyle("none"),
            tl: {
              hd: Css.unsafe("MozAppearance", "none"),
              tl: {
                hd: Css.unsafe("WebkitAppearance", "none"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.display("inlineFlex"),
                    tl: {
                      hd: Css.fontSize(Css.rem(1)),
                      tl: {
                        hd: Css.height(Css.em(2.5)),
                        tl: {
                          hd: Css.justifyContent("flexStart"),
                          tl: {
                            hd: Css.lineHeight(Css.em(1.5)),
                            tl: {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.verticalAlign("top"),
                                tl: {
                                  hd: Css.flexGrow(1),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tagsField = Curry._1(Css.merge, {
      hd: "field",
      tl: {
        hd: "input",
        tl: {
          hd: "is-grouped",
          tl: {
            hd: "is-grouped-multiline",
            tl: {
              hd: Curry._1(Css.style, {
                    hd: Css.important(Css.height("auto")),
                    tl: {
                      hd: Css.cursor("text"),
                      tl: {
                        hd: Css.child("div:last-child")({
                              hd: Css.flexGrow(1),
                              tl: {
                                hd: Css.maxWidth(Css.pct(100)),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.selector("input", {
                                          hd: Css.display("block"),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var addTagButtonContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.selector("button", {
              hd: Css.display("block"),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.rem(0)),
                  tl: {
                    hd: Css.top(Css.px(-40)),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var dropdownContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var xPadding = Curry._1(Css.style, {
      hd: Css.padding2(Css.rem(0), Css.rem(1)),
      tl: /* [] */0
    });

var Styles = {
  tagRow: tagRow,
  headerRow: headerRow,
  tagActions: tagActions,
  controls: controls,
  invisibleInput: invisibleInput,
  tagsField: tagsField,
  addTagButtonContainer: addTagButtonContainer,
  dropdownContainer: dropdownContainer,
  xPadding: xPadding
};

function Page_Tags_Listing(Props) {
  var tagTypes = Models_Tag$RecountTools.Kind.all(undefined);
  var match = React.useState(function () {
        
      });
  var selectTagType = match[1];
  var selectedTagType = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchInput = match$1[1];
  var searchInput = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSort = match$2[1];
  var sort = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var match$4 = React.useState(function () {
        return false;
      });
  var setNewTagModalOpen = match$4[1];
  var openModal = function (param) {
    return Curry._1(setNewTagModalOpen, (function (param) {
                  return true;
                }));
  };
  var match$5 = PaginationLogic$RecountTools.usePagination((function (cursor) {
          var tmp = searchInput === "" ? undefined : ({
                TAG: /* BySearch */1,
                _0: searchInput
              });
          TagStore$RecountTools.fetchBy(Arr$RecountTools.concatMany([
                    Option$RecountTools.toArray(Option$RecountTools.map(selectedTagType, (function (tt) {
                                return {
                                        TAG: /* ByTagType */0,
                                        _0: tt
                                      };
                              }))),
                    Option$RecountTools.toArray(tmp),
                    Option$RecountTools.toArray(Option$RecountTools.map(sort, (function (s) {
                                return {
                                        TAG: /* SortBy */3,
                                        _0: s
                                      };
                              }))),
                    Option$RecountTools.toArray({
                          TAG: /* ByCursor */2,
                          _0: cursor
                        })
                  ]));
          
        }), (function (cursor) {
          var tmp = searchInput === "" ? undefined : ({
                TAG: /* BySearch */1,
                _0: searchInput
              });
          return TagStore$RecountTools.getAllBy(Arr$RecountTools.concatMany([
                          Option$RecountTools.toArray(Option$RecountTools.map(selectedTagType, (function (tt) {
                                      return {
                                              TAG: /* ByTagType */0,
                                              _0: tt
                                            };
                                    }))),
                          Option$RecountTools.toArray(tmp),
                          Option$RecountTools.toArray(Option$RecountTools.map(sort, (function (s) {
                                      return {
                                              TAG: /* SortBy */3,
                                              _0: s
                                            };
                                    }))),
                          Option$RecountTools.toArray(Option$RecountTools.map(cursor, (function (c) {
                                      return {
                                              TAG: /* ByCursor */2,
                                              _0: c
                                            };
                                    })))
                        ]));
        }), 50);
  var clearPagination = match$5[3];
  var currentPageResults = match$5[1];
  var tmp = searchInput === "" ? undefined : ({
        TAG: /* BySearch */1,
        _0: searchInput
      });
  var query = Arr$RecountTools.concatMany([
        Option$RecountTools.toArray(Option$RecountTools.map(selectedTagType, (function (tt) {
                    return {
                            TAG: /* ByTagType */0,
                            _0: tt
                          };
                  }))),
        Option$RecountTools.toArray(tmp),
        Option$RecountTools.toArray(Option$RecountTools.map(match$5[2], (function (o) {
                    return {
                            TAG: /* ByCursor */2,
                            _0: o
                          };
                  }))),
        Option$RecountTools.toArray(Option$RecountTools.map(sort, (function (s) {
                    return {
                            TAG: /* SortBy */3,
                            _0: s
                          };
                  })))
      ]);
  React.useEffect((function () {
          TagStore$RecountTools.fetchBy(query);
          
        }), [
        selectedTagType,
        searchInput,
        sort
      ]);
  var closeModal = function (param) {
    Curry._1(clearPagination, undefined);
    Curry._1(setNewTagModalOpen, (function (param) {
            return false;
          }));
    return TagStore$RecountTools.fetchBy(query);
  };
  var handleClear = function (param) {
    Curry._1(clearPagination, undefined);
    return Curry._1(selectTagType, (function (param) {
                  
                }));
  };
  var onSearchInputChange = function (e) {
    Curry._1(clearPagination, undefined);
    e.preventDefault();
    var value = e.target.value;
    return Curry._1(setSearchInput, (function (param) {
                  return value;
                }));
  };
  var onSort = function (sort) {
    Curry._1(clearPagination, undefined);
    return Curry._1(setSort, (function (param) {
                  return sort;
                }));
  };
  var formatDate = (function(dateObj) {
      if (dateObj) {
        const date = new Date(dateObj).toLocaleDateString(undefined, { year: "numeric", month: "short", day: "numeric" })
        return date;
      }
    });
  var formatTime = (function(dateObj) {
      if (dateObj) {
        const time = new Date(dateObj).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: '2-digit' })
        return "at " + time;
      }
    });
  var columns = [
    {
      display: "Tag Name",
      value: "text",
      orderable: true
    },
    {
      display: "Tag Type",
      value: "tag_type_id",
      orderable: false
    },
    {
      display: "Created At",
      value: "created_at",
      orderable: true
    },
    {
      display: "",
      value: "edit",
      orderable: false
    }
  ];
  var renderTags = function (tags) {
    return Arr$RecountTools.map(tags, (function (t) {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: Models_Id$RecountTools.toString(t.id)
                            }, React.createElement("div", {
                                  className: tagRow
                                }, React.createElement("h2", {
                                      className: xPadding
                                    }, t.text), React.createElement("h2", {
                                      className: xPadding
                                    }, Models_Tag$RecountTools.Kind.toDisplay(t.kind)), React.createElement("h2", {
                                      className: xPadding
                                    }, formatDate(t.createdAt), React.createElement("br", undefined), formatTime(t.createdAt)), React.createElement("span", {
                                      className: tagActions + " " + xPadding
                                    }, React.createElement(Link$RecountTools.make, {
                                          href: "/tags/edit/" + Models_Id$RecountTools.toString(t.id),
                                          children: "Edit"
                                        }))), React.createElement("hr", {
                                  className: "hr"
                                }));
                }));
  };
  var tmp$1 = {
    label: "Tag Type",
    options: Arr$RecountTools.map(tagTypes, (function (t) {
            return {
                    label: Models_Tag$RecountTools.Kind.toDisplay(t),
                    value: t
                  };
          })),
    onSelect: (function (option) {
        Curry._1(clearPagination, undefined);
        return Curry._1(selectTagType, (function (param) {
                      return option;
                    }));
      }),
    onClear: handleClear
  };
  if (selectedTagType !== undefined) {
    tmp$1.value = Caml_option.valFromOption(selectedTagType);
  }
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: null,
              pageName: "Tags"
            }, React.createElement("div", {
                  className: "field"
                }, React.createElement("div", {
                      className: match$3[0] ? "control is-large is-loading" : "control"
                    }, React.createElement("div", {
                          className: tagsField
                        }, React.createElement("input", {
                              className: invisibleInput,
                              placeholder: "Search for tags",
                              type: "text",
                              value: searchInput,
                              onChange: onSearchInputChange
                            })))), React.createElement("div", {
                  className: controls
                }, React.createElement(DropDown$RecountTools.make, tmp$1)), React.createElement("div", {
                  className: addTagButtonContainer
                }, React.createElement("button", {
                      className: "button is-info",
                      onClick: openModal
                    }, "Add New Tag")), React.createElement(Page_Tags_AddTagForm$RecountTools.make, {
                  isOpen: match$4[0],
                  onClose: closeModal
                }), React.createElement("div", {
                  className: headerRow
                }, React.createElement(SortableHeaders$RecountTools.make, {
                      onSort: onSort,
                      initialSort: {
                        field: "text",
                        order: "asc"
                      },
                      columns: columns
                    })), React.createElement("hr", {
                  className: "hr"
                }), renderTags(currentPageResults !== undefined ? currentPageResults : []), Curry._1(match$5[0], undefined));
}

var AddTagForm;

var make = Page_Tags_Listing;

export {
  AddTagForm ,
  Styles ,
  make ,
  
}
/* tagRow Not a pure module */
