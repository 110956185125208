

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Arr$RecountTools from "../../util/Arr.bs.js";
import * as Link$RecountTools from "../../components/Link.bs.js";
import * as MicroObservables from "micro-observables";
import * as Layout$RecountTools from "../../layout/Layout.bs.js";
import * as Models_Id$RecountTools from "../../models/Models_Id.bs.js";
import * as SectionStore$RecountTools from "../../stores/SectionStore.bs.js";
import * as Page_Sections_AddForm$RecountTools from "./Page_Sections_AddForm.bs.js";

var uploadButtonContainer = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.selector("button", {
              hd: Css.display("block"),
              tl: {
                hd: Css.position("relative"),
                tl: {
                  hd: Css.marginLeft("auto"),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var sectionRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: /* [] */0
        }
      }
    });

var sectionActions = Curry._1(Css.style, {
      hd: Css.marginLeft("auto"),
      tl: {
        hd: Css.textAlign("right"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.selector("a", {
                    hd: Css.textTransform("uppercase"),
                    tl: {
                      hd: Css.fontSize(Css.rem(0.75)),
                      tl: {
                        hd: Css.fontWeight("bold"),
                        tl: {
                          hd: Css.padding(Css.rem(0.75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  uploadButtonContainer: uploadButtonContainer,
  sectionRow: sectionRow,
  sectionActions: sectionActions
};

function Page_Sections_Listing(Props) {
  var sections = MicroObservables.useObservable(SectionStore$RecountTools.all);
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var openModal = function (param) {
    return Curry._1(setModalOpen, (function (param) {
                  return true;
                }));
  };
  var closeModal = function (param) {
    return Curry._1(setModalOpen, (function (param) {
                  return false;
                }));
  };
  React.useEffect((function () {
          SectionStore$RecountTools.ensureFetched(undefined);
          
        }), []);
  var renderSections = function (param) {
    return Arr$RecountTools.map(sections, (function (s) {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: Models_Id$RecountTools.toString(s.id)
                            }, React.createElement("div", {
                                  className: sectionRow
                                }, React.createElement("h2", undefined, s.title), React.createElement("span", {
                                      className: sectionActions
                                    }, React.createElement(Link$RecountTools.make, {
                                          href: "/sections/edit/" + Models_Id$RecountTools.toString(s.id),
                                          children: "Edit"
                                        }))), React.createElement("hr", {
                                  className: "hr"
                                }));
                }));
  };
  return React.createElement(Layout$RecountTools.AppContainer.make, {
              children: null,
              pageName: "Sections"
            }, React.createElement("div", {
                  className: uploadButtonContainer
                }, React.createElement("button", {
                      className: "button is-info",
                      onClick: openModal
                    }, "Add a Section")), React.createElement(Page_Sections_AddForm$RecountTools.make, {
                  isOpen: match[0],
                  onClose: closeModal
                }), React.createElement("hr", {
                  className: "hr"
                }), renderSections(undefined));
}

var AddForm;

var make = Page_Sections_Listing;

export {
  AddForm ,
  Styles ,
  make ,
  
}
/* uploadButtonContainer Not a pure module */
