

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Form_CheckBox(Props) {
  var value = Props.value;
  var label = Props.label;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        return value;
      });
  var setState = match[1];
  var state = match[0];
  var handleChange = function (param) {
    Curry._1(onChange, !state);
    return Curry._1(setState, (function (state) {
                  return !state;
                }));
  };
  var tmp = {
    defaultChecked: state,
    type: "checkbox",
    onBlur: onBlur,
    onChange: handleChange
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", {
              key: label + "-field",
              className: "field"
            }, React.createElement("div", {
                  className: "control"
                }, React.createElement("label", {
                      className: "checkbox"
                    }, React.createElement("input", tmp), " ", label)));
}

var make = Form_CheckBox;

export {
  make ,
  
}
/* react Not a pure module */
