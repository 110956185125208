import ReactDOM from 'react-dom';
import { MenuItem, icons } from "prosemirror-menu";

import { make as LinkForm } from "./LinkForm.bs";

const markActive = (state, type) => {
  let { from, $from, to, empty } = state.selection
  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks());
  } else {
    return state.doc.rangeHasMark(from, to, type);
  }
};

// From https://discuss.prosemirror.net/t/updating-mark-attributes/776/4
// (EditorState, number, MarkType) => { from: int, to: int, mark: Mark }
const markAround = (state, pos, markType) => {
  const $pos = state.doc.resolve(pos);

  const { parent, parentOffset } = $pos;
  const start = parent.childAfter(parentOffset);
  if (!start.node) {
    return null;
  }

  const mark = start.node.marks.find((mark) => mark.type === markType);
  if (!mark) {
    return null;
  }

  let startIndex = $pos.index();
  let from = $pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let to = from + start.node.nodeSize;
  while (startIndex > 0 && mark.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1;
    from -= parent.child(startIndex).nodeSize;
  }
  while (endIndex < parent.childCount && mark.isInSet(parent.child(endIndex).marks)) {
    to += parent.child(endIndex).nodeSize;
    endIndex += 1;
  }
  return { from, to, mark };
};

const toggleLink = (markType) => new MenuItem({
  title: "Add or edit link",
  icon: icons.link,
  active(state) { return markActive(state, markType) },
  enable(state) {
    const inLink = (() => {
      if (!state.selection.empty) {
        return false;
      }
      const curNode = state.doc.nodeAt(state.selection.from);

      if (!curNode) {
        return false;
      }

      if (!markType.isInSet(curNode.marks)) {
        return false;
      }
      if (state.selection.from === 0) {
        return false;
      }
      const prevNode = state.doc.nodeAt(state.selection.from - 1);

      return prevNode === curNode;
    })();

    return !state.selection.empty || inLink;
  },
  run(state, dispatch, view) {
    let linkMark = null;
    let { from, to, empty } = state.selection;
    if (markActive(state, markType)) {
      let markAttempt = markAround(state, from, markType);
      if (markAttempt !== null && (empty || (markAttempt.from === from && markAttempt.to === to))) {
        ({ from, to, mark: linkMark } = markAttempt);
      }
    }
    const wrapper = document.body.appendChild(document.createElement("div"));
    const onClose = _ => {
      ReactDOM.unmountComponentAtNode(wrapper);
    }
    const onSubmit = (href, title) => {
      let tr = view.state.tr
      // Added mark will override the old one
      tr.addMark(from, to, markType.create({ href, title }))
      view.dispatch(tr.scrollIntoView())
      view.focus();
      ReactDOM.unmountComponentAtNode(wrapper);
    }
    let initialValues = undefined;
    let onDelete = undefined;
    if (linkMark !== null) {
      initialValues = [linkMark.attrs.href, linkMark.attrs.title];
      onDelete = () => {
        let tr = view.state.tr
        tr.removeMark(from, to, markType)
        view.dispatch(tr.scrollIntoView())
        view.focus();
        ReactDOM.unmountComponentAtNode(wrapper);
      }
    }

    ReactDOM.render(
      <LinkForm initialValues={initialValues} onDelete={onDelete} onClose={onClose} onSubmit={onSubmit} />,
      wrapper,
    );
  }
});

export { toggleLink };