

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Constants$RecountTools from "../util/Constants.bs.js";
import * as Models_Id$RecountTools from "./Models_Id.bs.js";
import * as Models_Tag$RecountTools from "./Models_Tag.bs.js";
import * as Models_Show$RecountTools from "./Models_Show.bs.js";
import * as Models_Caption$RecountTools from "./Models_Caption.bs.js";
import * as Models_Section$RecountTools from "./Models_Section.bs.js";
import * as Models_Rendition$RecountTools from "./Models_Rendition.bs.js";

function dateFromEpoch(json) {
  return new Date(Json_decode.$$float(json) * 1000);
}

function fromString(str) {
  switch (str) {
    case "draft" :
        return "draft";
    case "live" :
        return "live";
    case "pending" :
        return "pending";
    default:
      return ;
  }
}

function toString(status) {
  if (status === "draft") {
    return "draft";
  } else if (status === "pending") {
    return "pending";
  } else {
    return "live";
  }
}

function toDisplay(status) {
  if (status === "draft") {
    return "Draft";
  } else if (status === "pending") {
    return "Pending";
  } else {
    return "Published";
  }
}

function fromJson(json) {
  var str = Json_decode.string(json);
  var status = fromString(str);
  if (status !== undefined) {
    return status;
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Invalid Video.status: " + str,
        Error: new Error()
      };
}

var toJson = toString;

var Status = {
  fromString: fromString,
  toString: toString,
  toDisplay: toDisplay,
  fromJson: fromJson,
  toJson: toJson
};

function fromJson$1(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          externalId: Json_decode.field("external_id", Json_decode.string, json),
          meta: Belt_MapString.fromArray(Js_dict.entries(Json_decode.field("meta", (function (param) {
                          return Json_decode.dict(Json_decode.string, param);
                        }), json)))
        };
}

function toJson$1(v) {
  return Json_encode.object_({
              hd: [
                "type",
                v.type_
              ],
              tl: {
                hd: [
                  "externalId",
                  v.externalId
                ],
                tl: /* [] */0
              }
            });
}

var External = {
  fromJson: fromJson$1,
  toJson: toJson$1
};

function fromJson$2(json) {
  var thumbnailUrl = Json_decode.field("thumbnail_url", Json_decode.string, json);
  var renditions = Json_decode.withDefault([], (function (param) {
          return Json_decode.field("renditions", (function (param) {
                        return Json_decode.array(Models_Rendition$RecountTools.fromJson, param);
                      }), param);
        }), json);
  var thumbnailId = renditions.length === 0 ? /* ThumbnailProcessing */0 : (
      thumbnailUrl === "" ? /* NoThumbnail */1 : /* Thumbnail */({
            _0: thumbnailUrl
          })
    );
  var parseSearch = function (param) {
    return Json_decode.field("cloudflare_video_id", Json_decode.string, param);
  };
  var parseApi = function (json) {
    var renditions = Json_decode.field("renditions", (function (param) {
            return Json_decode.array(Models_Rendition$RecountTools.fromJson, param);
          }), json);
    var cloudflareRendition = Arr$RecountTools.find(renditions, (function (r) {
            return r.platform === "cloudflare";
          }));
    if (cloudflareRendition !== undefined) {
      return cloudflareRendition.jobId;
    }
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "Could not get cloudflareVideoId",
          Error: new Error()
        };
  };
  var cloudflareVideoId = Json_decode.optional(Json_decode.either(parseSearch, parseApi), json);
  var optionalDateFromEpoch = function (json) {
    var d = Json_decode.optional(Json_decode.$$float, json);
    return Option$RecountTools.flatMap(d, (function (d) {
                  var d$1 = d * 1000;
                  if (d$1 === 0) {
                    return ;
                  } else {
                    return Caml_option.some(new Date(d$1));
                  }
                }));
  };
  var article = Json_decode.field("article", (function (i) {
          return i;
        }), json);
  var article$1 = Caml_obj.caml_equal(article, null) ? undefined : Caml_option.some(article);
  return {
          id: Json_decode.field("id", Models_Id$RecountTools.fromJson, json),
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("project_id", Json_decode.string, param);
                }), json),
          status: Json_decode.field("status", fromJson, json),
          cloudflareVideoId: cloudflareVideoId,
          renditions: renditions,
          tags: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("tags", (function (param) {
                                return Json_decode.array(Models_Tag$RecountTools.fromJson, param);
                              }), param);
                }), json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          article: article$1,
          duration: Json_decode.withDefault(0, (function (param) {
                  return Json_decode.field("duration", Json_decode.$$int, param);
                }), json),
          title: Json_decode.field("title", Json_decode.string, json),
          thumbnailId: thumbnailId,
          url: Json_decode.optional((function (param) {
                  return Json_decode.field("url", Json_decode.string, param);
                }), json),
          slug: Json_decode.field("slug", Json_decode.string, json),
          show: Json_decode.either((function (json) {
                    return {
                            TAG: /* Resolved */0,
                            _0: Json_decode.field("show", Models_Show$RecountTools.fromJson, json)
                          };
                  }), (function (json) {
                    return {
                            TAG: /* Unresolved */1,
                            _0: Json_decode.field("show_id", Models_Id$RecountTools.fromJson, json)
                          };
                  }))(json),
          sections: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("sections", (function (param) {
                                return Json_decode.array(Models_Section$RecountTools.fromJson, param);
                              }), param);
                }), json),
          updatedAt: Json_decode.field("updated_at", dateFromEpoch, json),
          publishedAt: Json_decode.field("published_at", optionalDateFromEpoch, json),
          episode: Json_decode.optional((function (param) {
                  return Json_decode.field("episode_no", Json_decode.$$int, param);
                }), json),
          season: Json_decode.optional((function (param) {
                  return Json_decode.field("season_no", Json_decode.$$int, param);
                }), json),
          videoExternals: Json_decode.withDefault([], (function (param) {
                  return Json_decode.field("videoExternals", (function (param) {
                                return Json_decode.array(fromJson$1, param);
                              }), param);
                }), json),
          pinned: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("pinned", Json_decode.bool, param);
                }), json),
          rssYahoo: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("rssYahoo", Json_decode.bool, param);
                }), json),
          rssVideoElephant: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("rssVideoElephant", Json_decode.bool, param);
                }), json),
          rssMicrosoft: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("rssMicrosoft", Json_decode.bool, param);
                }), json),
          originalUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("original_url", Json_decode.string, param);
                }), json),
          caption: Json_decode.optional((function (param) {
                  return Json_decode.field("caption", Models_Caption$RecountTools.fromJson, param);
                }), json)
        };
}

function toJson$2(v) {
  var id = v.show;
  var show;
  show = id.TAG === /* Resolved */0 ? Models_Id$RecountTools.toJson(id._0.id) : Models_Id$RecountTools.toJson(id._0);
  var match = v.status;
  var status = match === "draft" ? "draft" : (
      match === "pending" ? "pending" : "live"
    );
  return Json_encode.object_({
              hd: [
                "id",
                Models_Id$RecountTools.toJson(v.id)
              ],
              tl: {
                hd: [
                  "status",
                  status
                ],
                tl: {
                  hd: [
                    "cloudflare_video_id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), v.cloudflareVideoId)
                  ],
                  tl: {
                    hd: [
                      "description",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), v.description)
                    ],
                    tl: {
                      hd: [
                        "duration",
                        v.duration
                      ],
                      tl: {
                        hd: [
                          "title",
                          v.title
                        ],
                        tl: {
                          hd: [
                            "url",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), v.url)
                          ],
                          tl: {
                            hd: [
                              "show",
                              show
                            ],
                            tl: {
                              hd: [
                                "episode_no",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), v.episode)
                              ],
                              tl: {
                                hd: [
                                  "season_no",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), v.season)
                                ],
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function publishStatus(video) {
  if (Models_Id$RecountTools.refAsId(video.show) === Constants$RecountTools.recountWire) {
    var match = video.status;
    if (match === "draft") {
      if (Option$RecountTools.isNone(video.publishedAt)) {
        return "draft";
      } else {
        return "pending";
      }
    } else if (match === "pending") {
      return "pending";
    } else if (Option$RecountTools.isNone(video.publishedAt)) {
      return "draft";
    } else {
      return "live";
    }
  }
  var match$1 = video.status;
  if (match$1 === "draft") {
    return "draft";
  } else if (match$1 === "pending" || Option$RecountTools.isNone(video.cloudflareVideoId)) {
    return "pending";
  } else {
    return "live";
  }
}

function preferredRendition(video) {
  return Option$RecountTools.orElse(Arr$RecountTools.find(video.renditions, (function (r) {
                    return r.platform === "normalized";
                  })), Arr$RecountTools.find(video.renditions, (function (r) {
                    return r.platform === "cloudflare";
                  })));
}

var Id;

var Caption;

var Show;

var Section;

var Tag;

var Rendition;

var Thumbnail;

export {
  Id ,
  Caption ,
  Show ,
  Section ,
  Tag ,
  Rendition ,
  Thumbnail ,
  Status ,
  External ,
  fromJson$2 as fromJson,
  toJson$2 as toJson,
  publishStatus ,
  preferredRendition ,
  
}
/* No side effect */
