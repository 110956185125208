

import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "name",
                v.name
              ],
              tl: {
                hd: [
                  "value",
                  v.value
                ],
                tl: /* [] */0
              }
            });
}

var Filter = {
  toJson: toJson
};

function toString(o) {
  if (o === "asc") {
    return "asc";
  } else {
    return "desc";
  }
}

var Order = {
  toString: toString
};

function toJson$1(v) {
  return Json_encode.object_({
              hd: [
                "field",
                v.field
              ],
              tl: {
                hd: [
                  "order",
                  toString(v.order)
                ],
                tl: /* [] */0
              }
            });
}

var Sort = {
  toJson: toJson$1
};

function toJson$2(v) {
  return Json_encode.object_({
              hd: [
                "text",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), v.text)
              ],
              tl: {
                hd: [
                  "filters",
                  Json_encode.array(toJson, v.filters)
                ],
                tl: {
                  hd: [
                    "sort",
                    Json_encode.nullable(toJson$1, v.sort)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

var empty_filters = [];

var empty = {
  text: undefined,
  filters: empty_filters,
  sort: undefined
};

var $$Request = {
  toJson: toJson$2,
  empty: empty
};

export {
  Filter ,
  Order ,
  Sort ,
  $$Request ,
  
}
/* No side effect */
