

import * as React from "react";
import * as ReactDom from "react-dom";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App$RecountTools from "./App.bs.js";
import * as Option$RecountTools from "./util/Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

Option$RecountTools.forEach(Caml_option.nullable_to_opt(document.querySelector("#root")), (function (div) {
        ReactDom.render(React.createElement(App$RecountTools.make, {}), div);
        
      }));

RescriptReactRouter.push("");

export {
  
}
/*  Not a pure module */
