

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProseMirror from "./ProseMirror";
import * as Option$RecountTools from "../../util/Option.bs.js";
import * as LocalStorage$RecountTools from "../../util/LocalStorage.bs.js";
import * as ConfirmationProvider$RecountTools from "../ConfirmationProvider.bs.js";

var main = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Css.hex("dbdbdb")),
      tl: {
        hd: Css.borderRadius(Css.px(4)),
        tl: {
          hd: Css.padding(Css.px(11)),
          tl: {
            hd: Css.margin2(Css.px(11), Css.px(0)),
            tl: {
              hd: Css.selector("h1", {
                    hd: Css.fontSize(Css.em(1.8)),
                    tl: {
                      hd: Css.margin2(Css.em(1), Css.em(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector("h2", {
                      hd: Css.fontSize(Css.em(1.3)),
                      tl: {
                        hd: Css.margin2(Css.em(1), Css.em(0)),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.selector(".word-count", {
                        hd: Css.padding2(Css.px(0), Css.px(8)),
                        tl: {
                          hd: Css.display("inlineBlock"),
                          tl: {
                            hd: Css.verticalAlign(Css.px(2)),
                            tl: {
                              hd: Css.lineHeight({
                                    NAME: "abs",
                                    VAL: 0.8
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  main: main
};

var article = ProseMirror.recountSchema;

function $$document(prim) {
  return ProseMirror.recountSchema.nodes.doc.create(undefined, prim);
}

function paragraph(prim) {
  return ProseMirror.recountSchema.nodes.paragraph.create(undefined, prim);
}

function text(prim) {
  return ProseMirror.recountSchema.text(prim);
}

var Article = {
  $$document: $$document,
  paragraph: paragraph,
  text: text
};

var Schema = {
  article: article,
  Article: Article
};

var toJson = (function(doc) { 
    const raw = doc.content.toJSON();
    if (!raw || raw.length === 1 && !raw[0].content) {
      return null;
    }
    // ProseMirror creates objects without prototypes which causes errors with rescript
    // https://github.com/rescript-lang/rescript-compiler/issues/5357
    // https://github.com/ProseMirror/prosemirror/issues/761
    const withPrototypes = JSON.parse(JSON.stringify(raw))
    return withPrototypes;
  });

var toPlainText = (function(doc) { return doc.textBetween(0, doc.content.size, '\n\n'); });

function fromJson(json) {
  var f = (function(d, j){ return d.nodeFromJSON({
      type: 'doc',
      content: j
    }); });
  return f(article, json);
}

var $$Document = {
  toJson: toJson,
  toPlainText: toPlainText,
  fromJson: fromJson
};

var make = ProseMirror.ProseMirror;

var Internal = {
  make: make
};

function ProseMirror$1(Props) {
  var $$document = Props.document;
  var autoSaveKey = Props.autoSaveKey;
  var prepopulated = Props.prepopulated;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var confirm = ConfirmationProvider$RecountTools.useConfirm(undefined);
  var match = React.useState(function () {
        return "pending";
      });
  var setDraftDecision = match[1];
  var draftDecision = match[0];
  var draft = Option$RecountTools.flatMap(autoSaveKey, LocalStorage$RecountTools.getJson);
  React.useEffect((function () {
          Option$RecountTools.forEach(draft, (function (doc) {
                  return Curry._5(confirm, /* Normal */0, (function (param) {
                                Curry._1(setDraftDecision, (function (param) {
                                        return "discard";
                                      }));
                                return Option$RecountTools.forEach(autoSaveKey, (function (prim) {
                                              localStorage.removeItem(prim);
                                              
                                            }));
                              }), "We found an in-progress draft for this video. Would you like to restore it?", (function (param) {
                                Curry._1(setDraftDecision, (function (param) {
                                        return {
                                                NAME: "use",
                                                VAL: doc
                                              };
                                      }));
                                return Option$RecountTools.forEach(onChange, (function (o) {
                                              return Curry._1(o, fromJson(doc));
                                            }));
                              }), undefined);
                }));
          
        }), []);
  return React.useMemo((function () {
                var exit = 0;
                if (draft !== undefined) {
                  if (draftDecision === "pending") {
                    return null;
                  }
                  exit = 2;
                } else {
                  exit = 2;
                }
                if (exit === 2 && typeof draftDecision === "object" && draftDecision.NAME === "use") {
                  var tmp = {
                    document: fromJson(draftDecision.VAL),
                    className: main
                  };
                  if (autoSaveKey !== undefined) {
                    tmp.autoSaveKey = autoSaveKey;
                  }
                  if (prepopulated !== undefined) {
                    tmp.prepopulated = Caml_option.valFromOption(prepopulated);
                  }
                  if (onChange !== undefined) {
                    tmp.onChange = Caml_option.valFromOption(onChange);
                  }
                  if (onBlur !== undefined) {
                    tmp.onBlur = Caml_option.valFromOption(onBlur);
                  }
                  return React.createElement(make, tmp);
                }
                var tmp$1 = {
                  className: main
                };
                if ($$document !== undefined) {
                  tmp$1.document = Caml_option.valFromOption($$document);
                }
                if (autoSaveKey !== undefined) {
                  tmp$1.autoSaveKey = autoSaveKey;
                }
                if (prepopulated !== undefined) {
                  tmp$1.prepopulated = Caml_option.valFromOption(prepopulated);
                }
                if (onChange !== undefined) {
                  tmp$1.onChange = Caml_option.valFromOption(onChange);
                }
                if (onBlur !== undefined) {
                  tmp$1.onBlur = Caml_option.valFromOption(onBlur);
                }
                return React.createElement(make, tmp$1);
              }), [draftDecision]);
}

var make$1 = ProseMirror$1;

export {
  Styles ,
  Schema ,
  $$Document ,
  Internal ,
  make$1 as make,
  
}
/* main Not a pure module */
