

import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Loadable$RecountTools from "../util/Loadable.bs.js";
import * as Ordering$RecountTools from "../util/Ordering.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Observable$RecountTools from "../util/Observable.bs.js";
import * as Models_Cursor$RecountTools from "../models/Models_Cursor.bs.js";
import * as Models_PodcastEpisode$RecountTools from "../models/Models_PodcastEpisode.bs.js";

var rawPodcasts = MicroObservables.observable(undefined);

var indices = MicroObservables.observable(undefined);

function makeIndexKey(query) {
  return Arr$RecountTools.join(Arr$RecountTools.sort(Arr$RecountTools.map(query, (function (qp) {
                        if (qp.TAG === /* ByShow */0) {
                          return "byshow:" + Models_Id$RecountTools.toString(qp._0);
                        } else {
                          return "bycursor:" + Models_Cursor$RecountTools.toString(qp._0);
                        }
                      })), Ordering$RecountTools.string), "/");
}

var defaultIncludes = "podcastShow";

function get(id) {
  return rawPodcasts.transform(function (ps) {
              var p = Belt_MapInt.get(ps, Models_Id$RecountTools.toInt(id));
              if (p !== undefined) {
                return p;
              } else {
                return /* NotLoaded */0;
              }
            });
}

function getAll(ids) {
  return rawPodcasts.transform(function (ps) {
              return Loadable$RecountTools.all(Arr$RecountTools.keepMap(ids, (function (id) {
                                return Belt_MapInt.get(ps, Models_Id$RecountTools.toInt(id));
                              })));
            });
}

function getAllBy(query) {
  return Observable$RecountTools.Writable.from(rawPodcasts, indices).transform(function (param) {
              var dict = param[0];
              var page = Belt_MapString.get(param[1], makeIndexKey(query));
              if (page !== undefined) {
                return {
                        data: Arr$RecountTools.keepMap(page.ids, (function (id) {
                                return Option$RecountTools.flatMap(Belt_MapInt.get(dict, Models_Id$RecountTools.toInt(id)), Loadable$RecountTools.toOption);
                              })),
                        pagination: page.pagination
                      };
              }
              
            });
}

function add(p) {
  rawPodcasts.update(function (dict) {
        return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(p.id), /* Loaded */{
                    _0: p
                  });
      });
  
}

function addAll(ps) {
  rawPodcasts.update(function (dict) {
        return Arr$RecountTools.reduce(ps, dict, (function (acc, p) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(p.id), /* Loaded */{
                                  _0: p
                                });
                    }));
      });
  
}

function update(id, fn) {
  rawPodcasts.update(function (dict) {
        return Belt_MapInt.update(dict, Models_Id$RecountTools.toInt(id), (function (__x) {
                      return Option$RecountTools.map(__x, (function (__x) {
                                    return Loadable$RecountTools.map(__x, fn);
                                  }));
                    }));
      });
  
}

function fetchOne(id) {
  var params = [[
      "include",
      defaultIncludes
    ]];
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_PodcastEpisode$RecountTools.fromJson,
        toJson: Models_PodcastEpisode$RecountTools.toJson
      });
  Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, params, undefined, undefined, {
                TAG: /* Api */0,
                _0: "/v2/podcast-episodes/" + Models_Id$RecountTools.toString(id)
              }), (function (req) {
              if (req.status === 404) {
                return Promise.resolve(/* NotFound */1);
              } else {
                return Async$RecountTools.map(Async$RecountTools.map(req.json(), R.fromJson), (function (resp) {
                              return /* Loaded */{
                                      _0: resp.data
                                    };
                            }));
              }
            })), (function (ep) {
          rawPodcasts.update(function (dict) {
                return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(id), ep);
              });
          
        }));
  
}

function fetchBy(query) {
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: Models_PodcastEpisode$RecountTools.fromJson,
        toJson: Models_PodcastEpisode$RecountTools.toJson
      });
  var params = Arr$RecountTools.map(query, (function (qp) {
          if (qp.TAG === /* ByShow */0) {
            return [
                    "filter[podcast_show_id]",
                    Models_Id$RecountTools.toString(qp._0)
                  ];
          } else {
            return [
                    "page[cursor]",
                    Models_Cursor$RecountTools.toString(qp._0)
                  ];
          }
        }));
  var params$1 = Arr$RecountTools.concat(params, [
        [
          "include",
          defaultIncludes
        ],
        [
          "page[size]",
          "150"
        ]
      ]);
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, params$1, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/v2/podcast-episodes"
                  }), (function (prim) {
                  return prim.json();
                })), $$Response.Page.fromJson), (function (resp) {
          addAll(resp.data);
          indices.update(function (dict) {
                return Belt_MapString.set(dict, makeIndexKey(query), {
                            ids: Arr$RecountTools.map(resp.data, (function (v) {
                                    return v.id;
                                  })),
                            pagination: {
                              first: resp.pagination.first,
                              next: resp.pagination.next,
                              prev: resp.pagination.prev,
                              last: resp.pagination.last,
                              total: resp.meta.total
                            }
                          });
              });
          
        }));
  
}

var OW;

var IMap;

var SMap;

export {
  OW ,
  IMap ,
  SMap ,
  get ,
  getAll ,
  getAllBy ,
  add ,
  addAll ,
  update ,
  fetchOne ,
  fetchBy ,
  
}
/* rawPodcasts Not a pure module */
