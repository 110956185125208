


function toOption(thumbnail) {
  if (typeof thumbnail === "number") {
    return ;
  } else {
    return thumbnail._0;
  }
}

export {
  toOption ,
  
}
/* No side effect */
