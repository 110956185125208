

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactDom from "react-dom";
import * as Int$RecountTools from "../util/Int.bs.js";

var modalContext = React.createContext(function (param) {
      
    });

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = modalContext.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function Modal(Props) {
  var isActive = Props.isActive;
  var children = Props.children;
  var onModalCloseOpt = Props.onModalClose;
  var hardOpt = Props.hard;
  var onModalClose = onModalCloseOpt !== undefined ? onModalCloseOpt : (function (param) {
        
      });
  var hard = hardOpt !== undefined ? hardOpt : false;
  var modalRef = React.useRef(null);
  React.useEffect(function () {
        var keyDownListener = function (e) {
          var keyCodeStr = Int$RecountTools.toString(undefined, e.keyCode);
          if (keyCodeStr === "27") {
            return Curry._1(onModalClose, undefined);
          }
          
        };
        document.addEventListener("keydown", keyDownListener);
        return (function (param) {
                  document.removeEventListener("keyDown", keyDownListener);
                  
                });
      });
  var modal;
  if (!isActive && hard) {
    modal = null;
  } else {
    var modalClass = isActive ? "modal is-active" : "modal";
    modal = React.createElement("div", {
          "aria-modal": true,
          className: modalClass,
          role: "dialog"
        }, React.createElement("div", {
              className: "modal-background",
              onClick: (function (param) {
                  return Curry._1(onModalClose, undefined);
                })
            }), React.createElement("div", {
              ref: modalRef,
              className: "modal-content"
            }, React.createElement(make, makeProps(onModalClose, children, undefined))));
  }
  return ReactDom.createPortal(modal, document.body);
}

function Modal$Header(Props) {
  var children = Props.children;
  var onModalClose = React.useContext(modalContext);
  return React.createElement("header", {
              className: "modal-card-head"
            }, React.createElement("p", {
                  className: "modal-card-title"
                }, children), React.createElement("button", {
                  "aria-label": "close",
                  className: "delete",
                  type: "button",
                  onClick: (function (param) {
                      return Curry._1(onModalClose, undefined);
                    })
                }));
}

var Header = {
  make: Modal$Header
};

function Modal$Body(Props) {
  var children = Props.children;
  return React.createElement("section", {
              className: "modal-card-body"
            }, children);
}

var Body = {
  make: Modal$Body
};

var footer = Curry._1(Css.merge, {
      hd: "modal-card-foot",
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("flexEnd"),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  footer: footer
};

function Modal$Footer(Props) {
  var children = Props.children;
  return React.createElement("footer", {
              className: footer
            }, children);
}

var Footer = {
  Styles: Styles,
  make: Modal$Footer
};

var make$1 = Modal;

export {
  modalContext ,
  ContextProvider ,
  make$1 as make,
  Header ,
  Body ,
  Footer ,
  
}
/* modalContext Not a pure module */
