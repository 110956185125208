

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Arr$RecountTools from "./Arr.bs.js";
import * as Int$RecountTools from "./Int.bs.js";
import * as Option$RecountTools from "./Option.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Observable$RecountTools from "./Observable.bs.js";
import * as Pagination$RecountTools from "../components/Pagination.bs.js";
import * as Models_Cursor$RecountTools from "../models/Models_Cursor.bs.js";

function usePagination(fetchPage, getPage, pageSize) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var parseInitialParamsFromUrl = function (param) {
    var params = new URLSearchParams(url.search);
    var value = params.get("cursor");
    var cursor = !(value == null) ? value : undefined;
    var value$1 = params.get("page");
    var page = !(value$1 == null) ? Int$RecountTools.fromString(value$1) : undefined;
    return [
            cursor,
            page
          ];
  };
  var match = parseInitialParamsFromUrl(undefined);
  var initialPage = match[1];
  var initialCursor = match[0];
  var match$1 = React.useState(function () {
        return initialCursor;
      });
  var setCursor = match$1[1];
  var cursor = match$1[0];
  var match$2 = React.useState(function () {
        return Option$RecountTools.getWithDefault(initialPage, 0);
      });
  var setCurrentPage = match$2[1];
  var currentPage = match$2[0];
  var loadedCursors = React.useRef(undefined);
  var currentApiPage = Observable$RecountTools.useMemoizedObservable(Curry._1(getPage, cursor));
  var pagination = Option$RecountTools.map(currentApiPage, (function (a) {
          return a.pagination;
        }));
  var currentPageResults = Option$RecountTools.map(currentApiPage, (function (page) {
          var subPage = currentPage % 3;
          return Arr$RecountTools.slice(page.data, Math.imul(subPage, pageSize), pageSize);
        }));
  React.useEffect((function () {
          var path = Arr$RecountTools.join(Belt_List.toArray(url.path), "/");
          var params = new URLSearchParams(url.search);
          if (cursor !== undefined) {
            params.set("cursor", Models_Cursor$RecountTools.toString(cursor));
          } else {
            params.delete("cursor");
          }
          params.set("page", Int$RecountTools.toString(undefined, currentPage));
          RescriptReactRouter.push(path + "?" + params.toString());
          
        }), [
        cursor,
        currentPage
      ]);
  React.useEffect((function () {
          var cursors = currentApiPage !== undefined ? [
              currentApiPage.pagination.first,
              currentApiPage.pagination.last,
              currentApiPage.pagination.prev,
              currentApiPage.pagination.next
            ] : [];
          Arr$RecountTools.map(cursors, (function (cursor) {
                  if (!Belt_SetString.has(loadedCursors.current, cursor === undefined ? "" : Models_Cursor$RecountTools.toString(Option$RecountTools.getExn(cursor)))) {
                    Option$RecountTools.forEach(cursor, fetchPage);
                    loadedCursors.current = Belt_SetString.add(loadedCursors.current, cursor === undefined ? "" : Models_Cursor$RecountTools.toString(Option$RecountTools.getExn(cursor)));
                    return ;
                  }
                  
                }));
          
        }), [currentApiPage]);
  var handlePaginationClick = function (pageNum) {
    return Option$RecountTools.forEach(currentApiPage, (function (page) {
                  Curry._1(setCurrentPage, (function (param) {
                          return pageNum;
                        }));
                  var apiPageSize = page.data.length;
                  var currentApiPosition = Math.floor(currentPage / (apiPageSize / pageSize));
                  var nextApiPosition = Math.floor(pageNum / (apiPageSize / pageSize));
                  var lastPage = Caml_int32.div(page.pagination.total, pageSize) - 1 | 0;
                  if (pageNum === lastPage || pageNum === (lastPage - 1 | 0) || pageNum === (lastPage - 2 | 0)) {
                    return Curry._1(setCursor, (function (param) {
                                  return page.pagination.last;
                                }));
                  } else if (pageNum === 0 || pageNum === 1 || pageNum === 2) {
                    return Curry._1(setCursor, (function (param) {
                                  return page.pagination.first;
                                }));
                  } else if (nextApiPosition > currentApiPosition) {
                    return Curry._1(setCursor, (function (param) {
                                  return page.pagination.next;
                                }));
                  } else if (nextApiPosition < currentApiPosition) {
                    return Curry._1(setCursor, (function (param) {
                                  return page.pagination.prev;
                                }));
                  } else {
                    return ;
                  }
                }));
  };
  var clearPagination = function (param) {
    loadedCursors.current = undefined;
    Curry._1(setCursor, (function (param) {
            
          }));
    return Curry._1(setCurrentPage, (function (param) {
                  return 0;
                }));
  };
  var component = function (param) {
    if (pagination !== undefined) {
      return React.createElement(Pagination$RecountTools.make, {
                  onClick: handlePaginationClick,
                  total: pagination.total,
                  pageSize: pageSize,
                  currentPage: currentPage
                });
    } else {
      return null;
    }
  };
  return [
          component,
          currentPageResults,
          cursor,
          clearPagination
        ];
}

var Cursor;

var Search;

export {
  Cursor ,
  Search ,
  usePagination ,
  
}
/* react Not a pure module */
