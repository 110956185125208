

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Arr$RecountTools from "../util/Arr.bs.js";
import * as MicroObservables from "micro-observables";
import * as Async$RecountTools from "../util/Async.bs.js";
import * as Option$RecountTools from "../util/Option.bs.js";
import * as Request$RecountTools from "../util/Request.bs.js";
import * as Models_Id$RecountTools from "../models/Models_Id.bs.js";
import * as Models_User$RecountTools from "../models/Models_User.bs.js";

var rawCurrentUser = MicroObservables.observable(/* NotLoaded */0);

var rawUsers = MicroObservables.observable(undefined);

function getCurrent(param) {
  return rawCurrentUser.readOnly();
}

function get(id) {
  return rawUsers.transform(function (vs) {
              var match = Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id));
              if (match === undefined) {
                return /* NotLoaded */0;
              }
              var v = Caml_option.valFromOption(match);
              if (v !== undefined) {
                return /* Loaded */{
                        _0: v
                      };
              } else {
                return /* NotFound */1;
              }
            });
}

function getAll(ids) {
  return rawUsers.transform(function (vs) {
              return Arr$RecountTools.keepMap(ids, (function (id) {
                            return Option$RecountTools.flatten(Belt_MapInt.get(vs, Models_Id$RecountTools.toInt(id)));
                          }));
            });
}

var all = rawUsers.transform(function (user) {
      return Belt_MapInt.valuesToArray(user).flatMap(Option$RecountTools.toArray);
    });

function addAll(usersArray) {
  rawUsers.update(function (dict) {
        return Arr$RecountTools.reduce(usersArray, dict, (function (acc, currentUser) {
                      return Belt_MapInt.set(acc, Models_Id$RecountTools.toInt(currentUser.id), currentUser);
                    }));
      });
  
}

function update(id, fn) {
  rawUsers.update(function (dict) {
        return Belt_MapInt.update(dict, Models_Id$RecountTools.toInt(id), (function (__x) {
                      return Option$RecountTools.map(__x, (function (__x) {
                                    return Option$RecountTools.map(__x, fn);
                                  }));
                    }));
      });
  
}

function fetchCurrent(param) {
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_User$RecountTools.fromJson,
        toJson: Models_User$RecountTools.toJson
      });
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/auth/current-user"
                  }), (function (prim) {
                  return prim.json();
                })), (function (json) {
              return Curry._1(R.fromJson, json).data;
            })), (function (currentUser) {
          rawCurrentUser.set(/* Loaded */{
                _0: currentUser
              });
          
        }));
  
}

function fetchAll(param) {
  var fromJson = function (param) {
    return Json_decode.array(Models_User$RecountTools.fromJson, param);
  };
  var toJson = function (param) {
    return Json_encode.array(Models_User$RecountTools.toJson, param);
  };
  var $$Response = Request$RecountTools.ApiEnvelope({
        fromJson: fromJson,
        toJson: toJson
      });
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/v2/users"
                  }), (function (prim) {
                  return prim.json();
                })), $$Response.fromJson), (function (resp) {
          return addAll(resp.data);
        }));
  
}

function fetchOne(id) {
  var R = Request$RecountTools.ApiEnvelope({
        fromJson: Models_User$RecountTools.fromJson,
        toJson: Models_User$RecountTools.toJson
      });
  Async$RecountTools.map(Async$RecountTools.map(Async$RecountTools.flatMap(Request$RecountTools.get(undefined, undefined, undefined, undefined, {
                    TAG: /* Api */0,
                    _0: "/v2/users/" + Models_Id$RecountTools.toString(id)
                  }), (function (prim) {
                  return prim.json();
                })), (function (json) {
              return Curry._1(R.fromJson, json).data;
            })), (function (u) {
          rawUsers.update(function (dict) {
                return Belt_MapInt.set(dict, Models_Id$RecountTools.toInt(id), u);
              });
          
        }));
  
}

function ensureFetchedCurrent(param) {
  var match = rawCurrentUser.get();
  if (match === 0) {
    return fetchCurrent(undefined);
  }
  
}

function ensureFetched(param) {
  var match = Belt_MapInt.valuesToArray(rawUsers.get());
  if (match.length !== 0) {
    return ;
  } else {
    return fetchAll(undefined);
  }
}

var OW;

var IMap;

export {
  OW ,
  IMap ,
  getCurrent ,
  get ,
  getAll ,
  all ,
  addAll ,
  update ,
  fetchCurrent ,
  fetchAll ,
  fetchOne ,
  ensureFetchedCurrent ,
  ensureFetched ,
  
}
/* rawCurrentUser Not a pure module */
