

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function handleClickOutside(domElement, e, fn) {
  var targetElement = e.target;
  if (!domElement.contains(targetElement)) {
    return Curry._1(fn, e);
  }
  
}

function useClickOutside(onClickOutside) {
  var elementRef = React.useRef(null);
  var handleMouseDown = function (e) {
    Belt_Option.map(Caml_option.nullable_to_opt(elementRef.current), (function (refValue) {
            return handleClickOutside(refValue, e, onClickOutside);
          }));
    
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", handleMouseDown);
          return (function (param) {
                    document.removeEventListener("mousedown", handleMouseDown);
                    
                  });
        }), []);
  return elementRef;
}

export {
  handleClickOutside ,
  useClickOutside ,
  
}
/* react Not a pure module */
